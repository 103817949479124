import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSpeedThirtyOneToFortyKmH = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        {active ? (
          <>
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M133.37,115.75a128.23,128.23,0,0,1,29.7-12.06"
            />
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M149.65,144.8A95.9,95.9,0,0,1,164.73,138"
            />
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M166.27,174.44c1-.58,2.08-1.12,3.13-1.64"
            />
          </>
        ) : (
          <>
            <path
              style={{
                fill: "#137F7B",
                stroke:"#137F7B",
                strokeWidth: "1.24px",
              }}
              d="M179,197.81l-62.83-112A163.31,163.31,0,0,1,170.3,67l7.89,49.6Z"
            />
          </>
        )}

        <path
          style={{
            fill: "none",
            stroke: "#332c2b",
            strokeWidth: "6.9px",
          }}
          d="M7.19,228C7.19,123.77,91.7,39.26,196,39.26A188.78,188.78,0,0,1,287.59,63"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M7.19,228C7.19,123.77,91.7,39.26,196,39.26a188.8,188.8,0,0,1,96.38,26.46"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M32.86,222.36A5.66,5.66,0,1,1,27.2,228,5.66,5.66,0,0,1,32.86,222.36Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M42.89,165.93a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,42.89,165.93Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M71.92,116.52a5.66,5.66,0,1,1-5.67,5.66A5.66,5.66,0,0,1,71.92,116.52Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M116.18,80.12a5.67,5.67,0,1,1-5.67,5.66A5.66,5.66,0,0,1,116.18,80.12Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M170.29,61.29A5.67,5.67,0,1,1,164.63,67,5.67,5.67,0,0,1,170.29,61.29Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M227.59,62.38A5.66,5.66,0,1,1,221.93,68,5.66,5.66,0,0,1,227.59,62.38Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M281,83.2a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,281,83.2Z"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.94px",
          }}
          x1="26.04"
          y1="261.2"
          x2="279.39"
          y2="261.23"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M206.44,210.42a20.5,20.5,0,1,1-25.83,4L176,99.66l26.89,97.93"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M190.69,234a8,8,0,1,1,11.26-.75"
        />
      </g>
    </SvgIcon>
  );
};

export default IconSpeedThirtyOneToFortyKmH;
