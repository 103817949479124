import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconScheidung = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59">
        <g>
          <path
            fill="none"
            d="M141.875,144.316h10.486c5.486,0,5.764-6.229,5.764-7.479v-25.479V93.313V78.401h-16.25v2.929v18.046V144.316
			z"
          />
          <g>
            <path
              fill="#137F7B"
              d="M135.553,46.59h28.622c4.696,0,9.449-2.903,9.449-8.45v-2.115c0-5.547-4.753-8.45-9.449-8.45h-28.622
				c-4.697,0-9.451,2.903-9.451,8.45v2.115C126.102,43.688,130.856,46.59,135.553,46.59z"
            />
            <polygon fill="#137F7B" points="109.836,90.354 116.708,97.607 138.335,97.607 138.335,83.1 116.708,83.1 			" />
            <polygon fill="#137F7B" points="183.291,95.082 161.663,95.082 161.663,109.589 183.291,109.589 190.163,102.335 			" />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M238.271,204.473c-2.214,0-4.008,1.794-4.008,4.008v58.377c0,2.279-1.855,4.133-4.135,4.133H69.875
				c-2.281,0-4.136-1.854-4.136-4.133V95.951c0-2.214-1.794-4.008-4.008-4.008s-4.008,1.794-4.008,4.008v170.908
				c0,6.7,5.452,12.15,12.153,12.15h160.253c6.7,0,12.151-5.45,12.151-12.15v-58.377
				C242.28,206.267,240.485,204.473,238.271,204.473z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M230.128,33.066h-51.422c-0.086,0-0.167,0.02-0.251,0.025c-1.364-6.247-7.046-10.666-14.28-10.666
				h-28.622c-7.225,0-12.901,4.408-14.275,10.641H69.875c-6.701,0-12.153,5.452-12.153,12.153v35.867
				c0,2.214,1.794,4.008,4.008,4.008s4.008-1.794,4.008-4.008V45.219c0-2.281,1.855-4.136,4.136-4.136h51.398
				c1.368,6.242,7.048,10.657,14.28,10.657h28.622c7.24,0,12.926-4.427,14.285-10.682c0.083,0.005,0.162,0.025,0.247,0.025h51.422
				c2.28,0,4.135,1.855,4.135,4.136v148.015c0,2.214,1.794,4.008,4.008,4.008s4.008-1.794,4.008-4.008V45.219
				C242.28,38.518,236.829,33.066,230.128,33.066z M164.174,46.59h-28.622c-4.697,0-9.451-2.903-9.451-8.45v-2.115
				c0-5.547,4.754-8.45,9.451-8.45h28.622c4.696,0,9.449,2.903,9.449,8.45v2.115C173.624,43.688,168.87,46.59,164.174,46.59z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M90.129,192.409h116.619c1.422,0,2.575-1.153,2.575-2.575s-1.153-2.575-2.575-2.575H90.129
				c-1.422,0-2.575,1.153-2.575,2.575S88.708,192.409,90.129,192.409z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.555,172.725c0,1.422,1.153,2.575,2.575,2.575h116.619c1.422,0,2.575-1.153,2.575-2.575
				s-1.153-2.575-2.575-2.575H90.129C88.708,170.15,87.555,171.303,87.555,172.725z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M196.129,223.519h-95.267c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575h95.267
				c1.422,0,2.575-1.153,2.575-2.575S197.551,223.519,196.129,223.519z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M196.129,205.067h-95.267c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575h95.267
				c1.422,0,2.575-1.153,2.575-2.575S197.551,205.067,196.129,205.067z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M159.894,74.862h-19.789c-0.978,0-1.77,0.792-1.77,1.77v2.929h-22.389c-0.486,0-0.951,0.2-1.285,0.552
				l-8.549,9.023c-0.646,0.683-0.646,1.752,0,2.435l8.549,9.023c0.334,0.353,0.799,0.552,1.285,0.552h22.389v44.939
				c0,0.978,0.792,1.77,1.77,1.77h12.255c6.426,0,9.303-5.534,9.303-11.018v-23.709h22.389c0.486,0,0.951-0.2,1.285-0.552
				l8.549-9.023c0.646-0.683,0.646-1.752,0-2.435l-8.549-9.023c-0.334-0.353-0.799-0.552-1.285-0.552h-22.389V76.632
				C161.663,75.654,160.871,74.862,159.894,74.862z M161.663,109.589V95.082h21.627l6.872,7.253l-6.872,7.254H161.663z
				 M138.335,83.1v14.507h-21.627l-6.872-7.253l6.872-7.254H138.335z M158.124,136.838c0,1.249-0.278,7.479-5.764,7.479h-10.486
				V99.377V81.331v-2.929h16.25v14.911v18.046V136.838z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconScheidung;
