import Group from "../../../Wizard/Group";
import Grid from "@mui/material/Grid";
import PersonIcon from "../../../../assets/icons/provision/toggleable/IconPerson";
import FamilyIcon from "../../../../assets/icons/generic/toggleable/IconFamilie";
import TwoPersons from "../../../../assets/icons/common/toggleable/IconZweiPersonen";
import MenuItem from "@mui/material/MenuItem";
import { requiredValidator } from "../../../../services/validationRules";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { useForm } from "../../../../provider/Form/FormProvider";
import Person from "../Person/Person";
import { useEffect } from "react";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type AdditionalClientsFormProps = {
  allowThreeOrMoreClients?: boolean;
  product: AbstractCase;
};

export default function AdditionalClientsForm({ allowThreeOrMoreClients = false, product }: AdditionalClientsFormProps) {
  const { values, handleChange } = useForm();
  const howManyClientsOptions = [
    {
      labelText: "Ich habe den Vertrag alleine abgeschlossen",
      labelIcon: <PersonIcon />,
      labelIconActive: <PersonIcon active />,
      value: "oneClient",
    },
    {
      labelText: "Ich habe den Vertrag gemeinsam mit einer anderen Person (Ehepartner, Geschwister etc.) abgeschlossen",
      labelIcon: <TwoPersons />,
      labelIconActive: <TwoPersons active />,
      value: "twoClients",
    },
  ];
  if (allowThreeOrMoreClients) {
    howManyClientsOptions.push({
      labelText: "Ich habe den Vertrag gemeinsam mit mehreren anderen Personen abgeschlossen (Wohngemeinschaft)",
      labelIcon: <FamilyIcon />,
      labelIconActive: <FamilyIcon active />,
      value: "threeOrMoreClients",
    });
  }
  useEffect(() => {
    if (values.contractorTypeClient !== "customer" || values.howManyClients === "oneClient") {
      handleChange({
        target: {
          name: "additionalClients",
          value: [],
        },
      });
      return;
    }
    if (values.howManyClients === "twoClients" && !values.additionalClients?.length) {
      handleChange({
        target: {
          name: "additionalClients",
          value: product.additionalClients?.length
            ? product.additionalClients.slice(0, 1)
            : [
                {
                  givenName: "",
                  familyName: "",
                  gender: "",
                },
              ],
        },
      });
    }
    if (values.howManyClients === "threeOrMoreClients" || values.howManyClients === "twoClients") {
      const numberOfExistingClients = values.additionalClients?.length || 0;
      const numberOfAdditionalClients = values.howManyClients === "threeOrMoreClients" ? parseInt(values.numberOfAdditionalClients ?? 0) : 1;
      if (numberOfAdditionalClients < numberOfExistingClients) {
        handleChange({
          target: {
            name: "additionalClients",
            value: values.additionalClients.slice(0, numberOfAdditionalClients),
          },
        });
        return;
      }
      if (numberOfAdditionalClients > numberOfExistingClients) {
        const additionalClients = Array.from({ length: numberOfAdditionalClients - numberOfExistingClients }, () => ({
          givenName: "",
          familyName: "",
          gender: "",
        }));
        handleChange({
          target: {
            name: "additionalClients",
            value: values.additionalClients.concat(additionalClients),
          },
        });
      }
    }
  }, [values.howManyClients, values.contractorTypeClient, values.numberOfAdditionalClients]);

  return (
    <>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="howManyClients"
          question={"Haben Sie den Vertrag alleine unterzeichnet / abgeschlossen oder zusammen mit einer anderen Person, wie zum Beispiel Ihrem (Ehe-)Partner?"}
          options={howManyClientsOptions}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.howManyClients === "threeOrMoreClients" && (
        <>
          <Grid item xs={12}>
            <ValidatorSelectField
              label="Mit wie vielen anderen Personen haben Sie den Vertrag geschlossen?"
              name="numberOfAdditionalClients"
              validators={[requiredValidator("Bitte geben Sie an, mit wie vielen anderen Personen Sie den Vertrag geschlossen haben.")]}
            >
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
              <MenuItem value={"5"}>5</MenuItem>
              <MenuItem value={"6"}>6</MenuItem>
              <MenuItem value={"7"}>7</MenuItem>
              <MenuItem value={"8"}>8</MenuItem>
              <MenuItem value={"9"}>9</MenuItem>
            </ValidatorSelectField>
          </Grid>
          {values.numberOfAdditionalClients &&
            [...Array(parseInt(values.numberOfAdditionalClients)).keys()].map((i) => (
              <Person
                path={`additionalClients[${i}]`}
                key={i}
                headline={`Bitte machen Sie hier weitere Angaben zu der ${i + 1}. Person, die mit Ihnen den Vertrag abgeschlossen hat.`}
                showDiverseGenderOption
              />
            ))}
        </>
      )}
      {values.howManyClients === "twoClients" && (
        <Person path="additionalClients[0]" headline="Bitte machen Sie hier weitere Angaben zu der Person, die mit Ihnen den Vertrag abgeschlossen hat." />
      )}
    </>
  );
}
