import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconWritingWbs = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.28px", fillRule: "evenodd" }}
          d="M229.9,48.64l17.76,17.77a8.81,8.81,0,0,1,0,12.41L106.14,220.34l-5.34,5.34M60.93,205.19,217.48,48.64a8.82,8.82,0,0,1,12.42,0"
        />
        <polyline
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.28px", fillRule: "evenodd" }}
          points="72.09 216.35 74.29 218.56 91.11 235.37 49.11 247.19 60.93 205.19"
        />
        <line style={{ stroke: "#ff3c1f", strokeWidth: "11.04px" }} x1="95.68" y1="200.62" x2="219.12" y2="77.18" />
        <path style={{ fill: "#ff3c1f", stroke: "#ff3c1f", strokeWidth: "5.52px" }} d="M125.78,238.64a8,8,0,1,1-8,8A8,8,0,0,1,125.78,238.64Z" />
        <path style={{ fill: "#ff3c1f", stroke: "#ff3c1f", strokeWidth: "5.52px" }} d="M158.66,238.64a8,8,0,1,1-8,8A8,8,0,0,1,158.66,238.64Z" />
        <path style={{ fill: "#ff3c1f", stroke: "#ff3c1f", strokeWidth: "5.52px" }} d="M191.53,238.64a8,8,0,1,1-8,8A8,8,0,0,1,191.53,238.64Z" />
        <path style={{ fill: "#ff3c1f", stroke: "#ff3c1f", strokeWidth: "5.52px" }} d="M224.41,238.64a8,8,0,1,1-8,8A8,8,0,0,1,224.41,238.64Z" />
      </g>
    </SvgIcon>
  );
};

export default IconWritingWbs;
