import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSpeedMoreThanFortyKmH = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        {active ? (
          <>
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M176,100.94a128.51,128.51,0,0,1,71.73,9.18"
            />
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M181.23,133.83a95.07,95.07,0,0,1,45.88,4"
            />
            <path
              style={{
                fill: "none",
                strokeLinecap: "round",
                strokeWidth: "6.9",
                fillRule: "evenodd",
                stroke: getIconColor(active, theme),
              }}
              d="M186.57,167.38A61.35,61.35,0,0,1,209,168"
            />
          </>
        ) : (
          <>
            <path
              style={{
                fill: "#137F7B",
                stroke: "#137F7B",
                strokeWidth: "1.24px",
              }}
              d="M192.53,206.46,170.31,66.94A163.1,163.1,0,0,1,281,88.84l-26.13,42.77Z"
            />
          </>
        )}

        <path
          style={{
            fill: "none",
            stroke: "#332c2b",
            strokeWidth: "6.9px",
          }}
          d="M7.19,228C7.19,123.76,91.7,39.24,196,39.24A188.76,188.76,0,0,1,287.61,63"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M7.19,228C7.19,123.76,91.7,39.24,196,39.24A188.81,188.81,0,0,1,292.35,65.7"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M32.86,222.35A5.67,5.67,0,1,1,27.2,228,5.65,5.65,0,0,1,32.86,222.35Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M42.89,165.92a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,42.89,165.92Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M71.92,116.5a5.67,5.67,0,1,1-5.66,5.67A5.67,5.67,0,0,1,71.92,116.5Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M116.18,80.1a5.67,5.67,0,1,1-5.66,5.67A5.67,5.67,0,0,1,116.18,80.1Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M170.31,61.27a5.67,5.67,0,1,1-5.67,5.67A5.66,5.66,0,0,1,170.31,61.27Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M227.61,62.36A5.66,5.66,0,1,1,222,68,5.65,5.65,0,0,1,227.61,62.36Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M281,83.18a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,281,83.18Z"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.94px",
          }}
          x1="26.04"
          y1="261.2"
          x2="279.41"
          y2="261.22"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M215.37,221.44a20.5,20.5,0,1,1-22.25-13.73l71-90.27L221,209.39"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M188.06,229.17a8,8,0,1,1,9.05,6.74"
        />
      </g>
    </SvgIcon>
  );
};

export default IconSpeedMoreThanFortyKmH;
