import React from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import CheckedIcon from "../../../assets/icons/generic/IconCheck";

type UploadCompletedProps = {
  product: AbstractCase;
};

export default function UploadCompleted({ product }: UploadCompletedProps) {
  return (
    <Stack alignItems={"center"} spacing={3}>
      <CheckedIcon sx={{ height: "100px", width: "100px" }} />
      <Typography>Ihre Dokumente wurden erfolgreich an unseren Kundenservice übermittelt.</Typography>
      <Button fullWidth={false} component={Link} to={getCaseLink(product)} variant={"contained"}>
        Zurück zur Servicewelt
      </Button>
    </Stack>
  );
}
