import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconPhone = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40">
        <path
          style={{ fill: theme.palette.iconColor.main }}
          d="M277.966,216.85c-7.721-5.36-43.101-28.24-50.841-32.28c-3.38-1.77-7.03-2.66-10.86-2.66
		c-7.06,0-14.899,2.87-25.43,9.31c-6.199,3.79-10.14,6.21-12.609,7.72c-8.291-6.69-29.11-23.66-41.88-35.48
		c-12.63-11.67-29.38-32.61-35.85-40.87c1.49-2.48,3.88-6.46,7.68-12.79c6.73-11.23,12.75-24.32,6.33-36.35
		c-4.09-7.67-27.28-42.86-32.71-50.55c-4.48-6.34-9.67-9.43-15.87-9.43c-4.29,0-8.79,1.51-14.19,4.75
		c-9.42,5.64-20.16,16.1-21.92,17.84c-2.72,2.27-17.34,15.1-17.34,29.54c0,13.3,14.27,75.81,84.09,138.75
		c68.22,61.49,124.12,82.18,139.14,82.18h0.16c14.44-0.13,27.149-14.86,29.38-17.6c1.721-1.77,12.09-12.6,17.66-22.07
		C285.655,242.19,294.015,227.97,277.966,216.85z M271.646,240.24c-5.131,8.72-15.83,19.67-15.94,19.78l-0.45,0.5
		c-2.66,3.34-11.97,12.88-19.51,12.95h-0.04c-11.01,0-64.47-19.4-130.39-78.82c-65.58-59.12-79.78-117.71-79.78-129.05
		c0-7.48,9.48-16.91,12.79-19.62l0.5-0.45c0.11-0.11,10.92-10.89,19.63-16.11c3.19-1.92,5.7-2.89,7.47-2.89c0.73,0,2.45,0,5.2,3.9
		c5.51,7.81,28.29,42.48,31.86,49.16c1.88,3.53,2.64,9.08-6.01,23.49c-8.53,14.22-9.97,16.61-9.97,16.61l-2.28,3.8l2.68,3.52
		c0.95,1.24,23.33,30.57,40.07,46.04c16.5,15.26,44.959,38,46.161,38.96l3.58,2.86l3.909-2.4c0.011,0,2.39-1.46,16.53-10.11
		c8.13-4.97,14.22-7.39,18.609-7.39c1.75,0,3.271,0.37,4.811,1.17c6.75,3.53,41.609,26,49.439,31.44
		C274.524,230.35,276.386,232.18,271.646,240.24z"
        />
      </g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconPhone;
