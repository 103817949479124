import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconIncomingCall = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <rect x="67.64" y="79.63" width="23.83" height="60.77" transform="translate(-54.73 90.03) rotate(-45.66)" style={{ fill: "#137F7B" }} />
          <rect x="181.86" y="191.79" width="23.83" height="60.77" transform="translate(-100.55 205.49) rotate(-45.66)" style={{ fill: "#137F7B" }} />
        </>
      )}

      <path
        d="M221.11,232.66l-38-38a6.41,6.41,0,0,0-9,0l-4.89,4.9a32.89,32.89,0,0,0-5.87,8,33.57,33.57,0,0,0-3.08,22.68c-22.14,2.86-42.8-15.68-57.36-30.24S69.76,164.7,72.63,142.56a33.52,33.52,0,0,0,22.68-3.08,33,33,0,0,0,8-5.86l4.89-4.9a6.41,6.41,0,0,0,0-9l-38-38a6.39,6.39,0,0,0-9,0l-4.9,4.89c-10.05,10.06-15,20.87-17,31.43-7.43,39.56,15.72,76.15,42.57,103C100.13,239.34,123,256,148.27,262.27c2.68.67,11.43,3.38,17.82,3.16m18.71-1.86c10.55-2,21.36-6.93,31.42-17l4.89-4.89a6.39,6.39,0,0,0,0-9"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8.28px",
          fillRule: "evenodd",
        }}
      />

      <polyline
        points="225.48 142.62 154.12 142.62 154.12 71.26"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "8.28px",
          fillRule: "evenodd",
        }}
      />

      <path
        d="M178.19,107.28a8,8,0,1,1,0,11.27A8,8,0,0,1,178.19,107.28Z"
        style={{
          fill: active ? theme.palette.primary.main : "#137F7B",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "5.52px",
          fillRule: "evenodd",
        }}
      />
      <path
        d="M201.44,84a8,8,0,1,1,0,11.27A8,8,0,0,1,201.44,84Z"
        style={{
          fill: active ? theme.palette.primary.main : "#137F7B",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "5.52px",
          fillRule: "evenodd",
        }}
      />
      <path
        d="M224.69,60.78a8,8,0,1,1,0,11.27A8,8,0,0,1,224.69,60.78Z"
        style={{
          fill: active ? theme.palette.primary.main : "#137F7B",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "5.52px",
          fillRule: "evenodd",
        }}
      />
      <path
        d="M247.94,37.53a8,8,0,1,1,0,11.27A8,8,0,0,1,247.94,37.53Z"
        style={{
          fill: active ? theme.palette.primary.main : "#137F7B",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "5.52px",
          fillRule: "evenodd",
        }}
      />
    </SvgIcon>
  );
};

export default IconIncomingCall;
