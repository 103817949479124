import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAnwalt = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77">
        <g>
          <path
            style={{ fill: "none" }}
            d="M188.266,213.419c-0.095,2.274,1.307,3.704,1.739,4.088c1.857,1.65,5.948,4.585,9.558,7.174
			c2.012,1.445,3.941,2.828,5.55,4.029c2.105-1.97,3.264-3.989,3.344-5.915c0.094-2.274-1.308-3.704-1.74-4.088
			c-1.857-1.651-5.949-4.586-9.559-7.176c-2.012-1.444-3.94-2.827-5.548-4.028C189.504,209.474,188.346,211.494,188.266,213.419z"
          />
          <path
            style={{ fill: "none" }}
            d="M202.956,121.905V76.693c-8.079,10.002-21.103,16.332-35.178,16.332
			c-17.855,0-33.724-9.753-40.425-24.847c-0.758-1.704,0.011-3.698,1.716-4.456c1.705-0.756,3.7,0.012,4.457,1.716
			c5.62,12.655,19.064,20.833,34.252,20.833c16.031,0,30.401-9.52,35.168-23.2c-0.214-26.862-21.916-46.318-51.771-46.318
			c-29.998,0-51.771,19.647-51.771,46.716v23.236c6.753-0.718,12.55-5.064,15.084-11.499c0.684-1.736,2.646-2.588,4.38-1.904
			c1.735,0.683,2.588,2.644,1.905,4.379c-3.56,9.037-11.816,15.079-21.369,15.816v28.406c0,23.146,16.508,41.556,41.079,45.808
			c6.633,1.149,13.819,1.197,20.32,0.172C186.02,163.981,202.956,145.503,202.956,121.905z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M148.795,186.31c1.852,0.28,3.78,0.131,5.428-0.418c2.997-0.951,4.649-2.831,4.854-3.476v-7.486
			c-2.578,0.293-5.225,0.444-7.901,0.444c-3.034,0-6.055-0.196-9.017-0.58v7.623c0.203,0.645,1.85,2.523,4.812,3.464l0.003,0.001
			C147.564,186.074,148.176,186.217,148.795,186.31z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M131.426,265.015c3.387,3.043,14.404,12.997,19.186,17.977
			c4.782-4.979,15.798-14.934,19.186-17.977l-16.563-74.265c-0.004,0-0.008,0-0.012,0c-0.338,0.054-0.683,0.089-1.03,0.123
			c-0.085,0.007-0.168,0.022-0.254,0.028c-0.429,0.034-0.864,0.05-1.303,0.05c-0.003,0-0.006,0.001-0.009,0.001
			c-0.005,0-0.01,0-0.015,0c-0.438,0-0.875-0.018-1.308-0.052c-0.024-0.002-0.049-0.007-0.074-0.008
			c-0.405-0.034-0.807-0.081-1.205-0.144c-0.012-0.002-0.023-0.002-0.034-0.003L131.426,265.015z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M150.627,190.954c-0.441,0-0.883-0.018-1.323-0.052c0.434,0.035,0.87,0.052,1.308,0.052
			C150.617,190.954,150.622,190.954,150.627,190.954z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M238.839,202.66c1.823-0.391,2.985-2.186,2.595-4.009c-0.741-3.462-1.606-6.314-2.571-8.48
				c-4.691-10.502-10.591-18.393-24.14-22.763c-7.924-2.557-15.773-6.153-20.417-8.424c9.74-9.477,15.405-22.407,15.405-37.079
				V63.469C209.71,32.986,184.546,10,151.175,10C117.811,10,92.65,32.986,92.65,63.469v58.436c0,14.074,5.273,26.611,14.389,36.007
				c-4.866,2.564-13.506,6.837-21.747,9.496c-13.553,4.372-19.453,12.261-24.141,22.763c-8.119,18.184-6.573,82.401-6.503,85.126
				c0.047,1.835,1.549,3.291,3.374,3.291c0.029,0,0.059,0,0.088,0c1.864-0.048,3.338-1.598,3.29-3.462
				c-0.018-0.653-1.583-65.399,5.919-82.201c4.169-9.339,8.786-15.456,20.046-19.089c10.243-3.304,20.745-8.784,25.018-11.12
				c6.973,5.463,15.541,9.392,25.271,11.33v8.456c0,2.454,2.529,5.254,6.02,6.907l-17.217,77.203l0.984,0.879
				c5.125,4.581,18.96,17.152,21.4,20.256l1.77,2.253l1.771-2.253c2.439-3.104,16.274-15.675,21.399-20.256l0.985-0.879
				l-17.218-77.204c3.5-1.656,6.031-4.455,6.031-6.906v-8.246c9.61-1.739,18.106-5.387,25.107-10.522
				c3.954,2.02,13.756,6.81,23.962,10.102c11.258,3.632,15.876,9.75,20.047,19.087c0.773,1.735,1.491,4.138,2.134,7.142
				c0.339,1.586,1.74,2.671,3.299,2.671C238.362,202.735,238.601,202.711,238.839,202.66z M169.797,265.015
				c-3.387,3.043-14.404,12.997-19.186,17.977c-4.781-4.979-15.798-14.934-19.186-17.977l16.564-74.27
				c0.011,0.001,0.022,0.001,0.034,0.003c0.398,0.063,0.8,0.11,1.205,0.144c0.025,0.001,0.05,0.006,0.074,0.008
				c0.44,0.035,0.882,0.052,1.323,0.052c0.003,0,0.006-0.001,0.009-0.001c0.439,0,0.874-0.016,1.303-0.05
				c0.085-0.006,0.168-0.021,0.254-0.028c0.347-0.033,0.692-0.068,1.03-0.123c0.004,0,0.008,0,0.012,0L169.797,265.015z
				 M159.076,182.417c-0.204,0.645-1.857,2.524-4.854,3.476c-1.648,0.549-3.576,0.698-5.428,0.418
				c-0.619-0.093-1.23-0.236-1.823-0.428l-0.003-0.001c-2.962-0.94-4.609-2.819-4.812-3.464v-7.623
				c2.962,0.384,5.983,0.58,9.017,0.58c2.676,0,5.323-0.151,7.901-0.444V182.417z M160.803,167.885
				c-6.501,1.024-13.687,0.977-20.32-0.172c-24.571-4.252-41.079-22.662-41.079-45.808V93.499
				c9.553-0.738,17.809-6.78,21.369-15.816c0.683-1.735-0.169-3.697-1.905-4.379c-1.734-0.685-3.696,0.168-4.38,1.904
				c-2.534,6.434-8.331,10.78-15.084,11.499V63.469c0-27.069,21.772-46.716,51.771-46.716c29.856,0,51.558,19.456,51.771,46.318
				c-4.767,13.681-19.137,23.2-35.168,23.2c-15.188,0-28.633-8.178-34.252-20.833c-0.756-1.705-2.752-2.473-4.457-1.716
				c-1.705,0.758-2.474,2.752-1.716,4.456c6.701,15.094,22.569,24.847,40.425,24.847c14.075,0,27.099-6.33,35.178-16.332v45.211
				C202.956,145.503,186.02,163.981,160.803,167.885z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M243.624,213.476c-0.188-1.854-1.851-3.21-3.701-3.018c-1.855,0.188-3.207,1.846-3.019,3.701
				c2.582,25.385,1.719,60.611,1.71,60.965c-0.048,1.864,1.425,3.414,3.29,3.462c0.029,0,0.058,0,0.087,0
				c1.825,0,3.328-1.455,3.374-3.291C245.375,274.94,246.25,239.292,243.624,213.476z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M211.205,213.66c-2.121-1.885-6.183-4.799-10.11-7.617c-2.685-1.925-5.22-3.745-6.938-5.089
				c-0.94-0.736-1.229-2.713-0.646-4.407c0.395-1.145,1.488-3.064,4.38-3.064c5.598,0,7.747,6.569,7.844,6.874
				c0.548,1.774,2.429,2.773,4.209,2.233c1.784-0.542,2.791-2.429,2.248-4.214c-0.145-0.476-3.665-11.647-14.301-11.647
				c-5.021,0-9.146,2.918-10.766,7.616c-0.943,2.736-0.868,5.623,0.063,8.037c-4.428,4.058-5.556,8.012-5.67,10.758
				c-0.147,3.539,1.349,7.059,4.001,9.416c2.12,1.885,6.181,4.797,10.107,7.614c2.686,1.926,5.222,3.746,6.94,5.092
				c0.94,0.735,1.23,2.713,0.646,4.407c-0.395,1.144-1.488,3.064-4.38,3.064c-5.601,0-7.75-6.579-7.844-6.874
				c-0.548-1.775-2.429-2.772-4.208-2.232c-1.785,0.542-2.791,2.429-2.248,4.213c0.145,0.476,3.665,11.646,14.301,11.646
				c5.021,0,9.146-2.918,10.766-7.616c0.943-2.736,0.869-5.623-0.062-8.035c4.427-4.059,5.556-8.012,5.67-10.758
				C215.353,219.538,213.857,216.018,211.205,213.66z M205.112,228.71c-1.608-1.201-3.538-2.584-5.55-4.029
				c-3.609-2.589-7.7-5.523-9.558-7.174c-0.432-0.384-1.834-1.813-1.739-4.088c0.08-1.926,1.238-3.945,3.344-5.916
				c1.608,1.201,3.536,2.584,5.548,4.028c3.61,2.59,7.702,5.524,9.559,7.176c0.432,0.384,1.834,1.814,1.74,4.088
				C208.376,224.721,207.218,226.741,205.112,228.71z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAnwalt;
