import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSeller = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 64 64">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M32,6.5c0.8,0,1.5-0.7,1.5-1.5S32.8,3.5,32,3.5S30.5,4.2,30.5,5S31.2,6.5,32,6.5z M32,4.5
	c0.3,0,0.5,0.2,0.5,0.5S32.3,5.5,32,5.5S31.5,5.3,31.5,5S31.7,4.5,32,4.5z"
        />
        <circle cx="36" cy="5" r="1" />
        <circle cx="28" cy="5" r="1" />
        <path
          d="M44,0H20c-2.8,0-5,2.2-5,5v7.6l-7.7,7.7l0,0c-0.1,0.1-0.1,0.2-0.2,0.3v0.1c0,0.1,0,0.2-0.1,0.3L7,21v4.8
	c0,2.2,1.6,4,3.8,4.2c1.2,0.1,2.4-0.4,3.2-1.4c0.3,0.3,0.6,0.6,1,0.8V43c-1.1,0-2,0.9-2,2v17c0,1.1,0.9,2,2,2h34c1.1,0,2-0.9,2-2V45
	c0-1.1-0.9-2-2-2V29.5c0.4-0.2,0.7-0.5,1-0.8c0.8,0.9,2,1.4,3.2,1.3c2.2-0.2,3.8-2,3.8-4.2V21c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.3
	v-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l0,0L49,12.6V5C49,2.2,46.8,0,44,0z M49.5,26.7c-0.4,0.1-0.7,0.3-1,0.6c-0.8,0.8-2.1,0.9-2.9,0.1
	c0,0,0,0-0.1-0.1c-0.3-0.3-0.6-0.5-1-0.6V22h5V26.7z M37.5,43v-3c-0.1-1.3-0.7-2.5-1.8-3.2c-0.1-0.1-0.2-0.2-0.2-0.4V36
	c0-0.8-0.7-1.5-1.5-1.5h-4c-0.8,0-1.5,0.7-1.5,1.5v0.5c0,0.2-0.1,0.3-0.2,0.4c-0.9,0.6-1.6,1.6-1.8,2.7l0,0v-2.1
	c0.8-0.3,1.2-1.1,0.9-1.9c-0.3-0.8-1.1-1.2-1.9-0.9s-1.2,1.1-0.9,1.9c0.2,0.4,0.5,0.8,0.9,0.9v2.2c-0.6,0.2-1,0.8-1,1.4v2H21V29.4
	c1.6,1,3.7,0.6,5-0.8c1.5,1.7,4,1.8,5.6,0.4c0.1-0.1,0.2-0.2,0.4-0.4c1.5,1.7,4,1.8,5.6,0.4c0.1-0.1,0.2-0.2,0.4-0.4
	c1.3,1.4,3.4,1.8,5,0.8V43H37.5z M26,36.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S26.3,36.5,26,36.5z M35.1,37.7
	c0.7,0.4,1.1,1.1,1.3,1.8h-8.9c0.2-0.8,0.7-1.4,1.3-1.8c0.4-0.3,0.6-0.7,0.6-1.2V36c0-0.3,0.2-0.5,0.5-0.5h4c0.3,0,0.5,0.2,0.5,0.5
	v0.5C34.5,36.9,34.7,37.4,35.1,37.7z M27.5,43v-2.5h9V43H27.5z M26,40.5h0.5V43h-1v-2C25.5,40.7,25.7,40.5,26,40.5z M15.5,27.3
	c-0.3-0.3-0.6-0.5-1-0.6V22h5v4.7c-0.4,0.1-0.7,0.3-1,0.6C17.7,28.1,16.4,28.2,15.5,27.3C15.5,27.4,15.5,27.3,15.5,27.3z M22.9,14
	l-3,6h-4.6l4.5-6H22.9z M39.9,14l3,6h-4.7l-1.5-6H39.9z M43.5,26.7c-0.4,0.1-0.7,0.3-1,0.6c-0.8,0.8-2.1,0.9-2.9,0.1
	c0,0,0,0-0.1-0.1c-0.3-0.3-0.6-0.5-1-0.6V22h5V26.7z M20.5,26.7V22h5v4.7c-0.4,0.1-0.7,0.3-1,0.6c-0.8,0.8-2.1,0.9-2.9,0.1
	c0,0,0,0-0.1-0.1C21.2,27,20.9,26.8,20.5,26.7L20.5,26.7z M26.5,22h5v4.7c-0.4,0.1-0.7,0.3-1,0.6c-0.8,0.8-2.1,0.9-2.9,0.1
	c0,0,0,0-0.1-0.1c-0.3-0.3-0.6-0.5-1-0.6V22z M32.5,22h5v4.7c-0.4,0.1-0.7,0.3-1,0.6c-0.8,0.8-2.1,0.9-2.9,0.1c0,0,0,0-0.1-0.1
	c-0.3-0.3-0.6-0.5-1-0.6V22z M35.7,14l1.5,6h-4.7v-6L35.7,14z M31.5,20h-4.7l1.5-6h3.2V20z M25.7,20h-4.7l3-6h3.2L25.7,20z M44.1,20
	l-3-6h3.1l4.5,6H44.1z M17,12V5.5h3c1,0,2,0.5,2.6,1.3c0.8,1,2,1.6,3.4,1.7h12c1.3,0,2.5-0.6,3.3-1.7C42,6,43,5.5,44,5.5h3V12H17z
	 M20,2h24c1.5,0,2.8,1,3,2.5h-3c-1.3,0-2.5,0.6-3.3,1.7C40,7,39,7.5,38,7.5H26c-1,0-2-0.5-2.6-1.3c-0.8-1-2-1.6-3.4-1.7h-3
	C17.3,3.1,18.5,2,20,2z M16.4,14h2.2l-4.5,6h-3.7L16.4,14z M10.9,28C9.8,27.9,9,26.9,9,25.8V22h4.5v4.7c-0.4,0.1-0.7,0.3-1,0.6
	C12.1,27.8,11.5,28,10.9,28z M17,30c0.3,0,0.5,0,0.8-0.1h0.1c0.2-0.1,0.5-0.1,0.7-0.2l0.1-0.1c0.1,0,0.2-0.1,0.3-0.1V43h-2V30z
	 M49,62h-3.5v-9c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v9h-25v-3c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v3H15V45h3.5v11
	c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V45h25v5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-5H49V62z M47,43h-2V29.5
	c0.1,0.1,0.2,0.1,0.3,0.1l0.1,0.1c0.2,0.1,0.5,0.2,0.7,0.2h0.2C46.5,30,46.7,30,47,30V43z M55,25.8c0,1.1-0.8,2-1.9,2.2
	c-0.6,0-1.2-0.2-1.6-0.7c-0.3-0.3-0.6-0.5-1-0.6V22H55V25.8z M49.9,20l-4.5-6h2.2l6,6H49.9z"
        />
        <path
          d="M39,52h-2.3l-4.4-4.3c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0L27.3,52H25c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h14
	c1.1,0,2-0.9,2-2v-4C41,52.9,40.1,52,39,52z M32,48.7l3.3,3.3h-6.6L32,48.7z M25,58v-4h14v4H25z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconSeller;
