import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDistanceThreeToTen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        {!active && (
          <>
            <path
              d="M4.55,237.24H24.67V237c0-12.27,10.34-22.22,23.08-22.22S70.83,224.7,70.83,237v.26H72.3c6.46,0,19.69-3.19,19.69-11.16V205.62H4.55Z"
              style={{ fill: "#137F7B" }}
            />
            <path
              d="M167.53,237.27h22.28V237a22.69,22.69,0,0,1,45.37,0v.27h59.71V205.64H173.94C168.73,213.23,168.31,223.14,167.53,237.27Z"
              style={{ fill: "#137F7B" }}
            />
          </>
        )}
        <path
          d="M91.36,49.85l16.21-24.4H70v8.46H91L75.33,56.75l4.46,3.77c9.2-4.6,17.86-.09,17.86,11.23,0,9.2-6.29,14.71-13.3,14.71a13.71,13.71,0,0,1-11.38-6l-9.11,3.94a25.48,25.48,0,0,0,21,10.49,22.85,22.85,0,0,0,23-23.17c0-12.15-6.47-19.14-16.4-21.9Zm31.42,61.64,31.33-86h-9.47l-31.42,86Zm58-86H160.48v8.46h10.2V94.46h10.11v-69ZM236,71.64V48.27C235.8,33.08,226.24,25,212.48,25S189,33.08,189,48.27V71.64c0,15.28,9.74,23.28,23.49,23.28s23.32-8,23.5-23.28Zm-10.11-23V71.27c0,11-5.65,15.19-13.39,15.19S199.1,82.32,199.1,71.27V48.64c0-11,5.64-15.19,13.38-15.19S225.87,37.6,225.87,48.64Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <path
          d="M91.36,49.85l16.21-24.4H70v8.46H91L75.33,56.75l4.46,3.77c9.2-4.6,17.86-.09,17.86,11.23,0,9.2-6.29,14.71-13.3,14.71a13.71,13.71,0,0,1-11.38-6l-9.11,3.94a25.48,25.48,0,0,0,21,10.49,22.85,22.85,0,0,0,23-23.17c0-12.15-6.47-19.14-16.4-21.9Zm31.42,61.64,31.33-86h-9.47l-31.42,86Zm58-86H160.48v8.46h10.2V94.46h10.11v-69ZM236,71.64V48.27C235.8,33.08,226.24,25,212.48,25S189,33.08,189,48.27V71.64c0,15.28,9.74,23.28,23.49,23.28s23.32-8,23.5-23.28Zm-10.11-23V71.27c0,11-5.65,15.19-13.39,15.19S199.1,82.32,199.1,71.27V48.64c0-11,5.64-15.19,13.38-15.19S225.87,37.6,225.87,48.64Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <line x1="4.55" y1="275.53" x2="294.89" y2="275.56" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.92", fill: "none" }} />

        <path
          d="M4.55,237.24h8.18M83,235.4c4.76-1.73,9-4.73,9-9.32V185.5H4.55"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }}
        />
        <path d="M70.23,244.55a22.16,22.16,0,1,1,0-15.16" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />

        <path
          d="M167.53,237.27h21.82V237m58.88.27h46.66m0-51.74H219.17c-50,10.26-50,21-51.64,51.74"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }}
        />
        <line x1="226.04" y1="185.53" x2="257.6" y2="156.35" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <path d="M232.38,244.57a22.15,22.15,0,1,1,0-15.15" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <line
          x1="116.29"
          y1="137.4"
          x2="116.32"
          y2="262.63"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.7" }}
        />
        <line
          x1="131.86"
          y1="137.4"
          x2="131.89"
          y2="262.63"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.7" }}
        />
        <line
          x1="147.43"
          y1="137.4"
          x2="147.46"
          y2="262.63"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.7" }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconDistanceThreeToTen;
