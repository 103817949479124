import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconAlcoholAndDrugs = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}>
        {!active && (
          <>
            <line
              style={{
                stroke: "#137F7B",
                strokeWidth: "6.58px",
              }}
              x1="159.54"
              y1="140.78"
              x2="268.99"
              y2="31.39"
            />
            <line
              style={{
                stroke: "#137F7B",
                strokeWidth: "6.58px",
              }}
              x1="30.48"
              y1="269.48"
              x2="140.12"
              y2="159.92"
            />
          </>
        )}
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
            fillRule: "evenodd",
          }}
          d="M59.61,172.07v28.24A25.76,25.76,0,0,0,85.3,226h0A25.77,25.77,0,0,0,111,200.31V172.07a.07.07,0,0,0-.07-.07H71"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
          }}
          x1="85.29"
          y1="257.39"
          x2="85.3"
          y2="235.43"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
          }}
          x1="69.59"
          y1="257.4"
          x2="100.98"
          y2="257.37"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
            fillRule: "evenodd",
          }}
          d="M75.16,292.43a77.88,77.88,0,0,1-66.91-67.2c-5.6-42.74,24.35-81.95,66.91-87.57a77.77,77.77,0,0,1,87.19,67.19c5.6,42.74-24.35,82-66.91,87.58"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
            fillRule: "evenodd",
          }}
          d="M204.12,163.46a77.88,77.88,0,0,1-66.91-67.19c-5.6-42.74,24.35-81.95,66.91-87.58a77.78,77.78,0,0,1,87.19,67.2c5.61,42.74-24.35,82-66.9,87.57"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
          }}
          d="M195.5,125.54c-8.85,4-18.24,4.65-25.83,2.48,3-6.9,9.39-13.34,18.24-17.32a41.75,41.75,0,0,1,14.67-3.63,57.58,57.58,0,0,1-22-7.69c-11.32-6.66-19.49-16.12-23.16-25.58,9.8-1.55,21.88.9,33.2,7.56a60.29,60.29,0,0,1,15.15,12.53,73.83,73.83,0,0,1-5.37-28.11c0-17.05,5.41-32.31,13.9-42.45,8.5,10.14,13.9,25.4,13.9,42.45a73.83,73.83,0,0,1-5.36,28.11,60.24,60.24,0,0,1,15.14-12.53c11.33-6.66,23.4-9.11,33.2-7.56-3.66,9.46-11.83,18.92-23.16,25.58a57.46,57.46,0,0,1-22,7.69,41.75,41.75,0,0,1,14.67,3.63c8.86,4,15.3,10.42,18.24,17.32-7.58,2.17-17,1.5-25.83-2.47"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.61px",
          }}
          x1="214.25"
          y1="148.84"
          x2="214.27"
          y2="106.94"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.56px",
            fillRule: "evenodd",
          }}
          d="M204.12,163.46a77.72,77.72,0,1,1,20.29,0"
        />
      </g>
    </SvgIcon>
  );
};

export default IconAlcoholAndDrugs;
