import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDismissal = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87">
        <g>
          <polygon
            style={{ fill: "none" }}
            points="117.643,214.485 47.725,275.642 252.275,275.642 182.367,214.485 173.12,214.485
			126.89,214.485 		"
          />
          <polygon style={{ fill: "none" }} points="38.72,125.328 56.026,140.82 56.026,109.857 		" />
          <polygon style={{ fill: "none" }} points="261.28,125.328 243.974,109.856 243.974,140.828 		" />
          <polygon style={{ fill: "none" }} points="150,25.846 128.303,45.243 171.698,45.243 		" />
          <g>
            <polygon style={{ fill: "#137F7B" }} points="128.556,205.769 171.454,205.769 194.036,185.547 105.975,185.547 			" />
            <path
              style={{ fill: "#137F7B" }}
              d="M217.665,164.388c-22.84,3.356-46.529,1.786-66.054-9.689H86.533v-8.717h53.213
				c-3.385-3.076-6.575-6.559-9.532-10.49c-2.985-3.969-5.618-7.848-7.966-11.643H86.533v-8.717h30.745
				c-4.052-7.822-6.809-15.212-8.584-22.132h-22.16v-8.717h20.389c-1.064-7.113-1.099-13.633-0.48-19.496
				c0.412-3.907,1.11-7.521,1.983-10.827h-2.951H71.28c-3.604,0-6.537,2.934-6.537,6.539v29.873v11.692v46.56l31.575,28.275v-0.069
				h107.363v0.08L217.665,164.388z"
            />
          </g>
          <path
            style={{ fill: "#FFFFFF" }}
            d="M228.72,53.96h-34.193h-13.078h-62.896h-10.128c-0.874,3.306-1.571,6.92-1.983,10.827
			c-0.619,5.863-0.584,12.383,0.48,19.496h106.545V93H108.693c1.775,6.92,4.532,14.31,8.584,22.132h96.189v8.717h-91.219
			c2.348,3.795,4.981,7.674,7.966,11.643c2.957,3.932,6.147,7.414,9.532,10.49h73.721v8.717H151.61
			c19.525,11.476,43.214,13.046,66.054,9.689l17.593-15.753v-46.571V90.372V60.499C235.257,56.894,232.325,53.96,228.72,53.96z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M270.658,241.724c2.407,0,4.358-1.951,4.358-4.358v-109.5c0-1.24-0.529-2.422-1.454-3.249
				l-29.588-26.451V60.499c0-8.412-6.843-15.256-15.254-15.256h-43.944l-31.871-28.492c-1.654-1.479-4.155-1.479-5.81,0
				l-31.872,28.492H71.28c-8.411,0-15.254,6.844-15.254,15.256v37.666l-29.588,26.451c-0.925,0.826-1.454,2.009-1.454,3.249v34.386
				c0,2.407,1.951,4.358,4.358,4.358c2.407,0,4.358-1.951,4.358-4.358v-29.719l23.774,21.284c0.001,0,0.001,0.001,0.002,0.002
				l58.06,51.992c-0.885,0.097-1.726,0.445-2.401,1.036L36.49,273.89c-1.702-1.406-2.789-3.533-2.789-5.908v-86.793
				c0-2.407-1.952-4.358-4.358-4.358c-2.407,0-4.358,1.951-4.358,4.358v86.793c0,6.872,4.26,12.759,10.273,15.186
				c0.033,0.013,0.068,0.023,0.102,0.037c1.86,0.736,3.881,1.154,6.001,1.154h217.29c2.137,0,4.173-0.424,6.046-1.172
				c0.012-0.005,0.024-0.009,0.036-0.014c6.019-2.422,10.284-8.313,10.284-15.191v-11.68c0-2.407-1.951-4.358-4.358-4.358
				c-2.407,0-4.358,1.951-4.358,4.358v11.68c0,2.376-1.086,4.503-2.787,5.909l-76.638-67.044c-0.675-0.591-1.516-0.939-2.401-1.036
				l58.05-51.981c0.001-0.001,0.001-0.002,0.002-0.003l23.774-21.293v104.832C266.299,239.772,268.251,241.724,270.658,241.724z
				 M203.681,176.91v-0.08H96.318v0.069l-31.575-28.275v-46.56V90.372V60.499c0-3.605,2.933-6.539,6.537-6.539h34.194h2.951h10.128
				h62.896h13.078h34.193c3.604,0,6.537,2.934,6.537,6.539v29.873v11.691v46.571l-17.593,15.753L203.681,176.91z M171.454,205.769
				h-42.897l-22.582-20.222h88.062L171.454,205.769z M150,25.846l21.698,19.397h-43.396L150,25.846z M56.026,109.857v30.963
				L38.72,125.328L56.026,109.857z M252.275,275.642H47.725l69.917-61.156h9.248h46.229h9.248L252.275,275.642z M243.974,140.828
				v-30.972l17.306,15.472L243.974,140.828z"
            />
            <polygon style={{ fill: theme.palette.iconColor.main }} points="86.533,84.283 86.533,93 108.693,93 213.466,93 213.466,84.283 106.921,84.283 			" />
            <polygon
              style={{ fill: theme.palette.iconColor.main }}
              points="86.533,115.132 86.533,123.849 122.247,123.849 213.466,123.849 213.466,115.132
				117.277,115.132 			"
            />
            <polygon
              style={{ fill: theme.palette.iconColor.main }}
              points="86.533,145.981 86.533,154.698 151.61,154.698 213.466,154.698 213.466,145.981
				139.746,145.981 			"
            />
          </g>
        </g>
      </g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDismissal;
