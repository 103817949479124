import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconShakeHands = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {active ? (
        <>
          <g
            style={{
              fill: "none",
              stroke: "#137f7b",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: "6.92",
              fillRule: "evenodd",
            }}
          >
            <path d="M291.46,79.63,226.05,101.8c-2.58.87-3.09,3.34-2.14,6l31.58,87.52c.95,2.62,4.68,3.65,7.26,2.78l28.71-9.74M263.05,108l28.41,80.28" />
            <path d="M8.57,79.63,74,101.8c2.58.87,3.08,3.34,2.13,6L44.53,195.28c-.94,2.62-4.67,3.65-7.25,2.78L8.57,188.32m26.9-76.84L8.57,188.32" />
          </g>
        </>
      ) : (
        <>
          <g
            style={{
              fill: "#137F7B",
              stroke: getIconColor(active, theme),
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
          >
            <rect x="238.87" y="93.45" width="35.53" height="103.63" transform="translate(-33.66 93.4) rotate(-19.38)" />
            <rect x="-9.33" y="127.5" width="103.63" height="35.53" transform="translate(-108.64 137.13) rotate(-70.62)" />
          </g>
        </>
      )}
      <g style={{ fill: "none", stroke: getIconColor(active, theme), strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          style={{ fill: "none", strokeWidth: "8.28px" }}
          d="M290.85,80.07,225.58,102.2c-2.58.87-3.08,3.33-2.13,5.94L255,195.48c.95,2.61,4.66,3.64,7.24,2.77l28.65-9.71"
        />
        <path
          style={{ fill: "none", strokeWidth: "8.28px" }}
          d="M8.56,80.07,73.83,102.2c2.58.87,3.08,3.33,2.14,5.94L44.45,195.48c-.94,2.61-4.66,3.64-7.24,2.77L8.56,188.54"
        />
        <path
          style={{ strokeWidth: "8.28px" }}
          d="M66.46,134.49l11.19-1.38,41.9-5.65c3.84-.47,7.33-.86,10.53-1.11m59.66,48.34,38.94,25,.06,0v0l.39.26h.06l0,0h.06l.25.19,0,0h.07l0,0h0l0,0h0v0c9.11,7.45-5.27,22.24-16,15.33l-6.32-4.06c11.14,7.16-3.87,23.24-15,16.08l-6.31-4c11.14,7.15-3.87,23.24-15,16.08l-6.32-4.06c11.14,7.15-3.87,23.23-15,16.08l-20-12.85L60.23,194.38M51.84,175l14.62-40.52"
        />
        <line style={{ strokeWidth: "8.28px" }} x1="207.36" y1="211.74" x2="177.85" y2="192.78" />
        <line style={{ strokeWidth: "8.28px" }} x1="186.04" y1="223.77" x2="159.33" y2="206.61" />
        <line style={{ strokeWidth: "8.28px" }} x1="164.71" y1="235.79" x2="140.81" y2="220.43" />
        <path
          style={{ strokeWidth: "8.28px" }}
          d="M231.7,131l-51.83-3.55c-23.48-2.89-25.63-7.67-42,8.45-30.26,29.89-31.29,28-17.43,35.46,15.21,8.18,31-21.24,40.56-15.11l11,7.06m79.63,23L231.7,131"
        />
      </g>
    </SvgIcon>
  );
};

export default IconShakeHands;
