import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconAlarm = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M256,121.5c-39.6,0-76.9,13-107.8,37.6c-30,23.9-51.5,57.5-60.5,94.5c-1.3,5.4,2,10.8,7.4,12.1
			c5.4,1.3,10.8-2,12.1-7.4c16.7-68.8,78-116.9,148.9-116.9c84.5,0,153.2,68.7,153.2,153.2S340.5,448,256,448
			c-71.6,0-133-48.6-149.2-118.2c-1.3-5.4-6.6-8.7-12-7.5c-5.4,1.3-8.7,6.6-7.5,12c8.7,37.4,30.2,71.4,60.3,95.6
			C178.2,454.4,216.7,468,256,468c95.5,0,173.2-77.7,173.2-173.2S351.5,121.5,256,121.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M489.2,67l-16.6-16.6c-14.7-14.7-34.2-22.8-55-22.8s-40.3,8.1-55,22.8c-3.9,3.9-3.9,10.2,0,14.1L398,99.8
			L381,116.9C347.3,93.2,307.7,79.6,266,77.7V60h21.3c16.5,0,30-13.5,30-30s-13.5-30-30-30h-62.5c-16.5,0-30,13.5-30,30
			s13.5,30,30,30H246v17.7c-41.7,1.9-81.3,15.5-114.9,39.2L114,99.8l35.3-35.3c3.9-3.9,3.9-10.2,0-14.1
			c-14.7-14.7-34.2-22.8-55-22.8s-40.3,8.1-55,22.8L22.8,67C8.1,81.6,0,101.2,0,121.9s8.1,40.3,22.8,55c1.9,1.9,4.4,2.9,7.1,2.9
			s5.2-1.1,7.1-2.9l34.8-34.8l15.6,15.6c-31.5,38.6-48.6,86.5-48.6,136.9c0,49.7,16.6,96.9,47.1,135.1c-4.2,6.9-11.3,18.6-18.4,30.4
			c-5.5,9.1-11,18.2-15.2,25.1c-2.1,3.4-3.8,6.3-5,8.3c-2.8,4.6-3.3,5.5-3.3,8.3c0,2.7,1.1,5.2,2.9,7.1c3,3,5,3,12.5,3
			c1.1,0,2.3,0,3.5,0c2.6,0,5.5,0,8.4,0c8.7,0,17.3-0.1,17.3-0.1c2.9,0,5.7-1.3,7.6-3.5l32.1-37.8C165.3,497.5,209.5,512,256,512
			s90.7-14.5,127.6-41.4l32.1,37.8c1.9,2.2,4.7,3.5,7.6,3.5c0,0,8.6,0,17.3,0.1c2.9,0,5.8,0,8.4,0c1.3,0,2.5,0,3.5,0
			c7.5,0,9.5,0,12.5-3c1.9-1.9,2.9-4.4,2.9-7.1c0-2.8-0.6-3.8-3.3-8.3c-1.2-2-2.9-4.9-5-8.3c-4.1-6.8-9.6-16-15.2-25.1
			c-7.1-11.8-14.2-23.5-18.4-30.4c30.5-38.3,47.1-85.4,47.1-135.1c0-50.5-17.1-98.3-48.6-136.9l15.6-15.6l34.8,34.8
			c1.9,1.9,4.4,2.9,7.1,2.9c2.7,0,5.2-1.1,7.1-2.9C519.6,146.6,519.6,97.3,489.2,67z M224.8,40c-5.5,0-10-4.5-10-10s4.5-10,10-10
			h62.5c5.5,0,10,4.5,10,10s-4.5,10-10,10H224.8z M64.6,120.9C64.6,120.9,64.6,120.9,64.6,120.9L64.6,120.9l-34.2,34.2
			C23.7,145.4,20,134,20,121.9c0-15.4,6-29.9,16.9-40.8l16.6-16.6c11.3-11.3,26-16.9,40.8-16.9c11.6,0,23.2,3.5,33.2,10.4L92.8,92.8
			c0,0,0,0,0,0s0,0,0,0L64.6,120.9z M85.8,128l14-14l15.3,15.3c-4.4,3.7-8.7,7.7-12.8,11.8c-0.6,0.6-1.1,1.2-1.7,1.7L85.8,128z
			 M84,492c-3.9,0-8.3,0-12.4,0c7.5-12.3,18.4-30.3,28.1-46.4c0.9,0.9,1.8,1.8,2.7,2.7c3.4,3.4,6.8,6.6,10.4,9.7L84,492z M440.4,492
			c-4.1,0-8.4,0-12.4,0l-28.8-33.9c3.6-3.1,7-6.4,10.4-9.7c0.9-0.9,1.8-1.8,2.7-2.7C422,461.6,432.9,479.7,440.4,492z M453.3,294.7
			C453.3,403.5,364.8,492,256,492c-108.8,0-197.3-88.5-197.3-197.3C58.8,186,147.2,97.5,256,97.5S453.3,186,453.3,294.7z
			 M411.4,142.8c-0.6-0.6-1.1-1.2-1.7-1.8c-4.1-4.1-8.4-8.1-12.8-11.8l15.3-15.3l14,14L411.4,142.8z M481.6,155.1l-34.1-34.2
			c0,0,0,0,0,0c0,0,0,0,0,0l-28.2-28.2c0,0,0,0,0,0c0,0,0,0,0,0l-34.7-34.7c9.6-6.8,21.1-10.5,33.2-10.5c15.4,0,29.9,6,40.8,16.9
			l16.6,16.6C495.2,101.2,497.4,132.6,481.6,155.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M256,260c-5.9,0-11.5,1.5-16.4,4.1l-40.7-40.7c-3.9-3.9-10.2-3.9-14.1,0c-3.9,3.9-3.9,10.2,0,14.1l40.7,40.7
			c-2.6,4.9-4.1,10.5-4.1,16.4c0,15.7,10.4,28.9,24.7,33.2v94.4c0,5.5,4.5,10,10,10s10-4.5,10-10v-94.4
			c14.3-4.3,24.7-17.6,24.7-33.2C290.7,275.6,275.1,260,256,260z M256,309.4c-8.1,0-14.7-6.6-14.7-14.7S247.9,280,256,280
			s14.7,6.6,14.7,14.7S264.1,309.4,256,309.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M356.9,224.8c-3.1-4.5-9.4-5.7-13.9-2.5s-5.7,9.4-2.5,13.9c11.9,17.2,18.3,37.4,18.3,58.5c0,5.5,4.5,10,10,10
			s10-4.5,10-10C378.7,269.6,371.2,245.4,356.9,224.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M375.8,324.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2,1.1-7.1,2.9s-2.9,4.4-2.9,7.1s1.1,5.2,2.9,7.1
			c1.9,1.9,4.4,2.9,7.1,2.9s5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1S377.7,326.8,375.8,324.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M99.9,287.6c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2,1.1-7.1,2.9s-2.9,4.4-2.9,7.1s1.1,5.2,2.9,7.1s4.4,2.9,7.1,2.9
			s5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1S101.7,289.5,99.9,287.6z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconAlarm;
