import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDistanceFourToTen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            d="M4.75,237.24H24.47V237c0-12.26,10.33-22.21,23.08-22.21s23.08,10,23.08,22.21v.26h1.48c6.45,0,19.68-3.18,19.68-11.15V205.63h-87Z"
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
          />
          <path
            d="M183,237.27h22.28V237a22.69,22.69,0,0,1,45.37,0v.27h44V205.65H189.41C184.21,213.23,183.79,223.14,183,237.27Z"
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
          />
        </>
      )}
      <g
        style={{
          fill: getIconColor(active, theme),
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      >
        <path
          d="M93.8,94.48h10.11V76.17h5.46V67.7h-5.46V25.47H93.07L61.84,68.72v7.45h32V94.48Zm0-26.78H73.59L93.8,40V67.7Zm30.6,43.8,31.32-86h-9.47l-31.41,86Zm58-86H162.1v8.47h10.2V94.48h10.1v-69Zm55.19,46.19V48.29C237.41,33.11,227.84,25,214.09,25s-23.49,8.1-23.49,23.28V71.66c0,15.28,9.75,23.28,23.49,23.28s23.32-8,23.5-23.28Zm-10.11-23V71.29c0,11-5.65,15.18-13.39,15.18s-13.38-4.14-13.38-15.18V48.66c0-11,5.64-15.19,13.38-15.19S227.48,37.62,227.48,48.66Z"
          style={{ fillRule: "evenodd" }}
        />
        <path
          d="M93.8,94.48h10.11V76.17h5.46V67.7h-5.46V25.47H93.07L61.84,68.72v7.45h32V94.48Zm0-26.78H73.59L93.8,40V67.7Zm30.6,43.8,31.32-86h-9.47l-31.41,86Zm58-86H162.1v8.47h10.2V94.48h10.1v-69Zm55.19,46.19V48.29C237.41,33.11,227.84,25,214.09,25s-23.49,8.1-23.49,23.28V71.66c0,15.28,9.75,23.28,23.49,23.28s23.32-8,23.5-23.28Zm-10.11-23V71.29c0,11-5.65,15.18-13.39,15.18s-13.38-4.14-13.38-15.18V48.66c0-11,5.64-15.19,13.38-15.19S227.48,37.62,227.48,48.66Z"
          style={{ fillRule: "evenodd" }}
        />
        <line x1="4.75" y1="275.54" x2="294.68" y2="275.56" style={{ strokeWidth: "6.92" }} />

        <path d="M4.75,237.24h7.79m70.27-1.84c4.76-1.72,9-4.72,9-9.31V185.51h-87" style={{ strokeWidth: "6.9", fill: "none" }} />
        <path d="M70,244.55a22.15,22.15,0,1,1,0-15.15" style={{ strokeWidth: "6.9", fill: "none" }} />
        <path d="M183,237.27h21.81V237m58.88.27h31m0-51.73h-60c-50,10.26-50,21-51.63,51.73" style={{ strokeWidth: "6.9", fill: "none" }} />
        <line x1="241.51" y1="185.54" x2="273.07" y2="156.36" style={{ strokeWidth: "6.9" }} />
        <path d="M247.85,244.58a22.16,22.16,0,1,1,0-15.16" style={{ strokeWidth: "6.9", fill: "none" }} />
        <line x1="116.09" y1="137.41" x2="116.11" y2="262.64" style={{ strokeDasharray: "6.9 20.7", strokeWidth: "6.9" }} />
        <line x1="131.66" y1="137.41" x2="131.68" y2="262.64" style={{ strokeDasharray: "6.9 20.7", strokeWidth: "6.9" }} />
        <line x1="147.23" y1="137.41" x2="147.25" y2="262.64" style={{ strokeDasharray: "6.9 20.7", strokeWidth: "6.9" }} />
        <line x1="162.79" y1="137.41" x2="162.82" y2="262.64" style={{ strokeDasharray: "6.9 20.7", strokeWidth: "6.9" }} />
      </g>
    </SvgIcon>
  );
};

export default IconDistanceFourToTen;
