import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCheck = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30">
        <g>
          <path
            fill="#137F7B"
            d="M211.275,124.533l59.271-84.981l-14.478-10.694L141.904,192.19c-0.862,1.234-2.561,1.537-3.796,0.674
			l-64.785-45.192l-10.051,13.564l83.064,56.394l54.549-78.202c0.86-1.234,2.559-1.539,3.795-0.679l7.269-10.421
			C210.716,127.467,210.414,125.768,211.275,124.533z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M253.371,94.938c-1.334,0.7-1.849,2.349-1.149,3.683c8.831,16.837,13.498,35.858,13.498,55.005
				c0,65.422-53.226,118.647-118.648,118.647c-65.422,0-118.646-53.225-118.646-118.647c0-65.421,53.225-118.646,118.646-118.646
				c21.72,0,42.962,5.921,61.432,17.122c1.288,0.78,2.966,0.371,3.747-0.917c0.781-1.288,0.37-2.965-0.917-3.747
				c-19.323-11.719-41.543-17.913-64.261-17.913c-68.43,0-124.102,55.671-124.102,124.101c0,68.431,55.672,124.102,124.102,124.102
				c68.431,0,124.104-55.671,124.104-124.102c0-20.026-4.883-39.923-14.122-57.538C256.354,94.753,254.705,94.239,253.371,94.938z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M275.923,36.741l-18.865-13.934c-0.592-0.438-1.333-0.617-2.062-0.498
				c-0.726,0.119-1.373,0.526-1.794,1.129L138.995,186.832l-64.724-45.15c-1.21-0.845-2.873-0.572-3.752,0.613l-13.321,17.979
				c-0.442,0.597-0.622,1.348-0.498,2.081s0.542,1.382,1.157,1.799l87.644,59.503c0.469,0.319,1.002,0.471,1.53,0.471
				c0.862,0,1.709-0.407,2.239-1.167l56.09-80.412c0.862-1.235,0.559-2.935-0.676-3.797c0-0.001-0.001-0.001-0.002-0.002
				c-1.236-0.86-2.935-0.556-3.795,0.679l-54.549,78.202l-83.064-56.394l10.051-13.564l64.785,45.192
				c1.235,0.862,2.934,0.56,3.796-0.674L256.068,28.858l14.478,10.694l-59.271,84.981c-0.861,1.234-0.559,2.934,0.675,3.796
				c0.001,0,0.001,0.001,0.001,0.001c1.237,0.862,2.937,0.559,3.798-0.677l60.79-87.158
				C277.385,39.283,277.111,37.619,275.923,36.741z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconCheck;
