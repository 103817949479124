import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import PensionsCostCalculatorModal from "../../../FormInfoModal/PensionsCostCalculatorModal";
import React from "react";
import { minNumber, requiredValidator } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import { useLocation, useNavigate } from "react-router-dom";
import divorceCostBoxStyle from "./divorceCostBoxStyle";
import { modalview } from "../../../../services/useTracker";
import {Box} from "@mui/material";

export default function DivorceCostCalculatorForm() {
  const { values, handleSubmit, handleChange } = useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const handleModalOpen = () => {
    modalview("Pensions Help Modal");
    navigate(location.pathname, { state: { helpModalOpen: "costCalculator" } });
  };

  const handlePensionModalClose = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <ValidatorTextField
              label="Ihr mtl. Nettoeinkommen"
              name="netIncomePetitioner"
              validators={[
                requiredValidator("Bitte geben Sie Ihr Einkommen an"),
                {
                  validator: minNumber,
                  params: [0],
                  message: "Bitte geben Sie Ihr Einkommen in EUR an",
                },
              ]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <ValidatorTextField
              label="Mtl. Nettoeinkommen Partner"
              name="netIncomePartner"
              validators={[
                requiredValidator("Bitte geben Sie das Einkommen Ihres Partners an"),
                {
                  validator: minNumber,
                  params: [0],
                  message: "Bitte geben Sie das Einkommen Ihres Partners in EUR an",
                },
              ]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Grid container>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="pensionsExcluded"
                    name="pensionsExcluded"
                    checked={values.pensionsExcluded}
                    onChange={handleChange}
                    value="pensionsExcluded"
                    color="primary"
                  />
                }
                label="Wir möchten den Versorgungsausgleich ausschließen"
              />
            </Grid>
            <Grid item xs={2} sx={divorceCostBoxStyle.InfoOutlinedIconContainer}>
              <Box component={"img"} src={InfoOutlinedIcon} alt={"(i)"} sx={divorceCostBoxStyle.info} onClick={handleModalOpen} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label={"Ihre Rentenversicherungen"}
            disabled={values.pensionsExcluded}
            name={"numberPensions"}
            helperText={"Wie viele Versicherungen haben Sie?"}
          >
            <MenuItem value={"0"}>0</MenuItem>
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label={"Rentenversicherungen Partner"}
            disabled={values.pensionsExcluded}
            name={"numberPensionsPartner"}
            helperText={"Wie viele Versicherungen hat Ihr Partner?"}
          >
            <MenuItem value={"0"}>0</MenuItem>
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <Button sx={divorceCostBoxStyle.costButton} variant="contained" type="submit" fullWidth>
            Kosten berechnen
          </Button>
        </Grid>
      </Grid>
      <PensionsCostCalculatorModal
        open={!!(location.state && location.state.helpModalOpen === "costCalculator")}
        onClose={handlePensionModalClose}
      />
    </form>
  );
}
