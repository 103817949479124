import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import wizardStyle from "../../../wizardStyle";
import ValidatorTextField from "../../../../FormFields/ValidatorTextField";
import { dateFieldDefault, pastDateDefault, requiredValidator } from "../../../../../services/validationRules";
import ValidatorSelectField from "../../../../FormFields/ValidatorSelectField";
import FormLegend from "../../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../../FormFields/ValidatorDateField";

type AlimonyChildProps = { childIndex: number };

export default function AlimonyChild({ childIndex }: AlimonyChildProps) {
  return (
    <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <FormLegend>Angaben zu Kind {childIndex + 1}</FormLegend>
      </Grid>
      <Grid item xs={12} sm={6} sx={wizardStyle.gridSpace}>
        <ValidatorTextField name={`children[${childIndex}].name`} label="Vorname" validators={[requiredValidator("Bitte geben Sie den Vornamen an")]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorDateField
          name={`children[${childIndex}].birthDate`}
          label="Geburtsdatum"
          validators={[requiredValidator("Bitte geben Sie das Geburtsdatum an"), dateFieldDefault, pastDateDefault]}
        />
      </Grid>
      <Grid item xs={12} sm={4} sx={wizardStyle.gridSpace}>
        <ValidatorSelectField
          name={`children[${childIndex}].childOf`}
          label="Kind von"
          validators={[requiredValidator("Bitte geben Sie an wessen Kind es ist")]}
        >
          <MenuItem value="both">Gemeinsames Kind</MenuItem>
          <MenuItem value="petitioner">Kind von mir</MenuItem>
          <MenuItem value="partner">Kind von meinem Partner</MenuItem>
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12} sm={4} sx={wizardStyle.gridSpace}>
        <ValidatorSelectField
          name={`children[${childIndex}].livesWith`}
          label="Kind lebt bei"
          validators={[requiredValidator("Bitte geben Sie an bei wem das Kind lebt")]}
        >
          <MenuItem value="petitioner">Lebt hauptsächlich bei mir</MenuItem>
          <MenuItem value="partner">Lebt hauptsächlich beim Partner</MenuItem>
          <MenuItem value="both">Lebt bei uns beiden zu gleichen Teilen</MenuItem>
          <MenuItem value="none">Lebt bei jemand anderem</MenuItem>
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ValidatorSelectField
          name={`children[${childIndex}].childAllowanceReceiver`}
          label="Kindergeldempfänger"
          validators={[requiredValidator("Bitte geben Sie an wer Kindergeld für dieses Kind erhält")]}
        >
          <MenuItem value="petitioner">Ich</MenuItem>
          <MenuItem value="partner">Mein Partner</MenuItem>
          <MenuItem value="child">Das Kind selbst</MenuItem>
          <MenuItem value="none">Keiner von uns</MenuItem>
        </ValidatorSelectField>
      </Grid>
    </Grid>
  );
}
