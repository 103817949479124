import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLicenceDurationMoreThanTen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path d="M240.69,38.17a143.14,143.14,0,1,0,25.72,27.11" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.91", fill: "none" }} />
        <path
          d="M158.68,115.29H138.36v8.47h10.21v60.6h10.11V115.29Zm55.23,46.23V138.13c-.18-15.2-9.75-23.3-23.51-23.3s-23.52,8.1-23.52,23.3v23.39c0,15.29,9.76,23.3,23.52,23.3s23.33-8,23.51-23.3Zm-10.12-23v22.65c0,11-5.65,15.19-13.39,15.19S177,172.2,177,161.15V138.5c0-11,5.65-15.2,13.4-15.2S203.79,127.45,203.79,138.5Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <line x1="116.65" y1="149.86" x2="86.45" y2="119.62" style={{ stroke: getIconColor(active, theme), strokeWidth: "8.31", fill: "none" }} />
        <line x1="116.67" y1="149.84" x2="86.43" y2="180.03" style={{ stroke: getIconColor(active, theme), strokeWidth: "8.31", fill: "none" }} />
        <path d="M68.67,250.47A129.5,129.5,0,1,0,45.4,226" style={{ stroke: active ? "none" : "#137F7B", strokeWidth: "6.91", fill: "none" }} />
      </g>
    </SvgIcon>
  );
};

export default IconLicenceDurationMoreThanTen;
