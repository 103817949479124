import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPhoneDrugsParking = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M122.11,36.792l-14.122,14.122l-3.791,3.791L72.633,86.268l-5.334,5.334L41.941,116.96
				c1.562,1.784,3.238,3.467,5.011,5.047l20.347-20.347l5.334-5.334l8.271-8.271l4.704-4.704l22.288-22.288l4.153-4.153
				l15.108-15.108C125.581,40.028,123.894,38.357,122.11,36.792z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M186.716,122.007l11.887-11.887l0.459-0.459l4.164-4.163l0.711-0.711l43.012-43.011l5.334-5.334
				l14.639-14.639c-1.576-1.774-3.264-3.446-5.047-5.011l-9.592,9.591l-4.846,4.845l-0.488,0.489l-4.846,4.846l-38.166,38.166
				l-5.334,5.334l-16.898,16.898C183.267,118.745,184.942,120.428,186.716,122.007z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M122.11,178.281l-16.479,16.479l-5.334,5.334l-24.471,24.471l-3.967,3.967L41.94,258.45
				c1.563,1.784,3.239,3.468,5.012,5.047l32.199-32.199l4.978-4.978l16.168-16.168l5.334-5.334l21.526-21.526
				C125.581,181.518,123.894,179.847,122.11,178.281z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M261.874,178.281l-22.701,22.7l-5.471,5.471l-14.545,14.545l-4.893,4.893l-5.906,5.906
				l-20.014,20.014l-6.641,6.64c1.563,1.784,3.238,3.468,5.012,5.047l6.971-6.97l5.325-5.326l11.854-11.854l5.598-5.597
				l15.643-15.643l6.6-6.6l28.215-28.215C265.345,181.518,263.657,179.847,261.874,178.281z"
            />
          </g>
        </>
      )}
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M94.342,88.686c10.349,0,19.773-10.042,19.773-21.07c0-3.649-0.631-7.385-2.066-10.706
				l-4.153,4.153c0.722,2.467,0.885,4.885,0.885,6.552c0,8.088-7.018,15.735-14.439,15.735h-8.734l-4.704,4.704
				c0.463,0.388,1.052,0.63,1.703,0.63H94.342z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M67.299,110.447c0,1.473,1.194,2.667,2.667,2.667s2.667-1.194,2.667-2.667v-14.12l-5.334,5.334
				V110.447z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M72.633,51.26h21.709c4.568,0,7.704,1.408,9.855,3.445l3.791-3.791
				c-3.12-3.035-7.563-4.989-13.646-4.989H69.966c-1.473,0-2.667,1.194-2.667,2.667v43.01l5.334-5.334V51.26z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M105.631,188.418c0-1.473-1.194-2.667-2.667-2.667H74.508c-1.473,0-2.667,1.194-2.667,2.667
				s1.194,2.667,2.667,2.667h25.789v9.008l5.334-5.334V188.418z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M100.297,211.909c0,12.376-11.263,14.205-16.168,14.412l-4.978,4.978
				c1.282,0.246,2.586,0.381,3.9,0.381c10.417,0,22.58-5.178,22.58-19.771v-7.09l-5.334,5.334V211.909z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M63.763,185.97c-1.473,0-2.667,1.194-2.667,2.667v23.271c0,6.605,4.509,12.973,10.763,16.624
				l3.967-3.967c-5.286-2.594-9.396-7.653-9.396-12.656v-23.271C66.43,187.164,65.236,185.97,63.763,185.97z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M70.577,255.861H96.15c1.473,0,2.667-1.194,2.667-2.667s-1.194-2.667-2.667-2.667H86.03v-14.176
				c0-1.473-1.194-2.667-2.667-2.667s-2.667,1.194-2.667,2.667v14.176H70.577c-1.473,0-2.667,1.194-2.667,2.667
				S69.104,255.861,70.577,255.861z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M225.288,233.684c-1.473,0-2.667,1.194-2.667,2.667v33.995c0,1.473,1.194,2.667,2.667,2.667
				s2.667-1.194,2.667-2.667v-33.995C227.955,234.878,226.761,233.684,225.288,233.684z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M271.69,213.439c0.345-0.757,0.317-1.631-0.074-2.364c-0.393-0.733-1.104-1.242-1.925-1.375
				c-0.899-0.146-18.575-2.849-31.837,7.248c0.324-1.675,0.618-3.503,0.852-5.44l-6.6,6.6c-1.008,4.585-2.102,7.525-2.139,7.62
				c-0.488,1.276,0.066,2.716,1.285,3.334c1.221,0.617,2.708,0.214,3.448-0.936c8.099-12.575,23.263-13.69,30.325-13.462
				c-9.899,16.446-30.465,19.011-30.682,19.036c-1.422,0.16-2.465,1.415-2.361,2.843s1.318,2.518,2.746,2.474
				c12.697-0.413,18.916,7.764,21.361,12.234c-7.137,0.729-14.561-2.899-14.646-2.941c-1.315-0.656-2.917-0.123-3.575,1.194
				c-0.658,1.317-0.125,2.92,1.193,3.578c0.369,0.185,7.305,3.597,15.074,3.597c2.164,0,4.392-0.265,6.561-0.938
				c0.688-0.213,1.26-0.695,1.586-1.336c0.327-0.641,0.381-1.387,0.15-2.068c-0.051-0.149-4.079-11.589-15.607-16.485
				C255.206,232.592,265.912,226.109,271.69,213.439z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M215.845,239.017c1.439,0.045,2.645-1.045,2.747-2.474c0.098-1.347-0.829-2.528-2.128-2.793
				l-5.598,5.597C212.409,239.091,214.056,238.959,215.845,239.017z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M225.288,176.725c7.383,9.014,8.898,20.274,8.414,29.728l5.471-5.471
				c-0.366-10.009-3.254-21.125-11.97-30.116c-0.503-0.519-1.193-0.811-1.915-0.811c-0.722,0-1.412,0.292-1.914,0.811
				c-13.889,14.323-12.986,34.041-10.655,46.083c-13.263-10.096-30.937-7.394-31.835-7.248c-0.82,0.133-1.532,0.642-1.924,1.375
				c-0.393,0.733-0.42,1.607-0.075,2.363c5.776,12.671,16.483,19.154,24.862,22.413c-10.123,4.301-14.463,13.646-15.402,15.959
				l20.014-20.014c-6.926-2.288-16.813-7.145-22.813-17.132c6.63-0.211,20.354,0.754,28.719,11.226l4.893-4.893
				C216.749,211.857,213.286,191.367,225.288,176.725z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M209.124,248.312c-0.066,0.033-4.668,2.29-10.112,2.89l-5.325,5.326
				c8.805,0.992,17.41-3.239,17.822-3.445c1.318-0.658,1.852-2.261,1.193-3.578S210.442,247.653,209.124,248.312z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M198.603,120.831c0,3.432,2.792,6.223,6.224,6.223h41.232c3.432,0,6.224-2.792,6.224-6.223V56.441
				l-5.334,5.334v59.055c0,0.49-0.398,0.889-0.89,0.889h-41.232c-0.491,0-0.89-0.399-0.89-0.889v-9.999h36.319
				c1.474,0,2.667-1.194,2.667-2.667s-1.193-2.667-2.667-2.667h-36.319v-0.711l-0.711,0.711l-4.164,4.163l-0.459,0.459V120.831z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M207.958,53.896c0,1.473,1.193,2.667,2.667,2.667h31.478l4.846-4.846l0.488-0.489l4.846-4.845
				v-4.524c0-3.432-2.792-6.224-6.224-6.224h-41.232c-3.432,0-6.224,2.792-6.224,6.224v58.204l5.334-5.334v-52.87
				c0-0.49,0.398-0.889,0.89-0.889h41.232c0.491,0,0.89,0.398,0.89,0.889v9.37h-36.323
				C209.151,51.228,207.958,52.422,207.958,53.896z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M233.396,47.877c0.981,0,1.778-0.796,1.778-1.778s-0.797-1.778-1.778-1.778h-15.909
				c-0.982,0-1.778,0.796-1.778,1.778s0.796,1.778,1.778,1.778H233.396z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M224.427,114.5c-0.982,0-1.778,0.796-1.778,1.778c0,0.982,0.796,1.778,1.778,1.778h2.027
				c0.982,0,1.778-0.796,1.778-1.778c0-0.981-0.796-1.778-1.778-1.778H224.427z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M84.673,17.333c-34.296,0-62.198,27.895-62.198,62.181c0,31.032,23.163,57.532,53.878,61.64
				c1.46,0.196,2.802-0.83,2.997-2.29c0.195-1.46-0.83-2.802-2.29-2.997c-11.531-1.542-21.894-6.543-30.107-13.86
				c-1.773-1.58-3.449-3.263-5.011-5.047c-8.824-10.078-14.132-23.242-14.132-37.446c0-31.345,25.509-56.846,56.864-56.846
				c14.33,0,27.428,5.342,37.437,14.124c1.784,1.565,3.471,3.237,5.047,5.011c8.924,10.043,14.359,23.251,14.359,37.711
				c0,28.39-21.173,52.617-49.25,56.353c-1.46,0.194-2.486,1.535-2.292,2.995c0.179,1.341,1.324,2.316,2.641,2.316
				c0.117,0,0.236-0.008,0.355-0.024c30.716-4.087,53.879-30.587,53.879-61.64C146.851,45.228,118.958,17.333,84.673,17.333z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M216.116,141.154c1.46,0.196,2.803-0.83,2.997-2.29c0.195-1.46-0.83-2.802-2.29-2.997
				c-11.531-1.542-21.894-6.543-30.107-13.86c-1.773-1.58-3.449-3.263-5.012-5.047c-8.824-10.078-14.132-23.242-14.132-37.446
				c0-31.345,25.509-56.846,56.864-56.846c14.33,0,27.428,5.342,37.438,14.124c1.783,1.565,3.471,3.237,5.047,5.011
				c8.924,10.043,14.359,23.251,14.359,37.711c0,28.39-21.173,52.617-49.25,56.353c-1.459,0.194-2.485,1.535-2.291,2.995
				c0.178,1.341,1.324,2.316,2.641,2.316c0.117,0,0.235-0.008,0.355-0.024c30.716-4.087,53.879-30.587,53.879-61.64
				c0-34.287-27.893-62.181-62.178-62.181c-34.297,0-62.198,27.895-62.198,62.181C162.238,110.546,185.401,137.046,216.116,141.154z
				"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M84.673,158.823c-34.296,0-62.198,27.894-62.198,62.181c0,31.032,23.163,57.532,53.878,61.64
				c1.46,0.198,2.802-0.83,2.997-2.29c0.195-1.46-0.83-2.802-2.29-2.997c-11.532-1.542-21.894-6.542-30.107-13.86
				c-1.773-1.579-3.449-3.263-5.012-5.047c-8.824-10.078-14.132-23.242-14.132-37.446c0-31.345,25.509-56.847,56.864-56.847
				c14.33,0,27.428,5.342,37.437,14.124c1.784,1.565,3.471,3.237,5.047,5.011c8.924,10.043,14.359,23.251,14.359,37.711
				c0,28.39-21.173,52.617-49.25,56.353c-1.46,0.194-2.486,1.536-2.292,2.996c0.179,1.341,1.324,2.315,2.641,2.315
				c0.117,0,0.236-0.008,0.355-0.023c30.716-4.088,53.879-30.587,53.879-61.641C146.851,186.717,118.958,158.823,84.673,158.823z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M224.437,158.823c-34.297,0-62.198,27.894-62.198,62.181c0,31.032,23.163,57.532,53.878,61.64
				c1.46,0.198,2.803-0.83,2.997-2.29c0.195-1.46-0.83-2.802-2.29-2.997c-11.531-1.542-21.894-6.542-30.107-13.86
				c-1.773-1.579-3.449-3.263-5.012-5.047c-8.824-10.078-14.132-23.242-14.132-37.446c0-31.345,25.509-56.847,56.864-56.847
				c14.33,0,27.429,5.342,37.438,14.124c1.783,1.565,3.471,3.237,5.047,5.011c8.924,10.043,14.359,23.251,14.359,37.711
				c0,28.39-21.173,52.617-49.25,56.353c-1.459,0.194-2.485,1.536-2.291,2.996c0.178,1.341,1.324,2.315,2.641,2.315
				c0.117,0,0.235-0.008,0.355-0.023c30.716-4.088,53.879-30.587,53.879-61.641C286.614,186.717,258.722,158.823,224.437,158.823z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconPhoneDrugsParking;
