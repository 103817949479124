import iconAttorneys from "../assets/icons/generic/IconAnwaelte";
import costsSecureIcon from "../assets/icons/generic/IconCostSecurity";
import ReactGA from "react-ga4";
import iconAttorneyCheck from "../assets/icons/settlement/iconAnwaltPruefen";
import iconCalculateSettlement from "../assets/icons/settlement/IconMoney";
import iconInformation from "../assets/icons/generic/IconGuidebook";
import iconDocument from "../assets/icons/generic/IconAntragErstellen";
import divorceTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Scheidung_v2.svg";
import alimonyTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Unterhalt_v2.svg";
import deathTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Tod_Erbe.svg";
import familyLawTeaser from "../assets/img/teaser/familyLaw/Familienrecht.svg";
import employmentLawTeaser from "../assets/img/products/Illustration_Arbeitsrecht.svg";
import settlementTeaser from "../assets/img/teaser/employmentLaw/Arbeitsrecht_Kuendigungsschutz.svg";
import trafficTeaser from "../assets/img/products/Illustration_Verkehrsrecht.svg";
import redLightTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Rotlichtverstoss.svg";
import distanceTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Abstandsverstoss.svg";
import speedingTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Geschwindigkeitsverstoss_v2.svg";
import trafficOtherTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Sonstiges.svg";
import contractLawTeaser from "../assets/img/products/Illustration_Vertragsrecht.svg";
import tenancyLawTeaser from "../assets/img/products/Illustration_Mietrecht.svg";
import purchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Kaufvertrag.svg";
import vehiclePurchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Fahrzeugkauf.svg";
import serviceContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handwerker.svg";
import membershipContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Mitgliedschaft.svg";
import subscriptionContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Abofalle.svg";
import providerContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handy_etc.svg";
import travelContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Reise.svg";
import eventContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Veranstaltung.svg";
import otherContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Sonstiges.svg";
import rentIncreaseImage from "../assets/img/teaser/tenancyLaw/Mietvertrag_mieterhoehung.svg";
import cancellationImage from "../assets/img/teaser/tenancyLaw/Mietrecht_Kündigung.svg";
import AnwaelteWbs from "../assets/icons/landingpage/wbs/AnwaelteWbs";
import IconCostSecurityWbs from "../assets/icons/landingpage/wbs/IconCostSecurityWbs";

export const legalFieldPageConfig = {
  familyLaw: {
    headline: "Familienrecht",
    subtitle: "Ob Scheidung, Unterhalt oder alle anderen Themen im Familienrecht - unsere Anwälte sind jederzeit für Sie da!",
    headlineImage: familyLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Scheidung",
        subText: "Wir begleiten Sie professionell und persönlich bei Ihrer Scheidung.",
        image: divorceTeaser,
      },
      {
        headline: "Unterhalt",
        subText: "Ehegatten-, Kindesunterhalt oder andere Fragen rund um das Thema Unterhalt.",
        image: alimonyTeaser,
      },
      {
        headline: "Todesfall & Erbrecht",
        subText: "Beratung bei allen Angelegenheiten rund um das Thema Erbrecht.",
        image: deathTeaser,
      },
    ],
  },
  trafficLaw: {
    headline: "Verkehrsrecht",
    subtitle: "Zu schnell gefahren oder rote Ampel übersehen? Wir tun alles, damit Sie Ihren Führerschein behalten können!",
    headlineImage: trafficTeaser,
    zipperTeaserRows: [
      {
        headline: "Tempoverstoß",
        subText: "Bußgeld und Punkte wegen Geschwindigkeitsverstoß vermeiden oder reduzieren.",
        image: speedingTeaser,
      },
      {
        headline: "Rotlichtverstoß",
        subText: "Professionell gegen Bußgeld oder Fahrverbot wegen überfahrenen Rotlichts vorgehen.",
        image: redLightTeaser,
      },
      {
        headline: "Abstandsverstoß",
        subText: "Zu nah aufgefahren? Wir prüfen das vermeintliche Vergehen und setzen uns für Sie ein.",
        image: distanceTeaser,
      },
      {
        headline: "Handy/ Drogen/ Parken/ Sonstiges",
        subText: "Hilfestellung bei allen sonstigen Arten von Bußgeldern im Bereich Verkehr.",
        image: trafficOtherTeaser,
      },
    ],
  },
  employmentLaw: {
    headline: "Arbeitsrecht",
    subtitle: "Ärger mit dem Arbeitgeber? Wir helfen bei allen Problemen rund um Ihrem Arbeitsplatz.",
    headlineImage: employmentLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Abfindung bei Kündigung",
        subText: "Wir gehen gegen Ihre Kündigung vor und Erwirken - wenn gewünscht - eine Abfindung für Sie.",
        image: settlementTeaser,
      },
    ],
  },
  contractLaw: {
    headline: "Vertragsrecht",
    subtitle: "Ob Ärger mit dem Fitnessstudio, dem Stromanbieter oder mit anderen Verträgen - wir setzen uns für Sie ein.",
    headlineImage: contractLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Kaufvertrag",
        subText: "Unterstützung bei Problemen mit Ihrem Kaufvertrag - egal ob als Käufer oder Verkäufer.",
        image: purchaseContractTeaser,
      },
      {
        headline: "Fahrzeugkauf",
        subText: "Hilfe bei allen Vertragsthemen rund um den Kauf oder Verkauf von Fahrzeugen.",
        image: vehiclePurchaseContractTeaser,
      },
      {
        headline: "Handwerker / Dienstleistung",
        subText: "Wir setzen uns für Sie ein, wenn zum Beispiel Ihr Handwerker die Leistung nicht wie vereinbart erfüllt.",
        image: serviceContractTeaser,
      },
      {
        headline: "Mitgliedschaft",
        subText: "Uneinigkeiten mit Ihren Mitgliedschaften, beispielsweise Ihrem Fitnessstudio oder Sportverein.",
        image: membershipContractTeaser,
      },
      {
        headline: "Abo / Abofalle",
        subText: "Sie sind in eine Abofalle geraten? Wir wehren die Forderung für Sie ab.",
        image: subscriptionContractTeaser,
      },
      {
        headline: "Handy / DSL / Strom / Gas-Vertrag",
        subText: "Für alle Probleme mit Versorgern, wie Mobilfunkanbietern oder Stromversorger.",
        image: providerContractTeaser,
      },
      {
        headline: "Reisevertrag",
        subText: "Reisen sollten entspannend sein, daher helfen wir bei allen Problemen rund um dem Reisevertrag.",
        image: travelContractTeaser,
      },
      {
        headline: "Veranstaltung",
        subText: "Veranstaltung ausgefallen, verschoben oder sonstige Probleme? Wir unterstützen Sie!",
        image: eventContractTeaser,
      },
      {
        headline: "Sonstiger Vertrag",
        subText: "Auch bei allen anderen Verträgen helfen wir Ihnen weiter! Eine kurze Schilderung genügt!",
        image: otherContractTeaser,
      },
    ],
  },
  tenancyLaw: {
    headline: "Mietrecht",
    subtitle: "Bei allen Anliegen rund um Ihren Mietvertrag, setzen wir uns professionell für Ihre Rechte ein!",
    headlineImage: tenancyLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Mietmängel",
        subText: "Heizung ausgefallen oder Schimmel im Schlafzimmer? Wir sorgen dafür, dass Mietmängel zeitnah behoben werden.",
        image: purchaseContractTeaser,
      },
      {
        headline: "Mietminderung",
        subText: "Lärmbelästigung oder andere Mängel, die nicht behoben werden, dann helfen wir Ihnen dabei, die Miete zu mindern.",
        image: subscriptionContractTeaser,
      },
      {
        headline: "Mieterhöhung",
        subText: "Ihre Miete wurde unrechtmäßig erhöht und Sie möchten dagegen vorgehen.",
        image: rentIncreaseImage,
      },
      {
        headline: "Nebenkostenabrechnung",
        subText: "Sie haben eine sehr hohe Abrechnung erhalten, die Ihnen falsch erscheint? Wir prüfen Ihre Nebenkostenabrechnung auf Rechtmäßigkeit.",
        image: otherContractTeaser,
      },
      {
        headline: "Kaution",
        subText: "Wenn die Kaution Ihnen gar nicht oder nicht vollständig zurückgezahlt wird, setzen wir uns für Sie ein.",
        image: divorceTeaser,
      },
      {
        headline: "Kündigung",
        subText: "Ihnen wurde das Mietverhältnis gekündigt und Sie möchten wissen, ob dies rechtmäßig war? Dann prüfen wir dies gerne für Sie.",
        image: cancellationImage,
      },
      {
        headline: "Mietvertrag prüfen",
        subText: "Sie möchten einen neuen Mietvertrag unterzeichnen oder sind allgemein unsicher, ob Ihr Vertrag rechtmäßig ist.",
        image: otherContractTeaser,
      },
    ],
  },
};

export const defaultProvenExpertTeaserCard = {
  image: "https://images.provenexpert.com/ca/70/17a7e460654ab3270025208dddab/widget_square_200_de_0.png",
  heading: "Persönlich & schnell",
  link: "https://www.provenexpert.com/legalbird/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget",
  linkType: "external",
  hideMobileButton: true,
};

export const getProvenExpertTeaserCardWithHeading = (heading) => {
  let provenExpertTeaserCard = { ...defaultProvenExpertTeaserCard };
  provenExpertTeaserCard.heading = heading;
  return provenExpertTeaserCard;
};

const handleClickGA = (actionName) => {
  ReactGA.event({
    category: "dismissal action choice",
    action: actionName,
  });
};

export const divorceTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const wbsTeaserCards = [
  {
    image: IconCostSecurityWbs,
    heading: "Kostenlose Prüfung",
  },
  {
    image: AnwaelteWbs,
    heading: "Professionelle Beratung",
  },
  defaultProvenExpertTeaserCard,
];

export const settlementChoiceCards = [
  {
    image: iconAttorneyCheck,
    heading: "Kostenlos prüfen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("dismissal check"),
  },
  {
    image: iconCalculateSettlement,
    heading: "Abfindung berechnen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("calculate settlement"),
  },
  {
    image: iconInformation,
    heading: "Allgemein informieren",
    link: "/ratgeber/arbeitsrecht",
    onClick: () => handleClickGA("more information"),
  },
];

export const settlementTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const contractTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: iconDocument,
    heading: "Unkomplizierte Abwicklung",
  },
];

export const alimonyTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Unterhalt berechnen",
  },
];

export const documentTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: iconDocument,
    heading: "Persönliches Dokument",
  },
];

export const trafficTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Ersteinschätzung",
  },
];
