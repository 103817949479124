import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import CheckIcon from "../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../assets/icons/common/toggleable/IconKreuz";
import Pad from "../../Wizard/Pad";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import _ from "lodash";
import { getFinishedStep } from "../../../services/messagingService";
import { MessageCreateData } from "../../../types/Messaging/MessageCreateData";
import structuredClone from "@ungap/structured-clone";
import { MediaObjectCombinationRequest } from "../../../types/Entities/MediaObject/MediaObjectCombinationRequest";

const FURTHER_DOCUMENT_LIMIT = 9;

type AttachmentFormStepProps = {
  product: AbstractCase;
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
  headline: string;
  subHeadline: string;
  messageCreateData: MessageCreateData | null;
  mediaObjectCombinationRequestUpdateAction: (id: number, data: Record<string, any>) => Promise<void>;
  mediaObjectCombinationRequest: MediaObjectCombinationRequest;
};

export default function AttachmentFormStep({
  product,
  currentStep,
  setCurrentStep,
  steps,
  setSteps,
  setIsLoading,
  headline,
  messageCreateData,
  subHeadline,
  mediaObjectCombinationRequestUpdateAction,
  mediaObjectCombinationRequest,
}: AttachmentFormStepProps) {
  const [hasFurtherDocument, setHasFurtherDocument] = useState(false);
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource);

  //since the first step isnt a file upload
  const filesToCombineDataCurrentFileIndex = currentStep - 1;

  useEffect(() => {
    let mediaObjectCombinationRequestClone = structuredClone(mediaObjectCombinationRequest);
    if (hasFurtherDocument && !mediaObjectCombinationRequestClone.filesToCombine[filesToCombineDataCurrentFileIndex]) {
      _.set(mediaObjectCombinationRequestClone, "filesToCombine[" + filesToCombineDataCurrentFileIndex + "]", {
        files: [],
        description: "",
        fieldName: "uploadedFile",
      });
      mediaObjectCombinationRequestUpdateAction(mediaObjectCombinationRequest.id, mediaObjectCombinationRequestClone);
    }
    if (!hasFurtherDocument && mediaObjectCombinationRequestClone.filesToCombine[filesToCombineDataCurrentFileIndex]) {
      mediaObjectCombinationRequestClone.filesToCombine.splice(filesToCombineDataCurrentFileIndex, 1);
      mediaObjectCombinationRequestUpdateAction(mediaObjectCombinationRequest.id, mediaObjectCombinationRequestClone);
    }
  }, [hasFurtherDocument]);

  const handleNextStepClick = async () => {
    setIsLoading(true);
    const finishedStep = getFinishedStep(steps);
    if (hasFurtherDocument && currentStep < FURTHER_DOCUMENT_LIMIT) {
      //save description
      let mediaObjectCombinationRequestClone = structuredClone(mediaObjectCombinationRequest);
      _.set(mediaObjectCombinationRequestClone.filesToCombine, [filesToCombineDataCurrentFileIndex, "description"], description);

      await mediaObjectCombinationRequestUpdateAction(mediaObjectCombinationRequest.id, mediaObjectCombinationRequestClone); //id und data

      setIsLoading(false);

      if (currentStep + 1 === finishedStep) {
        //increase the general number of steps if the user presses next and the next step would be the note step
        setSteps((previousSteps: number) => previousSteps + 1);
      }
      setCurrentStep((previousStep: number) => previousStep + 1);
      return;
    }

    await createMutation.mutateAsync({
      uri: "/messages",
      data: messageCreateData!,
    });

    await queryClient.invalidateQueries(queryKeys.collection("/conversations"));
    setIsLoading(false);
    setCurrentStep(finishedStep);
  };

  const hasDocument = _.get(mediaObjectCombinationRequest.filesToCombine, `[${filesToCombineDataCurrentFileIndex}].files`, []).length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          {headline}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          {subHeadline}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Ja"}
          onChange={() => setHasFurtherDocument(true)}
          type={"radio"}
          active={hasFurtherDocument}
          error={false}
          name={"yes"}
          labelIcon={<CheckIcon />}
          labelIconActive={<CheckIcon active />}
        />
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Nein"}
          onChange={() => setHasFurtherDocument(false)}
          type={"radio"}
          active={!hasFurtherDocument}
          error={false}
          name={"no"}
          labelIcon={<CrossIcon />}
          labelIconActive={<CrossIcon active />}
        />
      </Grid>
      {hasFurtherDocument && (
        <Grid item xs={12}>
          <UploadBox
            product={product}
            currentFileIndex={filesToCombineDataCurrentFileIndex}
            description={description}
            setDescription={setDescription}
            updateAction={mediaObjectCombinationRequestUpdateAction}
            mediaObjectCombinationRequest={mediaObjectCombinationRequest}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Button fullWidth onClick={() => setCurrentStep((currentStep: number) => currentStep - 1)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button disabled={hasFurtherDocument && (!hasDocument || description.length < 5)} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}
