import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconStatus = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30">
        <g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M262.619,138.767c-2.209,0-4,1.791-4,4v120.26c0,5.988-4.872,10.861-10.86,10.861H52.242
				c-5.988,0-10.86-4.873-10.86-10.861V61.448c0-5.988,4.872-10.859,10.86-10.859h127.816c2.209,0,4-1.791,4-4s-1.791-4-4-4H52.242
				c-10.399,0-18.86,8.459-18.86,18.859v201.578c0,10.4,8.461,18.861,18.86,18.861h195.517c10.399,0,18.86-8.461,18.86-18.861
				v-120.26C266.619,140.558,264.828,138.767,262.619,138.767z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M184.224,25.325l23.255,17.254c2.845,2.021,5.772,5.172,5.91,6.295
				c-0.137,1.176-3.066,4.328-5.979,6.41l-23.188,17.234c-1.773,1.318-2.142,3.824-0.824,5.596c0.785,1.057,1.992,1.615,3.213,1.615
				c0.83,0,1.667-0.258,2.383-0.791l23.119-17.184c2.176-1.545,9.275-7.041,9.275-12.848s-7.099-11.303-9.208-12.801L188.99,18.901
				c-1.773-1.318-4.279-0.947-5.596,0.828S182.449,24.011,184.224,25.325z"
            />
          </g>
          <polygon
            style={{ fill: "#137F7B" }}
            points="234.819,63.653 126.342,209.019 74.676,176.433 60.806,198.425 132.804,243.833
			255.657,79.202 		"
          />
        </g>
      </g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconStatus;
