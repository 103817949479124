import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconHighway = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
            d="M14.25,85H285.13a4.15,4.15,0,0,1,4.14,4.14v15.43a4.15,4.15,0,0,1-4.14,4.14H270.29v31.44a4.15,4.15,0,0,1-4.14,4.14H248.56c-2.27,0-3.73-1.9-4.14-4.14l-5.84-31.44H60.8L55,140.11c-.41,2.24-1.87,4.14-4.14,4.14H33.22a4.15,4.15,0,0,1-4.13-4.14V108.67H14.25a4.15,4.15,0,0,1-4.14-4.14V89.1A4.15,4.15,0,0,1,14.25,85Z"
          />
        </>
      )}
      <g
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      >
        <path style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }} d="M149.69,290.34V133.9m0-63V10.28" />
        <path style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }} d="M47.28,290.34l34-159.4m12.81-60,12.94-60.66" />
        <path
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }}
          d="M252.1,290.34,217.76,129.46M205.27,70.94l-13-60.66"
        />
        <path
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }}
          d="M10.11,85H165.43m-31.91,23.71H60.8L55,140.11c-.41,2.24-1.87,4.14-4.14,4.14H33.22a4.15,4.15,0,0,1-4.13-4.14V108.67H14.25a4.15,4.15,0,0,1-4.14-4.14V98.75"
        />
        <path
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }}
          d="M289.27,85H134m26.74,23.71h77.89l5.84,31.44c.42,2.24,1.87,4.14,4.14,4.14h17.6a4.15,4.15,0,0,0,4.14-4.14V108.67h14.83a4.15,4.15,0,0,0,4.14-4.14V98.75"
        />
      </g>
    </SvgIcon>
  );
};

export default IconHighway;
