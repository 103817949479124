import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAssistant = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75">
        <g>
          <path
            style={{ fill: "none" }}
            d="M97.279,207.054c1.559,1.046,2.041,3.072,1.212,4.703c0.771,0.278,1.539,0.488,2.3,0.605
			c-0.015-1.772,1.246-3.348,3.051-3.65c3.125-0.525,6.375-2.882,9.4-6.815c1.071-1.392,2.942-1.8,4.468-1.083
			c-1.314-1.078-1.742-2.964-0.926-4.531c1.955-3.752,3.453-7.95,4.378-12.099c-3.218,1.157-6.59,2.019-10.086,2.561
			c-2.89,0.456-5.919,0.688-8.994,0.688c-3.363,0-6.719-0.286-9.973-0.849c-4.202-0.731-8.178-1.915-11.911-3.509
			C82.693,190.233,87.745,200.653,97.279,207.054z"
          />
          <path
            style={{ fill: "none" }}
            d="M151.56,93.636v32.421h32.742c1.11,0,2.16,0.502,2.857,1.365c0.337,0.417,7.136,9.073,5.571,24.452
			c4.874-4.517,11.154-12.511,11.501-22.276c0.07-1.976,1.692-3.541,3.669-3.541h63.184c0.867,0,1.573-0.706,1.573-1.574V25.04
			c0-0.866-0.706-1.573-1.573-1.573H126.968c-0.868,0-1.575,0.707-1.575,1.573v28.327C141.213,60.745,151.56,75.644,151.56,93.636z
			 M219.85,73.435c2.421,0,4.382,1.962,4.382,4.383c0,2.42-1.961,4.382-4.382,4.382c-2.419,0-4.382-1.962-4.382-4.382
			C215.468,75.397,217.431,73.435,219.85,73.435z M203.93,73.435c2.421,0,4.382,1.962,4.382,4.383c0,2.42-1.961,4.382-4.382,4.382
			c-2.419,0-4.382-1.962-4.382-4.382C199.548,75.397,201.511,73.435,203.93,73.435z M188.011,73.435c2.42,0,4.382,1.962,4.382,4.383
			c0,2.42-1.961,4.382-4.382,4.382s-4.382-1.962-4.382-4.382C183.629,75.397,185.59,73.435,188.011,73.435z"
          />
          <path
            style={{ fill: "none" }}
            d="M115.875,120.442c-13.166,0-25.524-6.872-32.145-17.477c-0.619,0.879-1.376,1.874-2.251,2.901
			c-0.033,0.117-0.053,0.236-0.098,0.351c-2.003,5.084-6.051,9.147-10.83,10.868c-1.477,0.531-3.349,0.96-5.546,0.96
			c-1.53,0-3.224-0.219-5.046-0.745v24.861c0,10.929,4.479,20.767,12.611,27.7c1.214,1.044,2.514,2.021,3.873,2.91
			c4.958,3.275,10.648,5.486,16.922,6.577c5.433,0.939,11.292,0.974,16.578,0.14c5.076-0.785,9.828-2.322,14.137-4.572
			c2.555-1.321,4.915-2.877,7.025-4.634c1.536-1.256,2.969-2.642,4.285-4.137c5.772-6.587,8.827-14.882,8.827-23.983v-38.141
			C137.496,113.192,127.321,120.442,115.875,120.442z M82.461,131.641c0-2.109,1.711-3.822,3.821-3.822
			c2.112,0,3.822,1.712,3.822,3.822c0,2.112-1.71,3.824-3.822,3.824C84.172,135.465,82.461,133.753,82.461,131.641z
			 M116.486,155.494c-0.281,0.286-6.987,7.013-16.403,7.013c-9.667,0-15.249-7.96-15.482-8.3c-0.736-1.072-0.465-2.539,0.608-3.276
			c1.07-0.736,2.532-0.466,3.271,0.601c0.055,0.08,4.465,6.263,11.602,6.263c7.399,0,12.987-5.549,13.043-5.605
			c0.914-0.922,2.404-0.933,3.329-0.021C117.381,153.081,117.395,154.566,116.486,155.494z M117.9,135.532
			c-2.109,0-3.821-1.71-3.821-3.821s1.712-3.825,3.821-3.825c2.112,0,3.824,1.714,3.824,3.825S120.012,135.532,117.9,135.532z"
          />
          <path
            style={{ fill: "none" }}
            d="M102.083,55.709c-4.852,0-9.433,0.64-13.669,1.822c-2.201,11.418-4.316,25.832-3.524,36.142
			c1.399,0.009,2.73,0.813,3.334,2.174c4.583,10.319,15.694,17.252,27.651,17.252c12.564,0,24.124-12.279,28.053-24.162
			c-1.622-13.53-10.228-24.272-22.905-29.586c-0.437-0.084-0.84-0.253-1.207-0.477C114.505,56.832,108.531,55.709,102.083,55.709z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M135.833,178.604c-0.67-0.354-1.185-0.888-1.518-1.509c-1.593,1.215-3.291,2.339-5.077,3.36
				c-0.745,6.469-2.815,13.216-5.939,19.218c-0.655,1.256-1.935,1.977-3.26,1.977c-0.539,0-1.083-0.13-1.598-0.379
				c1.56,1.245,1.845,3.513,0.624,5.102c-4.177,5.43-9.019,8.743-14.005,9.581c-0.206,0.035-0.411,0.052-0.613,0.052
				c-1.761,0-3.314-1.27-3.617-3.064c-0.032-0.196-0.036-0.387-0.038-0.579c-0.761-0.117-1.528-0.327-2.3-0.605
				c-0.066,0.131-0.125,0.266-0.209,0.392c-0.709,1.055-1.87,1.626-3.052,1.626c-0.704,0-1.415-0.202-2.043-0.624
				c-15.772-10.588-20.878-29.566-22.076-35.156c-0.5-0.353-0.994-0.706-1.477-1.078c-1.791,0.792-3.477,1.531-5.072,2.223
				c3.663,10.73,9.168,25.519,13.872,32.543c5.189,7.75,18.603,16.9,28.522,14.646c7.941-1.804,25.02-11.858,30.093-47.095
				C136.629,179.019,136.213,178.806,135.833,178.604z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M68.064,110.175c2.821-1.015,5.245-3.501,6.484-6.648c0.743-1.887,2.875-2.813,4.761-2.072
				c1.771,0.697,2.678,2.618,2.17,4.411c0.875-1.027,1.632-2.022,2.251-2.901c-0.828-1.326-1.58-2.701-2.218-4.138
				c-0.823-1.853,0.012-4.021,1.865-4.845c0.492-0.218,1.006-0.313,1.511-0.309c-0.792-10.31,1.323-24.724,3.524-36.142
				c-17.076,4.767-28.455,18.497-28.455,36.104v15.854C62.877,110.83,65.599,111.063,68.064,110.175z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M60.485,223.877c-2.027,0-3.671,1.643-3.671,3.67v52.658c0,2.027,1.644,3.671,3.671,3.671
				s3.671-1.644,3.671-3.671v-52.658C64.156,225.52,62.512,223.877,60.485,223.877z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M143.353,223.877c-2.028,0-3.671,1.643-3.671,3.67v52.658c0,2.027,1.644,3.671,3.671,3.671
				c2.027,0,3.671-1.644,3.671-3.671v-52.658C147.024,225.52,145.38,223.877,143.353,223.877z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M271.084,16.124H126.968c-4.917,0-8.917,3.999-8.917,8.916v25.569
				c-4.972-1.452-10.328-2.242-15.969-2.242c-28.2,0-49.466,19.461-49.466,45.27v48.525c0,11.166,3.923,21.385,11.123,29.327
				c-10.581,4.632-15.384,6.46-17.564,7.162c-11.553,3.736-16.581,10.445-20.576,19.375c-6.812,15.282-5.523,64.784-5.465,66.884
				c0.055,1.992,1.688,3.57,3.668,3.57c0.034,0,0.069,0,0.104-0.001c2.027-0.056,3.625-1.744,3.568-3.772
				c-0.382-13.84-0.26-52.265,4.83-63.686c3.368-7.528,7.088-12.458,16.127-15.381c3.014-0.972,8.43-3.155,16.129-6.498
				c1.595-0.692,3.281-1.431,5.072-2.223c0.483,0.373,0.978,0.725,1.477,1.078c1.198,5.59,6.303,24.567,22.076,35.156
				c0.628,0.422,1.34,0.624,2.043,0.624c1.182,0,2.343-0.571,3.052-1.626c0.084-0.126,0.143-0.261,0.209-0.392
				c0.829-1.631,0.347-3.657-1.212-4.703c-9.534-6.401-14.586-16.821-17.08-23.979c3.733,1.595,7.708,2.778,11.911,3.509
				c3.254,0.563,6.61,0.849,9.973,0.849c3.074,0,6.104-0.232,8.994-0.688c3.497-0.542,6.869-1.404,10.086-2.561
				c-0.926,4.149-2.424,8.346-4.378,12.099c-0.816,1.567-0.388,3.454,0.926,4.531c-1.526-0.717-3.397-0.309-4.468,1.083
				c-3.025,3.933-6.276,6.291-9.4,6.815c-1.805,0.303-3.066,1.878-3.051,3.65c0.002,0.192,0.006,0.383,0.038,0.579
				c0.303,1.794,1.856,3.064,3.617,3.064c0.202,0,0.407-0.017,0.613-0.052c4.987-0.837,9.829-4.151,14.005-9.581
				c1.222-1.589,0.937-3.857-0.624-5.102c0.515,0.25,1.059,0.379,1.598,0.379c1.325,0,2.605-0.72,3.26-1.977
				c3.124-6.001,5.195-12.749,5.939-19.218c1.787-1.021,3.484-2.145,5.077-3.36c0.333,0.621,0.848,1.154,1.518,1.509
				c0.381,0.202,0.797,0.415,1.215,0.63c3.9,2.006,9.35,4.552,15.087,6.402c9.042,2.924,12.761,7.854,16.127,15.379
				c5.113,11.452,5.902,49.906,5.775,63.757c-0.019,2.027,1.61,3.687,3.637,3.706c0.012,0,0.023,0,0.035,0
				c2.012,0,3.652-1.622,3.671-3.638c0.02-2.098,0.397-51.567-6.414-66.821c-3.992-8.925-9.019-15.634-20.574-19.371
				c-5.642-1.821-11.038-4.399-14.709-6.322c0.417-0.437,0.828-0.883,1.228-1.338c6.968-7.952,10.651-17.919,10.651-28.828V133.4
				h30.763c1.792,3.15,5.324,11.559,1.807,24.668c-0.411,1.526,0.05,3.09,1.203,4.082c0.573,0.495,1.425,0.957,2.606,0.957
				c0.695,0,1.503-0.161,2.436-0.569c5.564-2.433,18.566-13.933,20.865-29.138h59.845c4.917,0,8.916-4,8.916-8.917V25.04
				C280,20.123,276.001,16.124,271.084,16.124z M144.217,142.161c0,9.101-3.055,17.396-8.827,23.983
				c-1.316,1.496-2.749,2.881-4.285,4.137c-2.111,1.756-4.47,3.313-7.025,4.634c-4.309,2.25-9.062,3.788-14.137,4.572
				c-5.286,0.834-11.146,0.799-16.578-0.14c-6.274-1.091-11.964-3.302-16.922-6.577c-1.359-0.89-2.658-1.866-3.873-2.91
				c-8.132-6.933-12.611-16.771-12.611-27.7V117.3c1.823,0.526,3.516,0.745,5.046,0.745c2.197,0,4.069-0.429,5.546-0.96
				c4.779-1.72,8.827-5.783,10.83-10.868c0.045-0.115,0.065-0.233,0.098-0.351c0.508-1.793-0.398-3.714-2.17-4.411
				c-1.886-0.742-4.019,0.185-4.761,2.072c-1.24,3.147-3.663,5.633-6.484,6.648c-2.465,0.888-5.187,0.654-8.105-0.686V93.636
				c0-17.607,11.379-31.338,28.455-36.104c4.236-1.183,8.817-1.822,13.669-1.822c6.449,0,12.423,1.123,17.734,3.166
				c0.366,0.223,0.77,0.392,1.207,0.477c12.677,5.314,21.283,16.056,22.905,29.586c-3.929,11.882-15.488,24.162-28.053,24.162
				c-11.957,0-23.069-6.933-27.651-17.252c-0.604-1.361-1.936-2.165-3.334-2.174c-0.505-0.004-1.019,0.091-1.511,0.309
				c-1.854,0.824-2.688,2.992-1.865,4.845c0.638,1.437,1.39,2.812,2.218,4.138c6.62,10.604,18.979,17.477,32.145,17.477
				c11.445,0,21.62-7.25,28.342-16.421V142.161z M272.657,124.483c0,0.868-0.706,1.574-1.573,1.574h-63.184
				c-1.977,0-3.599,1.565-3.669,3.541c-0.347,9.766-6.627,17.76-11.501,22.276c1.564-15.379-5.234-24.035-5.571-24.452
				c-0.697-0.863-1.747-1.365-2.857-1.365H151.56V93.636c0-17.993-10.347-32.891-26.167-40.27V25.04
				c0-0.866,0.707-1.573,1.575-1.573h144.116c0.867,0,1.573,0.707,1.573,1.573V124.483z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="188.011" cy="77.817" r="4.382" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="203.93" cy="77.817" r="4.382" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="219.85" cy="77.817" r="4.382" />
          </g>
        </g>
      </g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAssistant;
