const teaserWbsStyle = {
  subText: {
    margin: "1rem 0",
  },
  textContent: (theme) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }),
  button: {
    minWidth: 200,
  },
  smallImage: {
    maxWidth: "100%",
    width: "120px",
    height: "120px",
    display: "block",
    margin: "0 auto",
  },
};

export default teaserWbsStyle;
