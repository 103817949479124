import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCosts = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M97.298,244.285c1.515,0,6.024-3.639,9.007-6.047c1.176-0.959,119.408-97.343,135.956-111.538
			c1.1-0.944,2.135-1.822,3.108-2.649c13.238-11.246,14.03-11.918,17.219-40.123c0.939-8.346,1.89-15.28,2.654-20.851
			c1.336-9.743,2.219-16.18,1.672-20.139c-0.246-1.747-0.739-2.954-1.649-4.024c-0.044-0.052-0.087-0.105-0.129-0.159
			c-2.537-3.293-7.706-3.814-23.661-4.964c-5.606-0.404-12.584-0.907-20.94-1.712c-8.269-0.8-14.778-1.333-19.67-1.333
			c-10.39,0-13.094,2.088-23.154,9.855c-1.01,0.779-2.082,1.607-3.232,2.486c-17.323,13.25-136.102,108.945-137.299,109.91
			c-3.067,2.495-7.289,5.928-7.755,7.484c-0.2,0.668,0.899,2.579,3.267,5.681l59.789,73.751
			C94.973,242.814,96.595,244.285,97.298,244.285z M238.657,44.096c6.413,0,11.612,5.198,11.612,11.612S245.07,67.32,238.657,67.32
			c-6.412,0-11.611-5.198-11.611-11.612S232.245,44.096,238.657,44.096z M115.655,116.496c-1.478-1.848-1.178-4.543,0.669-6.022
			c1.849-1.478,4.544-1.178,6.022,0.669l7.785,9.731c0.827-0.769,1.685-1.545,2.586-2.332c0.106-0.093,0.217-0.18,0.331-0.262
			c1.122-0.8,2.214-1.539,3.292-2.25l-7.605-9.507c-1.478-1.848-1.178-4.544,0.669-6.022c1.848-1.479,4.544-1.178,6.022,0.669
			l8.358,10.448c22.283-11.59,34.078-2.248,44.992,9.465c1.613,1.731,1.518,4.442-0.213,6.055c-1.731,1.613-4.442,1.517-6.055-0.213
			c-9.62-10.323-17.309-16.43-33.299-8.526l16.166,20.209c1.478,1.848,1.178,4.543-0.669,6.021c-0.79,0.632-1.735,0.939-2.673,0.939
			c-1.257,0-2.502-0.55-3.349-1.608l-16.973-21.217c-1.138,0.744-2.303,1.53-3.516,2.392c-0.953,0.834-1.839,1.646-2.696,2.447
			l16.797,20.997c1.478,1.848,1.178,4.544-0.669,6.022c-0.79,0.632-1.735,0.939-2.673,0.939c-1.257,0-2.502-0.55-3.349-1.608
			l-16.105-20.132c-11.894,14.009-7.698,23.007,0.241,34.946c1.311,1.97,0.775,4.63-1.195,5.94
			c-0.729,0.485-1.553,0.717-2.368,0.717c-1.386,0-2.746-0.672-3.571-1.913c-8.957-13.47-15.375-27.244,1.489-46.448
			L115.655,116.496z"
        />
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M277.169,46.379c-0.462-0.603-0.974-1.133-1.533-1.613c0.123,4.688-0.695,10.656-1.904,19.475
				c-0.758,5.525-1.701,12.402-2.628,20.647c-3.455,30.554-5.214,32.975-20.186,45.693c-0.964,0.819-1.988,1.688-3.077,2.623
				c-16.629,14.264-134.944,110.716-136.137,111.688c-6.305,5.089-10.074,7.961-14.405,7.961c-4.626,0-7.881-3.354-11.359-7.405
				c-0.026-0.031-0.052-0.062-0.077-0.093l-54.945-67.777c-0.992,0.857-1.537,1.328-1.537,1.328
				c-10.854,8.826-11.882,10.369-5.169,19.151l59.866,73.847c7.196,8.397,8.926,7.698,19.807-1.085
				c0,0,133.028-116.233,149.809-130.628c16.767-14.411,18.181-13.583,21.778-45.543C279.082,62.674,282.666,52.82,277.169,46.379z"
          />
          <circle style={{ fill: "#137F7B" }} cx="238.657" cy="55.708" r="11.612" />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M275.636,44.767c-0.028-1.063-0.104-2.062-0.235-3.012c-0.472-3.351-1.597-5.995-3.54-8.311
				c-5.003-6.416-12.791-6.977-29.77-8.2c-5.561-0.401-12.481-0.899-20.733-1.694c-8.514-0.824-15.257-1.373-20.493-1.373
				c-13.313,0-18.038,3.648-28.391,11.641c-1,0.771-2.061,1.591-3.2,2.462C151.865,49.595,33.002,145.358,31.788,146.336
				c-11.668,9.488-14.697,13.527-5.869,25.077c0.025,0.032,0.05,0.064,0.076,0.096l4.921,6.07l54.945,67.777
				c0.025,0.031,0.051,0.062,0.077,0.093c3.478,4.051,6.733,7.405,11.359,7.405c4.331,0,8.1-2.872,14.405-7.961
				c1.193-0.973,119.508-97.424,136.137-111.688c1.089-0.934,2.113-1.804,3.077-2.623c14.972-12.718,16.731-15.139,20.186-45.693
				c0.928-8.246,1.871-15.122,2.628-20.647C274.941,55.423,275.759,49.455,275.636,44.767z M29.424,160.48
				c0.466-1.557,4.688-4.99,7.755-7.484c1.198-0.965,119.977-96.66,137.299-109.91c1.15-0.878,2.222-1.707,3.232-2.486
				c10.061-7.767,12.765-9.855,23.154-9.855c4.892,0,11.401,0.533,19.67,1.333c8.356,0.805,15.334,1.308,20.94,1.712
				c15.956,1.15,21.124,1.67,23.661,4.964c0.042,0.054,0.084,0.107,0.129,0.159c0.91,1.071,1.403,2.278,1.649,4.024
				c0.547,3.959-0.335,10.396-1.672,20.139c-0.764,5.571-1.714,12.505-2.654,20.851c-3.188,28.205-3.981,28.877-17.219,40.123
				c-0.974,0.827-2.008,1.706-3.108,2.649c-16.548,14.195-134.78,110.579-135.956,111.538c-2.983,2.408-7.492,6.047-9.007,6.047
				c-0.703,0-2.325-1.471-4.819-4.372L32.69,166.162C30.323,163.06,29.224,161.148,29.424,160.48z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M122.604,173.492c0.825,1.241,2.185,1.913,3.571,1.913c0.815,0,1.639-0.232,2.368-0.717
				c1.971-1.31,2.506-3.97,1.195-5.94c-7.939-11.939-12.135-20.938-0.241-34.946l16.105,20.132c0.846,1.058,2.091,1.608,3.349,1.608
				c0.938,0,1.883-0.307,2.673-0.939c1.848-1.478,2.147-4.174,0.669-6.022l-16.797-20.997c0.857-0.801,1.743-1.612,2.696-2.447
				c1.213-0.862,2.378-1.648,3.516-2.392l16.973,21.217c0.846,1.058,2.091,1.608,3.349,1.608c0.938,0,1.883-0.307,2.673-0.939
				c1.848-1.478,2.147-4.174,0.669-6.021L149.208,118.4c15.99-7.904,23.68-1.796,33.299,8.526c1.613,1.73,4.324,1.826,6.055,0.213
				c1.731-1.613,1.827-4.324,0.213-6.055c-10.914-11.713-22.709-21.055-44.992-9.465l-8.358-10.448
				c-1.478-1.848-4.174-2.148-6.022-0.669c-1.848,1.478-2.147,4.174-0.669,6.022l7.605,9.507c-1.077,0.71-2.169,1.45-3.292,2.25
				c-0.114,0.082-0.225,0.169-0.331,0.262c-0.901,0.786-1.759,1.563-2.586,2.332l-7.785-9.731c-1.478-1.847-4.173-2.147-6.022-0.669
				c-1.848,1.479-2.147,4.174-0.669,6.022l8.438,10.548C107.229,146.248,113.647,160.022,122.604,173.492z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconCosts;
