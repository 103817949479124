import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconBuyer = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M501.8,43.8H471c-4.7,0-8.8,3.2-9.9,7.8l-14.5,60.6H286.1V22.1c0-5.6-4.6-10.2-10.2-10.2h-82.2
        c-5.6,0-10.2,4.6-10.2,10.2v90.2H10.2c-6.3,0-11.2,6-10,12.2l40.9,205.9c4,20.4,19.2,34.6,36.8,34.6h111.8
        c5.6,0,10.2-4.6,10.2-10.2s-4.6-10.2-10.2-10.2H77.9c-7.7,0-14.8-7.6-16.8-18.1L22.6,132.7h160.8v61.3h-50.2
        c-8.7,0-13.4,10.7-7.5,17.1l101.5,109.1c4,4.3,11,4.3,14.9,0l101.5-109.1c6-6.4,1.3-17.1-7.5-17.1h-50.2v-61.3h155.7l-50.6,211.9
        H280.8c-5.6,0-10.2,4.6-10.2,10.2s4.6,10.2,10.2,10.2h111.5l22,56.1H103.1c-1.1,0-2.1,0.2-3.1,0.5c-19,2.9-33.5,19.3-33.5,39.1
        c0,21.8,17.7,39.5,39.5,39.5s39.5-17.7,39.5-39.5c0-6.9-1.8-13.5-5-19.1h245.3c-3.2,5.7-5,12.2-5,19.1c0,21.8,17.7,39.5,39.5,39.5
        s39.5-17.7,39.5-39.5c0-15.5-8.9-28.8-21.9-35.3l-28-71.5L479,64.2h22.8c5.6,0,10.2-4.6,10.2-10.2S507.4,43.8,501.8,43.8
        L501.8,43.8z M312.8,214.3l-78.1,83.9l-78.1-83.9h37c5.6,0,10.2-4.6,10.2-10.2v-81.6c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1V32.3h61.8
        v171.8c0,5.6,4.6,10.2,10.2,10.2L312.8,214.3z M105.9,479.7c-10.5,0-19.1-8.6-19.1-19.1s8.6-19.1,19.1-19.1s19.1,8.6,19.1,19.1
        S116.4,479.7,105.9,479.7z M420.2,479.7c-10.5,0-19.1-8.6-19.1-19.1s8.6-19.1,19.1-19.1s19.1,8.6,19.1,19.1
        S430.7,479.7,420.2,479.7z"
        />
        <circle cx="234.9" cy="355.1" r="10" />
      </g>
    </SvgIcon>
  );
};

export default IconBuyer;
