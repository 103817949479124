/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Theme } from "@mui/material/styles";

const messageCenterConversationStyle = {
  conversationContainer: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      padding: "0 .5rem",
      margin: "0 .5rem",
    },
    padding: "0 1rem",
    margin: "0 1rem",
  }),
};
export default messageCenterConversationStyle;
