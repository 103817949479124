import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAntragErstellen = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20">
        <g>
          <path
            style={{ fill: "none" }}
            d="M216.446,168.456c-0.089-0.136-0.179-0.271-0.27-0.406c-0.542-0.806-1.108-1.598-1.698-2.374
			c-0.096-0.125-0.193-0.249-0.29-0.374c-0.608-0.784-1.235-1.554-1.89-2.304c-0.075-0.085-0.153-0.169-0.228-0.254
			c-0.694-0.784-1.408-1.554-2.153-2.298c-0.745-0.745-1.515-1.459-2.3-2.154c-0.083-0.074-0.165-0.149-0.249-0.223
			c-0.75-0.655-1.521-1.284-2.306-1.892c-0.124-0.096-0.247-0.193-0.371-0.288c-0.774-0.589-1.566-1.154-2.372-1.697
			c-0.137-0.092-0.275-0.184-0.413-0.274c-0.814-0.537-1.642-1.052-2.485-1.54c-0.047-0.027-0.096-0.053-0.143-0.081l-39.439,39.439
			c-0.036,0.039-0.072,0.075-0.11,0.11l-5.165,5.165c0.085,0.051,0.167,0.11,0.252,0.162c0.817,0.495,1.623,1.009,2.414,1.548
			c0.063,0.043,0.128,0.084,0.192,0.127c0.816,0.561,1.613,1.151,2.399,1.757c0.146,0.113,0.293,0.225,0.439,0.34
			c0.762,0.601,1.51,1.221,2.241,1.864c0.131,0.115,0.259,0.233,0.389,0.349c0.757,0.678,1.501,1.37,2.223,2.092
			c0.724,0.723,1.417,1.469,2.096,2.227c0.115,0.128,0.231,0.255,0.345,0.384c0.643,0.732,1.264,1.479,1.864,2.241
			c0.116,0.147,0.23,0.296,0.345,0.445c0.605,0.784,1.193,1.579,1.752,2.393c0.046,0.066,0.088,0.135,0.134,0.201
			c0.537,0.789,1.049,1.592,1.542,2.406c0.052,0.085,0.11,0.166,0.161,0.251l6.238-6.237l0,0l38.476-38.474
			c-0.025-0.045-0.05-0.091-0.076-0.135C217.502,170.105,216.985,169.274,216.446,168.456z M174.609,207.988
			c-0.352,0.351-0.812,0.527-1.272,0.527s-0.92-0.176-1.272-0.527c-0.702-0.703-0.702-1.841,0-2.544l37.865-37.865
			c0.702-0.702,1.841-0.702,2.544,0c0.702,0.703,0.702,1.841,0,2.544L174.609,207.988z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M164.739,20.899h-29.763c-5.235,0-9.247,2.9-10.376,7.169c-0.209,0.791-0.331,1.623-0.331,2.498
				v2.199c0,0.875,0.122,1.708,0.331,2.498c1.13,4.269,5.142,7.169,10.376,7.169h29.763c5.234,0,9.247-2.9,10.377-7.169
				c0.209-0.791,0.331-1.623,0.331-2.498v-2.199c0-0.875-0.122-1.707-0.331-2.498C173.986,23.799,169.974,20.899,164.739,20.899z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M232.622,150.25l-12.505-12.504c-0.781-0.781-1.85-1.21-3.009-1.21
				c-1.311,0-2.62,0.557-3.591,1.527l-11.604,11.603c0.087,0.052,0.169,0.111,0.255,0.163c0.813,0.493,1.613,1.004,2.399,1.539
				c0.07,0.048,0.142,0.093,0.212,0.141c0.812,0.558,1.605,1.145,2.387,1.748c0.151,0.117,0.303,0.232,0.453,0.351
				c0.758,0.598,1.501,1.215,2.229,1.854c0.135,0.119,0.267,0.24,0.401,0.36c0.746,0.668,1.481,1.352,2.193,2.063
				c0.006,0.006,0.013,0.012,0.02,0.019c0.007,0.007,0.013,0.014,0.019,0.021c0.711,0.711,1.394,1.445,2.061,2.19
				c0.122,0.136,0.246,0.271,0.367,0.408c0.638,0.727,1.254,1.47,1.852,2.227c0.119,0.151,0.235,0.303,0.352,0.455
				c0.604,0.782,1.191,1.577,1.75,2.389c0.045,0.065,0.087,0.132,0.132,0.198c0.54,0.792,1.056,1.601,1.552,2.42
				c0.05,0.082,0.106,0.16,0.156,0.243l11.603-11.605C234.212,154.943,234.354,151.982,232.622,150.25z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M168.83,215.396c-0.07-0.104-0.137-0.209-0.208-0.312c-0.523-0.766-1.073-1.517-1.641-2.255
				c-0.107-0.14-0.215-0.28-0.324-0.418c-0.571-0.728-1.162-1.443-1.774-2.141c-0.1-0.114-0.203-0.226-0.304-0.338
				c-0.649-0.727-1.314-1.442-2.007-2.135c-0.693-0.693-1.409-1.357-2.135-2.006c-0.113-0.1-0.224-0.203-0.337-0.302
				c-0.698-0.612-1.413-1.203-2.141-1.774c-0.139-0.109-0.279-0.217-0.42-0.325c-0.739-0.567-1.489-1.118-2.256-1.642
				c-0.102-0.07-0.207-0.136-0.31-0.205c-0.77-0.519-1.553-1.018-2.35-1.492c-0.042-0.025-0.081-0.053-0.122-0.078l0,0.002
				l-1.033,3.3l-2.547,8.132l-0.041,0.13l-1.977,6.313c0,0,0,0,0,0c0.043,0.025,0.089,0.054,0.133,0.08
				c0.105,0.062,0.209,0.124,0.315,0.188c0.224,0.133,0.446,0.271,0.671,0.414c0.117,0.074,0.23,0.148,0.346,0.225
				c0.224,0.149,0.439,0.305,0.655,0.465c0.099,0.074,0.201,0.146,0.298,0.222c0.302,0.238,0.593,0.485,0.854,0.748
				c0.262,0.261,0.508,0.55,0.746,0.852c0.081,0.102,0.155,0.207,0.232,0.312c0.155,0.21,0.307,0.42,0.452,0.638
				c0.081,0.122,0.159,0.241,0.236,0.364c0.138,0.217,0.271,0.431,0.399,0.646c0.068,0.115,0.135,0.225,0.201,0.338
				c0.024,0.041,0.051,0.084,0.074,0.125l8.607-2.696l9.268-2.904l0.003-0.001c-0.026-0.044-0.056-0.085-0.082-0.129
				C169.842,216.941,169.346,216.163,168.83,215.396z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M241.795,209.936c-1.987,0-3.598,1.61-3.598,3.597v57.104c0,2.683-2.183,4.866-4.865,4.866H66.665
				c-2.681,0-4.863-2.183-4.863-4.866V92.882c0-1.987-1.611-3.597-3.598-3.597s-3.598,1.61-3.598,3.597v177.756
				c0,6.65,5.41,12.06,12.058,12.06h166.667c6.65,0,12.061-5.41,12.061-12.06v-57.104
				C245.393,211.546,243.782,209.936,241.795,209.936z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M233.333,28.069h-54.531c-1.192-6.284-6.834-10.767-14.063-10.767h-29.763
				c-7.228,0-12.87,4.483-14.063,10.767H66.665c-6.648,0-12.058,5.41-12.058,12.06v37.294c0,1.987,1.611,3.597,3.598,3.597
				s3.598-1.61,3.598-3.597V40.129c0-2.683,2.182-4.866,4.863-4.866h54.248c1.193,6.283,6.835,10.767,14.063,10.767h29.763
				c7.228,0,12.87-4.483,14.063-10.767h54.531c2.683,0,4.865,2.183,4.865,4.866v155.5c0,1.987,1.611,3.597,3.598,3.597
				s3.598-1.61,3.598-3.597v-155.5C245.393,33.479,239.983,28.069,233.333,28.069z M175.447,30.566v2.199
				c0,0.875-0.122,1.708-0.331,2.498c-1.13,4.269-5.143,7.169-10.377,7.169h-29.763c-5.234,0-9.246-2.9-10.376-7.169
				c-0.209-0.791-0.331-1.623-0.331-2.498v-2.199c0-0.875,0.122-1.707,0.331-2.498c1.129-4.27,5.141-7.169,10.376-7.169h29.763
				c5.235,0,9.248,2.9,10.377,7.169C175.325,28.859,175.447,29.691,175.447,30.566z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.733,99.876h121.284c0.993,0,1.799-0.806,1.799-1.799s-0.806-1.799-1.799-1.799H87.733
				c-0.994,0-1.798,0.806-1.798,1.799S86.74,99.876,87.733,99.876z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.733,80.688h121.284c0.993,0,1.799-0.806,1.799-1.799s-0.806-1.799-1.799-1.799H87.733
				c-0.994,0-1.798,0.806-1.798,1.799S86.74,80.688,87.733,80.688z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M98.896,138.251h99.076c0.993,0,1.799-0.806,1.799-1.799s-0.806-1.799-1.799-1.799H98.896
				c-0.993,0-1.799,0.806-1.799,1.799S97.903,138.251,98.896,138.251z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M98.896,119.063h99.076c0.993,0,1.799-0.806,1.799-1.799s-0.806-1.799-1.799-1.799H98.896
				c-0.993,0-1.799,0.806-1.799,1.799S97.903,119.063,98.896,119.063z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M234.848,159.393c3.31-3.308,3.453-8.55,0.318-11.687l-12.507-12.504
				c-1.459-1.46-3.431-2.264-5.552-2.264c-2.258,0-4.493,0.94-6.134,2.58l-13.272,13.272l-22.15,22.15H87.74
				c-0.994,0-1.798,0.806-1.798,1.799s0.804,1.799,1.798,1.799h84.214l-14.19,14.19H87.74c-0.994,0-1.798,0.806-1.798,1.799
				s0.804,1.799,1.798,1.799h66.426l-3.809,3.809l-0.301,0.299c-0.207,0.206-0.361,0.459-0.448,0.738l-3.715,11.863H98.892
				c-0.993,0-1.798,0.806-1.798,1.799s0.805,1.799,1.798,1.799h45.875l-1.73,5.524c0,0,0,0.001,0,0.001l-3.323,10.072H98.896
				c-0.993,0-1.799,0.806-1.799,1.799s0.806,1.799,1.799,1.799h42.539c0.9,0,1.639-0.664,1.771-1.526l9.004-2.971l0,0l0.004-0.001
				l20.979-6.573c0.275-0.086,0.526-0.238,0.73-0.441l0.301-0.299l0.001-0.001c0.001,0,0.001-0.001,0.002-0.001l7.381-7.381h14.664
				c0.994,0,1.798-0.806,1.798-1.799s-0.805-1.799-1.798-1.799h-11.066l16.71-16.709h9.285c0.994,0,1.798-0.806,1.798-1.799
				s-0.805-1.799-1.798-1.799h-5.687l16.062-16.061L234.848,159.393z M201.913,149.665l11.604-11.603
				c0.971-0.97,2.28-1.527,3.591-1.527c1.159,0,2.228,0.43,3.009,1.21l12.505,12.504c1.732,1.733,1.59,4.693-0.317,6.599
				l-11.603,11.605c-0.049-0.083-0.106-0.161-0.156-0.243c-0.496-0.82-1.012-1.628-1.552-2.42c-0.045-0.066-0.087-0.133-0.132-0.198
				c-0.559-0.813-1.146-1.607-1.75-2.389c-0.117-0.152-0.233-0.304-0.352-0.455c-0.597-0.757-1.213-1.5-1.852-2.227
				c-0.121-0.137-0.245-0.272-0.367-0.408c-0.667-0.745-1.35-1.479-2.061-2.19c-0.006-0.007-0.012-0.014-0.019-0.021
				c-0.007-0.006-0.013-0.012-0.02-0.019c-0.712-0.711-1.447-1.395-2.193-2.063c-0.134-0.12-0.267-0.242-0.401-0.36
				c-0.728-0.639-1.472-1.256-2.229-1.854c-0.15-0.119-0.302-0.234-0.453-0.351c-0.782-0.603-1.575-1.19-2.387-1.748
				c-0.07-0.048-0.142-0.093-0.212-0.141c-0.786-0.535-1.586-1.046-2.399-1.539C202.082,149.776,202,149.717,201.913,149.665z
				 M170.393,217.864l-9.268,2.904l-8.607,2.696c-0.023-0.04-0.05-0.083-0.074-0.124c-0.065-0.111-0.134-0.226-0.201-0.338
				c-0.128-0.215-0.262-0.431-0.399-0.646c-0.077-0.122-0.156-0.243-0.236-0.364c-0.145-0.217-0.298-0.429-0.452-0.638
				c-0.077-0.104-0.152-0.211-0.232-0.312c-0.237-0.301-0.484-0.591-0.746-0.852c-0.262-0.262-0.553-0.509-0.854-0.748
				c-0.097-0.077-0.199-0.148-0.298-0.222c-0.215-0.159-0.432-0.316-0.655-0.465c-0.115-0.076-0.23-0.151-0.346-0.225
				c-0.224-0.143-0.448-0.282-0.671-0.414c-0.105-0.063-0.211-0.127-0.315-0.188c-0.044-0.026-0.09-0.055-0.133-0.08c0,0,0,0,0,0
				l1.977-6.313l0.041-0.13l2.547-8.132l1.033-3.3l0-0.002c0.042,0.024,0.08,0.053,0.122,0.078c0.797,0.474,1.58,0.974,2.35,1.492
				c0.103,0.069,0.208,0.135,0.31,0.205c0.767,0.524,1.518,1.075,2.256,1.642c0.141,0.108,0.281,0.216,0.42,0.325
				c0.728,0.571,1.443,1.162,2.141,1.774c0.114,0.1,0.225,0.202,0.337,0.302c0.726,0.649,1.442,1.313,2.135,2.006
				c0.693,0.693,1.358,1.408,2.007,2.135c0.101,0.113,0.204,0.225,0.304,0.338c0.613,0.698,1.203,1.413,1.774,2.141
				c0.109,0.139,0.217,0.279,0.324,0.418c0.567,0.739,1.117,1.489,1.641,2.255c0.071,0.103,0.138,0.208,0.208,0.312
				c0.516,0.767,1.013,1.546,1.485,2.339c0.026,0.044,0.056,0.085,0.082,0.129L170.393,217.864z M179.592,209.562L179.592,209.562
				l-6.238,6.238c-0.051-0.085-0.109-0.166-0.161-0.251c-0.493-0.814-1.005-1.617-1.542-2.406c-0.045-0.066-0.088-0.135-0.134-0.201
				c-0.559-0.813-1.147-1.609-1.752-2.393c-0.115-0.148-0.229-0.297-0.345-0.445c-0.601-0.762-1.222-1.509-1.864-2.241
				c-0.114-0.129-0.23-0.256-0.345-0.384c-0.679-0.758-1.373-1.504-2.096-2.227c-0.722-0.722-1.466-1.414-2.223-2.092
				c-0.129-0.116-0.258-0.234-0.389-0.349c-0.731-0.643-1.479-1.263-2.241-1.864c-0.146-0.115-0.292-0.227-0.439-0.34
				c-0.786-0.606-1.583-1.196-2.399-1.757c-0.063-0.043-0.128-0.084-0.192-0.127c-0.791-0.539-1.597-1.053-2.414-1.548
				c-0.085-0.052-0.167-0.11-0.252-0.162l5.165-5.165l0.11-0.11l39.439-39.439c0.047,0.027,0.096,0.053,0.143,0.081
				c0.843,0.488,1.671,1.003,2.485,1.54c0.138,0.091,0.276,0.182,0.413,0.274c0.806,0.542,1.597,1.108,2.372,1.697
				c0.125,0.095,0.248,0.192,0.371,0.288c0.785,0.608,1.556,1.237,2.306,1.892c0.084,0.073,0.165,0.149,0.249,0.223
				c0.785,0.695,1.555,1.41,2.3,2.154c0.745,0.745,1.459,1.514,2.153,2.298c0.075,0.085,0.153,0.168,0.228,0.254
				c0.655,0.75,1.282,1.52,1.89,2.304c0.097,0.125,0.194,0.249,0.29,0.374c0.589,0.775,1.155,1.567,1.698,2.374
				c0.09,0.135,0.18,0.271,0.27,0.406c0.539,0.818,1.056,1.649,1.545,2.496c0.026,0.044,0.051,0.09,0.076,0.135L179.592,209.562z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M209.93,167.579l-37.865,37.865c-0.702,0.703-0.702,1.841,0,2.544
				c0.352,0.351,0.812,0.527,1.272,0.527s0.92-0.176,1.272-0.527l37.865-37.865c0.702-0.703,0.702-1.841,0-2.544
				C211.771,166.877,210.632,166.877,209.93,167.579z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAntragErstellen;
