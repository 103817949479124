import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconRedTrafficLight = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            style={{ fill: "#137F7B" }}
            d="M119.795,43.332h-10.354c0.002,1.342-0.744,2.63-2.038,3.24c-3.73,1.759-6.141,5.564-6.141,9.695
			v39.834v34.212v40.601v34.212v40.605v19.983c0,5.908,4.806,10.713,10.714,10.713h7.818v-22.717
			c-2.942-5.107-4.635-11.021-4.635-17.326s1.692-12.219,4.635-17.326v-40.161c-2.942-5.107-4.635-11.021-4.635-17.326
			s1.692-12.219,4.635-17.326v-40.161c-2.942-5.107-4.635-11.021-4.635-17.326s1.692-12.219,4.635-17.326V43.332z"
          />
        </>
      )}
      <g>
        <circle style={{ fill: active ? "#137F7B" : "#C33135", stroke: "C33135", strokeMiterlimit: 10 }} cx="149.991" cy="86.758" r="21.04" />
        <path
          style={{ fill: "none" }}
          d="M211.591,237.805c2.207-2.682,6.992-9.888,11.213-16.247c4.049-6.098,6.592-9.912,7.826-11.421
			c0.408-0.499,0.613-0.907,0.707-1.17c-0.352-0.125-1.023-0.269-2.107-0.269h-23.348v33.273
			C207.905,241.622,209.267,240.627,211.591,237.805z"
        />
        <path
          style={{ fill: "none" }}
          d="M70.772,59.071c-1.086,0-1.759,0.145-2.11,0.27c0.094,0.263,0.299,0.67,0.708,1.169
			c1.232,1.506,3.771,5.313,7.81,11.397c4.228,6.368,9.019,13.584,11.23,16.271c2.322,2.822,3.683,3.814,5.711,4.164V59.071H70.772z
			"
        />
        <path
          style={{ fill: "none" }}
          d="M211.591,162.991c2.207-2.682,6.992-9.888,11.213-16.247c4.049-6.099,6.592-9.913,7.826-11.421
			c0.408-0.5,0.613-0.907,0.707-1.17c-0.352-0.125-1.023-0.269-2.107-0.269h-23.348v33.27
			C207.907,166.805,209.269,165.813,211.591,162.991z"
        />
        <path
          style={{ fill: "none" }}
          d="M70.772,133.885c-1.086,0-1.758,0.145-2.11,0.27c0.094,0.262,0.299,0.67,0.707,1.169
			c1.232,1.506,3.771,5.313,7.81,11.397c4.228,6.368,9.019,13.584,11.23,16.271c2.322,2.822,3.683,3.814,5.711,4.164v-33.27H70.772z
			"
        />
        <path
          style={{ fill: "none" }}
          d="M211.591,88.178c2.207-2.682,6.992-9.888,11.213-16.247c4.049-6.099,6.592-9.913,7.826-11.421
			c0.408-0.5,0.613-0.908,0.707-1.17c-0.352-0.125-1.023-0.269-2.107-0.269h-23.348v33.27
			C207.907,91.992,209.269,90.999,211.591,88.178z"
        />
        <path
          style={{ fill: "none" }}
          d="M70.772,208.698c-1.086,0-1.758,0.145-2.11,0.27c0.094,0.263,0.299,0.67,0.708,1.169
			c1.232,1.507,3.77,5.313,7.81,11.397c4.228,6.367,9.019,13.584,11.23,16.27c2.324,2.824,3.685,3.817,5.711,4.167v-33.273H70.772z"
        />
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M114.361,42.552c1.705,0.991,3.891,0.411,4.882-1.294c6.338-10.909,18.121-17.687,30.748-17.687
				c12.625,0,24.409,6.777,30.751,17.688c0.663,1.141,1.86,1.777,3.091,1.777c0.609,0,1.227-0.156,1.791-0.484
				c1.705-0.991,2.284-3.177,1.293-4.882c-7.617-13.102-21.766-21.24-36.926-21.24c-15.163,0-29.312,8.139-36.924,21.241
				C112.076,39.375,112.655,41.562,114.361,42.552z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M141.885,276.428h-22.09h-7.818c-5.908,0-10.714-4.806-10.714-10.713v-19.983v-40.605v-34.212
				v-40.601V96.101V56.267c0-4.13,2.41-7.936,6.141-9.695c1.294-0.61,2.041-1.898,2.038-3.24c-0.001-0.508-0.101-1.023-0.332-1.513
				c-0.84-1.784-2.969-2.548-4.753-1.707c-4.879,2.301-8.393,6.704-9.685,11.816h-23.9c-5.511,0-7.771,2.329-8.697,4.282
				c-1.252,2.642-0.592,5.939,1.766,8.821c1.069,1.307,4.282,6.146,7.388,10.826c4.323,6.511,9.222,13.891,11.667,16.859
				c3.927,4.771,6.932,6.351,11.226,6.797v27.228H70.772c-5.511,0-7.771,2.329-8.697,4.282c-1.252,2.642-0.592,5.939,1.766,8.821
				c1.069,1.308,4.282,6.146,7.388,10.826c4.323,6.511,9.222,13.891,11.667,16.859c3.927,4.771,6.932,6.351,11.226,6.797v27.228
				H70.772c-5.511,0-7.771,2.329-8.697,4.282c-1.252,2.642-0.592,5.939,1.765,8.821c1.069,1.308,4.282,6.146,7.388,10.826
				c4.323,6.511,9.222,13.891,11.667,16.859c3.929,4.774,6.934,6.354,11.226,6.801v16.57c0,9.846,8.01,17.856,17.856,17.856h29.908
				c1.972,0,3.571-1.599,3.571-3.571S143.857,276.428,141.885,276.428z M88.41,88.178c-2.212-2.686-7.003-9.903-11.23-16.271
				c-4.04-6.085-6.578-9.891-7.81-11.397c-0.408-0.499-0.613-0.907-0.708-1.169c0.352-0.125,1.024-0.27,2.11-0.27h23.349v33.271
				C92.092,91.992,90.732,91,88.41,88.178z M88.41,162.991c-2.212-2.686-7.003-9.903-11.23-16.271
				c-4.04-6.085-6.578-9.891-7.81-11.397c-0.408-0.499-0.613-0.907-0.707-1.169c0.352-0.125,1.024-0.27,2.11-0.27h23.349v33.27
				C92.092,166.806,90.732,165.813,88.41,162.991z M88.41,237.805c-2.212-2.686-7.003-9.903-11.23-16.27
				c-4.04-6.085-6.578-9.891-7.81-11.397c-0.408-0.499-0.613-0.907-0.708-1.169c0.352-0.125,1.024-0.27,2.11-0.27h23.349v33.273
				C92.094,241.622,90.733,240.628,88.41,237.805z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M149.991,51.928c-12.9,0-24.176,7.055-30.195,17.504c-2.942,5.107-4.635,11.021-4.635,17.326
				s1.692,12.219,4.635,17.326c6.02,10.449,17.295,17.503,30.195,17.503c10.98,0,21.442-5.269,27.985-14.094
				c1.175-1.584,0.843-3.821-0.742-4.996c-1.584-1.175-3.82-0.843-4.995,0.742c-5.28,7.121-13.389,11.205-22.248,11.205
				c-15.267,0-27.688-12.42-27.688-27.687c0-15.267,12.42-27.688,27.688-27.688c15.266,0,27.686,12.42,27.686,27.688
				c0,1.972,1.6,3.571,3.572,3.571c1.972,0,3.57-1.599,3.57-3.571C184.819,67.553,169.195,51.928,149.991,51.928z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M149.991,133.885c15.266,0,27.686,12.42,27.686,27.687c0,1.972,1.6,3.571,3.572,3.571
				c1.972,0,3.57-1.599,3.57-3.571c0-19.205-15.624-34.83-34.829-34.83c-12.9,0-24.176,7.055-30.195,17.503
				c-2.942,5.107-4.635,11.021-4.635,17.326s1.692,12.219,4.635,17.326c6.02,10.449,17.295,17.503,30.195,17.503
				c10.98,0,21.442-5.269,27.985-14.093c1.175-1.584,0.843-3.821-0.742-4.996c-1.584-1.175-3.82-0.843-4.995,0.742
				c-5.28,7.121-13.389,11.205-22.248,11.205c-15.267,0-27.688-12.42-27.688-27.687S134.724,133.885,149.991,133.885z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M177.976,257.125c1.175-1.584,0.844-3.82-0.741-4.996c-1.585-1.176-3.821-0.843-4.995,0.741
				c-5.281,7.119-13.391,11.202-22.249,11.202c-15.267,0-27.688-12.42-27.688-27.687s12.42-27.687,27.688-27.687
				c15.266,0,27.686,12.42,27.686,27.687c0,1.972,1.6,3.571,3.572,3.571c1.972,0,3.57-1.599,3.57-3.571
				c0-19.205-15.624-34.83-34.829-34.83c-12.9,0-24.176,7.055-30.195,17.503c-2.942,5.107-4.635,11.021-4.635,17.326
				s1.692,12.219,4.635,17.326c6.02,10.449,17.295,17.503,30.195,17.503C160.97,271.215,171.431,265.948,177.976,257.125z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M217.105,167.531c2.44-2.966,7.122-10.017,11.648-16.836c3.114-4.69,6.333-9.54,7.405-10.85
				c2.357-2.883,3.018-6.182,1.766-8.823c-0.926-1.953-3.186-4.28-8.695-4.28h-23.348V99.514c4.291-0.447,7.295-2.026,11.224-6.797
				c2.44-2.966,7.122-10.017,11.648-16.836c3.114-4.69,6.333-9.54,7.405-10.85c2.357-2.883,3.018-6.182,1.766-8.823
				c-0.926-1.953-3.186-4.28-8.695-4.28h-23.9c-1.292-5.111-4.807-9.514-9.688-11.816c-1.787-0.841-3.914-0.077-4.754,1.707
				c-0.842,1.784-0.077,3.912,1.707,4.753c3.732,1.76,6.145,5.566,6.145,9.695v39.834v34.212v40.601v34.212v40.605v19.983
				c0,5.908-4.807,10.713-10.715,10.713h-29.908c-1.973,0-3.572,1.599-3.572,3.571s1.6,3.571,3.572,3.571h29.908
				c9.847,0,17.857-8.01,17.857-17.856v-16.57c4.289-0.447,7.293-2.028,11.224-6.801c2.44-2.966,7.122-10.017,11.648-16.835
				c3.114-4.69,6.333-9.54,7.405-10.85c2.357-2.883,3.018-6.182,1.766-8.823c-0.926-1.952-3.186-4.28-8.695-4.28h-23.348v-27.228
				C210.173,173.881,213.177,172.301,217.105,167.531z M229.229,133.885c1.084,0,1.756,0.145,2.107,0.269
				c-0.094,0.263-0.299,0.67-0.707,1.17c-1.234,1.508-3.777,5.322-7.826,11.421c-4.221,6.358-9.006,13.565-11.213,16.247
				c-2.322,2.821-3.684,3.814-5.709,4.163v-33.27H229.229z M231.337,59.34c-0.094,0.263-0.299,0.671-0.707,1.17
				c-1.234,1.508-3.777,5.322-7.826,11.421c-4.221,6.358-9.006,13.565-11.213,16.247c-2.322,2.821-3.684,3.814-5.709,4.163v-33.27
				h23.348C230.313,59.071,230.985,59.215,231.337,59.34z M229.229,208.698c1.084,0,1.756,0.145,2.107,0.269
				c-0.094,0.263-0.299,0.671-0.707,1.17c-1.234,1.509-3.777,5.323-7.826,11.421c-4.221,6.358-9.006,13.565-11.213,16.247
				c-2.324,2.823-3.686,3.817-5.709,4.167v-33.273H229.229z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconRedTrafficLight;
