import React from "react";
import Grid from "@mui/material/Grid";
import _includes from "lodash/includes";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../assets/icons/common/toggleable/IconKreuz";
import MoneyIcon from "../../../assets/icons/contract/toggleable/IconGeldEinfordern";
import GoodsIcon from "../../../assets/icons/contract/toggleable/IconWareEinfordern";
import QuestionMarkIcon from "../../../assets/icons/common/toggleable/IconFragezeichen";
import InputAdornment from "@mui/material/InputAdornment";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import { dateFieldDefault, isIban, isMoney, pastDateDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Group from "../../Wizard/Group";
import FormLegend from "../Common/FormLegend/FormLegend";
import { IbanInput } from "../../FormFields/MaskedInputs/IbanInput";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type ContractBaseEnforceClaimProps = {
  product: AbstractCase;
};
const ContractBaseEnforceClaim = ({ product }: ContractBaseEnforceClaimProps) => {
  const { values } = useForm();

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>Welches Ziel verfolgen Sie?</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField label="Zielsetzung" name="objectiveClient" validators={[requiredValidator("Bitte geben Sie Ihr Ziel an")]}>
          <MenuItem value="fulfillment">Erfüllung des Vertrags</MenuItem>
          <MenuItem value="withdrawal">Rücktritt vom Vertrag</MenuItem>
          {!_includes(["travelContract", "membershipContract", "eventContract"], product.name) && (
            <MenuItem value="replacement">Ersatz oder Austausch</MenuItem>
          )}
          {!_includes(["travelContract", "membershipContract", "eventContract"], product.name) && (
            <MenuItem value="repair">Nachbesserung oder Reparatur</MenuItem>
          )}
          <MenuItem value="reduction">Volle oder teilweise Erstattung</MenuItem>
          <MenuItem value="compensation">Schadensersatz</MenuItem>
          {!_includes(["eventContract", "serviceContract", "travelContract", "vehicleContract"], product.name) && (
            <MenuItem value="other">Andere Zielsetzung</MenuItem>
          )}
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="subjectOfDispute"
          question="Möchten Sie Geld oder den Erhalt einer Ware/Leistung einfordern?"
          options={[
            {
              labelText: "Ich möchte Geld einfordern",
              labelIcon: <MoneyIcon />,
              labelIconActive: <MoneyIcon active />,
              value: "money",
            },
            {
              labelText: "Ich möchte eine Ware oder Leistung einfordern",
              labelIcon: <GoodsIcon />,
              labelIconActive: <GoodsIcon active />,
              value: "goods",
            },
            {
              labelText: "Ich fordere etwas ganz anderes",
              labelIcon: <QuestionMarkIcon />,
              labelIconActive: <QuestionMarkIcon active />,
              value: "other",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.subjectOfDispute === "money" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wie viel Geld möchten Sie einfordern?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="Forderung in €"
              name="claimedAmountClient"
              validators={[
                requiredValidator("Bitte geben Sie Ihre Forderung an."),
                {
                  validator: isMoney,
                  message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00",
                },
              ]}
              helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLegend>Wie lautet Ihre Bankverbindung, auf die der geforderte Betrag überwiesen werden soll?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="IBAN für Rückerstattung"
              name="clientBankData.iban"
              validators={[
                {
                  validator: isIban,
                  message: "Die eingegebene IBAN ist ungültig",
                },
              ]}
              InputProps={{
                startAdornment: <InputAdornment position="start">DE</InputAdornment>,
                inputComponent: IbanInput,
              }}
              helperText={"Wenn Sie die IBAN gerade nicht wissen, lassen Sie das Feld gerne einfach leer."}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Group
          type="radio"
          name="clientHasAlreadyClaimed"
          question="Haben Sie bereits schriftlich eine Forderung gestellt?"
          options={[
            {
              labelText: "Ja, ich habe bereits etwas eingefordert.",
              labelIcon: <CheckIcon />,
              labelIconActive: <CheckIcon active />,
              value: "yes",
            },
            {
              labelText: "Nein, ich habe bisher nichts eingefordert.",
              labelIcon: <CrossIcon />,
              labelIconActive: <CrossIcon active />,
              value: "no",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.clientHasAlreadyClaimed === "yes" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben Sie schriftlich etwas eingefordert?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDateClient"
              label="Datum des Forderungsschreibens"
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastDateDefault]}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDeadlineClient"
              label="Frist im Forderungsschreiben "
              validators={[dateFieldDefault]}
              helperText={"Wenn Sie keine Frist gesetzt haben, dann lassen Sie das Feld einfach leer."}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default ContractBaseEnforceClaim;
