import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconBroken = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M488,208h-56c0-13.2-10.8-24-24-24h-32c-13.2,0-24,10.8-24,24h-32v-32c13.2,0,24-10.8,24-24v-32 c0-13.2-10.8-24-24-24V24c0-4.4-3.6-8-8-8H200c-4.4,0-8,3.6-8,8v72c-13.2,0-24,10.8-24,24v32c0,13.2,10.8,24,24,24v32h-32 c0-13.2-10.8-24-24-24h-32c-13.2,0-24,10.8-24,24H24c-4.4,0-8,3.6-8,8v112c0,4.4,3.6,8,8,8h56c0,13.2,10.8,24,24,24h32 c13.2,0,24-10.8,24-24h192c0,13.2,10.8,24,24,24h32c13.2,0,24-10.8,24-24h56c4.4,0,8-3.6,8-8V216C496,211.6,492.4,208,488,208z M80,320H32v-96h48V320z M144,336c0,4.4-3.6,8-8,8h-32c-4.4,0-8-3.6-8-8V208c0-4.4,3.6-8,8-8h32c4.4,0,8,3.6,8,8V336z M208,32h96 v64h-96V32z M184,152v-32c0-4.4,3.6-8,8-8h128c4.4,0,8,3.6,8,8v32c0,4.4-3.6,8-8,8H192C187.6,160,184,156.4,184,152z M280,320h-16 v-4.7l16-16V320z M352,320h-56v-40c0-3.2-2-6.2-5-7.4c-3-1.3-6.4-0.6-8.7,1.7l-32,32c-1.5,1.5-2.3,3.6-2.3,5.7v8h-88v-96h40 c4.4,0,8-3.6,8-8v-40h96v40c0,4.4,3.6,8,8,8h40V320z M416,336c0,4.4-3.6,8-8,8h-32c-4.4,0-8-3.6-8-8V208c0-4.4,3.6-8,8-8h32 c4.4,0,8,3.6,8,8V336z M480,320h-48v-96h48V320z" />
        <path d="M305.4,435.5C300.2,443,288,461.8,288,472c0,13.3,10.7,24,24,24s24-10.7,24-24c0-10.2-12.2-29-17.4-36.5 c-2.8-3.6-8-4.3-11.6-1.5C306.4,434.4,305.9,434.9,305.4,435.5z M312,480c-4.4,0-8-3.6-8-8c1.7-6.1,4.4-11.9,8-17.2 c3.6,5.3,6.3,11.1,8,17.2C320,476.4,316.4,480,312,480z" />
        <path d="M312,392c0-10.2-12.2-29-17.4-36.5c-2.8-3.6-8-4.3-11.6-1.5c-0.6,0.4-1.1,1-1.5,1.5C276.2,363,264,381.8,264,392c0,13.3,10.7,24,24,24S312,405.3,312,392z M280,392c1.7-6.1,4.4-11.9,8-17.2c3.6,5.3,6.3,11.1,8,17.2c0,4.4-3.6,8-8,8S280,396.4,280,392z" />
        <path d="M241.4,411.5C236.2,419,224,437.8,224,448c0,13.3,10.7,24,24,24c13.3,0,24-10.7,24-24 c0-10.2-12.2-29-17.4-36.5c-2.8-3.6-8-4.3-11.6-1.5C242.4,410.4,241.9,410.9,241.4,411.5z M248,456c-4.4,0-8-3.6-8-8 c1.7-6.1,4.4-11.9,8-17.2c3.6,5.3,6.3,11.1,8,17.2C256,452.4,252.4,456,248,456z" />
        <path d="M48,240h16v16H48V240z" />
        <path d="M176,288h16v16h-16V288z" />
        <path d="M208,288h32v16h-32V288z" />
        <path d="M448,240h16v16h-16V240z" />
      </g>
    </SvgIcon>
  );
};

export default IconBroken;
