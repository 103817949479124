import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconFamilie = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86">
        <g>
          <path
            style={{ fill: "none" }}
            d="M207.122,191.668c-3.097-1.115-5.722-3.754-7.023-7.059c-0.396-1.006,0.099-2.144,1.104-2.54
			c1.007-0.397,2.143,0.099,2.539,1.105c0.895,2.271,2.655,4.069,4.708,4.809c1.52,0.548,3.162,0.519,4.899-0.08v-11.628
			c0-14.445-11.681-24.93-27.775-24.93c-15.987,0-27.615,10.342-27.774,24.633c3.348,7.211,10.355,14.016,18.426,14.016
			c8.311,0,16.037-4.825,19.228-12.007c0.438-0.988,1.596-1.435,2.584-0.995c0.988,0.439,1.434,1.596,0.995,2.584
			c-3.808,8.574-12.973,14.333-22.806,14.333c-7.383,0-13.973-4.571-18.434-10.441v25.696c0,5.974,2.004,11.419,5.794,15.747
			c0.85,0.963,1.778,1.861,2.75,2.659c0.024,0.02,0.048,0.04,0.072,0.061c1.377,1.147,2.927,2.169,4.606,3.037
			c0.658,0.344,1.358,0.675,2.096,0.989c2.25,0.951,4.693,1.639,7.254,2.038c3.573,0.561,7.443,0.528,10.984-0.092
			c2.608-0.454,5.088-1.209,7.375-2.247c1.333-0.603,2.61-1.305,3.797-2.088c0.88-0.576,1.735-1.219,2.552-1.917
			c5.34-4.555,8.279-11.013,8.279-18.187v-17.199c-0.945,0.221-1.833,0.319-2.647,0.319
			C209.283,192.287,208.075,192.012,207.122,191.668z"
          />
          <path
            style={{ fill: "none" }}
            d="M217.711,129.479c-2.577,0.404-5.252,0.61-7.945,0.61c-2.964,0-5.929-0.252-8.812-0.75
			c-6.434-1.12-12.29-3.402-17.411-6.785c-0.788-0.519-1.534-1.061-2.255-1.609c-0.682,0.319-1.502,0.7-2.413,1.119l13.874,23.39
			c11.827,2.133,21.083,9.502,25.038,19.597l24.465-41.372c-1.513-0.674-3.115-1.402-4.761-2.164
			c-1.646,1.196-3.42,2.298-5.297,3.269C227.761,127.098,222.885,128.679,217.711,129.479z"
          />
          <path
            style={{ fill: "none" }}
            d="M130.042,140.259v-35.215c-5.872,8.396-15.012,15.153-25.322,15.153
			c-12.968,0-25.054-7.597-30.074-18.905c-0.585-1.317,0.008-2.86,1.327-3.446c1.318-0.585,2.86,0.01,3.445,1.327
			c4.196,9.452,14.365,15.802,25.302,15.802c11.465,0,21.308-10.436,25.212-20.716c-1.369-18.24-16.722-31.213-37.527-31.213
			c-21.804,0-37.629,14.249-37.629,33.882v14.875c2.67,1.166,5.18,1.349,7.478,0.521c2.697-0.969,5.009-3.333,6.187-6.32
			c0.529-1.341,2.045-2.001,3.386-1.471c1.342,0.528,2,2.044,1.472,3.386c-1.721,4.365-5.188,7.848-9.278,9.32
			c-1.256,0.452-2.849,0.815-4.72,0.815c-1.365,0-2.884-0.204-4.525-0.698v22.902c0,9.766,4.001,18.553,11.266,24.745
			c1.095,0.941,2.254,1.813,3.457,2.598c4.427,2.925,9.509,4.901,15.121,5.879c4.874,0.839,10.076,0.87,14.805,0.124
			c4.535-0.701,8.781-2.076,12.629-4.085c2.284-1.182,4.393-2.573,6.278-4.141c1.369-1.12,2.649-2.358,3.826-3.694
			C127.313,155.8,130.042,148.39,130.042,140.259z"
          />
          <path
            style={{ fill: "none" }}
            d="M186.445,250.313c7.571-0.527,10.269-6.603,11.202-11.569c-1.675,0.542-3.402,0.982-5.18,1.291
			c-2.231,0.39-4.553,0.588-6.894,0.588c-2.106,0-4.198-0.161-6.219-0.479c-1.667-0.259-3.294-0.63-4.876-1.094
			C175.842,245.206,179.084,250.824,186.445,250.313z"
          />
          <path
            style={{ fill: "none" }}
            d="M130.042,200.537H113c-0.899,0-1.63,0.732-1.63,1.632v21.105c0,0.901,0.731,1.633,1.63,1.633h17.042
			c0.899,0,1.632-0.732,1.632-1.633v-21.105C131.674,201.269,130.941,200.537,130.042,200.537z M127.766,209.296h-12.489
			c-0.901,0-1.632-0.73-1.632-1.631c0-0.901,0.731-1.632,1.632-1.632h12.489c0.9,0,1.631,0.731,1.631,1.632
			C129.397,208.566,128.666,209.296,127.766,209.296z"
          />
          <path
            style={{ fill: "none" }}
            d="M165.134,127.853c-0.819,0.29-1.661,0.566-2.514,0.848c-6.06,1.993-11.785,3.876-14.977,11.859
			c-3.337,8.34-4.292,28.586-4.506,34.603c3.599,1.73,6.157,3.863,8.128,6.29v-5.176c0-17.933,14.75-31.457,34.309-31.457
			c1.041,0,2.065,0.046,3.077,0.122l-12.765-21.521C172.372,124.991,168.283,126.74,165.134,127.853z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M91.387,180.648v40.339c0,0.901-0.731,1.632-1.632,1.632c-0.901,0-1.631-0.731-1.631-1.632
				v-40.495c-1.554-0.126-3.098-0.317-4.62-0.58c-0.143-0.024-0.281-0.058-0.424-0.084v84.73h14.023v-84.104
				c-1.545,0.138-3.115,0.215-4.698,0.215C92.065,180.669,91.726,180.654,91.387,180.648z M91.387,262.062
				c0,0.902-0.731,1.632-1.632,1.632c-0.901,0-1.631-0.73-1.631-1.632v-31.968c0-0.9,0.73-1.631,1.631-1.631
				c0.901,0,1.632,0.731,1.632,1.631V262.062z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M236.752,283.026c-0.076-17.295-1.284-29.436-3.402-34.187c-2.343-5.237-4.935-8.67-11.251-10.712
				c-2.295-0.742-6.859-2.609-13.267-5.428c-0.883,0.726-1.795,1.414-2.73,2.026c-1.334,0.88-2.772,1.668-4.26,2.365
				c-1.059,10.19-6.575,16.534-15.124,17.129c-0.411,0.028-0.816,0.043-1.213,0.043c-8.141,0-13.595-6.008-15.285-16.746
				c-0.778-0.341-1.525-0.692-2.219-1.055c-1.827-0.944-3.541-2.058-5.111-3.293c-0.289,0.153-0.471,0.243-0.491,0.252
				c-2.595,1.373-6.644,3.328-10.912,4.706c-6.319,2.044-8.91,5.477-11.251,10.714c-0.804,1.796-1.941,5.743-2.795,14.342
				c12.684,0.383,38.23,1.766,55.988,6.813c18.066,5.143,36.744,12.16,45.28,15.483v-0.504
				C237.633,284.976,236.757,284.104,236.752,283.026z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M164.944,102.784c-2.741-20.462-2.458-60.434-1.979-66.427c0.182-2.271,1.342-5.97,2.861-10
				c-6.812,4.879-12.6,13.346-12.6,28.042c0,2.565-0.017,5.034-0.033,7.409C153.05,83.028,152.969,95.691,164.944,102.784z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M277.389,203.939L277.389,203.939c1.442,0,2.611-1.168,2.611-2.609
				c0.007-23.979-0.915-54.361-5.351-62.938c-5.259-10.166-12.642-12.73-19.156-14.994l-1.339-0.468
				c-2.022-0.714-4.72-1.805-8.015-3.241c-1.317-0.576-2.736-1.21-4.211-1.881c0.742-0.719,1.46-1.47,2.147-2.252
				c1.178-1.346,2.25-2.773,3.206-4.235c24.201-7.102,24.093-23.278,23.915-49.545c-0.015-2.364-0.033-4.821-0.033-7.374
				c0-38.76-35.088-39.879-39.089-39.879c-1.442,0-2.611,1.168-2.611,2.611c0,1.442,1.168,2.61,2.611,2.61
				c3.466,0,33.868,0.973,33.868,34.658c0,2.565,0.016,5.034,0.032,7.41c0.156,23.083,0.235,36.039-15.196,42.715
				c1.772-4.511,2.71-9.396,2.71-14.441V67.851c0-1.803-1.461-3.264-3.264-3.264c-0.932,0-1.767,0.395-2.361,1.022
				c-8.459,0.768-12.799-8.68-12.999-9.125c-0.584-1.316-2.123-1.91-3.439-1.33c-1.319,0.582-1.917,2.123-1.336,3.442
				c2.035,4.615,7.91,12.281,16.805,12.281c0.022,0,0.045-0.002,0.067-0.002v19.208c0,6.448-1.685,12.328-5.013,17.48
				c-0.821,1.277-1.758,2.52-2.78,3.688c-1.161,1.321-2.425,2.542-3.781,3.65c-0.281,0.233-0.567,0.465-0.875,0.701
				c-1.617,1.257-3.405,2.396-5.326,3.389c-3.809,1.988-8.005,3.346-12.478,4.037c-4.729,0.742-9.843,0.705-14.639-0.122
				c-5.538-0.963-10.56-2.915-14.933-5.803c-1-0.658-1.937-1.344-2.782-2.04c-0.03-0.024-0.059-0.047-0.087-0.069
				c-0.181-0.149-0.364-0.301-0.554-0.465c-2.369-2.02-4.409-4.332-6.062-6.872c-3.363-5.164-5.067-11.078-5.067-17.576V60.114
				c5.362,4.943,13.01,9.694,22.625,9.694c0.731,0,1.478-0.028,2.231-0.085c20.858-1.577,28.805-18.546,29.133-19.269
				c0.597-1.312,0.017-2.86-1.296-3.457c-1.313-0.598-2.861-0.017-3.457,1.296c-0.068,0.148-6.963,14.876-24.773,16.223
				c-11.149,0.849-19.684-6.453-24.485-11.992l-2.332-2.903c-1.307-1.766-2.011-2.971-2.038-3.019
				c-0.713-1.252-2.305-1.689-3.558-0.978c-1.254,0.712-1.693,2.306-0.981,3.56c0.125,0.219,0.941,1.629,2.403,3.618v37.281
				c0,4.814,0.835,9.483,2.444,13.815c-0.554-0.241-1.102-0.484-1.621-0.743c-0.592-0.294-1.229-0.389-1.84-0.313
				c-0.033-0.019-0.063-0.04-0.095-0.059c-11.976-7.093-11.894-19.755-11.75-40.976c0.016-2.375,0.033-4.843,0.033-7.409
				c0-14.696,5.788-23.163,12.6-28.042c8.802-6.304,19.314-6.616,21.269-6.616h32.73c7.949,12.511,3.984,18.573,3.752,18.907
				c-0.848,1.141-0.627,2.755,0.504,3.625c1.144,0.879,2.783,0.665,3.662-0.478c0.288-0.375,6.933-9.374-4.34-26.122
				c-0.485-0.721-1.297-1.153-2.166-1.153h-34.142c-4,0-39.09,1.119-39.09,39.879c0,2.553-0.017,5.01-0.032,7.374
				c-0.151,22.31-0.238,37.333,14.561,45.707c0.303,0.619,0.792,1.152,1.457,1.483c2.603,1.294,5.546,2.367,8.914,3.285
				c1.222,1.746,2.587,3.396,4.099,4.927c-3.869,1.78-9.624,4.345-13.61,5.755c-0.783,0.277-1.588,0.542-2.404,0.81
				c-6.281,2.066-14.097,4.639-18.194,14.881c-3.383,8.455-4.484,26.75-4.81,34.542c-4.854-1.652-9.416-3.846-12.516-5.479
				c0.544-0.549,1.078-1.11,1.591-1.693c6.22-7.098,9.508-15.996,9.508-25.732v-43.33c0-23.037-18.987-40.409-44.165-40.409
				c-25.173,0-44.156,17.372-44.156,40.409v43.33c0,10.214,3.667,19.546,10.4,26.705c-9.624,4.216-13.988,5.876-15.961,6.514
				c-10.082,3.258-14.475,9.125-17.969,16.936c-2.563,5.747-4.15,17.54-4.717,35.048c-0.047,1.441,1.083,2.647,2.524,2.695
				c0.029,0,0.058,0,0.086,0c1.403,0,2.562-1.114,2.608-2.526c0.679-20.964,2.695-29.566,4.266-33.089
				c3.084-6.895,6.496-11.412,14.807-14.097c3.19-1.031,9.434-3.597,18.565-7.63c0.988,0.792,2.004,1.553,3.055,2.24
				c5.053,3.34,10.827,5.612,17.167,6.771c0.143,0.026,0.281,0.06,0.424,0.084c1.522,0.263,3.066,0.454,4.62,0.58v40.495
				c0,0.901,0.73,1.632,1.631,1.632c0.901,0,1.632-0.731,1.632-1.632v-40.339c0.339,0.006,0.678,0.021,1.018,0.021
				c1.583,0,3.153-0.078,4.698-0.215c1.125-0.101,2.239-0.228,3.328-0.4c5.233-0.81,10.159-2.408,14.634-4.743
				c2.392-1.238,4.623-2.692,6.656-4.298c0.232,0.493,0.612,0.92,1.131,1.194c3.479,1.84,8.809,4.415,14.45,6.238
				c8.48,2.741,11.216,7.271,13.964,13.351v17.371c0,7.58,2.562,14.512,7.417,20.055c0.351,0.399,0.727,0.771,1.097,1.149
				c-2.48,1.273-5.915,2.874-9.497,4.029c-7.647,2.475-10.976,6.924-13.621,12.841c-1.361,3.041-2.38,8.13-3.118,15.559
				c-0.385,3.88-0.681,8.305-0.879,13.15c-0.044,1.081,0.795,1.993,1.876,2.037c0.027,0.001,0.054,0.002,0.081,0.002
				c1.045,0,1.912-0.825,1.955-1.878c0.195-4.771,0.485-9.119,0.863-12.924c0-0.002,0-0.004,0-0.005
				c0.854-8.599,1.991-12.545,2.795-14.342c2.341-5.237,4.933-8.67,11.251-10.714c4.269-1.378,8.317-3.333,10.912-4.706
				c0.02-0.009,0.202-0.099,0.491-0.252c1.57,1.235,3.284,2.349,5.111,3.293c0.693,0.363,1.441,0.714,2.219,1.055
				c1.69,10.738,7.145,16.746,15.285,16.746c0.397,0,0.803-0.015,1.213-0.043c8.548-0.595,14.064-6.939,15.124-17.129
				c1.487-0.697,2.926-1.484,4.26-2.365c0.935-0.612,1.847-1.3,2.73-2.026c6.408,2.818,10.972,4.686,13.267,5.428
				c6.316,2.042,8.908,5.475,11.251,10.712c2.118,4.751,3.326,16.892,3.402,34.187c0.004,1.079,0.88,1.95,1.958,1.95
				c0.003,0,0.006,0,0.009,0c1.082-0.005,1.954-0.885,1.949-1.967c-0.055-12.611-0.749-29.053-3.742-35.767
				c-2.648-5.919-5.979-10.369-13.622-12.841c-1.45-0.468-4.598-1.663-11.366-4.616c5.141-5.56,7.939-12.754,7.939-20.617v-32.891
				c0-2.425-0.278-4.766-0.793-7.008l26.18-44.271c2.87,1.232,5.272,2.19,7.151,2.855l1.365,0.478
				c6.135,2.131,11.93,4.145,16.232,12.46c3.039,5.878,4.776,27.942,4.767,60.537C274.778,202.77,275.947,203.939,277.389,203.939z
				 M118.331,165.378c-1.886,1.568-3.995,2.959-6.278,4.141c-3.849,2.009-8.095,3.384-12.629,4.085
				c-4.729,0.747-9.932,0.716-14.805-0.124c-5.611-0.979-10.693-2.954-15.121-5.879c-1.203-0.786-2.362-1.657-3.457-2.598
				c-7.265-6.191-11.266-14.979-11.266-24.745v-22.902c1.641,0.494,3.16,0.698,4.525,0.698c1.871,0,3.464-0.364,4.72-0.815
				c4.089-1.472,7.557-4.955,9.278-9.32c0.528-1.342-0.13-2.857-1.472-3.386c-1.341-0.53-2.857,0.13-3.386,1.471
				c-1.178,2.987-3.49,5.351-6.187,6.32c-2.297,0.827-4.808,0.644-7.478-0.521V96.929c0-19.633,15.825-33.882,37.629-33.882
				c20.806,0,36.158,12.973,37.527,31.213c-3.904,10.28-13.747,20.716-25.212,20.716c-10.937,0-21.105-6.35-25.302-15.802
				c-0.585-1.317-2.127-1.913-3.445-1.327c-1.318,0.585-1.912,2.128-1.327,3.446c5.02,11.308,17.106,18.905,30.074,18.905
				c10.31,0,19.45-6.757,25.322-15.153v35.215c0,8.131-2.729,15.541-7.885,21.425C120.98,163.02,119.7,164.258,118.331,165.378z
				 M143.137,175.163c0.214-6.017,1.169-26.263,4.506-34.603c3.192-7.983,8.917-9.866,14.977-11.859
				c0.854-0.281,1.695-0.558,2.514-0.848c3.148-1.113,7.237-2.862,10.751-4.434l12.765,21.521c-1.012-0.075-2.037-0.122-3.077-0.122
				c-19.559,0-34.309,13.524-34.309,31.457v5.176C149.294,179.026,146.736,176.893,143.137,175.163z M179.354,240.145
				c2.021,0.318,4.113,0.479,6.219,0.479c2.341,0,4.663-0.198,6.894-0.588c1.778-0.309,3.505-0.75,5.18-1.291
				c-0.934,4.966-3.631,11.042-11.202,11.569c-7.361,0.511-10.604-5.107-11.967-11.262
				C176.061,239.515,177.687,239.886,179.354,240.145z M213.349,209.167c0,7.174-2.939,13.631-8.279,18.187
				c-0.816,0.698-1.671,1.341-2.552,1.917c-1.187,0.783-2.464,1.485-3.797,2.088c-2.287,1.038-4.767,1.793-7.375,2.247
				c-3.541,0.62-7.411,0.652-10.984,0.092c-2.561-0.399-5.004-1.086-7.254-2.038c-0.738-0.314-1.438-0.646-2.096-0.989
				c-1.679-0.867-3.229-1.889-4.606-3.037c-0.024-0.021-0.047-0.041-0.072-0.061c-0.972-0.798-1.899-1.696-2.75-2.659
				c-3.79-4.328-5.794-9.772-5.794-15.747v-25.696c4.461,5.87,11.051,10.441,18.434,10.441c9.833,0,18.999-5.76,22.806-14.333
				c0.439-0.988-0.006-2.146-0.995-2.584c-0.988-0.439-2.146,0.007-2.584,0.995c-3.19,7.182-10.917,12.007-19.228,12.007
				c-8.071,0-15.078-6.805-18.426-14.016c0.16-14.292,11.788-24.633,27.774-24.633c16.094,0,27.775,10.484,27.775,24.93v11.628
				c-1.737,0.598-3.379,0.628-4.899,0.08c-2.053-0.74-3.813-2.537-4.708-4.809c-0.396-1.007-1.532-1.502-2.539-1.105
				c-1.005,0.396-1.5,1.533-1.104,2.54c1.301,3.305,3.927,5.944,7.023,7.059c0.953,0.344,2.161,0.619,3.58,0.619
				c0.814,0,1.702-0.098,2.647-0.319V209.167z M217.787,165.05c-3.955-10.095-13.21-17.464-25.038-19.597l-13.874-23.39
				c0.911-0.418,1.731-0.799,2.413-1.119c0.721,0.549,1.467,1.091,2.255,1.609c5.121,3.383,10.978,5.665,17.411,6.785
				c2.883,0.498,5.848,0.75,8.812,0.75c2.692,0,5.368-0.206,7.945-0.61c5.173-0.8,10.049-2.381,14.482-4.695
				c1.877-0.97,3.651-2.073,5.297-3.269c1.646,0.762,3.248,1.49,4.761,2.164L217.787,165.05z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M277.374,209.273c-1.411,0.02-2.622,1.145-2.635,2.586c-0.079,8.444-0.25,10.196-0.255,10.24
				c-0.174,1.432,0.847,2.732,2.278,2.906c0.106,0.012,0.212,0.019,0.317,0.019c1.301,0,2.428-0.972,2.588-2.297
				c0.021-0.175,0.21-1.968,0.292-10.819C279.973,210.466,278.815,209.287,277.374,209.273z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M89.754,228.463c-0.901,0-1.631,0.731-1.631,1.631v31.968c0,0.902,0.73,1.632,1.631,1.632
				c0.901,0,1.632-0.73,1.632-1.632v-31.968C91.387,229.194,90.656,228.463,89.754,228.463z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M127.766,206.033h-12.489c-0.901,0-1.632,0.731-1.632,1.632c0,0.901,0.731,1.631,1.632,1.631
				h12.489c0.9,0,1.631-0.73,1.631-1.631C129.397,206.764,128.666,206.033,127.766,206.033z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M134.938,223.275v-21.105c0-2.699-2.197-4.896-4.896-4.896H113c-2.699,0-4.895,2.196-4.895,4.896
				v21.105c0,2.7,2.196,4.896,4.895,4.896h17.042C132.741,228.171,134.938,225.975,134.938,223.275z M131.674,223.275
				c0,0.901-0.732,1.633-1.632,1.633H113c-0.899,0-1.63-0.732-1.63-1.633v-21.105c0-0.9,0.731-1.632,1.63-1.632h17.042
				c0.899,0,1.632,0.732,1.632,1.632V223.275z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconFamilie;
