import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconMobileDesktop = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path style={{ fill: "none" }} d="M233.723,196.592c0,0-0.019,1.687,3.396,2.302C237.118,198.894,237.786,196.183,233.723,196.592z" />
        <path style={{ fill: "none" }} d="M38.135,186.567H24.717c1.408,3.463,4.515,7.822,11.233,7.822h80.895v-7.822H38.135z" />
        <polygon style={{ fill: "none" }} points="144.315,194.389 160.444,194.389 162.368,186.567 144.315,186.567 		" />
        <path
          style={{ fill: "none" }}
          d="M60.231,66.746v96.834h107.787L183,102.633c0.926-3.769,3.269-6.955,6.596-8.969
			s7.235-2.614,11.005-1.687l0.337,0.083V66.746H60.231z M162.198,151.15c-2.384-2.835-12.522-1.94-21.631-0.746
			c-9.098,1.197-35.204,7.755-35.204,7.755c5.67-6.114,13.577-10.139,17.603-10.59c4.035-0.447,6.273,1.197,6.273-0.892
			c0-2.085-4.781-0.604-7.464-6.412c-2.682-5.822,1.646-25.513,1.646-25.513c3.882-11.647,3.303-11.326-3.625-15.275
			c3.955,5.65,1.55,8.191-1.275,18.394c-3.222,11.641-0.519,22.863-0.519,22.863c-1.456-1.663-4.679-4.366-7.222-7.066
			c-2.544-2.703-6.188-9.666-6.188-9.666s1.457,1.35,3.432,2.807c1.976,1.457,5.716,4.675,5.716,4.675
			c0.21-6.962-2.288-7.168-6.963-10.6c-4.678-3.432-4.678-8.106-4.678-8.106c0,0.728,3.324,3.119,6.547,5.818
			c3.222,2.704,4.678,5.72,4.678,5.72c0.416-5.823-0.519-6.444-7.068-11.851c-6.548-5.407-5.819-11.538-5.819-11.538
			c0.206,2.498,3.428,5.719,7.897,9.094c4.469,3.375,5.822,7.744,5.822,7.744c0.831-7.897-1.872-9.041-8.835-15.382
			c-6.963-6.341-5.51-12.575-5.51-12.575c0.625,2.806,6.238,8.731,10.707,12.263c4.469,3.535,4.678,9.563,4.678,9.563
			c4.054-7.275-6.237-14.55-10.188-19.851c-3.928-5.273-3.512-12.391-3.507-12.472c-0.588-2.833-0.315-4.686-0.315-4.686
			c3.137,5.67,19.548,15.513,24.916,19.542c5.367,4.024,4.061,9.026,3.278,14.472c-1.001,6.995-4.088,27.847,2.683,32.22
			c7.165,4.622,17.009,0.597,21.187,1.041c4.028,0.437,4.873,2.536,7.065,4.558c0.082,0.075,0.163,0.149,0.245,0.22
			C163.484,149.228,162.198,151.15,162.198,151.15z"
        />
        <path style={{ fill: "none" }} d="M152.564,144.166c0,0,0.408,1.758,4.113,1.527C156.678,145.693,156.678,142.706,152.564,144.166z" />
        <path
          style={{ fill: "none" }}
          d="M198.248,109.39l-23.985,97.578l68.319,16.794l23.987-97.579L198.248,109.39z M240.916,205.078
			c-1.535-3.104-10.941-4.554-19.478-5.502c-8.529-0.945-33.698-0.817-33.698-0.817c6.516-4.288,14.594-6.174,18.348-5.687
			c3.766,0.497,5.429,2.486,5.894,0.586c0.466-1.886-4.206-1.609-5.346-7.485c-1.137-5.883,7.189-22.795,7.189-22.795
			c6.128-9.711,5.529-9.549,0.119-14.684c2.331,6.013-0.422,7.782-5.263,16.417c-5.524,9.851-5.577,20.65-5.577,20.65
			c-0.952-1.84-3.271-5.009-4.977-8.032s-3.46-10.153-3.46-10.153s1.016,1.545,2.486,3.311c1.471,1.758,4.146,5.521,4.146,5.521
			c1.744-6.277-0.473-7.023-3.957-11.183c-3.481-4.16-2.438-8.397-2.438-8.397c-0.163,0.657,2.327,3.566,4.646,6.742
			c2.323,3.172,2.974,6.234,2.974,6.234c1.673-5.193,0.966-5.968-3.772-12.337c-4.739-6.373-2.711-11.78-2.711-11.78
			c-0.369,2.316,1.837,5.965,5.141,10.025c3.308,4.061,3.56,8.334,3.56,8.334c2.515-6.991,0.319-8.632-4.59-15.943
			c-4.909-7.311-2.199-12.646-2.199-12.646c-0.057,2.686,3.716,9.318,6.988,13.527c3.268,4.202,2.109,9.723,2.109,9.723
			c5.304-5.702-2.415-14.604-4.816-20.298c-2.401-5.698-0.401-12.113-0.401-12.113s0.002,0.003,0.002,0.004
			c0.1-2.701,0.762-4.32,0.762-4.32c1.577,5.844,14.287,18.451,18.259,23.3c3.976,4.856,1.673,9.104-0.249,13.875
			c-2.476,6.128-9.932,24.376-4.764,29.857c5.475,5.797,15.318,4.334,19.009,5.669c3.56,1.3,3.854,3.393,5.399,5.72
			c0.058,0.085,0.114,0.17,0.178,0.252C242.511,203.622,240.916,205.078,240.916,205.078z"
        />
        <g>
          <path
            style={{ fill: "#60C0B8" }}
            d="M112.827,95.072c-7.839-5.553-10.478-11.676-11.326-15.762c-0.005,0.082-0.42,7.199,3.507,12.472
				c3.95,5.3,14.241,12.575,10.188,19.851c0,0-0.209-6.028-4.678-9.563c-4.469-3.531-10.082-9.457-10.707-12.263
				c0,0-1.453,6.234,5.51,12.575c6.963,6.341,9.666,7.485,8.835,15.382c0,0-1.354-4.37-5.822-7.744
				c-4.47-3.375-7.691-6.597-7.897-9.094c0,0-0.729,6.131,5.819,11.538c6.55,5.407,7.484,6.028,7.068,11.851
				c0,0-1.456-3.016-4.678-5.72c-3.223-2.7-6.547-5.09-6.547-5.818c0,0,0,4.675,4.678,8.106c4.675,3.432,7.173,3.638,6.963,10.6
				c0,0-3.74-3.218-5.716-4.675c-1.975-1.457-3.432-2.807-3.432-2.807s3.645,6.963,6.188,9.666c2.543,2.7,5.766,5.403,7.222,7.066
				c0,0-2.703-11.222,0.519-22.863c2.825-10.203,5.229-12.744,1.275-18.394C117.94,98.418,115.632,97.057,112.827,95.072z"
          />
          <path
            style={{ fill: "#60C0B8" }}
            d="M213.944,148.693c-1.45-1.375-3.24-3.126-5.345-5.554c-5.882-6.794-6.908-12.944-6.765-16.841
				c0,0-0.002-0.004-0.002-0.004s-2,6.416,0.401,12.113c2.401,5.695,10.12,14.597,4.816,20.298c0,0,1.158-5.521-2.109-9.723
				c-3.272-4.209-7.045-10.842-6.988-13.527c0,0-2.71,5.335,2.199,12.646c4.909,7.311,7.104,8.952,4.59,15.943
				c0,0-0.252-4.273-3.56-8.334c-3.304-4.06-5.51-7.708-5.141-10.025c0,0-2.028,5.407,2.711,11.78
				c4.738,6.37,5.445,7.144,3.772,12.337c0,0-0.65-3.062-2.974-6.234c-2.319-3.176-4.81-6.085-4.646-6.742
				c0,0-1.044,4.238,2.438,8.397c3.484,4.16,5.701,4.906,3.957,11.183c0,0-2.675-3.762-4.146-5.521
				c-1.471-1.766-2.486-3.311-2.486-3.311s1.755,7.13,3.46,10.153s4.024,6.192,4.977,8.032c0,0,0.053-10.799,5.577-20.65
				C213.522,156.476,216.275,154.707,213.944,148.693z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M160.561,146.677c-0.082-0.071-0.163-0.146-0.245-0.22c-2.192-2.021-3.037-4.121-7.065-4.558
				c-4.178-0.444-14.021,3.581-21.187-1.041c-6.771-4.373-3.684-25.225-2.683-32.22c0.782-5.446,2.089-10.448-3.278-14.472
				c-5.368-4.029-21.779-13.872-24.916-19.542c0,0-0.273,1.854,0.315,4.686c0.848,4.085,3.486,10.209,11.326,15.762
				c2.805,1.985,5.113,3.346,6.969,4.405c6.928,3.95,7.507,3.628,3.625,15.275c0,0-4.327,19.69-1.646,25.513
				c2.683,5.808,7.464,4.327,7.464,6.412c0,2.089-2.238,0.444-6.273,0.892c-4.025,0.451-11.933,4.476-17.603,10.59
				c0,0,26.106-6.558,35.204-7.755c9.108-1.194,19.247-2.089,21.631,0.746C162.198,151.15,163.484,149.228,160.561,146.677z
				 M152.564,144.166c4.113-1.46,4.113,1.527,4.113,1.527C152.973,145.924,152.564,144.166,152.564,144.166z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M240.429,200.652c-0.063-0.082-0.12-0.167-0.178-0.252c-1.545-2.327-1.84-4.419-5.399-5.72
				c-3.69-1.335-13.534,0.128-19.009-5.669c-5.168-5.481,2.288-23.729,4.764-29.857c1.922-4.771,4.225-9.02,0.249-13.875
				c-3.972-4.849-16.682-17.457-18.259-23.3c0,0-0.662,1.62-0.762,4.32c-0.144,3.897,0.883,10.047,6.765,16.841
				c2.104,2.428,3.895,4.179,5.345,5.554c5.41,5.135,6.009,4.972-0.119,14.684c0,0-8.326,16.913-7.189,22.795
				c1.14,5.876,5.812,5.599,5.346,7.485c-0.465,1.9-2.128-0.089-5.894-0.586c-3.754-0.487-11.832,1.399-18.348,5.687
				c0,0,25.169-0.127,33.698,0.817c8.536,0.948,17.942,2.398,19.478,5.502C240.916,205.078,242.511,203.622,240.429,200.652z
				 M237.118,198.894c-3.414-0.615-3.396-2.302-3.396-2.302C237.786,196.183,237.118,198.894,237.118,198.894z"
          />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M270.97,123.52l-75.386-18.531l-25.722,104.644l75.384,18.53L270.97,123.52z M174.263,206.968
				l23.985-97.578l68.321,16.793l-23.987,97.579L174.263,206.968z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M272.558,109.664l-49.542-12.177V51.03c0-4.012-3.264-7.275-7.275-7.275H45.41
				c-4.012,0-7.275,3.264-7.275,7.275v53.2h7.275v-53.2h170.33v44.668l-7.527-1.85V59.47H52.956v111.385h113.273l-2.073,8.437H45.41
				v-65.292h-7.275v65.292H15.907l0.48,4.064c0.748,6.333,5.8,18.309,19.563,18.309h122.707l-4.638,18.865
				c-1.916,7.79,2.864,15.688,10.656,17.605l71.958,17.688c1.162,0.285,2.324,0.421,3.469,0.421c6.541,0,12.502-4.45,14.132-11.079
				l26.206-106.609l-7.064-1.737l-26.206,106.61c-0.958,3.895-4.905,6.284-8.8,5.329l-71.957-17.687
				c-3.896-0.959-6.287-4.908-5.328-8.804l28.98-117.896c0.462-1.882,1.633-3.474,3.299-4.482c1.664-1.008,3.616-1.308,5.501-0.846
				l71.955,17.687c3.897,0.959,6.288,4.908,5.329,8.806l-0.788,3.215l7.066,1.731l0.787-3.212
				C285.132,119.479,280.351,111.582,272.558,109.664z M24.717,186.567h13.418h78.71v7.822H35.95
				C29.232,194.389,26.125,190.03,24.717,186.567z M144.315,194.389v-7.822h18.053l-1.924,7.822H144.315z M189.596,93.664
				c-3.327,2.014-5.67,5.2-6.596,8.969l-14.981,60.946H60.231V66.746h140.706V92.06l-0.337-0.083
				C196.831,91.05,192.923,91.65,189.596,93.664z"
          />
          <circle style={{ fill: theme.palette.iconColor.main }} cx="204.923" cy="229.605" r="3.888" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconMobileDesktop;
