import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";


const IconMultiplePeople = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 1000 1000">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M989.8,771.3c-24.7-136.9-126.1-246.9-262.2-287.9c43-34.6,68.7-87,68.7-143.3c0-101.4-82.5-183.9-183.9-183.9c-17.9,0-30.6,2.5-44.2,6.3c-5.5,1.5-9.6,6.2-10.3,11.8c-0.7,5.7,2,11.2,7,14c76.3,44,89.5,119.6,90.6,160.8c1.2,47.5-18.9,111.6-65.9,140.1c-4.9,3-7.5,8.5-6.6,14.2c0.8,5.6,5,10.2,10.5,11.6C716,546.4,812,643.3,856.6,779.2c1.9,5.8,7.3,10.7,13.4,10.7h105.8c4.2,0,8.2-2.8,10.8-6C989.4,780.7,990.5,775.5,989.8,771.3z M880.2,761.7c-45.6-134.4-136.6-226-253.1-265.1c31.9-30.7,58.2-83.2,56.6-147.6c-1.7-68-28-125.2-74.9-164.1c1.2,0,2.4-0.2,3.6-0.2c85.8,0,155.6,69.7,155.6,155.5c0,57-31.1,109.3-81.2,136.7c-5.2,2.8-8.1,8.6-7.2,14.4c0.8,5.8,5.2,11.5,11,12.8c134.6,29,238.2,130.3,268,257.6L880.2,761.7L880.2,761.7z" />
        <path d="M559,513.5c49.5-38.5,79.2-98,79.2-161.9c0-113.1-92-205.1-205.1-205.1c-113.1,0-205.1,92-205.1,205.1c0,63.9,29.7,123.4,79.2,161.9C153.1,558.6,38.1,682.6,10.2,837c-0.7,4.1,0.4,8.4,3.1,11.6c2.7,3.2,6.7,5.1,10.8,5.1h818c4.2,0,8.2-1.8,10.8-5.1c2.7-3.2,3.8-7.4,3.1-11.6C828.2,682.6,713.2,558.6,559,513.5z M41.5,825.3c33.1-148.5,150.5-259.2,303.4-292.2c5.8-1.3,10.2-5.9,11-11.8c0.8-5.9-2-11.6-7.2-14.4c-56.9-31-92.2-90.5-92.2-155.3c0-97.5,79.3-176.8,176.8-176.8c97.5,0,176.8,79.3,176.8,176.8c0,64.8-35.4,124.3-92.3,155.3c-5.2,2.8-8.1,8.6-7.2,14.4c0.8,5.9,5.2,10.6,11,11.8c152.9,33,270.3,143.7,303.4,292.2L41.5,825.3L41.5,825.3z" />
      </g>
    </SvgIcon>
  );
};

export default IconMultiplePeople;
