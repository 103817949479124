import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CheckedIcon from "../../../assets/icons/generic/IconCheck";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type MessageCompletedProps = {
  product: AbstractCase;
};

export default function MessageCompleted({ product }: MessageCompletedProps) {
  return (
    <Stack alignItems={"center"} spacing={3}>
      <CheckedIcon sx={{ height: "100px", width: "100px" }} />
      <Typography>Ihre Nachricht wurde erfolgreich an unser Team versendet.</Typography>
      <Button fullWidth={false} component={Link} to={"/servicewelt/nachrichten?caseId=" + product.id} variant={"contained"}>
        Zurück zur Nachrichtenseite
      </Button>
    </Stack>
  );
}
