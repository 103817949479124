import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDistance = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g fill="none" style={{ fill: getIconColor(active, theme) }}>
        {!active && (
          <>
            <g fill="#137F7B">
              <path d="M20,173.19h13.674c1.32,0,2.391,1.07,2.391,2.391c0,0.004-0.001,0.008-0.001,0.012h6.695c0-0.002,0-0.004,0-0.007c0-12.085,9.833-21.917,21.919-21.917c9.079,0,17.32,5.706,20.507,14.199c0.464,1.236-0.162,2.615-1.398,3.078c-0.275,0.104-0.558,0.145-0.836,0.145v4.501h6.56c-0.003-0.971,0.582-1.887,1.544-2.248c1.862-0.705,7.214-3.479,7.493-6.891V150.99H20V173.19z" />
              <path d="M150.762,150.99c-0.319,0.425-0.629,0.855-0.917,1.3c-1.781,2.732-2.869,5.742-3.24,8.954v11.949h13.065c1.197-10.963,10.508-19.524,21.785-19.524c9.082,0,17.322,5.706,20.506,14.2c0.463,1.236-0.163,2.615-1.399,3.078c-0.278,0.104-0.562,0.146-0.841,0.146v4.501h9.361c0-0.004-0.002-0.008-0.002-0.012c0-1.321,1.07-2.391,2.391-2.391h68.523v-22.2H150.762z" />
            </g>
          </>
        )}
        <g>
          <path d="M33.673,177.971c1.316,0,2.384-1.064,2.39-2.379c0-0.004,0.001-0.008,0.001-0.012c0-1.321-1.071-2.391-2.391-2.391H20c-1.32,0-2.391,1.07-2.391,2.391s1.071,2.391,2.391,2.391H33.673z" />
          <path d="M20.004,133.126h78.542v17.864v15.463c-0.278,3.412-5.631,6.186-7.493,6.891c-0.963,0.361-1.548,1.277-1.544,2.248c0.001,0.276,0.042,0.556,0.145,0.829c0.359,0.96,1.271,1.553,2.239,1.553c0.278,0,0.562-0.049,0.838-0.153c1.037-0.389,10.146-4.002,10.592-11.135c0.003-0.05,0.005-0.099,0.005-0.149v-35.803c0-1.321-1.071-2.391-2.391-2.391H20.004c-1.32,0-2.391,1.07-2.391,2.391S18.684,133.126,20.004,133.126z" />
          <path d="M64.677,158.45c7.098,0,13.54,4.459,16.03,11.097c0.36,0.96,1.273,1.545,2.242,1.544c0.278,0,0.561-0.042,0.836-0.145c1.236-0.464,1.862-1.842,1.398-3.078c-3.187-8.493-11.428-14.199-20.507-14.199c-12.086,0-21.919,9.832-21.919,21.917c0,0.002,0,0.004,0,0.007c0.003,12.083,9.835,21.913,21.919,21.913c9.101,0,17.343-5.718,20.509-14.229c0.46-1.238-0.169-2.614-1.407-3.074c-1.237-0.461-2.615,0.17-3.075,1.407c-2.474,6.648-8.915,11.114-16.028,11.114c-9.45,0-17.137-7.688-17.137-17.138C47.54,166.137,55.228,158.45,64.677,158.45z" />
          <path d="M144.214,177.974h15.457c1.193,10.967,10.506,19.531,21.785,19.531c9.1,0,17.342-5.718,20.508-14.229c0.461-1.238-0.17-2.614-1.407-3.074c-1.236-0.461-2.614,0.17-3.074,1.407c-2.474,6.648-8.914,11.114-16.026,11.114c-9.066,0-16.49-7.083-17.078-16.003c0.186-0.34,0.3-0.723,0.3-1.137c0-0.414-0.114-0.797-0.3-1.136c0.591-8.917,8.014-15.997,17.078-15.997c7.1,0,13.541,4.459,16.028,11.096c0.36,0.959,1.27,1.544,2.237,1.545c0.279,0,0.563-0.041,0.841-0.146c1.236-0.463,1.862-1.842,1.399-3.078c-3.184-8.493-11.424-14.2-20.506-14.2c-11.277,0-20.588,8.561-21.785,19.524h-13.065v-11.949c0.371-3.211,1.459-6.221,3.24-8.954c0.288-0.444,0.598-0.875,0.917-1.3c11.12-14.787,40.834-17.864,48.415-17.864h56.428h0.012h14.967c0.008,0,0.016,0.003,0.024,0.003c0.011,0,0.021-0.002,0.031-0.003h9.356c1.32,0,2.392-1.07,2.392-2.391s-1.071-2.391-2.392-2.391h-6.334c0.422-1.607,1.041-3.858,1.902-6.728c0.705-2.348,0.457-4.25-0.736-5.653c-2.18-2.564-6.356-2.017-9.403-1.618c-0.601,0.079-1.134,0.152-1.548,0.174c-1.055,0.059-4.188,0.236-9.9,13.825h-52.264c6.346-5.827,17.543-16.165,21.918-20.539c0.934-0.934,0.934-2.448,0-3.381s-2.447-0.934-3.382,0c-5.536,5.537-22.802,21.349-25.835,24.122c-13.107,0.987-38.703,5.894-48.578,21.139c-2.214,3.397-3.559,7.154-3.998,11.165c-0.01,0.086-0.015,0.173-0.015,0.26v14.474C141.823,176.904,142.894,177.974,144.214,177.974z M264.461,119.271c0.47-0.039,0.994-0.108,1.583-0.185c1.106-0.144,4.47-0.584,5.105-0.079c0.002,0.003,0.105,0.333-0.166,1.235c-1.125,3.748-1.846,6.457-2.262,8.103h-9.516C261.223,123.911,263.405,120.096,264.461,119.271z" />
          <path d="M279.995,173.19h-68.523c-1.32,0-2.391,1.07-2.391,2.391c0,0.004,0.002,0.008,0.002,0.012c0.006,1.315,1.072,2.379,2.389,2.379h68.523c1.32,0,2.392-1.07,2.392-2.391S281.315,173.19,279.995,173.19z" />
          <path d="M280.001,207.255H22.059c-1.32,0-2.391,1.07-2.391,2.391s1.071,2.391,2.391,2.391h257.942c1.32,0,2.391-1.07,2.391-2.391S281.321,207.255,280.001,207.255z" />
          <path d="M125.069,98.846c1.32,0,2.391-1.07,2.391-2.391v-6.101c0-1.321-1.071-2.391-2.391-2.391s-2.391,1.07-2.391,2.391v6.101C122.678,97.775,123.749,98.846,125.069,98.846z" />
          <path d="M125.069,146.663c1.32,0,2.391-1.07,2.391-2.391V137.1c0-1.321-1.071-2.391-2.391-2.391s-2.391,1.07-2.391,2.391v7.173C122.678,145.593,123.749,146.663,125.069,146.663z" />
          <path d="M125.069,194.481c1.32,0,2.391-1.07,2.391-2.391v-7.173c0-1.321-1.071-2.391-2.391-2.391s-2.391,1.07-2.391,2.391v7.173C122.678,193.411,123.749,194.481,125.069,194.481z" />
          <path d="M125.069,170.572c1.32,0,2.391-1.07,2.391-2.391v-7.173c0-1.321-1.071-2.391-2.391-2.391s-2.391,1.07-2.391,2.391v7.173C122.678,169.502,123.749,170.572,125.069,170.572z" />
          <path d="M125.069,122.754c1.32,0,2.391-1.07,2.391-2.391v-7.173c0-1.321-1.071-2.391-2.391-2.391s-2.391,1.07-2.391,2.391v7.173C122.678,121.684,123.749,122.754,125.069,122.754z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconDistance;
