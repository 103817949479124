import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconLock = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46">
        <g>
          <path
            style={{ fill: "none" }}
            d="M150.01,56.642c-19.473,0-34.147,14.902-34.147,34.671v33.465h68.291V91.313
			C184.153,71.544,169.483,56.642,150.01,56.642z"
          />
          <path
            style={{ fill: "none" }}
            d="M220.358,140.481H79.658c-11.15,0-11.15,1.728-11.15,12.93v57.003
			c0,20.781,23.03,53.882,50.338,53.882h62.326c27.291,0,50.32-33.101,50.32-53.882v-57.003
			C231.491,142.209,231.491,140.481,220.358,140.481z M165.435,189.78c0,0,0,0,0,0.017c-0.055,0.437-0.314,2.198-0.771,4.676
			c-1.689,9.334-6.279,28.998-14.653,28.998c-8.36,0-12.93-19.524-14.641-28.892c-0.542-2.951-0.805-4.904-0.805-4.904
			c0-8.533,6.912-15.441,15.445-15.441c8.512,0,15.424,6.908,15.424,15.441V189.78z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M150.01,33.958c-31.864,0-56.83,25.198-56.83,57.354v33.465h15.703V91.313
			c0-23.746,17.674-41.651,41.127-41.651c23.45,0,41.122,17.905,41.122,41.651v33.465h15.705V91.313
			C206.837,59.156,181.87,33.958,150.01,33.958z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M220.794,124.778V91.313c0-39.992-31.09-71.313-70.784-71.313
				c-39.695,0-70.789,31.321-70.789,71.313v33.465c-26.417,0.19-26.417,17.99-26.417,28.633v57.003
				c0,27.375,28.354,69.585,66.041,69.585h62.326c37.672,0,66.025-42.21,66.025-69.585v-57.003
				C247.196,142.769,247.196,124.969,220.794,124.778z M93.18,91.313c0-32.157,24.966-57.354,56.83-57.354
				c31.86,0,56.827,25.198,56.827,57.354v33.465h-15.705V91.313c0-23.746-17.672-41.651-41.122-41.651
				c-23.453,0-41.127,17.905-41.127,41.651v33.465H93.18V91.313z M184.153,91.313v33.465h-68.291V91.313
				c0-19.769,14.675-34.671,34.147-34.671C169.483,56.642,184.153,71.544,184.153,91.313z M231.491,210.415
				c0,20.781-23.029,53.882-50.32,53.882h-62.326c-27.308,0-50.338-33.101-50.338-53.882v-57.003c0-11.202,0-12.93,11.15-12.93
				h140.701c11.133,0,11.133,1.728,11.133,12.93V210.415z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M150.01,174.233c-8.533,0-15.445,6.908-15.445,15.441c0,0,0.263,1.953,0.805,4.904
				c1.711,9.368,6.28,28.892,14.641,28.892c8.374,0,12.963-19.664,14.653-28.998c0.457-2.478,0.717-4.239,0.771-4.676
				c0-0.017,0-0.017,0-0.017v-0.105C165.435,181.141,158.522,174.233,150.01,174.233z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconLock;
