/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { AbstractCase } from "../../types/Entities/AbstractCase";
import React, { useMemo } from "react";
import { translate } from "../translations/translations";
import PriceIncreaseIcon from "../../assets/icons/provider/toggleable/IconPriceIncrease";
import CrossIcon from "../../assets/icons/common/toggleable/IconKreuz";
import ProviderCancellationIcon from "../../assets/icons/provider/toggleable/IconProviderCancellation";
import CancellationRefusedIcon from "../../assets/icons/contract/toggleable/IconWehren";
import WrongInvoiceIcon from "../../assets/icons/provider/toggleable/IconProviderWrongInvoice";
import QuestionMarkIcon from "../../assets/icons/common/toggleable/IconFragezeichen";
import GymIcon from "../../assets/icons/contract/toggleable/IconGym";
import ClubIcon from "../../assets/icons/contract/toggleable/IconClub";
import CalendarIcon from "../../assets/icons/common/toggleable/IconCalendar";

export const membershipContractFormDefinitions = {
  reasonForDispute: reasonForDispute,
  membershipType: membershipType,
  feePaymentFrequency: feePaymentFrequency,
  opponentReactionToCancellation: opponentReactionToCancellation,
};

function feePaymentFrequency() {
  return {
    options: ["monthly", "quarterly", "yearly"],
  };
}

function opponentReactionToCancellation(values: AbstractCase) {
  return [
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.noReaction.label`, values),
      labelIcon: <CrossIcon />,
      labelIconActive: <CrossIcon active />,
      value: "noReaction",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.noReaction.optionHintText`, values),
    },
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.label`, values),
      labelIcon: <CrossIcon />,
      labelIconActive: <CrossIcon active />,
      value: "cancellationRefused",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.optionHintText`, values),
    },
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.differentDate.label`, values),
      labelIcon: <CalendarIcon />,
      labelIconActive: <CalendarIcon active />,
      value: "differentDate",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.differentDate.optionHintText`, values),
    },
  ];
}

function membershipType(values: AbstractCase) {
  return useMemo(() => {
    return [
      {
        labelText: translate(`${values.name}.membershipType.options.gym.label`, values),
        labelIcon: <GymIcon />,
        labelIconActive: <GymIcon active />,
        value: "gym",
      },
      {
        labelText: translate(`${values.name}.membershipType.options.club.label`, values),
        labelIcon: <ClubIcon />,
        labelIconActive: <ClubIcon active />,
        value: "club",
      },
      {
        labelText: translate(`${values.name}.membershipType.options.other.label`, values),
        labelIcon: <QuestionMarkIcon />,
        labelIconActive: <QuestionMarkIcon active />,
        value: "other",
      },
    ];
  }, [values.providerType]);
}

function reasonForDispute(values: AbstractCase) {
  return useMemo(() => {
    return [
      {
        labelText: translate(`${values.name}.reasonForDispute.options.priceIncrease.label`, values),
        labelIcon: <PriceIncreaseIcon />,
        labelIconActive: <PriceIncreaseIcon active />,
        value: "priceIncrease",
        optionHintText: translate(`${values.name}.reasonForDispute.options.priceIncrease.optionHintText`, values),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.label`, values),
        labelIcon: <ProviderCancellationIcon />,
        labelIconActive: <ProviderCancellationIcon active />,
        value: "cancellationByContractPartner",
        optionHintText: translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.optionHintText`, values),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.cancellationRefused.label`, values),
        labelIcon: <CancellationRefusedIcon />,
        labelIconActive: <CancellationRefusedIcon active />,
        value: "cancellationRefused",
        optionHintText: translate(`${values.name}.reasonForDispute.options.cancellationRefused.optionHintText`, values),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.label`, values),
        labelIcon: <WrongInvoiceIcon />,
        labelIconActive: <WrongInvoiceIcon active />,
        value: "invoiceIncorrect",
        optionHintText: translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.optionHintText`, values),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.other.label`, values),
        labelIcon: <QuestionMarkIcon />,
        labelIconActive: <QuestionMarkIcon active />,
        value: "other",
        optionHintText: translate(`${values.name}.reasonForDispute.options.other.optionHintText`, values),
      },
    ];
  }, [values.providerType]);
}
