import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDistanceTwoToTen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            d="M4.75,237.27H24.46V237c0-12.26,10.33-22.2,23.07-22.2S70.6,224.74,70.6,237v.26h1.48c6.44,0,19.67-3.18,19.67-11.15V205.67h-87Z"
            style={{ fill: "#137F7B" }}
          />
          <path
            d="M159.72,237.3H182V237a22.68,22.68,0,0,1,45.35,0v.27h67.21V205.69H166.12C160.92,213.27,160.5,223.18,159.72,237.3Z"
            style={{ fill: "#137F7B" }}
          />
        </>
      )}
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          d="M74.92,86.11c0-11.22,5.19-12.6,17.11-20.51,7.46-5,14.29-9.1,14.29-20.6,0-10.67-5.74-19.86-19.57-19.86-15.29,0-21.57,11-21.57,20.23l10.1,2c0-8.55,4.28-13.79,11.38-13.79,6.73,0,9.55,5.42,9.55,11.4,0,11-8.73,13.34-20.2,20.7-7.1,4.6-11.2,8.55-11.2,20.41v8.47h41.51V86.11Zm46.42,25.48,31.31-86h-9.47l-31.4,86Zm58-86H159v8.46h10.19V94.58h10.11v-69Zm55.16,46.17V48.41c-.19-15.18-9.74-23.27-23.49-23.27s-23.48,8.09-23.48,23.27V71.77C187.51,87,197.25,95,211,95s23.3-8,23.49-23.27Zm-10.11-23V71.4c0,11-5.64,15.17-13.38,15.17s-13.38-4.14-13.38-15.17V48.77c0-11,5.65-15.17,13.38-15.17S224.37,37.74,224.37,48.77Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <line x1="4.75" y1="275.55" x2="294.54" y2="275.57" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.92", fill: "none" }} />
        <line
          x1="120.17"
          y1="137.48"
          x2="120.2"
          y2="262.65"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.69" }}
        />

        <path
          d="M4.75,237.27h7.78m70.24-1.84c4.76-1.72,9-4.72,9-9.31V185.56h-87"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }}
        />
        <path d="M70,244.58a22.14,22.14,0,1,1,0-15.15" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />

        <path
          d="M159.72,237.3h21.81V237m58.86.27h54.16m0-51.71H211.33c-50,10.26-50,21-51.61,51.71"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }}
        />
        <line x1="218.19" y1="185.59" x2="249.74" y2="156.42" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <path d="M224.53,244.6a22.14,22.14,0,1,1,0-15.14" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <line
          x1="120.17"
          y1="137.48"
          x2="120.2"
          y2="262.65"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.69" }}
        />
        <line
          x1="135.74"
          y1="137.48"
          x2="135.76"
          y2="262.65"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none", strokeDasharray: "6.9 20.69" }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconDistanceTwoToTen;
