import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconPersonSmile = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10">
        <g>
          <path
            style={{ fill: "none" }}
            d="M170.194,186.604c2.675-5.137,4.665-10.961,5.732-16.617c-4.314,1.705-8.882,2.949-13.65,3.686
			c-3.415,0.539-6.989,0.813-10.611,0.813c-3.968,0-7.928-0.337-11.77-1.001c-5.765-1.003-11.17-2.722-16.17-5.097
			c2.719,8.648,8.9,22.971,21.607,31.501c1.456,0.978,1.886,2.892,1.055,4.399c1.607,0.7,3.202,1.094,4.774,1.212
			c-0.077-1.674,1.09-3.179,2.782-3.465c4.007-0.673,8.125-3.631,11.909-8.554c1.104-1.435,3.135-1.722,4.599-0.684
			c0.369-0.56,0.73-1.135,1.082-1.72C169.958,190.193,169.354,188.218,170.194,186.604z"
          />
          <path
            style={{ fill: "none" }}
            d="M131.163,64.27c1.243,0.046,2.414,0.77,2.953,1.982c5.651,12.728,19.343,21.28,34.069,21.28
			c16.117,0,29.854-15.296,34.597-29.688c0.018-0.054,0.047-0.098,0.068-0.151c-2.737-24.047-23.413-40.975-51.185-40.975
			c-5.716,0-11.128,0.723-16.149,2.072C132.726,33.021,129.947,51.525,131.163,64.27z"
          />
          <path
            style={{ fill: "none" }}
            d="M115.641,155.27c1.493,1.282,3.085,2.479,4.742,3.563c6.054,4,13,6.701,20.661,8.034
			c6.619,1.145,13.739,1.189,20.195,0.169c6.193-0.958,11.995-2.835,17.253-5.581c3.122-1.614,6.011-3.521,8.6-5.674
			c1.873-1.532,3.631-3.231,5.246-5.067c7.083-8.083,10.831-18.255,10.831-29.412V72.306c-7.778,11.994-20.522,21.943-34.984,21.943
			c-15.574,0-30.19-8.195-37.869-20.817c-1.247,1.863-3.097,4.339-5.483,6.753c-2.503,4.72-6.585,8.413-11.287,10.105
			c-1.673,0.602-3.793,1.085-6.286,1.085c-2.109,0-4.49-0.359-7.087-1.274v31.2C100.172,134.702,105.666,146.765,115.641,155.27z
			 M170.608,104.206c2.53,0,4.581,2.051,4.581,4.58c0,2.527-2.051,4.576-4.581,4.576c-2.526,0-4.577-2.049-4.577-4.576
			C166.031,106.257,168.082,104.206,170.608,104.206z M164.577,133.313c1.094-1.104,2.88-1.117,3.988-0.024
			c1.107,1.092,1.125,2.872,0.036,3.982c-0.336,0.343-8.368,8.4-19.646,8.4c-11.576,0-18.264-9.535-18.542-9.941
			c-0.883-1.284-0.558-3.042,0.727-3.924c1.282-0.882,3.035-0.56,3.919,0.72c0.091,0.129,5.369,7.501,13.896,7.501
			C157.817,140.026,164.509,133.38,164.577,133.313z M132.74,104.123c2.528,0,4.577,2.052,4.577,4.579
			c0,2.527-2.049,4.579-4.577,4.579s-4.577-2.051-4.577-4.579C128.163,106.175,130.211,104.123,132.74,104.123z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M192.574,164.033c-1.03-0.544-1.659-1.549-1.771-2.627c-2.336,1.888-4.885,3.603-7.594,5.117
				c-0.826,7.71-3.343,16.051-7.056,23.185c-0.599,1.149-1.77,1.809-2.982,1.809c-0.523,0-1.054-0.123-1.549-0.381
				c-0.032-0.017-0.058-0.04-0.089-0.058c-0.352,0.585-0.713,1.16-1.082,1.72c0.036,0.026,0.076,0.042,0.111,0.069
				c1.471,1.131,1.746,3.239,0.616,4.71c-4.837,6.292-10.412,10.125-16.121,11.084c-0.188,0.033-0.376,0.047-0.561,0.047
				c-1.61,0-3.032-1.161-3.309-2.802c-0.022-0.136-0.02-0.27-0.026-0.405c-1.572-0.118-3.167-0.512-4.774-1.212
				c-0.047,0.086-0.083,0.178-0.139,0.261c-0.648,0.966-1.71,1.487-2.792,1.487c-0.643,0-1.294-0.184-1.869-0.57
				c-18.987-12.747-24.852-35.856-26.088-41.858c-0.867-0.603-1.715-1.229-2.543-1.883c-2.327,1.03-4.515,1.99-6.57,2.882
				c4.381,12.915,11.174,31.336,16.953,39.965c6.214,9.281,22.28,20.242,34.161,17.542c9.592-2.18,30.313-14.414,36.193-57.496
				C193.307,164.418,192.931,164.222,192.574,164.033z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M111.271,83.969c3.655-1.315,6.789-4.515,8.381-8.561c0.681-1.726,2.631-2.575,4.357-1.894
				c1.726,0.68,2.574,2.63,1.894,4.356c-0.313,0.795-0.674,1.566-1.07,2.313c2.386-2.414,4.236-4.89,5.483-6.753
				c-0.871-1.431-1.656-2.915-2.34-4.454c-0.753-1.695,0.011-3.68,1.707-4.433c0.482-0.214,0.987-0.293,1.48-0.275
				c-1.216-12.745,1.563-31.249,4.352-45.479c-21.187,5.692-35.343,22.626-35.343,44.392v19.627
				C104.147,84.786,107.878,85.189,111.271,83.969z"
            />
          </g>
          <g>
            <circle style={{ fill: theme.palette.iconColor.main }} cx="132.74" cy="108.702" r="4.577" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="170.608" cy="108.784" r="4.578" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M101.84,220.213c-1.855,0-3.358,1.504-3.358,3.359v63.068c0,1.855,1.503,3.359,3.358,3.359
				s3.358-1.504,3.358-3.359v-63.068C105.199,221.717,103.696,220.213,101.84,220.213z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M238.01,188.633c-4.669-10.439-10.541-18.283-24.014-22.64c-7.07-2.281-13.845-5.555-18.284-7.901
				c-0.272-0.143-0.556-0.231-0.842-0.295c0.864-0.853,1.708-1.734,2.515-2.652c8.177-9.33,12.5-21.033,12.5-33.844v-58.12
				c0-30.318-25.029-53.182-58.221-53.182c-33.185,0-58.21,22.863-58.21,53.182v58.12c0,13.61,4.954,26.028,14.034,35.492
				c-13.67,6.007-19.758,8.326-22.468,9.2c-13.475,4.356-19.345,12.2-24.016,22.643c-8.063,18.089-6.525,77.15-6.456,79.655
				c0.051,1.824,1.544,3.267,3.355,3.267c0.032,0,0.063,0,0.095-0.002c1.854-0.051,3.316-1.597,3.265-3.451
				c-0.017-0.598-1.569-60.031,5.875-76.73c4.153-9.285,8.749-15.369,19.946-18.988c3.576-1.154,10.06-3.77,19.3-7.781
				c2.055-0.892,4.243-1.852,6.57-2.882c0.829,0.654,1.676,1.28,2.543,1.883c1.236,6.002,7.101,29.112,26.088,41.858
				c0.575,0.386,1.226,0.57,1.869,0.57c1.082,0,2.144-0.521,2.792-1.487c0.056-0.083,0.092-0.175,0.139-0.261
				c0.831-1.508,0.401-3.422-1.055-4.399c-12.707-8.53-18.888-22.853-21.607-31.501c5,2.375,10.406,4.094,16.17,5.097
				c3.842,0.665,7.802,1.001,11.77,1.001c3.622,0,7.196-0.274,10.611-0.813c4.769-0.737,9.336-1.981,13.65-3.686
				c-1.067,5.656-3.058,11.48-5.732,16.617c-0.84,1.613-0.235,3.588,1.339,4.473c0.031,0.018,0.057,0.041,0.089,0.058
				c0.495,0.258,1.026,0.381,1.549,0.381c1.212,0,2.383-0.66,2.982-1.809c3.713-7.134,6.23-15.474,7.056-23.185
				c2.709-1.515,5.258-3.229,7.594-5.117c0.112,1.078,0.741,2.083,1.771,2.627c0.357,0.189,0.733,0.385,1.118,0.585
				c4.657,2.409,11.286,5.525,18.239,7.769c11.197,3.621,15.793,9.705,19.945,18.988c7.472,16.73,7.011,76.195,7.007,76.794
				c-0.018,1.855,1.473,3.373,3.328,3.39c0.009,0,0.02,0,0.03,0c1.841,0,3.342-1.484,3.359-3.328
				C245.624,265.726,246.076,206.694,238.01,188.633z M161.239,167.035c-6.456,1.02-13.576,0.976-20.195-0.169
				c-7.661-1.333-14.607-4.034-20.661-8.034c-1.657-1.084-3.25-2.281-4.742-3.563c-9.976-8.505-15.469-20.568-15.469-33.968v-31.2
				c2.598,0.915,4.978,1.274,7.087,1.274c2.493,0,4.613-0.483,6.286-1.085c4.702-1.692,8.784-5.386,11.287-10.105
				c0.396-0.747,0.757-1.518,1.07-2.313c0.68-1.727-0.168-3.677-1.894-4.356c-1.726-0.681-3.676,0.168-4.357,1.894
				c-1.593,4.046-4.727,7.245-8.381,8.561c-3.392,1.221-7.124,0.817-11.098-1.16V63.182c0-21.765,14.156-38.7,35.343-44.392
				c5.021-1.349,10.434-2.072,16.149-2.072c27.772,0,48.448,16.928,51.185,40.975c-0.021,0.053-0.05,0.098-0.068,0.151
				c-4.743,14.392-18.48,29.688-34.597,29.688c-14.726,0-28.418-8.553-34.069-21.28c-0.539-1.213-1.71-1.937-2.953-1.982
				c-0.494-0.018-0.999,0.061-1.48,0.275c-1.696,0.753-2.459,2.737-1.707,4.433c0.684,1.539,1.469,3.023,2.34,4.454
				c7.679,12.622,22.295,20.817,37.869,20.817c14.461,0,27.206-9.95,34.984-21.943v48.996c0,11.157-3.747,21.329-10.831,29.412
				c-1.615,1.835-3.374,3.535-5.246,5.067c-2.589,2.153-5.478,4.06-8.6,5.674C173.233,164.2,167.432,166.078,161.239,167.035z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M201.094,220.213c-1.855,0-3.359,1.504-3.359,3.359v63.068c0,1.855,1.504,3.359,3.359,3.359
				s3.359-1.504,3.359-3.359v-63.068C204.453,221.717,202.949,220.213,201.094,220.213z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M170.562,192.866c-0.035-0.027-0.075-0.043-0.111-0.069c-1.464-1.038-3.496-0.75-4.599,0.684
				c-3.785,4.923-7.902,7.881-11.909,8.554c-1.692,0.286-2.859,1.791-2.782,3.465c0.006,0.135,0.004,0.269,0.026,0.405
				c0.276,1.641,1.698,2.802,3.309,2.802c0.185,0,0.373-0.014,0.561-0.047c5.709-0.958,11.284-4.792,16.121-11.084
				C172.308,196.105,172.033,193.997,170.562,192.866z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M164.577,133.313c-0.067,0.067-6.76,6.714-15.622,6.714c-8.527,0-13.805-7.372-13.896-7.501
				c-0.885-1.279-2.638-1.602-3.919-0.72c-1.285,0.882-1.61,2.64-0.727,3.924c0.278,0.406,6.966,9.941,18.542,9.941
				c11.278,0,19.31-8.058,19.646-8.4c1.089-1.11,1.071-2.891-0.036-3.982C167.457,132.195,165.67,132.208,164.577,133.313z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconPersonSmile;
