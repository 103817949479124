import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocuments = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M142.048,259.237c0.196-0.501,0.104-0.766-0.025-0.977c-0.149-0.243-0.315-0.446-0.742-0.446
			c-0.112,0-0.242,0.014-0.394,0.045c-2.527,0.511-6.065,4.445-6.379,6.53c-0.037,0.248,0.04,0.476,0.174,0.676
			C137.623,263.613,141.261,261.251,142.048,259.237z"
        />
        <path
          style={{ fill: "none" }}
          d="M115.44,261.567c9.954-4.686,12.818-8.463,13.623-10.438c0.384-0.942,0.437-1.796,0.156-2.538
			c-0.201-0.098-0.531-0.149-0.961-0.149c-1.984,0-6.101,1.09-9.522,3.711c-2.365,1.811-4.91,4.773-3.605,8.601
			C115.228,261.035,115.331,261.306,115.44,261.567z"
        />
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M143.085,61.328h-25.133c-4.076,0-7.252,2.132-8.365,5.33c-0.28,0.804-0.435,1.671-0.435,2.593
				v1.855c0,0.921,0.155,1.789,0.435,2.592c1.113,3.199,4.29,5.331,8.365,5.331h25.133c4.076,0,7.252-2.132,8.366-5.331
				c0.28-0.803,0.435-1.671,0.435-2.592V69.25c0-0.922-0.155-1.789-0.435-2.593C150.337,63.459,147.161,61.328,143.085,61.328z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M190.172,26.21c-1.113-3.198-4.29-5.33-8.366-5.33h-25.133c-4.076,0-7.252,2.132-8.365,5.33
				c-0.28,0.804-0.435,1.671-0.435,2.592v1.856c0,0.921,0.155,1.789,0.435,2.592c1.113,3.199,4.29,5.33,8.365,5.33h25.133
				c4.076,0,7.252-2.131,8.366-5.33c0.28-0.803,0.435-1.671,0.435-2.592v-1.856C190.607,27.881,190.452,27.014,190.172,26.21z"
          />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M78.059,206.084h102.416c0.973,0,1.76-0.788,1.76-1.761c0-0.972-0.788-1.76-1.76-1.76H78.059
				c-0.972,0-1.76,0.788-1.76,1.76C76.299,205.296,77.087,206.084,78.059,206.084z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M78.059,191.06h102.416c0.973,0,1.76-0.788,1.76-1.761c0-0.972-0.788-1.76-1.76-1.76H78.059
				c-0.972,0-1.76,0.788-1.76,1.76C76.299,190.272,77.087,191.06,78.059,191.06z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M78.059,128.018h102.416c0.973,0,1.76-0.788,1.76-1.76c0-0.973-0.788-1.761-1.76-1.761H78.059
				c-0.972,0-1.76,0.788-1.76,1.761C76.299,127.23,77.087,128.018,78.059,128.018z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M78.059,111.815h102.416c0.973,0,1.76-0.788,1.76-1.76c0-0.973-0.788-1.761-1.76-1.761H78.059
				c-0.972,0-1.76,0.788-1.76,1.761C76.299,111.027,77.087,111.815,78.059,111.815z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M171.148,156.903H87.485c-0.973,0-1.76,0.788-1.76,1.761c0,0.972,0.788,1.76,1.76,1.76h83.663
				c0.972,0,1.759-0.788,1.759-1.76C172.908,157.691,172.121,156.903,171.148,156.903z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M171.148,140.701H87.485c-0.973,0-1.76,0.788-1.76,1.76c0,0.973,0.788,1.761,1.76,1.761h83.663
				c0.972,0,1.759-0.788,1.759-1.761C172.908,141.489,172.121,140.701,171.148,140.701z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M172.908,236.167c0-0.972-0.787-1.76-1.759-1.76H87.485c-0.973,0-1.76,0.788-1.76,1.76
				c0,0.973,0.788,1.761,1.76,1.761h83.663C172.121,237.927,172.908,237.139,172.908,236.167z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M171.148,218.204H87.485c-0.973,0-1.76,0.788-1.76,1.76c0,0.973,0.788,1.761,1.76,1.761h83.663
				c0.972,0,1.759-0.788,1.759-1.761C172.908,218.992,172.121,218.204,171.148,218.204z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M246.876,172.933c1.944,0,3.52-1.576,3.52-3.521V36.877c0-5.882-4.785-10.667-10.667-10.667
				h-45.903c-1.2-5.189-5.963-8.851-12.02-8.851h-25.133c-6.056,0-10.819,3.661-12.019,8.851H98.991
				c-5.88,0-10.666,4.785-10.666,10.667v29.78H60.269c-5.881,0-10.665,4.785-10.665,10.667v31.492c0,1.944,1.576,3.52,3.52,3.52
				s3.52-1.575,3.52-3.52V77.325c0-2,1.626-3.627,3.625-3.627h31.577h14.086c1.2,5.19,5.963,8.852,12.02,8.852h25.133
				c6.056,0,10.819-3.661,12.019-8.852h45.903c2,0,3.626,1.627,3.626,3.627v6.73h-87.852c-0.973,0-1.76,0.788-1.76,1.761
				c0,0.972,0.788,1.76,1.76,1.76h87.852v151.097v33.302c0,1.999-1.627,3.626-3.626,3.626H60.269c-1.999,0-3.625-1.627-3.625-3.626
				V121.871c0-1.944-1.576-3.52-3.52-3.52s-3.52,1.575-3.52,3.52v150.104c0,5.881,4.784,10.666,10.665,10.666h140.738
				c5.882,0,10.667-4.785,10.667-10.666v-29.781h28.056c5.881,0,10.667-4.785,10.667-10.667v-49.054c0-1.944-1.576-3.52-3.52-3.52
				c-1.944,0-3.521,1.575-3.521,3.52v49.054c0,2-1.627,3.627-3.626,3.627h-28.056v-39.004h7.515c0.972,0,1.76-0.788,1.76-1.761
				c0-0.972-0.788-1.76-1.76-1.76h-7.515v-11.177h7.515c0.972,0,1.76-0.788,1.76-1.761c0-0.972-0.788-1.76-1.76-1.76h-7.515v-11.194
				h7.515c0.972,0,1.76-0.788,1.76-1.76c0-0.973-0.788-1.761-1.76-1.761h-7.515V152.04h7.515c0.972,0,1.76-0.788,1.76-1.76
				c0-0.973-0.788-1.761-1.76-1.761h-7.515V87.576h7.523c0.972,0,1.76-0.788,1.76-1.76c0-0.973-0.788-1.761-1.76-1.761h-7.523v-6.73
				c0-2.203-0.671-4.251-1.82-5.952h9.343c0.972,0,1.76-0.788,1.76-1.761c0-0.972-0.788-1.76-1.76-1.76H205.89
				c-1.465-0.758-3.123-1.194-4.883-1.194h-45.903c-1.2-5.189-5.963-8.851-12.019-8.851h-25.133c-6.056,0-10.819,3.661-12.02,8.851
				H95.366v-29.78c0-2,1.626-3.627,3.625-3.627h45.664c1.2,5.189,5.963,8.851,12.019,8.851h25.133c6.057,0,10.82-3.661,12.02-8.851
				h45.903c1.999,0,3.626,1.627,3.626,3.627v132.535C243.355,171.357,244.932,172.933,246.876,172.933z M109.151,71.106V69.25
				c0-0.922,0.155-1.789,0.435-2.593c1.113-3.198,4.29-5.33,8.365-5.33h25.133c4.076,0,7.252,2.132,8.366,5.33
				c0.28,0.804,0.435,1.671,0.435,2.593v1.855c0,0.921-0.155,1.789-0.435,2.592c-1.114,3.199-4.29,5.331-8.366,5.331h-25.133
				c-4.076,0-7.252-2.132-8.365-5.331C109.307,72.895,109.151,72.027,109.151,71.106z M190.607,28.802v1.856
				c0,0.921-0.155,1.789-0.435,2.592c-1.113,3.199-4.29,5.33-8.366,5.33h-25.133c-4.076,0-7.252-2.131-8.365-5.33
				c-0.28-0.803-0.435-1.671-0.435-2.592v-1.856c0-0.921,0.155-1.788,0.435-2.592c1.113-3.198,4.29-5.33,8.365-5.33h25.133
				c4.076,0,7.252,2.132,8.366,5.33C190.452,27.014,190.607,27.881,190.607,28.802z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M109.377,266.072c0.364,0.901,1.391,1.337,2.291,0.974c0.806-0.325,1.587-0.651,2.348-0.979
				c2.472,3.154,6.242,4.788,11.008,4.788c2.321,0,4.88-0.388,7.64-1.177c0.366-0.104,0.917-0.301,1.589-0.578
				c3.09,1.504,8.087,1.494,11.785-1.5c1.556-1.26,2.883-2.495,3.95-3.487c0.199-0.186,0.41-0.382,0.621-0.576
				c-0.006,1.208,0.156,2.354,1.242,3.008c1.376,0.828,3.028,0.014,4.601-0.892c0.843-0.485,1.133-1.562,0.648-2.404
				c-0.485-0.842-1.562-1.131-2.404-0.646c-0.208,0.119-0.39,0.22-0.55,0.305c0-0.021,0.001-0.04,0.002-0.061
				c0.014-0.396,0.029-0.83,0.029-1.292c0-1.384-0.471-2.29-1.399-2.694c-1.576-0.685-2.859,0.508-5.188,2.677
				c-1.027,0.955-2.305,2.145-3.766,3.327c-1.368,1.108-3.044,1.636-4.622,1.71c2.64-1.622,5.222-3.738,6.127-6.057
				c0.561-1.437,0.457-2.852-0.301-4.092c-1.036-1.694-2.798-2.429-4.838-2.018c-4.112,0.832-8.647,6.033-9.162,9.455
				c-0.133,0.876-0.001,1.732,0.366,2.517c-4.787,1.321-10.547,1.794-14.03-1.826c8.27-3.944,13.293-8.002,14.961-12.097
				c0.769-1.887,0.792-3.758,0.067-5.411c-1.317-3.006-6.311-2.313-10.188-0.806c-6.916,2.688-12.815,8.568-10.402,15.648
				c0.13,0.384,0.272,0.756,0.426,1.115c-0.604,0.258-1.228,0.516-1.875,0.777C109.449,264.145,109.013,265.17,109.377,266.072z
				 M140.886,257.859c0.152-0.031,0.282-0.045,0.394-0.045c0.427,0,0.593,0.203,0.742,0.446c0.129,0.211,0.221,0.476,0.025,0.977
				c-0.787,2.015-4.425,4.376-7.367,5.828c-0.134-0.2-0.211-0.428-0.174-0.676C134.821,262.304,138.359,258.37,140.886,257.859z
				 M118.737,252.152c3.421-2.621,7.538-3.711,9.522-3.711c0.43,0,0.76,0.052,0.961,0.149c0.28,0.742,0.228,1.596-0.156,2.538
				c-0.805,1.976-3.669,5.753-13.623,10.438c-0.109-0.261-0.213-0.532-0.309-0.814C113.827,256.925,116.372,253.962,118.737,252.152
				z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconDocuments;
