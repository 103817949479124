import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconCar = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd" }}>
        {!active && (
          <>
            <path
              d="M11.48,158.14h280v14.63a16.35,16.35,0,0,1-16.3,16.3h-6.53a25.51,25.51,0,0,0-34.21-24.27,25.51,25.51,0,0,0-16.77,24.27H83.28A25.18,25.18,0,0,0,81.74,180a25.49,25.49,0,0,0-49.44,9.06H11.48Z"
              style={{ fill: "#137F7B" }}
            />
          </>
        )}
        <path
          d="M7.13,189.07H32.24v-.31m67.75.31H201m81.29-2.29c5.3-2,9.89-5.41,9.89-10.55V129.54H194.32m-21,0H66.55C9,141.35,9.05,153.75,7.13,189.07"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <path
          d="M74.45,129.54,110.77,96c5.4-5,12.56-9.53,19.92-9.53h98L253,110.7"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <path
          d="M81.74,197.45a25.49,25.49,0,1,1,0-17.44"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <path
          d="M267.14,197.47a25.49,25.49,0,1,1,0-17.43"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="173.32"
          y1="129.54"
          x2="173.32"
          y2="102.84"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconCar;
