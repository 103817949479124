import CheckIcon from "../../../../../assets/icons/common/toggleable/IconHaken";
import PartialIcon from "../../../../../assets/icons/common/toggleable/IconIncomplete";
import CrossIcon from "../../../../../assets/icons/common/toggleable/IconKreuz";
import Grid from "@mui/material/Grid";
import React from "react";
import { requiredValidator } from "../../../../../services/validationRules";
import Group from "../../../../Wizard/Group";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type FinalClaimStatusClientProps = {
  product: AbstractCase;
  hasClaimStatusClient: boolean;
};
const FinalClaimStatusClient = ({ product, hasClaimStatusClient }: FinalClaimStatusClientProps) => {
  if (!hasClaimStatusClient) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"finalClaimStatusClient"}
        question={"Wie ist der aktuelle Stand der Forderung aus Ihrer Sicht?"}
        options={[
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Die Forderung wird/wurde durch die Gegenseite voll erfüllt und der Fall kann abgeschlossen werden"
                : "Die Gegenseite hat die Forderung gegen mich vollständig aufgegeben und der Fall kann abgeschlossen werden. ",
            labelIcon: <CheckIcon />,
            labelIconActive: <CheckIcon active />,
            value: "fullyAgreed",
          },
          {
            labelText:
              product.caseCategory === "enforceClaim"
                ? "Die Forderung wird/wurde durch die Gegenseite nur teilweise erfüllt"
                : "Die Gegenseite hat die Forderung gegen mich teilweise aufgegeben ",
            labelIcon: <PartialIcon />,
            labelIconActive: <PartialIcon active />,
            value: "partiallyAgreed",
          },
          {
            labelText:
              product.caseCategory === "enforceClaim" ? "Die Forderung wird/wurde nicht erfüllt" : "Die Gegenseite hält vollständig an der Forderung fest",
            labelIcon: <CrossIcon />,
            labelIconActive: <CrossIcon active />,
            value: "notAgreed",
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default FinalClaimStatusClient;
