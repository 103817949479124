import React from "react";
import {SvgIcon} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {ToggleableIconProps} from "../../../../types/IconProps";
import {getIconColor} from "../../../../services/iconService";

const IconLicenceDurationTwoToTen = ({active = false, sx = {}}: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{...sx}} viewBox="0 0 300 300">
      <g style={{fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round"}}>
        <path
          d="M75.29,175.9c0-11.23,5.19-12.61,17.13-20.53,7.48-5,14.31-9.12,14.31-20.63,0-10.69-5.74-19.89-19.59-19.89-15.31,0-21.6,11.05-21.6,20.26l10.11,2c0-8.56,4.29-13.81,11.4-13.81,6.74,0,9.57,5.43,9.57,11.42,0,11-8.75,13.35-20.24,20.72-7.11,4.6-11.21,8.56-11.21,20.44v8.48h41.56V175.9Zm104.63-60.59H159.59v8.47H169.8v60.6h10.12V115.31Zm55.23,46.23v-23.4c-.18-15.19-9.75-23.29-23.52-23.29s-23.51,8.1-23.51,23.29v23.4c0,15.28,9.75,23.3,23.51,23.3s23.34-8,23.52-23.3Zm-10.12-23v22.66c0,11.05-5.65,15.19-13.4,15.19s-13.4-4.14-13.4-15.19V138.51c0-11,5.66-15.19,13.4-15.19S225,127.46,225,138.51Z"
          style={{fill: getIconColor(active, theme)}}
        />
        <line x1="120.28" y1="149.86" x2="150.16" y2="149.83"
              style={{stroke: getIconColor(active, theme), strokeWidth: "8.29", fill: "none"}}/>
        <path d="M240.69,38.18a143.12,143.12,0,1,0,25.72,27.1"
              style={{fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.91"}}/>
        <path d="M69.11,250.06a129.51,129.51,0,1,0-23.27-24.52"
              style={{fill: "none", stroke: active ? "none" : "#137F7B", strokeWidth: "6.91"}}/>
      </g>
    </SvgIcon>
  );
};

export default IconLicenceDurationTwoToTen;
