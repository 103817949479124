import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconTimeMoreThanOneSecond = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {active ? (
        <>
          <g style={{ fill: "none", stroke: "#137f7b", strokeLinecap: "round", strokeLinejoin: "round" }}>
            <path style={{ strokeWidth: "6.92px" }} d="M150.34,51.39a97.19,97.19,0,0,1,52.38,15.93" />
            <path style={{ strokeWidth: "6.92px" }} d="M150.09,76.6a72.11,72.11,0,0,1,33.74,8.77" />
            <path style={{ strokeWidth: "6.92px" }} d="M149.83,102.33a46.46,46.46,0,0,1,16.67,3.28" />
          </g>
        </>
      ) : (
        <>
          <path
            style={{
              fillRule: "evenodd",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fill: "#137F7B",
              stroke: "#137F7B",
              strokeWidth: "6.9px",
            }}
            d="M149.68,151.14V15.31a135.85,135.85,0,0,1,67.51,18Z"
          />
        </>
      )}
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path style={{ fill: getIconColor(active, theme) }} d="M149.68,22a8.63,8.63,0,1,1-8.63,8.63A8.63,8.63,0,0,1,149.68,22Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M89.9,38.05a8.63,8.63,0,1,1-8.62,8.62A8.63,8.63,0,0,1,89.9,38.05Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M46.08,81.83a8.63,8.63,0,1,1-8.62,8.62A8.62,8.62,0,0,1,46.08,81.83Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M30,141.65a8.63,8.63,0,1,1-8.62,8.62A8.62,8.62,0,0,1,30,141.65Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M46,201.49a8.62,8.62,0,1,1-8.62,8.62A8.63,8.63,0,0,1,46,201.49Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M89.82,245.3a8.63,8.63,0,1,1-8.62,8.63A8.62,8.62,0,0,1,89.82,245.3Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M149.64,261.36A8.63,8.63,0,1,1,141,270,8.63,8.63,0,0,1,149.64,261.36Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M209.48,245.33a8.63,8.63,0,1,1-8.63,8.63A8.63,8.63,0,0,1,209.48,245.33Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M253.28,201.54a8.63,8.63,0,1,1-8.62,8.63A8.63,8.63,0,0,1,253.28,201.54Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M269.34,141.72a8.62,8.62,0,1,1-8.62,8.62A8.62,8.62,0,0,1,269.34,141.72Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M253.31,81.87a8.63,8.63,0,1,1-8.62,8.63A8.64,8.64,0,0,1,253.31,81.87Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M209.51,38.07a8.62,8.62,0,1,1-8.63,8.62A8.62,8.62,0,0,1,209.51,38.07Z" />
        <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.39px" }} x1="96.05" y1="118.53" x2="150.96" y2="149.4" />
        <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.34px" }} x1="150.38" y1="149.92" x2="233.23" y2="103.22" />
        <path style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.9px" }} d="M137.21,293.85a143,143,0,1,1,24.93,0" />
      </g>
    </SvgIcon>
  );
};

export default IconTimeMoreThanOneSecond;
