import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCallClock = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90">
        <g>
          <path
            style={{ fill: "none" }}
            d="M242.439,92.951c-0.563,0-1.121,0.148-1.616,0.428c-1.57,0.891-2.126,2.899-1.234,4.474
			c0.58,1.025,1.677,1.664,2.859,1.664c0.563,0,1.12-0.148,1.615-0.428c1.572-0.891,2.129-2.899,1.238-4.478
			C244.724,93.588,243.626,92.951,242.439,92.951z"
          />
          <path
            style={{ fill: "none" }}
            d="M152.484,43.204c0-1.81-1.472-3.282-3.28-3.282c-1.81,0-3.282,1.472-3.282,3.282
			s1.472,3.282,3.282,3.282C151.013,46.485,152.484,45.013,152.484,43.204z"
          />
          <path
            style={{ fill: "none" }}
            d="M256.771,153.836l0.07,0c1.77,0,3.239-1.446,3.277-3.224c0.03-1.803-1.414-3.304-3.219-3.335
			c-0.001,0-0.002,0-0.002,0c-1.845,0-3.315,1.443-3.35,3.217c-0.016,0.876,0.312,1.708,0.92,2.34
			C255.076,153.465,255.894,153.821,256.771,153.836z"
          />
          <path
            style={{ fill: "none" }}
            d="M205.978,55.056c-0.508-0.305-1.092-0.467-1.685-0.467c-1.167,0-2.22,0.593-2.817,1.587
			c-0.933,1.552-0.432,3.574,1.118,4.506c0.512,0.308,1.097,0.471,1.688,0.471c1.164,0,2.218-0.594,2.817-1.59
			C208.032,58.015,207.528,55.993,205.978,55.056z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M200.834,201.215c-3.935,2.406-6.919,4.375-9.552,6.113c-6.529,4.309-10.47,6.909-17.647,8.434
				c-0.716,0.152-1.468,0.229-2.237,0.229h-0.002c-9.878-0.001-24.975-13.342-45.224-32.188c-1.525-1.419-3.013-2.804-4.45-4.132
				c-26.664-24.645-41.228-41.134-36.763-52.488c0.552-1.405,1.029-2.651,1.468-3.795c2.658-6.932,3.992-10.411,11.297-22.59
				l0.613-1.021c0.991-1.652,1.985-3.311,2.952-4.952L59.795,33.493c-8.089,5.413-17.071,14.361-17.168,14.458l-0.153,0.147
				c-7.079,6.56-17.051,18.768-17.051,25.365c0,5.399,2.19,24.862,12.189,49.756c12.833,31.948,33.089,60.541,60.205,84.982
				c27.311,24.618,56.023,43.069,85.34,54.84c22.619,9.083,39.225,11.506,45.471,11.505l0.156,0
				c6.599-0.061,18.745-10.117,25.262-17.238l0.135-0.143c0.094-0.095,8.488-8.673,13.858-16.614l-59.8-44.329
				C206.007,197.852,203.569,199.541,200.834,201.215z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M108.983,74.436c-3.05-5.721-20.59-30.454-28.08-41.015c-1.178-1.66-2.157-3.042-2.865-4.044
				c-1.304-1.846-2.489-2.225-3.868-2.225c-2.744,0-6.499,1.742-9.73,3.534l39.849,58.899
				C107.962,82.912,110.426,77.135,108.983,74.436z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M271.112,220.275c-1.98-1.374-5.475-3.862-9.898-7.012c-12.434-8.853-33.242-23.669-38.127-26.222
				c-0.288-0.15-0.469-0.171-0.619-0.171c-1.892,0-5.863,3.028-9.705,5.958l58.086,43.059
				C273.483,230.864,275.989,223.657,271.112,220.275z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M277.29,211.368c-1.926-1.335-5.396-3.807-9.789-6.935c-17.838-12.701-34.272-24.323-39.396-27
				c-1.782-0.931-3.68-1.403-5.638-1.403c-5.553,0-10.287,3.609-16.279,8.178c-3.18,2.424-6.784,5.173-11.012,7.759
				c-4.092,2.502-7.293,4.615-9.864,6.312c-6.014,3.968-8.759,5.779-13.915,6.87c-6.379-0.001-26.846-19.049-37.841-29.283
				c-1.534-1.427-3.03-2.82-4.476-4.157c-8.783-8.118-17.852-16.732-24.684-24.733c-10.374-12.148-9.363-15.794-9.349-15.831
				c0.564-1.438,1.053-2.71,1.501-3.88c2.508-6.541,3.653-9.527,10.471-20.894l0.611-1.018
				c8.916-14.855,15.958-26.588,10.915-36.024c-2.963-5.558-15.593-23.557-28.8-42.181c-1.172-1.652-2.146-3.027-2.853-4.027
				c-3.148-4.456-7.548-6.812-12.723-6.812c-6.115,0-12.488,3.331-17.915,6.588c-9.359,5.618-20.116,16.217-21.216,17.311
				c-2.435,2.262-20.455,19.473-20.455,33.253c0,7.438,2.711,28.255,12.971,53.796c13.455,33.495,34.653,63.437,63.006,88.994
				c62.92,56.714,120.998,69.134,138.068,69.133l0.252-0.001c13.777-0.128,30.871-18.261,33.102-20.693
				c1.059-1.083,11.588-11.947,17.135-21.375C287.259,229.465,286.593,217.819,277.29,211.368z M254.046,257.308
				c-6.517,7.121-18.663,17.177-25.262,17.238l-0.156,0c-6.246,0-22.852-2.423-45.471-11.505
				c-29.316-11.771-58.029-30.222-85.34-54.84c-27.116-24.441-47.372-53.034-60.205-84.982c-10-24.894-12.189-44.357-12.189-49.756
				c0-6.597,9.972-18.805,17.051-25.365l0.153-0.147c0.098-0.098,9.08-9.045,17.168-14.458l41.494,61.331
				c-0.967,1.642-1.961,3.3-2.952,4.952l-0.613,1.021c-7.305,12.179-8.639,15.658-11.297,22.59
				c-0.439,1.145-0.917,2.391-1.468,3.795c-4.465,11.354,10.099,27.843,36.763,52.488c1.437,1.328,2.925,2.713,4.45,4.132
				c20.25,18.846,35.346,32.186,45.224,32.188h0.002c0.77,0,1.521-0.077,2.237-0.229c7.178-1.524,11.118-4.125,17.647-8.434
				c2.633-1.737,5.617-3.707,9.552-6.113c2.735-1.674,5.173-3.363,7.405-4.993l59.8,44.329c-5.37,7.941-13.765,16.519-13.858,16.614
				L254.046,257.308z M74.169,27.151c1.379,0,2.564,0.379,3.868,2.225c0.708,1.003,1.688,2.384,2.865,4.044
				c7.49,10.562,25.03,35.294,28.08,41.015c1.443,2.7-1.021,8.476-4.695,15.149L64.439,30.686
				C67.67,28.893,71.425,27.151,74.169,27.151z M212.763,192.828c3.842-2.929,7.813-5.958,9.705-5.958
				c0.15,0,0.331,0.021,0.619,0.171c4.885,2.553,25.693,17.37,38.127,26.222c4.424,3.149,7.918,5.638,9.898,7.012
				c4.877,3.382,2.371,10.589-0.264,15.612L212.763,192.828z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.976,155.89l38.091,21.871c0.852,0.489,1.779,0.721,2.694,0.721
				c1.878,0,3.703-0.977,4.705-2.722c1.491-2.596,0.595-5.909-2.001-7.399l-35.371-20.308V64.703c0-2.993-2.427-5.42-5.42-5.42
				s-5.42,2.427-5.42,5.42v86.486C144.255,153.131,145.293,154.923,146.976,155.89z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M157.904,43.204c0-4.798-3.903-8.702-8.7-8.702c-4.798,0-8.702,3.903-8.702,8.702
				c0,4.798,3.904,8.702,8.702,8.702C154.001,51.905,157.904,48.001,157.904,43.204z M145.922,43.204
				c0-1.81,1.472-3.282,3.282-3.282c1.809,0,3.28,1.472,3.28,3.282s-1.472,3.282-3.28,3.282
				C147.394,46.485,145.922,45.013,145.922,43.204z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M204.293,49.169c-3.04,0-5.899,1.614-7.462,4.214c-2.473,4.113-1.141,9.47,2.969,11.944
				c1.357,0.815,2.906,1.246,4.482,1.246c3.036,0,5.895-1.614,7.461-4.213c2.472-4.106,1.143-9.464-2.971-11.946
				C207.418,49.599,205.868,49.169,204.293,49.169z M207.1,59.563c-0.6,0.996-1.653,1.59-2.817,1.59
				c-0.592,0-1.177-0.163-1.688-0.471c-1.55-0.932-2.051-2.954-1.118-4.506c0.598-0.994,1.65-1.587,2.817-1.587
				c0.593,0,1.177,0.163,1.685,0.467C207.528,55.993,208.032,58.015,207.1,59.563z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M250.021,91.944c-1.541-2.723-4.446-4.414-7.581-4.414c-1.496,0-2.978,0.391-4.288,1.132
				c-4.169,2.366-5.641,7.686-3.279,11.86c1.541,2.722,4.445,4.414,7.576,4.414c1.496,0,2.977-0.391,4.285-1.131
				C250.901,101.444,252.378,96.125,250.021,91.944z M244.063,99.088c-0.495,0.28-1.053,0.428-1.615,0.428
				c-1.183,0-2.279-0.638-2.859-1.664c-0.892-1.575-0.336-3.583,1.234-4.474c0.495-0.28,1.054-0.428,1.616-0.428
				c1.187,0,2.284,0.638,2.862,1.66C246.192,96.189,245.636,98.198,244.063,99.088z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M256.671,159.255l0.17,0.002c4.694,0,8.596-3.826,8.695-8.54c0.083-4.8-3.75-8.774-8.537-8.858
				l-0.17-0.002c-4.708,0-8.611,3.829-8.699,8.539c-0.041,2.326,0.824,4.528,2.436,6.201
				C252.18,158.271,254.35,159.215,256.671,159.255z M256.897,147.278c0,0,0.001,0,0.002,0c1.805,0.032,3.249,1.533,3.219,3.335
				c-0.038,1.777-1.508,3.224-3.277,3.224l-0.07,0c-0.877-0.015-1.694-0.371-2.303-1.002c-0.608-0.631-0.936-1.463-0.92-2.34
				C253.582,148.721,255.053,147.278,256.897,147.278z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M108.693,32.635c41.774-14.922,88.696-6.234,122.455,22.673
				c10.94,9.365,20.121,20.528,27.284,33.179c18.043,31.861,21.116,69.861,8.434,104.256c-1.035,2.808,0.402,5.925,3.211,6.96
				c0.619,0.228,1.252,0.336,1.875,0.336c2.205,0,4.277-1.356,5.086-3.546c13.787-37.392,10.443-78.706-9.172-113.347
				c-7.787-13.75-17.769-25.887-29.668-36.072c-36.705-31.429-87.725-40.873-133.151-24.647c-2.819,1.007-4.288,4.108-3.281,6.928
				C102.773,32.173,105.874,33.642,108.693,32.635z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconCallClock;
