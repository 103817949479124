import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconServicewelt = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "none" }}
          d="M171.305,186.329c1.102-0.721,2.15-1.509,3.126-2.348c6.573-5.604,10.189-13.544,10.189-22.367
			v-20.191c-1.536,0.469-2.958,0.66-4.236,0.66c-1.737,0-3.215-0.338-4.383-0.758c-1.905-0.685-3.662-1.842-5.142-3.335
			c-3.25-1.582-5.817-3.783-7.424-5.383c-5.543,7-14.442,11.401-23.852,11.401c-9.311,0-17.569-6.031-22.919-13.565v31.171
			c0,7.346,2.466,14.041,7.129,19.363c1.058,1.204,2.215,2.322,3.437,3.321c1.723,1.433,3.627,2.689,5.676,3.749
			c3.483,1.819,7.315,3.059,11.398,3.69c4.276,0.675,8.987,0.646,13.37-0.111c3.81-0.664,7.354-1.84,10.575-3.506
			c0.021-0.013,0.041-0.023,0.061-0.033C169.341,187.549,170.342,186.965,171.305,186.329z M134.899,153.176
			c0-1.691,1.373-3.065,3.066-3.065c1.691,0,3.064,1.374,3.064,3.065c0,1.693-1.373,3.066-3.064,3.066
			C136.272,156.242,134.899,154.87,134.899,153.176z M161.979,172.307c-0.226,0.231-5.604,5.626-13.157,5.626
			c-7.752,0-12.229-6.385-12.417-6.657c-0.59-0.859-0.374-2.037,0.487-2.627c0.858-0.59,2.032-0.374,2.624,0.481
			c0.053,0.074,3.589,5.023,9.306,5.023c5.936,0,10.417-4.452,10.461-4.496c0.734-0.741,1.929-0.748,2.671-0.018
			C162.696,170.372,162.708,171.564,161.979,172.307z M163.323,156.297c-1.692,0-3.065-1.372-3.065-3.064
			c0-1.694,1.373-3.066,3.065-3.066c1.694,0,3.067,1.373,3.067,3.066C166.39,154.925,165.017,156.297,163.323,156.297z"
        />
        <path
          style={{ fill: "none" }}
          d="M139.584,138.493c9.663,0,18.646-5.605,22.35-13.947c0.17-0.384,0.422-0.702,0.72-0.96
			c0.8-8.895,0.293-21.197-1.14-30.072c-3.373-0.926-7.015-1.426-10.868-1.426c-18.273,0-31.887,11.089-33.754,26.86
			c0.001,0.005,0.004,0.009,0.006,0.015C120.019,128.431,129.03,138.493,139.584,138.493z"
        />
        <path
          style={{ fill: "#137F7B" }}
          d="M162.653,123.585c0.783-0.676,1.912-0.888,2.919-0.441c1.392,0.618,2.02,2.247,1.4,3.638
			c-0.929,2.092-2.125,4.041-3.537,5.825c1.606,1.6,4.174,3.801,7.424,5.383c-1.458-1.473-2.646-3.272-3.435-5.272
			c-0.557-1.416,0.138-3.018,1.555-3.576c1.416-0.558,3.018,0.138,3.575,1.555c1.015,2.574,3,4.608,5.313,5.439
			c2.057,0.741,4.325,0.53,6.751-0.61v-12.831c0-14.265-9.247-25.376-23.106-29.182
			C162.946,102.389,163.453,114.691,162.653,123.585z"
        />
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M89.464,206.497c-5.446,12.218-4.414,51.88-4.367,53.563c0.042,1.496,1.268,2.682,2.755,2.682
				c0.025,0,0.051-0.001,0.077-0.001c1.521-0.043,2.722-1.311,2.68-2.833c-0.309-11.114-0.208-41.97,3.89-51.162
				c2.724-6.088,5.732-10.075,13.05-12.44c2.907-0.937,8.655-3.303,17.091-7.033c0.433,0.336,0.874,0.665,1.326,0.982
				c0.931,4.383,4.988,19.646,17.64,28.143c0.472,0.316,1.006,0.467,1.534,0.467c0.889,0,1.76-0.428,2.292-1.22
				c0.849-1.264,0.511-2.977-0.752-3.825c-7.872-5.286-11.955-13.962-13.913-19.735c3.346,1.473,6.928,2.52,10.687,3.101
				c2.315,0.366,4.736,0.552,7.192,0.552c2.688,0,5.369-0.229,7.97-0.679c2.642-0.458,5.167-1.146,7.579-2.035
				c-0.738,3.453-1.972,6.961-3.599,10.086c-0.703,1.35-0.179,3.015,1.172,3.718c0.407,0.213,0.841,0.312,1.271,0.312
				c0.996,0,1.956-0.54,2.447-1.484c2.473-4.748,4.172-10.292,4.762-15.444c0.709-0.406,1.408-0.827,2.087-1.276
				c0.768-0.501,1.513-1.036,2.235-1.594c0.242,0.55,0.652,1.033,1.226,1.336c3.149,1.664,7.968,3.991,13.043,5.628
				c7.318,2.366,10.328,6.353,13.048,12.439c4.115,9.215,4.751,40.092,4.648,51.214c-0.014,1.522,1.208,2.768,2.731,2.782
				c0.008,0.001,0.017,0.001,0.026,0.001c1.51,0,2.741-1.219,2.756-2.732c0.016-1.682,0.317-41.318-5.126-53.515
				c-3.183-7.113-7.188-12.46-16.389-15.436c-4.647-1.499-9.105-3.646-12.06-5.204c6.249-6.451,9.66-14.935,9.66-24.241v-38.919
				c0-10.25-4.156-19.555-11.702-26.203c-7.263-6.396-17.13-9.918-27.785-9.918c-10.664,0-20.535,3.522-27.796,9.92
				c-7.544,6.645-11.699,15.949-11.699,26.201v38.919c0,8.703,2.938,16.655,8.499,23.001c0.176,0.199,0.36,0.394,0.542,0.59
				c-8.673,3.8-12.58,5.287-14.338,5.854C96.654,194.034,92.648,199.381,89.464,206.497z M116.665,161.614v-31.171
				c5.351,7.534,13.609,13.565,22.919,13.565c9.41,0,18.309-4.4,23.852-11.401c1.412-1.784,2.608-3.732,3.537-5.825
				c0.619-1.392-0.009-3.021-1.4-3.638c-1.007-0.447-2.136-0.234-2.919,0.441c-0.297,0.258-0.549,0.576-0.72,0.96
				c-3.704,8.342-12.687,13.947-22.35,13.947c-10.554,0-19.565-10.062-22.686-19.53c-0.002-0.006-0.005-0.01-0.006-0.015
				c1.867-15.771,15.481-26.86,33.754-26.86c3.853,0,7.495,0.5,10.868,1.426c13.859,3.805,23.106,14.917,23.106,29.182v12.831
				c-2.426,1.14-4.694,1.351-6.751,0.61c-2.313-0.831-4.299-2.866-5.313-5.439c-0.558-1.417-2.159-2.113-3.575-1.555
				c-1.417,0.558-2.112,2.16-1.555,3.576c0.789,2,1.977,3.799,3.435,5.272c1.479,1.493,3.236,2.65,5.142,3.335
				c1.168,0.42,2.646,0.758,4.383,0.758c1.278,0,2.7-0.191,4.236-0.66v20.191c0,8.822-3.616,16.762-10.189,22.367
				c-0.976,0.839-2.024,1.626-3.126,2.348c-0.962,0.637-1.963,1.221-2.995,1.758c-0.02,0.009-0.039,0.022-0.061,0.033
				c-3.221,1.667-6.765,2.843-10.575,3.506c-4.382,0.757-9.093,0.786-13.37,0.111c-4.083-0.632-7.915-1.872-11.398-3.69
				c-2.049-1.06-3.953-2.316-5.676-3.749c-1.222-0.999-2.379-2.117-3.437-3.321C119.13,175.655,116.665,168.96,116.665,161.614z"
          />
          <circle style={{ fill: theme.palette.iconColor.main }} cx="137.965" cy="153.177" r="3.065" />
          <circle style={{ fill: theme.palette.iconColor.main }} cx="163.323" cy="153.232" r="3.065" />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M159.737,209.64c-2.457,3.193-5.104,5.108-7.657,5.537c-1.502,0.253-2.515,1.675-2.262,3.176
				c0.226,1.347,1.394,2.301,2.715,2.301c0.151,0,0.306-0.014,0.46-0.039c3.951-0.663,7.795-3.297,11.114-7.613
				c0.928-1.206,0.702-2.937-0.505-3.866C162.396,208.207,160.665,208.433,159.737,209.64z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M139.517,169.13c-0.592-0.855-1.766-1.072-2.624-0.481c-0.861,0.59-1.077,1.768-0.487,2.627
				c0.188,0.272,4.665,6.657,12.417,6.657c7.553,0,12.931-5.395,13.157-5.626c0.729-0.743,0.717-1.935-0.024-2.667
				c-0.742-0.73-1.938-0.723-2.671,0.018c-0.044,0.044-4.526,4.496-10.461,4.496C143.105,174.153,139.57,169.204,139.517,169.13z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M242.834,136.402c-1.827,0-3.308,1.481-3.308,3.309v134.62c0,6.794-1.927,9.053-13.333,9.053
				H73.807c-11.405,0-13.332-2.259-13.332-9.053V139.71c0-1.827-1.481-3.309-3.309-3.309c-1.826,0-3.308,1.481-3.308,3.309v134.62
				c0,13.771,9.588,15.67,19.949,15.67h152.387c10.36,0,19.949-1.899,19.949-15.67V139.71
				C246.143,137.883,244.662,136.402,242.834,136.402z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M263.338,130.306c-0.897-0.979-89.85-97.9-98.754-108.646l-1.041-1.256
				C155.559,10.76,154.886,9.981,150.4,10l-0.4,0.002L149.6,10c-0.068,0-0.136,0-0.204,0c-4.323,0-5.077,0.908-12.934,10.398
				l-1.044,1.261c-8.905,10.746-97.857,107.667-98.754,108.646c-1.236,1.345-1.146,3.438,0.199,4.673
				c1.347,1.237,3.439,1.146,4.674-0.199c3.67-3.999,89.965-98.023,98.976-108.898l1.046-1.263c3.808-4.6,6.326-7.639,7.051-7.977
				c0.13-0.029,0.584-0.025,0.952-0.023L150,16.619l0.439-0.001c0.367-0.002,0.824-0.006,0.919,0.013
				c0.758,0.349,3.276,3.39,7.088,7.993l1.042,1.258c9.011,10.875,95.306,104.9,98.975,108.898c0.652,0.711,1.544,1.071,2.438,1.071
				c0.798,0,1.601-0.289,2.236-0.872C264.484,133.743,264.573,131.651,263.338,130.306z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconServicewelt;
