import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLicenceDurationUnderTwo = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          d="M172.48,176.15c0-11.24,5.2-12.62,17.14-20.54,7.47-5,14.31-9.12,14.31-20.63,0-10.69-5.74-19.9-19.6-19.9-15.31,0-21.6,11.06-21.6,20.27l10.12,2c0-8.56,4.28-13.81,11.39-13.81,6.75,0,9.57,5.43,9.57,11.42,0,11-8.75,13.35-20.23,20.72-7.11,4.61-11.21,8.57-11.21,20.45v8.47h41.56v-8.47Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <line x1="96.39" y1="149.88" x2="126.59" y2="119.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "8.31", fill: "none" }} />
        <line x1="96.37" y1="149.86" x2="126.61" y2="180.06" style={{ stroke: getIconColor(active, theme), strokeWidth: "8.31", fill: "none" }} />
        <path d="M69.25,250.43A129.51,129.51,0,1,0,46,225.91" style={{ fill: "none", stroke: active ? "none" : "#137F7B", strokeWidth: "6.91" }} />
        <path d="M240.23,38.6A143.16,143.16,0,1,0,266,65.71" style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.91" }} />
      </g>
    </SvgIcon>
  );
};

export default IconLicenceDurationUnderTwo;
