import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconSendMail = ({ sx = {} }: IconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 256 256">
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" style={{ fill: "#137F7B" }}>
        <path d="M25.4,46.5H7.5C6.7,46.5,6,45.8,6,45s0.7-1.5,1.5-1.5h17.9c0.8,0,1.5,0.7,1.5,1.5S26.3,46.5,25.4,46.5z" />
        <path d="M12.5,36h-11C0.7,36,0,35.3,0,34.5S0.7,33,1.5,33h11c0.8,0,1.5,0.7,1.5,1.5S13.3,36,12.5,36z" />
        <path d="M12.5,57H4.4c-0.8,0-1.5-0.7-1.5-1.5S3.6,54,4.4,54h8.1c0.8,0,1.5,0.7,1.5,1.5S13.3,57,12.5,57z" />
        <path d="M63.7,45l16.7-15.3c0.6-0.6,0.7-1.5,0.1-2.1c-0.6-0.6-1.5-0.6-2.1-0.1l-24,22l-24-22c-0.6-0.6-1.6-0.5-2.1,0.1c-0.6,0.6-0.5,1.6,0.1,2.1L45.1,45L28.4,60.3c-0.6,0.6-0.7,1.5-0.1,2.1c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.7-0.1,1-0.4L47.3,47l6.1,5.6c0.3,0.3,0.7,0.4,1,0.4s0.7-0.1,1-0.4l6.1-5.6l16.9,15.5c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.8-0.2,1.1-0.5c0.6-0.6,0.5-1.6-0.1-2.1L63.7,45z" />
      </g>
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" style={{ fill: theme.palette.iconColor.main }}>
        <path d="M82.1,71.3H26.7c-4.3,0-7.9-3.5-7.9-7.9V53.2c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v10.2c0,2.7,2.2,4.9,4.9,4.9h55.4c2.7,0,4.9-2.2,4.9-4.9V26.6c0-2.7-2.2-4.9-4.9-4.9H26.7c-2.7,0-4.9,2.2-4.9,4.9v10c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-10c0-4.3,3.5-7.9,7.9-7.9h55.4c4.3,0,7.9,3.5,7.9,7.9v36.8C90,67.7,86.5,71.3,82.1,71.3z" />
      </g>
    </SvgIcon>
  );
};

export default IconSendMail;
