import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconProviderWrongInvoice = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 122.9 119.2">
      <g style={{ fill: getIconColor(active, theme) }}>
        <circle cx="26.2" cy="59.3" r="4" />
        <path d="M75.2,112.4H7.8c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V95.7c-2.2-0.2-4.5-0.2-6.8-0.1v15.7c0,4.3,3.5,7.9,7.9,7.9h67.4c1.9,0,3.4-1.5,3.4-3.4C78.6,113.9,77.1,112.4,75.2,112.4z" />
        <path d="M105.5,7.9v5.2h6.8V7.9c0-4.3-3.5-7.9-7.9-7.9H7.8C3.5,0,0,3.5,0,7.9v77.9c2.3-0.1,4.5-0.1,6.8,0V7.9c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h96.5C105,6.8,105.4,7.3,105.5,7.9z" />
        <path d="M105.5,23.1v23.8c0,0.1,0,0.2,0,0.4c0.1,1.9,1.7,3.3,3.6,3.2s3.3-1.7,3.2-3.6V23.1H105.5z" />
        <circle cx="26.2" cy="83.7" r="4" />
        <path d="M71.9,87.7c1.4-0.3,2.5-1.4,2.8-2.8c0.4-1.9-0.9-3.7-2.8-4.1H42.5c-1.4,0.3-2.5,1.4-2.8,2.8c-0.4,1.9,0.9,3.7,2.8,4.1H71.9z" />
        <path d="M109.8,102.1c-4.2,0-7-2-8.2-6h9.8l1.4-5.9l0,0h-12v-1.7c0-0.6,0-1.2,0.1-1.9h11.4l1.4-5.9H102c1.5-3.8,4.3-5.6,8.5-5.6c3.2,0,6.2,1.3,9.2,4l2.9-11.7c-3.8-2.1-8.1-3.2-12.5-3.3c-12.6,0-20.3,5.5-23.1,16.6h-3.6l-1.5,5.9h4.3c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.7h-3.6l-1.5,5.9H87c0.9,4.5,3.3,8.6,6.7,11.7c3.7,3.5,9.3,5.2,17,5.2c4.3,0.1,8.6-1.1,12.2-3.4v-12C119.1,100.5,114.5,102.1,109.8,102.1z" />
        <path d="M82.6,60.2c0.3-1.9-0.9-3.6-2.8-4H42.5c-1.4,0.3-2.5,1.4-2.8,2.8c-0.3,1.9,0.9,3.6,2.8,4h37.4C81.3,62.7,82.4,61.6,82.6,60.2z" />
      </g>
      <text x="15.0214" y="39.2129" style={{ fill: getIconColor(active, theme), fontFamily: "'Roboto-Bold'", fontSize: "22px" }}>
        1 + 1 = 3
      </text>
    </SvgIcon>
  );
};

export default IconProviderWrongInvoice;
