import React, { FormEvent, useState } from "react";
import { getCaseLink, getProductData } from "../../../services/productService";
import ApiClient from "../../../services/apiClient";
import Paper from "@mui/material/Paper";
import FormFader from "../../../components/Form/Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import wizardStyle from "../wizardStyle";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import { useNavigate } from "react-router-dom";
import { requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import CarIcon from "../../../assets/icons/contract/toggleable/IconCar";
import BuyIcon from "../../../assets/icons/contract/toggleable/IconWareEinfordern";
import ServiceIcon from "../../../assets/icons/contract/toggleable/IconHandwerker";
import MembershipIcon from "../../../assets/icons/contract/toggleable/IconClub";
import OtherIcon from "../../../assets/icons/contract/toggleable/IconContract";
import SubscriptionIcon from "../../../assets/icons/contract/toggleable/IconWiederholen";
import ProviderIcon from "../../../assets/icons/provision/toggleable/IconSendemast";
import TravelIcon from "../../../assets/icons/contract/toggleable/IconReisen";
import EventIcon from "../../../assets/icons/contract/toggleable/IconVeranstaltung";
import Group from "../../Wizard/Group";
import { useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import FormSubmitProps from "../../../types/FormSubmitProps";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { Box } from "@mui/material";

type ContractLawCaseSelectionProps = {
  product: AbstractCase;
  submitProps: FormSubmitProps;
};

export default function ContractLawCaseSelection({ product, submitProps }: ContractLawCaseSelectionProps) {
  const navigate = useNavigate();
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const { values, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const handleSubmitInternal = async (e: FormEvent<HTMLFormElement>) => {
    //-- check if switch is required
    if (values.name !== product.name) {
      e.preventDefault();
      setIsLoadingInternal(true);
      const switchResponse = await ApiClient.post(`/product_switch_case/${product.id}/${values.name}`);
      const newCase = await queryClient.fetchQuery(queryKeys.item(getProductData(switchResponse.name, "apiUri"), switchResponse.id), () =>
        fetchResource(getProductData(switchResponse.name, "apiUri"), switchResponse.id)
      );
      navigate(getCaseLink(newCase) + "/antrag");
      return;
    }
    handleSubmit(e);
  };

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmitInternal}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Vertragsart
            </Typography>
          </Hidden>
          <Group
            type="radio"
            name="name"
            question="Um welche Art von Vertrag handelt es sich?"
            options={[
              {
                labelText: "Kaufvertrag",
                labelIcon: <BuyIcon />,
                labelIconActive: <BuyIcon active />,
                value: "purchaseContract",
                optionHintText: "Probleme mit einem Kaufvertrag - egal ob als Käufer oder Verkäufer.",
              },
              {
                labelText: "Fahrzeugkauf",
                labelIcon: <CarIcon />,
                labelIconActive: <CarIcon active />,
                value: "vehicleContract",
                optionHintText: "Themen rund um den Kauf oder Verkauf von Fahrzeugen.",
              },
              {
                labelText: "Handwerker / Dienstleistung",
                labelIcon: <ServiceIcon />,
                labelIconActive: <ServiceIcon active />,
                value: "serviceContract",
                optionHintText: "Eine (handwerkliche) Dienstleistung wurde nicht wie vereinbart erfüllt.",
              },
              {
                labelText: "Mitgliedschaft",
                labelIcon: <MembershipIcon />,
                labelIconActive: <MembershipIcon active />,
                value: "membershipContract",
                optionHintText: "Uneinigkeiten mit einer Mitgliedschaft - beispielsweise Fitnessstudio oder Sportverein.",
              },
              {
                labelText: "Abo / Abofalle",
                labelIcon: <SubscriptionIcon />,
                labelIconActive: <SubscriptionIcon active />,
                value: "subscriptionContract",
                optionHintText: "Alles rund um Abos oder sogenannte “Abofallen”.",
              },
              {
                labelText: "Handy / DSL / Strom / Gas -Vertrag",
                labelIcon: <ProviderIcon />,
                labelIconActive: <ProviderIcon active />,
                value: "providerContract",
                optionHintText: "Probleme mit Versorgern, wie Mobilfunkanbietern oder Stromversorger.",
              },
              {
                labelText: "Reisevertrag",
                labelIcon: <TravelIcon />,
                labelIconActive: <TravelIcon active />,
                value: "travelContract",
                optionHintText: "Reise hat gar nicht, oder nicht wie vereinbart stattgefunden.",
              },
              {
                labelText: "Veranstaltung",
                labelIcon: <EventIcon />,
                labelIconActive: <EventIcon active />,
                value: "eventContract",
                optionHintText: "Veranstaltung ausgefallen, verschoben oder andere Probleme.",
              },
              {
                labelText: "Sonstiger Vertrag",
                labelIcon: <OtherIcon />,
                labelIconActive: <OtherIcon active />,
                value: "otherContract",
                optionHintText: "Auch bei anderen Arten von Verträgen helfen wir gerne weiter.",
              },
            ]}
            validators={[requiredValidator("Bitte geben Sie die Art des Vertrags an.")]}
          />
          <FormSubmit {...submitProps} isLoading={isLoadingInternal || submitProps.isLoading} />
        </Box>
      </FormFader>
    </Paper>
  );
}
