import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconVeranstaltung = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 463 463">
      <g style={{ fill: getIconColor(active, theme), stroke: "none" }}>
        <path d="M423.5,44H407v-8.5c0-12.958-10.542-23.5-23.5-23.5S360,22.542,360,35.5V44H103v-8.5C103,22.542,92.458,12,79.5,12 S56,22.542,56,35.5V44H39.5C17.72,44,0,61.72,0,83.5v328C0,433.28,17.72,451,39.5,451h384c21.78,0,39.5-17.72,39.5-39.5v-328 C463,61.72,445.28,44,423.5,44z M375,35.5c0-4.687,3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5v15.942c0,0.02-0.003,0.038-0.003,0.058 S392,51.538,392,51.558V67.5c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5V35.5z M79.5,27c4.687,0,8.5,3.813,8.5,8.5v32 c0,4.687-3.813,8.5-8.5,8.5S71,72.187,71,67.5V51.558c0-0.02,0.003-0.038,0.003-0.058S71,51.462,71,51.442V35.5 C71,30.813,74.813,27,79.5,27z M423.5,436h-384C25.99,436,15,425.01,15,411.5V123h56.498c4.143,0,7.5-3.357,7.5-7.5 s-3.357-7.5-7.5-7.5H15V83.5C15,69.99,25.99,59,39.5,59H56v8.5C56,80.458,66.542,91,79.5,91S103,80.458,103,67.5V59h257v8.5 c0,12.958,10.542,23.5,23.5,23.5S407,80.458,407,67.5V59h16.5c13.51,0,24.5,10.99,24.5,24.5V108h-40.497 c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5H448v288.5C448,425.01,437.01,436,423.5,436z" />
        <path d="m375.498,108h-15.997c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5 7.5,7.5h15.997c4.143,0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z" />
        <path d="m335.001,115.5c0-4.143-3.357-7.5-7.5-7.5h-224.004c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5 7.5,7.5h224.004c4.143,0 7.5-3.357 7.5-7.5z" />
        <path d="m330.271,235.762h-56.27c-2.479,0-4.658-1.584-5.424-3.94l-17.388-53.516v-0.001c-2.823-8.69-10.551-14.305-19.689-14.305s-16.866,5.615-19.69,14.306l-17.388,53.516c-0.766,2.356-2.945,3.94-5.424,3.94h-56.27c-9.138,0-16.866,5.615-19.69,14.306-2.823,8.69 0.129,17.776 7.521,23.146l45.522,33.074c2.005,1.457 2.838,4.02 2.072,6.377l-17.389,53.515c-2.823,8.69 0.129,17.776 7.521,23.147s16.947,5.371 24.338,0l45.522-33.074c2.006-1.457 4.699-1.457 6.705,0l45.522,33.074c3.696,2.686 7.933,4.028 12.169,4.028s8.473-1.343 12.169-4.028c7.393-5.371 10.345-14.457 7.521-23.147l-17.389-53.515c-0.766-2.357 0.067-4.92 2.072-6.377l45.522-33.074c7.393-5.37 10.345-14.456 7.521-23.146-2.819-8.691-10.548-14.306-19.686-14.306zm3.353,25.317l-45.522,33.074c-7.279,5.288-10.302,14.591-7.521,23.146l17.389,53.515c1.188,3.657-1.286,5.806-2.072,6.377-0.785,0.572-3.592,2.263-6.705,0.001l-45.522-33.074c-3.64-2.645-7.904-3.967-12.169-3.967s-8.529,1.322-12.169,3.967l-45.522,33.074c-3.113,2.262-5.919,0.571-6.705-0.001-0.786-0.571-3.26-2.72-2.072-6.377l17.386-53.514c2.78-8.556-0.242-17.858-7.521-23.146l-45.522-33.074c-3.11-2.26-2.372-5.452-2.071-6.376 0.3-0.925 1.579-3.941 5.424-3.941h56.27c8.996,0 16.909-5.749 19.689-14.306l17.388-53.515c1.188-3.657 4.453-3.941 5.425-3.941s4.236,0.284 5.425,3.94l17.388,53.516c2.78,8.557 10.693,14.306 19.689,14.306h56.27c3.845,0 5.124,3.017 5.424,3.941 0.298,0.923 1.036,4.115-2.074,6.375z" />
        <path d="m267.893,269.317c-11.736,9.127-19.098,22.842-20.198,37.628-0.466,6.262 0.187,12.509 1.938,18.569 0.949,3.283 3.946,5.419 7.201,5.419 0.689,0 1.392-0.096 2.087-0.297 3.979-1.15 6.272-5.309 5.122-9.288-1.256-4.342-1.723-8.813-1.39-13.29 0.786-10.566 6.053-20.371 14.448-26.901 3.27-2.543 3.858-7.255 1.315-10.524-2.541-3.271-7.252-3.859-10.523-1.316z" />
        <path d="m238.553,242.9c-1.408-3.895-5.705-5.91-9.604-4.502-3.895,1.408-5.911,5.708-4.502,9.604 2.185,6.041 5.401,11.563 9.561,16.413 1.484,1.729 3.584,2.617 5.697,2.617 1.728,0 3.464-0.594 4.879-1.807 3.144-2.697 3.507-7.432 0.811-10.576-2.975-3.467-5.277-7.42-6.842-11.749z" />
      </g>
    </SvgIcon>
  );
};

export default IconVeranstaltung;
