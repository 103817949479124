import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAgreement = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79">
        <g>
          <path
            style={{ fill: "none" }}
            d="M107.68,198.303l6.291-8.335c1.775-2.352,1.357-6.427-0.827-8.075l-1.276-0.961
			c-0.856-0.647-1.946-0.783-2.708-0.783c-2.134,0-4.412,1.044-5.541,2.54l-6.033,7.996c-0.781,1.034-1.075,2.382-0.83,3.794
			c0.25,1.441,1.04,2.755,2.166,3.605l1.275,0.963c0.962,0.727,2.185,1.126,3.443,1.126
			C105.311,200.173,106.783,199.492,107.68,198.303z"
          />
          <path
            style={{ fill: "none" }}
            d="M123.244,210.049l9.102-12.059c0.78-1.033,1.074-2.381,0.828-3.794
			c-0.25-1.442-1.04-2.755-2.164-3.604l-1.276-0.961c-0.964-0.729-2.187-1.129-3.445-1.129c-1.669,0-3.14,0.682-4.037,1.87
			l-9.103,12.06c-1.635,2.168-1.01,5.625,1.339,7.399l1.275,0.962c0.962,0.727,2.186,1.126,3.443,1.126
			C120.875,211.919,122.347,211.237,123.244,210.049z"
          />
          <path
            style={{ fill: "none" }}
            d="M146.573,202.338l-1.276-0.962c-0.963-0.727-2.186-1.127-3.443-1.127c-1.669,0-3.142,0.682-4.04,1.871
			l-9.1,12.058c-0.78,1.033-1.075,2.381-0.829,3.794c0.25,1.441,1.041,2.756,2.167,3.605l1.275,0.963
			c0.962,0.727,2.185,1.127,3.443,1.127c1.669,0,3.141-0.682,4.038-1.87l9.102-12.061c0.78-1.034,1.074-2.381,0.828-3.794
			C148.487,204.501,147.698,203.188,146.573,202.338z"
          />
          <path
            style={{ fill: "none" }}
            d="M92.117,186.556l4.244-5.626c0.781-1.034,1.075-2.382,0.83-3.795c-0.25-1.441-1.04-2.755-2.165-3.604
			l-1.276-0.962c-0.964-0.728-2.187-1.128-3.445-1.128c-1.669,0-3.141,0.682-4.038,1.87l-4.247,5.627
			c-0.779,1.034-1.073,2.381-0.828,3.794c0.251,1.441,1.04,2.755,2.166,3.604l1.276,0.964c0.962,0.726,2.185,1.126,3.443,1.126
			C89.747,188.426,91.219,187.745,92.117,186.556z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M62.133,79.454c-4.619-0.616-28.939-3.112-29.184-3.138c-0.02-0.002-0.038-0.009-0.058-0.012
				l-11.692,97.371c0.008,0.001,0.016,0,0.023,0c0.244,0.031,24.499,3.111,29.137,3.558c3.116,0.302,8.484-0.442,9.391-8.808
				l9.534-78.268C70.296,81.808,65.238,79.867,62.133,79.454z M41.278,170.677c-6.083,0-11.033-4.95-11.033-11.033
				s4.949-11.033,11.033-11.033s11.033,4.95,11.033,11.033S47.362,170.677,41.278,170.677z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M267.109,76.304c-0.02,0.002-0.038,0.01-0.058,0.012c-0.245,0.025-24.565,2.522-29.183,3.138
				c-3.106,0.413-8.164,2.354-7.151,10.706l9.528,78.221c0.913,8.41,6.281,9.153,9.395,8.853c4.641-0.446,28.893-3.526,29.137-3.558
				c0.008-0.001,0.016,0,0.023,0L267.109,76.304z M257.45,171.101c-6.083,0-11.033-4.949-11.033-11.033s4.949-11.033,11.033-11.033
				s11.033,4.949,11.033,11.033S263.534,171.101,257.45,171.101z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M41.278,155.412c-2.334,0-4.233,1.898-4.233,4.232s1.899,4.232,4.233,4.232
				s4.233-1.898,4.233-4.232S43.612,155.412,41.278,155.412z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M257.45,155.835c-2.334,0-4.233,1.899-4.233,4.233c0,2.333,1.899,4.232,4.233,4.232
				s4.233-1.898,4.233-4.232C261.683,157.734,259.784,155.835,257.45,155.835z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M52.311,159.644c0-6.083-4.949-11.033-11.033-11.033s-11.033,4.95-11.033,11.033
				s4.949,11.033,11.033,11.033S52.311,165.728,52.311,159.644z M41.278,155.412c2.334,0,4.233,1.898,4.233,4.232
				s-1.899,4.232-4.233,4.232s-4.233-1.898-4.233-4.232S38.944,155.412,41.278,155.412z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M257.45,149.036c-6.083,0-11.033,4.949-11.033,11.033s4.949,11.033,11.033,11.033
				s11.033-4.949,11.033-11.033S263.534,149.036,257.45,149.036z M257.45,164.3c-2.334,0-4.233-1.898-4.233-4.232
				c0-2.334,1.899-4.233,4.233-4.233s4.233,1.899,4.233,4.233C261.683,162.402,259.784,164.3,257.45,164.3z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M150.669,196.91l-1.275-0.962c-2.135-1.611-4.813-2.499-7.54-2.499
				c-0.65,0-1.284,0.065-1.908,0.16c-0.025-0.193-0.039-0.385-0.072-0.579c-0.543-3.122-2.281-5.989-4.769-7.867l-1.275-0.961
				c-2.135-1.612-4.813-2.5-7.542-2.5c-1.756,0-3.426,0.367-4.939,1.03c-0.689-2.481-2.07-4.729-4.109-6.268l-1.275-0.961
				c-1.867-1.409-4.219-2.154-6.804-2.154c-1.925,0-3.852,0.439-5.616,1.194c-0.763-2.557-2.32-4.854-4.423-6.44l-1.275-0.961
				c-2.135-1.612-4.813-2.5-7.541-2.5c-3.821,0-7.271,1.666-9.465,4.573l-1.072,1.421l-13.338-0.911
				c0.023-0.177,0.058-0.34,0.078-0.522l8.746-71.798l18.11,1.719l0.941-2.372c0.02-0.049,2-5.012,4.337-8.81
				c3.123-5.074,5.296-6.734,8.017-6.13c5.559,1.235,10.168,2.379,10.214,2.391c1.824,0.455,3.667-0.657,4.12-2.479
				c0.453-1.822-0.657-3.667-2.479-4.12c-0.046-0.012-4.733-1.176-10.379-2.431c-8.461-1.88-13.242,5.888-15.284,9.205
				c-1.578,2.564-2.974,5.5-3.862,7.5L76.06,90.651c1.275-11.772-6.186-17.027-13.029-17.939c-4.72-0.629-28.383-3.058-29.388-3.161
				c-1.878-0.19-3.538,1.168-3.729,3.035c-0.189,1.848,1.141,3.497,2.978,3.718c0.02,0.002,0.038,0.01,0.058,0.012
				c0.245,0.025,24.565,2.522,29.184,3.138c3.105,0.413,8.164,2.354,7.151,10.704l-9.534,78.268
				c-0.907,8.365-6.274,9.109-9.391,8.808c-4.639-0.446-28.893-3.526-29.137-3.558c-0.008-0.001-0.016,0-0.023,0
				c-1.854-0.22-3.542,1.09-3.778,2.945c-0.236,1.863,1.082,3.565,2.944,3.802c1.002,0.127,24.602,3.124,29.342,3.58
				c0.538,0.052,1.084,0.079,1.637,0.079c4.872,0,10.176-2.104,13.091-7.676l10.825,0.739c-0.899,2.069-1.18,4.392-0.769,6.755
				c0.543,3.123,2.282,5.99,4.768,7.866l1.276,0.964c2.135,1.611,4.813,2.498,7.54,2.498c0.65,0,1.284-0.065,1.908-0.16
				c0.024,0.192,0.038,0.384,0.072,0.577c0.543,3.122,2.281,5.99,4.768,7.868l1.275,0.963c2.135,1.611,4.813,2.499,7.541,2.499
				c0.652,0,1.288-0.066,1.914-0.161c0.403,3.23,2.053,6.345,4.835,8.446l1.276,0.963c2.135,1.61,4.813,2.498,7.539,2.498
				c0.65,0,1.283-0.065,1.908-0.16c0.024,0.192,0.038,0.384,0.072,0.578c0.543,3.122,2.281,5.989,4.769,7.867l1.275,0.963
				c2.135,1.611,4.813,2.499,7.541,2.499c3.823,0,7.273-1.667,9.466-4.573l9.102-12.061c1.926-2.553,2.672-5.769,2.1-9.057
				C154.895,201.654,153.156,198.787,150.669,196.91z M84.634,187.3l-1.276-0.964c-1.125-0.849-1.915-2.163-2.166-3.604
				c-0.246-1.414,0.048-2.761,0.828-3.794l4.247-5.627c0.897-1.188,2.369-1.87,4.038-1.87c1.258,0,2.481,0.4,3.445,1.128
				l1.276,0.962c1.125,0.85,1.915,2.163,2.165,3.604c0.246,1.413-0.048,2.761-0.83,3.795l-4.244,5.626
				c-0.897,1.188-2.37,1.87-4.04,1.87C86.819,188.426,85.596,188.026,84.634,187.3z M100.198,199.046l-1.275-0.963
				c-1.126-0.85-1.916-2.164-2.166-3.605c-0.246-1.413,0.048-2.76,0.83-3.794l6.033-7.996c1.128-1.495,3.407-2.54,5.541-2.54
				c0.763,0,1.852,0.135,2.708,0.783l1.276,0.961c2.184,1.648,2.602,5.724,0.827,8.075l-6.291,8.335
				c-0.897,1.188-2.369,1.87-4.039,1.87C102.383,200.173,101.16,199.773,100.198,199.046z M115.763,210.792l-1.275-0.962
				c-2.349-1.773-2.974-5.231-1.339-7.399l9.103-12.06c0.896-1.188,2.368-1.87,4.037-1.87c1.258,0,2.481,0.4,3.445,1.129
				l1.276,0.961c1.125,0.849,1.914,2.162,2.164,3.604c0.246,1.414-0.048,2.761-0.828,3.794l-9.102,12.059
				c-0.897,1.188-2.369,1.87-4.038,1.87C117.948,211.919,116.725,211.519,115.763,210.792z M147.91,209.736l-9.102,12.061
				c-0.897,1.188-2.369,1.87-4.038,1.87c-1.258,0-2.481-0.4-3.443-1.127l-1.275-0.963c-1.126-0.85-1.916-2.164-2.167-3.605
				c-0.246-1.413,0.048-2.761,0.829-3.794l9.1-12.058c0.897-1.189,2.37-1.871,4.04-1.871c1.258,0,2.48,0.4,3.443,1.127l1.276,0.962
				c1.125,0.849,1.914,2.163,2.165,3.604C148.984,207.355,148.69,208.703,147.91,209.736z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M282.579,176.62c-0.236-1.855-1.931-3.17-3.778-2.945c-0.008,0.001-0.016,0-0.023,0
				c-0.244,0.031-24.496,3.111-29.137,3.558c-3.114,0.301-8.482-0.442-9.395-8.853l-9.528-78.221
				c-1.013-8.352,4.045-10.293,7.151-10.706c4.618-0.616,28.938-3.112,29.183-3.138c0.02-0.002,0.038-0.009,0.058-0.012
				c1.837-0.221,3.167-1.87,2.978-3.718c-0.192-1.868-1.854-3.226-3.729-3.035c-1.005,0.103-24.668,2.532-29.387,3.161
				c-6.218,0.829-12.934,5.252-13.157,14.88l-7.518,0.583c-4.135-2.356-20.748-11.779-25.526-13.856
				c-6.009-2.613-16.624-0.863-17.814-0.655c-1.85,0.323-3.087,2.085-2.764,3.935s2.082,3.086,3.935,2.764
				c3.719-0.65,10.791-1.173,13.932,0.193c5.018,2.181,25.564,13.923,25.771,14.042l0.908,0.519l9.644-0.747l8.754,71.867h-14.65
				c-1.071,0-2.015,0.505-2.638,1.279c-10.465-8.457-27.689-23.452-36.686-31.337c8.707-1.99,16.551-7.69,23.361-17.007
				c1.108-1.516,0.778-3.643-0.738-4.751c-1.516-1.108-3.643-0.778-4.751,0.738c-6.651,9.098-14.238,14.113-22.552,14.908
				c-13.772,1.315-25.424-9.348-25.535-9.451l-2.661-2.493l-2.301,2.828c-0.069,0.084-6.997,8.575-15.39,16.944
				c-4.628,4.613-10.766,4.668-14.014,4.291c-4.447-0.518-7.389-2.18-8.258-3.039c0.66-2.896,5.437-11.202,10.476-19.963
				c2.5-4.347,5.334-9.275,8.382-14.734c10.547-18.896,13.259-21.004,16.933-21.311c5.479-0.457,16.898-1.463,16.898-1.463
				l-0.597-6.773c0,0-11.397,1.004-16.867,1.46c-7.313,0.61-11.274,5.008-22.305,24.772c-3.026,5.421-5.849,10.329-8.339,14.659
				c-7.793,13.55-11.248,19.7-11.447,23.485c-0.154,2.933,2.173,5.625,6.554,7.58c3.151,1.406,6.991,2.268,10.963,2.268
				c5.765,0,11.808-1.816,16.422-6.417c5.862-5.845,10.928-11.623,13.677-14.854c4.118,3.092,12.44,8.278,22.581,9.019
				c5.567,4.911,35.423,31.18,46.783,39.756c3.591,2.71,5.616,5.383,5.858,7.729c0.238,2.309-1.338,4.109-2.095,4.975l-0.19,0.22
				c-2.068,2.414-5.954,6.13-8.753,3.891c-5.615-4.491-16.271-13.333-16.378-13.421c-1.445-1.2-3.589-1-4.788,0.445
				c-1.199,1.445-1,3.588,0.445,4.787c0.077,0.064,5.568,4.619,10.761,8.866c-4.312,6.938-10.021,10.69-12.545,9.431
				c-0.665-0.333-1.491-0.875-2.411-1.548c-0.21-0.387-0.499-0.739-0.863-1.031c-0.388-0.31-0.824-0.515-1.275-0.631
				c-3.957-3.228-8.694-7.77-10.766-9.84c-1.327-1.327-3.481-1.328-4.808,0c-1.328,1.328-1.328,3.48,0,4.808
				c0.747,0.746,5.209,5.176,9.749,9.022c-1.405,1.685-3.307,3.922-5.309,6.146c-2.813,3.126-7.247,2.782-7.387,2.771
				c-1.847-0.192-3.524,1.148-3.73,3.007c-0.207,1.866,1.138,3.547,3.004,3.754c0.072,0.008,0.511,0.053,1.207,0.053
				c2.413,0,7.915-0.541,11.96-5.036c2.171-2.412,4.193-4.795,5.65-6.546c0.693,0.458,1.351,0.859,1.938,1.153
				c1.274,0.637,2.649,0.943,4.084,0.943c5.509,0,11.891-4.526,16.815-12.144c0.135,0.108,0.27,0.216,0.399,0.32
				c1.865,1.492,3.928,2.225,6.089,2.225c3.9,0,8.122-2.385,12.075-7l0.145-0.167c1.06-1.21,4.283-4.895,3.742-10.149
				c-0.378-3.668-2.51-7.233-6.309-10.627h11.486c2.322,8.125,8.676,11.044,14.422,11.044c0.552,0,1.099-0.027,1.636-0.079
				c4.742-0.456,28.34-3.453,29.342-3.58C281.498,180.185,282.816,178.483,282.579,176.62z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAgreement;
