import React from "react";
import calendlyStyle from "./calendlyStyle";
import callIcon from "../../assets/icons/common/toggleable/IconFragezeichen";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import ContentBox from "../Box/ContentBox/ContentBox";
import {getCaseLink} from "../../services/productService";
import { AbstractCase } from "../../types/Entities/AbstractCase";

type CalendlyLimitReachedProps = {
  product: AbstractCase;
};

const CalendlyLimitReached = ({ product }: CalendlyLimitReachedProps) => {
  if (!product) {
    return null;
  }

  return (
    <Box sx={calendlyStyle.contentContainer}>
      <ContentBox headline={"Weitere Hilfe benötigt?"} icon={callIcon}>
        <div>
          Es haben in diesem Fall bereits zwei Beratungen stattgefunden. Um Kosten für Sie zu vermeiden, ist es Ihnen hier nicht möglich, einen weiteren
          Beratungstermin zu buchen. Sollten Sie weitere Hilfe benötigen, wenden Sie sich bitte kurz per E-Mail an service@legalbird.de, um zu klären, wie wir
          Ihnen behilflich sein können und ob die Kosten von Ihrer Rechtsschutzversicherung gedeckt sind.
        </div>
        <Button component={Link} to={getCaseLink(product)} sx={calendlyStyle.backButton} color={"primary"} fullWidth>
          Zurück zum Fall
        </Button>
      </ContentBox>
    </Box>
  );
};


export default CalendlyLimitReached;
