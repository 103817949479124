import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSpeedTwentyOneToThirtyKmH = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {active ? (
        <>
          <path
            style={{
              fill: "none",
              strokeLinecap: "round",
              strokeWidth: "6.9",
              fillRule: "evenodd",
              stroke: getIconColor(active, theme),
            }}
            d="M98.49,144.41a128.21,128.21,0,0,1,23.69-21.6"
          />
          <path
            style={{
              fill: "none",
              strokeLinecap: "round",
              strokeWidth: "6.9",
              fillRule: "evenodd",
              stroke: getIconColor(active, theme),
            }}
            d="M123.82,166a95.7,95.7,0,0,1,11.79-11.6"
          />
          <path
            style={{
              fill: "none",
              strokeLinecap: "round",
              strokeWidth: "6.9",
              fillRule: "evenodd",
              stroke: getIconColor(active, theme),
            }}
            d="M149.66,188.08c.77-.9,1.56-1.77,2.37-2.62"
          />
        </>
      ) : (
        <>
          <path
            style={{
              fill: "#137F7B",
              stroke: "#137F7B",
              strokeWidth: "1.24px",
            }}
            d="M170.85,206.63l-99-84.46a163.11,163.11,0,0,1,44.28-36.38L135.33,120Z"
          />
        </>
      )}
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          style={{
            fill: "none",
            stroke: "#332c2b",
            strokeWidth: "6.9px",
          }}
          d="M7.19,228c0-104.24,84.5-188.74,188.74-188.74A188.75,188.75,0,0,1,287.57,63"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M7.19,228c0-104.24,84.5-188.74,188.74-188.74A188.71,188.71,0,0,1,292.3,65.74"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M32.85,222.36A5.67,5.67,0,1,1,27.19,228,5.67,5.67,0,0,1,32.85,222.36Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M42.89,165.94a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,42.89,165.94Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M71.91,116.53a5.67,5.67,0,1,1-5.66,5.66A5.66,5.66,0,0,1,71.91,116.53Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M116.17,80.14a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,116.17,80.14Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M170.28,61.31A5.67,5.67,0,1,1,164.62,67,5.66,5.66,0,0,1,170.28,61.31Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M227.58,62.39a5.67,5.67,0,1,1-5.67,5.67A5.67,5.67,0,0,1,227.58,62.39Z"
        />
        <path
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52px",
          }}
          d="M281,83.21a5.67,5.67,0,1,1-5.66,5.66A5.67,5.67,0,0,1,281,83.21Z"
        />
        <line
          style={{
            stroke: getIconColor(active, theme),
            strokeWidth: "6.94px",
          }}
          x1="26.03"
          y1="261.2"
          x2="279.37"
          y2="261.23"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M199.56,207.86a20.49,20.49,0,1,1-22.77,12.84L132.14,114.89l59.61,82.2"
        />
        <path
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9px",
          }}
          d="M193.12,235.5a8,8,0,1,1,10.28-4.67"
        />
      </g>
    </SvgIcon>
  );
};

export default IconSpeedTwentyOneToThirtyKmH;
