import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconEinfordern = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <g>
          <path
            d="M256,145.1c-31.6,0-57.2,25.5-57.2,56.9c0,31.4,25.6,56.9,57.2,56.9c31.6,0,57.2-25.5,57.2-56.9
          C313.2,170.5,287.6,145.1,256,145.1z M256,241.8c-22.1,0-40-17.9-40-39.8c0-22,18-39.8,40-39.8c22.1,0,40,17.9,40,39.8
          C296,223.9,278.1,241.8,256,241.8z"
          />
        </g>
        <g>
          <path
            d="M330,48.4c-25.3,0-45.8,20.4-45.8,45.5c0,25.1,20.5,45.5,45.8,45.5c25.3,0,45.8-20.4,45.8-45.5
          C375.8,68.7,355.3,48.4,330,48.4z M330,122.3c-15.8,0-28.6-12.8-28.6-28.5c0-15.7,12.8-28.4,28.6-28.4c15.8,0,28.6,12.8,28.6,28.4
          C358.6,109.5,345.8,122.3,330,122.3z"
          />
        </g>
        <g>
          <path
            d="M473.5,266c-5.7,0-10.9,1.4-15.5,3.4c-7-9-17.9-14.7-30.3-14.8c-6.4,0-12.2,1.7-17.2,4.2l-16.2,9.6
          c-7-8.4-17.6-13.8-29.4-13.8c-6.4,0-12.2,1.7-17.2,4.2l-58.5,35.6c-14.2-4.2-75.8-21.6-87.7-24.5c-5.9-1.5-11.1-2.7-15.4-3.6
          c-2.1-0.4-4-0.8-5.7-1c-1.7-0.3-3.1-0.4-4.7-0.4c-1.2,0-2.3,0.1-3.5,0.3c-4.4,0.6-10.7,1.9-18.2,3.7c-15.2,3.6-34.6,8.7-45.3,11.6
          v-15.9c0-12.5-10.3-22.8-22.9-22.8H22.9C10.3,241.8,0,252,0,264.5v176.4c0,12.5,10.3,22.8,22.9,22.8h62.9
          c12.6,0,22.9-10.2,22.9-22.8v-13.7c13.6,2.9,43.7,9.4,75.5,15.9c21.5,4.4,43.7,8.8,62,12.1c9.2,1.6,17.4,3,24.1,4
          c6.8,1,11.8,1.6,15.7,1.6c3,0,5.8-0.6,8.9-1.4c5.7-1.6,12.2-4.3,19.6-7.9c25.9-12.5,63-36,98-59.3c35-23.2,67.7-46.1,83.7-57.1
          c10-6.9,15.7-18.5,15.7-30.8C512,283.2,494.8,266,473.5,266z M91.5,440.9c0,3.1-2.6,5.7-5.7,5.7H22.9c-3.1,0-5.7-2.6-5.7-5.7
          V264.5c0-3.1,2.6-5.7,5.7-5.7h62.9c3.1,0,5.7,2.6,5.7,5.7L91.5,440.9L91.5,440.9z M486.6,321c-19.7,13.5-63.8,44.5-106.5,72.1
          c-21.3,13.8-42.3,26.7-59.5,36.1c-8.6,4.7-16.3,8.5-22.4,11c-3.1,1.3-5.7,2.2-7.9,2.8c-2.1,0.6-3.7,0.8-4.3,0.8
          c-1.9,0-6.8-0.5-13.2-1.4c-22.5-3.3-64.7-11.7-101.1-19.2c-18.2-3.8-34.9-7.3-47.1-10c-7.1-1.5-12.6-2.7-16-3.4V298.1
          c1.7-0.5,3.9-1.1,6.5-1.8c9-2.4,22.9-6.1,35.2-9.1c6.2-1.5,12-2.9,16.7-3.8c2.3-0.5,4.3-0.9,5.9-1.1c1.5-0.3,2.7-0.3,2.7-0.3
          c0,0,0.9,0.1,2.2,0.2c4.7,0.7,14.4,3,26.3,6c35.6,9.3,91.8,26,114.5,32.8c7.9,2.4,15,5.9,19.6,10.2c2.3,2.2,4.1,4.4,5.3,6.9
          c1.2,2.5,1.9,5.2,1.9,8.5c0,6.7-2.7,12.7-7.1,17.1c-4.4,4.3-10.3,7-17,7.1l-22.1-2.2l-75.6-9.6c-4.7-0.6-9,2.7-9.6,7.4
          c-0.6,4.7,2.7,9,7.4,9.6l75.8,9.7l23.5,2.3h0.4c22.9,0,41.4-18.5,41.5-41.2c0-5.7-1.3-11.1-3.5-15.8c-3.4-7.1-8.9-12.6-15.1-16.8
          c-6.2-4.2-13.2-7.2-20.4-9.4c-3.4-1-7.6-2.3-12.4-3.7l44.2-26.9c3-1.5,6.1-2.3,9.4-2.3c5.5,0,10.6,2.1,14.3,5.6l-23,13.6
          c-4.1,2.4-5.4,7.6-3,11.7c2.4,4,7.7,5.4,11.8,3l53.4-31.5c3-1.5,6.1-2.3,9.4-2.3c5.9,0,11.2,2.4,15.1,6.3l-62.2,36.7
          c-4.1,2.4-5.4,7.6-3,11.7c2.4,4,7.7,5.4,11.8,3l74.7-44c3-1.5,6.1-2.3,9.4-2.3c5.9,0,11.2,2.4,15.1,6.2c3.9,3.9,6.2,9.1,6.2,15
          C494.8,311.4,491.5,317.7,486.6,321z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconEinfordern;
