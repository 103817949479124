import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconStrikethroughPhone = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <line style={{ stroke: "#137F7B", strokeWidth: "6.92px" }} x1="48.87" y1="251.06" x2="250.71" y2="49.35" />
        </>
      )}
      <g style={{ fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }}
          d="M102.87,50.52h96.46A9.69,9.69,0,0,1,209,60.19V248.57a9.68,9.68,0,0,1-9.66,9.66H102.87a9.69,9.69,0,0,1-9.67-9.66V60.19A9.7,9.7,0,0,1,102.87,50.52Z"
        />
        <line style={{ stroke: getIconColor(active, theme), strokeWidth: "7.06px" }} x1="125.12" y1="70.44" x2="160.5" y2="70.51" />
        <line style={{ stroke: getIconColor(active, theme), strokeWidth: "8.94px" }} x1="149.69" y1="238.7" x2="152.5" y2="238.77" />
        <line style={{ stroke: getIconColor(active, theme), strokeWidth: "6.91px" }} x1="107.48" y1="89.41" x2="205.35" y2="89.41" />
        <line style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9px" }} x1="93.2" y1="219.34" x2="194.23" y2="219.34" />
        <line style={{ stroke: getIconColor(active, theme), strokeWidth: "8.94px" }} x1="171.98" y1="70.47" x2="174.79" y2="70.53" />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.9px", fillRule: "evenodd" }}
          d="M131.11,293.34A143.42,143.42,0,0,1,7.9,169.59C-2.42,90.88,52.74,18.68,131.11,8.32s150.26,45,160.57,123.75S246.84,283,168.47,293.34"
        />
      </g>
    </SvgIcon>
  );
};

export default IconStrikethroughPhone;
