import CheckIcon from "../../../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../../../assets/icons/common/toggleable/IconKreuz";
import Grid from "@mui/material/Grid";
import React from "react";
import Group from "../../../../Wizard/Group";
import { requiredValidator } from "../../../../../services/validationRules";
import { AbstractCase } from "../../../../../types/Entities/AbstractCase";

type ClientAgreesProps = {
  product: AbstractCase;
};

const ClientAgrees = ({ product }: ClientAgreesProps) => {
  if (product.extrajudicialReaction !== "fullyAgreed") {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"clientAgrees"}
        question={"Ist die Reaktion der Gegenseite für Sie zufriedenstellend?"}
        options={[
          {
            labelText: "Ja",
            labelIcon: <CheckIcon />,
            labelIconActive: <CheckIcon active />,
            value: "yes",
            optionHintText:
              "Hier geht es zunächst nur darum, dass die Reaktion der Gegenseite in Ihrem Sinne ist. Sollten noch Einzelheiten zur vollständigen Abwicklung des Falls zwischen Ihnen und der Gegenseite geklärt werden müssen, können Sie dies natürlich noch im Nachgang tun.",
          },
          {
            labelText: "Nein",
            labelIcon: <CrossIcon />,
            labelIconActive: <CrossIcon active />,
            value: "no",
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default ClientAgrees;
