import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconTimeLessThanOneSecond = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {active ? (
        <>
          <g style={{ fill: "none", stroke: "#137f7b", strokeLinecap: "round", strokeLinejoin: "round" }}>
            <path style={{ strokeWidth: "6.92px" }} d="M149.66,51.39A97.19,97.19,0,0,0,97.28,67.32" />
            <path style={{ strokeWidth: "6.92px" }} d="M149.91,76.6a72.11,72.11,0,0,0-33.74,8.77" />
            <path style={{ strokeWidth: "6.92px" }} d="M150.17,102.33a46.46,46.46,0,0,0-16.67,3.28" />
          </g>
        </>
      ) : (
        <>
          <path
            style={{
              fillRule: "evenodd",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fill: "#137F7B",
              stroke: "#137F7B",
              strokeWidth: "6.9px",
            }}
            d="M149.76,150.24V14.33a135.89,135.89,0,0,0-67.55,18Z"
          />
        </>
      )}

      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path style={{ fill: getIconColor(active, theme) }} d="M149.76,21.06a8.63,8.63,0,1,0,8.63,8.63A8.63,8.63,0,0,0,149.76,21.06Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M209.57,37.09a8.63,8.63,0,1,0,8.62,8.62A8.62,8.62,0,0,0,209.57,37.09Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M253.41,80.89A8.63,8.63,0,1,0,262,89.52,8.63,8.63,0,0,0,253.41,80.89Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M269.49,140.74a8.63,8.63,0,1,0,8.63,8.63A8.64,8.64,0,0,0,269.49,140.74Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M253.46,200.61a8.63,8.63,0,1,0,8.63,8.63A8.63,8.63,0,0,0,253.46,200.61Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M209.65,244.45a8.63,8.63,0,1,0,8.62,8.63A8.63,8.63,0,0,0,209.65,244.45Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M149.79,260.52a8.63,8.63,0,1,0,8.63,8.63A8.64,8.64,0,0,0,149.79,260.52Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M89.93,244.48a8.63,8.63,0,1,0,8.62,8.63A8.63,8.63,0,0,0,89.93,244.48Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M46.09,200.67a8.63,8.63,0,1,0,8.63,8.63A8.63,8.63,0,0,0,46.09,200.67Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M30,140.81a8.63,8.63,0,1,0,8.63,8.63A8.63,8.63,0,0,0,30,140.81Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M46.06,80.93a8.63,8.63,0,1,0,8.63,8.63A8.63,8.63,0,0,0,46.06,80.93Z" />
        <path style={{ fill: getIconColor(active, theme) }} d="M89.89,37.1a8.63,8.63,0,1,0,8.63,8.63A8.62,8.62,0,0,0,89.89,37.1Z" />
        <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.4px" }} x1="203.42" y1="117.61" x2="148.47" y2="148.49" />
        <line style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "8.34px" }} x1="149.06" y1="149.02" x2="66.16" y2="102.29" />
        <path style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.9px" }} d="M162.23,293a143.05,143.05,0,1,0-24.94,0" />
      </g>
    </SvgIcon>
  );
};

export default IconTimeLessThanOneSecond;
