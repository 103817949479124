import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLicensePointsFourToEight = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      >
        <polyline style={{ stroke: active ? theme.palette.primary.main : "#137F7B", fillRule: "evenodd" }} points="39.9 260.1 259.6 260.1 259.6 40.41" />
        <path
          style={{ fill: getIconColor(active, theme), fillRule: "evenodd" }}
          d="M93.38,184.76h10.21V166.45h5.52V158h-5.52V115.74h-11L61.07,159v7.46H93.38v18.31Zm0-26.78H72.94l20.44-27.7V158Zm130.87-14.17c3.22-1.93,7.73-6.08,7.73-12.79,0-9.12-7.54-15.74-18-15.74-10.76,0-18.13,6.62-18.13,15.74a14.92,14.92,0,0,0,7.55,12.79c-6.81,2.48-14.17,8.65-14.17,19.42,0,13.53,10.67,22,24.75,22,13.9,0,24.49-8.46,24.49-22,0-10.77-7.28-16.94-14.18-19.42ZM206,131.48c0-4,2.58-7.73,7.91-7.73a7.4,7.4,0,0,1,7.83,7.73c0,4.23-2.58,7.73-7.83,7.73s-7.91-3.5-7.91-7.73Zm7.91,45.28c-8.83,0-14.54-5.62-14.54-13.81s5.71-13.8,14.54-13.8c8.66,0,14.27,5.52,14.27,13.8S222.6,176.76,213.94,176.76Z"
        />
        <line style={{ stroke: getIconColor(active, theme) }} x1="139.63" y1="150.24" x2="159.86" y2="150.26" />
        <path
          style={{ stroke: getIconColor(active, theme), fillRule: "evenodd" }}
          d="M35.76,23.84h228a12.46,12.46,0,0,1,12.42,12.42v228a12.46,12.46,0,0,1-12.42,12.42h-228a12.46,12.46,0,0,1-12.42-12.42V70.74m0-21.94V36.26A12.46,12.46,0,0,1,35.76,23.84"
        />
      </g>
    </SvgIcon>
  );
};

export default IconLicensePointsFourToEight;
