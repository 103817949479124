import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconFirma = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M288.4,0c-4.1,0-7.5,3.4-7.5,7.5v45.7h-86.5c-8.1,0-14.7,6.6-14.7,14.7v178.9L66.8,318.3c-8.1,5.1-13,14-13,23.6V497c-4.1,0.1-7.4,3.4-7.4,7.5c0,4.1,3.4,7.5,7.5,7.5h404.2c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.3-7.5-7.4-7.5V371.6c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5V497H329.7V212.2h113.5v124.3c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V212c0-8.1-6.6-14.7-14.7-14.7h-46.3V67.9c0-8.1-6.6-14.7-14.7-14.7h-25.2c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h25v129h-18.7V97.1c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v100.1h-18.7V97.1c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5V497H194.7c0-4.1,0-418,0-428.8h127.4c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5h-26.2V7.5C295.9,3.4,292.6,0,288.4,0L288.4,0z M179.7,497H68.8V341.9c0-4.4,2.2-8.5,6-10.9l104.9-66.4V497z" />
        <path d="M213.5,97.1v375.2c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V97.1c0-4.1-3.4-7.5-7.5-7.5S213.5,93,213.5,97.1L213.5,97.1z" />
        <path d="M280.9,97.1v375.2c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V97.1c0-4.1-3.4-7.5-7.5-7.5S280.9,93,280.9,97.1L280.9,97.1z" />
        <path d="M247.2,97.1v375.2c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V97.1c0-4.1-3.4-7.5-7.5-7.5S247.2,93,247.2,97.1L247.2,97.1z" />
        <path d="M124.3,390.1c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C116.8,386.7,120.1,390.1,124.3,390.1z" />
        <path d="M95.9,390.1c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C88.4,386.7,91.7,390.1,95.9,390.1z" />
        <path d="M152.7,390.1c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C145.2,386.7,148.5,390.1,152.7,390.1z" />
        <path d="M124.3,434.9c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C116.8,431.6,120.1,434.9,124.3,434.9z" />
        <path d="M95.9,434.9c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C88.4,431.6,91.7,434.9,95.9,434.9z" />
        <path d="M152.7,434.9c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C145.2,431.6,148.5,434.9,152.7,434.9z" />
        <path d="M124.3,345.3c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C116.8,341.9,120.1,345.3,124.3,345.3z" />
        <path d="M152.7,345.3c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C145.2,341.9,148.5,345.3,152.7,345.3z" />
        <path d="M124.3,479.8c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C116.8,476.4,120.1,479.8,124.3,479.8z" />
        <path d="M95.9,479.8c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C88.4,476.4,91.7,479.8,95.9,479.8z" />
        <path d="M152.7,479.8c4.1,0,7.5-3.4,7.5-7.5v-8.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v8.5C145.2,476.4,148.5,479.8,152.7,479.8z" />
        <path d="M417.4,415.9h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,415.9,417.4,415.9z" />
        <path d="M417.4,450.5h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,450.5,417.4,450.5z" />
        <path d="M417.4,311.9h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,311.9,417.4,311.9z" />
        <path d="M417.4,346.6h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,346.6,417.4,346.6z" />
        <path d="M417.4,381.2h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,381.2,417.4,381.2z" />
        <path d="M417.4,242.6h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,242.6,417.4,242.6z" />
        <path d="M417.4,277.2h-62c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h62c4.1,0,7.5-3.4,7.5-7.5S421.5,277.2,417.4,277.2z" />
      </g>
    </SvgIcon>
  );
};

export default IconFirma;
