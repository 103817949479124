/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { useForm } from "../../provider/Form/FormProvider";
import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { formValue } from "../../services/formServiceFunctions";
import _get from "lodash/get";
import { FormDataTypes } from "../../types/FormDataTypes";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ValidatorType } from "../../types/ValidatorType";

export type ValidatorTextFieldProps = {
  name: string;
  label: string;
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<string>;
  helperText?: string | null | ReactJSXElement;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: "normal" | "dense";
  multiline?: boolean;
  rows?: number;
  InputProps?: Object;

  inputProps?: Record<string, any>;
  InputLabelProps?: Object;
} & TextFieldProps;

export default function ValidatorTextField({
  name,
  label,
  helperText,
  validators = [],
  dependentValidationFields = [],
  multiline = false,
  rows = multiline ? 6 : 1,
  fullWidth = true,
  margin = "normal",
  InputProps = {},
  inputProps = {},
  placeholder,
  InputLabelProps,
  ...rest
}: ValidatorTextFieldProps) {
  const { values, errors, setError, handleChange, handleBlur, registerValidators }: FormDataTypes = useForm();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  if (!multiline && !inputProps.maxLength) {
    inputProps.maxLength = 250;
  }

  return (
    <TextField
      name={name}
      label={label}
      value={formValue(values, name)}
      error={error}
      helperText={_get(errors, name) || helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={inputProps}
      rows={rows}
      multiline={multiline}
      fullWidth={fullWidth}
      margin={margin}
      InputProps={InputProps}
      placeholder={placeholder}
      InputLabelProps={InputLabelProps}
      {...rest}
    />
  );
}
