import React from "react";
import calendlyStyle from "./calendlyStyle";
import _get from "lodash/get";
import _includes from "lodash/includes";
import ContentBox from "../Box/ContentBox/ContentBox";
import callIcon from "../../assets/icons/generic/IconCallClock";
import moment from "moment";
import { Button, Link } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Customer } from "../../types/Entities/Customer";
import CalendlyData from "../../types/Entities/CalendlyData";

type CalendlyAppointmentProps = {
  calendlyData: CalendlyData;
  customer: Customer;
};

const CalendlyAppointment = ({ calendlyData, customer }: CalendlyAppointmentProps) => {
  if (!_get(calendlyData, "appointment") || !customer || _includes(["processed", "not_processed"], _get(calendlyData, "status"))) {
    return null;
  }

  return (
    <ContentBox headline={"Mein Beratungstermin"} icon={callIcon}>
      <div>
        Sie haben einen Termin für den {moment(calendlyData.appointment.date).format("DD.MM.YYYY")} um {moment(calendlyData.appointment.date).format("HH:mm")}{" "}
        Uhr gebucht und werden unter der Nummer {customer.telephone} angerufen.
      </div>
      <Button component={Link} href={calendlyData.rescheduleUrl} target="_blank" sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Termin verschieben
      </Button>
      <Button component={Link} href={calendlyData.cancelUrl} target="_blank" sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Termin absagen
      </Button>
      <Button component={HashLink} to={"/servicewelt/mein-konto#personalData"} sx={calendlyStyle.appointmentBoxbutton} color={"primary"} fullWidth>
        Telefonnummer ändern
      </Button>
    </ContentBox>
  );
};

export default CalendlyAppointment;
