import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import {getIconColor} from "../../../../services/iconService";

const IconBehoerde = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 211.46 219.28">
      <g
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
        }}
      >
        <line x1="62.08" y1="208.63" x2="29.76" y2="208.63" strokeWidth="5" />
        <g>
          <path d="M188.2,237.48H232c7.35,0,15.78,1.12,15.78-19.55V180" transform="translate(-38.82 -28.84)" strokeWidth="5" />
        </g>
        <line x1="70.58" y1="153.59" x2="138.33" y2="153.59" strokeWidth="5" />
        <line x1="101.43" y1="178.13" x2="111.17" y2="178.13" strokeWidth="5" />
        <line x1="80.45" y1="208.41" x2="80.45" y2="154.72" strokeWidth="5" />
        <line x1="128.07" y1="208.41" x2="128.07" y2="154.72" strokeWidth="5" />
        <line x1="106.1" y1="209.96" x2="106.1" y2="155.39" strokeWidth="3" />
        <g>
          <path d="M41.37,211.26H93.81s7.09,0,7.09,7.09v11.9" transform="translate(-38.82 -28.84)" strokeWidth="3" />
          <path d="M246.68,211.26H194.24s-7.09,0-7.09,7.09v11.9" transform="translate(-38.82 -28.84)" strokeWidth="3" />
        </g>
        <line x1="11.15" y1="93.53" x2="200.32" y2="93.53" strokeWidth="5" />
        <g>
          <path d="M184.37,105.3c0-21.82-18-39.51-40.13-39.51s-40.13,17.69-40.13,39.51" transform="translate(-38.82 -28.84)" strokeWidth="4.16" />
          <path d="M139.12,66.06c-15.9,9.22-21.23,22.87-18.66,39.4" transform="translate(-38.82 -28.84)" strokeWidth="2.82" />
          <path d="M149.27,66.06c15.9,9.22,21.23,22.87,18.66,39.4" transform="translate(-38.82 -28.84)" strokeWidth="2.82" />
        </g>
        <line x1="104.78" y1="37.51" x2="104.78" y2="76.62" strokeWidth="2.82" />
        <line x1="60.06" y1="85.54" x2="150.77" y2="85.54" strokeWidth="3" />
        <g>
          <line x1="60.06" y1="217.78" x2="150.77" y2="217.78" strokeWidth="3" />
          <polyline points="105.14 30.5 105.14 1.5 131.13 1.5 126.18 8.31 130.93 15.12 109.69 15.12" strokeWidth="3" />
          <path
            d="M65,175.48s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52H85s5.52,0,5.52-5.52V181s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <path
            d="M203.16,175.48s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52h20s5.52,0,5.52-5.52V181s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <path
            d="M65,138.2s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52H85s5.52,0,5.52-5.52V143.72s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <path
            d="M203.16,138.2s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52h20s5.52,0,5.52-5.52V143.72s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <path
            d="M158,138.2s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52h20s5.52,0,5.52-5.52V143.72s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <path
            d="M111.18,138.2s-5.52,0-5.52,5.52v13.34s0,5.52,5.52,5.52h20s5.52,0,5.52-5.52V143.72s0-5.52-5.52-5.52Z"
            transform="translate(-38.82 -28.84)"
            strokeWidth="3"
          />
          <line x1="208.96" y1="135.68" x2="208.96" y2="85.18" strokeWidth="5" />
          <path d="M57.1,237.48c-7.35,0-15.78,1.12-15.78-19.55V114" transform="translate(-38.82 -28.84)" strokeWidth="5" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconBehoerde;
