import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSendemast = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  151.16 210.88">
      <defs>
        <clipPath id="clip-path" transform="translate(-72.18 -36.29)">
          <rect x="72.18" y="36.29" width="151.16" height="210.88" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M147.36,92.63a8.91,8.91,0,1,0-8.9-8.91A8.91,8.91,0,0,0,147.36,92.63Z"
          transform="translate(-72.18 -36.29)"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="35.43"
          y1="191.37"
          x2="72.99"
          y2="57.29"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="115.89"
          y1="191.37"
          x2="78.33"
          y2="57.29"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M179.36,121.76a41.33,41.33,0,0,0,29.84-12.67"
          transform="translate(-72.18 -36.29)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          d="M175.69,100a18.6,18.6,0,0,0,0-37.19"
          transform="translate(-72.18 -36.29)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M216.45,98.89a41.52,41.52,0,0,0-37.09-60.1"
          transform="translate(-72.18 -36.29)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M116.17,121.76a41.49,41.49,0,0,1-37-60.17"
          transform="translate(-72.18 -36.29)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "3px", strokeLinecap: "round" }}
          d="M119.83,100a18.6,18.6,0,0,1,0-37.19"
          transform="translate(-72.18 -36.29)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M84.09,54a41.41,41.41,0,0,1,32.08-15.18"
          transform="translate(-72.18 -36.29)"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="45.73"
          y1="161.13"
          x2="103.8"
          y2="161.13"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="54.23"
          y1="125.7"
          x2="94.59"
          y2="125.7"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="75.97"
          y1="168.64"
          x2="76.11"
          y2="208.38"
        />
        <line
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          x1="75.83"
          y1="158.54"
          x2="75.83"
          y2="56.16"
        />
      </g>
    </SvgIcon>
  );
};

export default IconSendemast;
