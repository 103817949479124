import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconMotorcycle = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path d="M241.62,150.92a31.81,31.81,0,1,1-31.8,31.8A31.8,31.8,0,0,1,241.62,150.92Z" style={{ fill: "#137F7B", fillRule: "evenodd" }} />
          <path d="M59.88,150.92a31.81,31.81,0,1,1-31.8,31.8A31.8,31.8,0,0,1,59.88,150.92Z" style={{ fill: "#137F7B", fillRule: "evenodd" }} />
        </>
      )}

      <path
        d="M90.2,218.87a47.18,47.18,0,1,1,12.44-16.21"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
          fillRule: "evenodd",
        }}
      />
      <path
        d="M273.91,215a47.17,47.17,0,1,1,10.41-17.57"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
          fillRule: "evenodd",
        }}
      />

      <path
        d="M60,182.24,94.58,93.3l2.75-7.09c3.1-8,11.89-15.48,20.43-15.48h13.13"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
          fillRule: "evenodd",
        }}
      />
      <line
        x1="136.29"
        y1="185.6"
        x2="240.93"
        y2="185.74"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.95px",
        }}
      />
      <line
        x1="168.11"
        y1="185.74"
        x2="186.17"
        y2="141.02"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      />
      <path
        d="M161,108.09H262.56c8.35,0,11.73,2.38,11.73,7.87v10.46H148.72"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.94px",
          fillRule: "evenodd",
        }}
      />
      <line
        x1="136.29"
        y1="185.74"
        x2="118.22"
        y2="141.02"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      />

      <rect
        x="88.8"
        y="100.29"
        width="72.46"
        height="25.74"
        rx="12.87"
        ry="12.87"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      />
      <rect
        x="59.43"
        y="93.05"
        width="28.17"
        height="26.49"
        rx="6.26"
        ry="6.26"
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      />
    </SvgIcon>
  );
};

export default IconMotorcycle;
