import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPhone = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M232.5,390.3c-3.9,3.9-3.9,10.2,0,14.1c3.9,3.9,10.2,3.9,14.1,0c3.9-3.9,3.9-10.2,0-14.1C242.7,386.4,236.4,386.4,232.5,390.3z" />
        <path d="M488.9,373L413,308.4c-0.4-0.3-0.7-0.6-1.1-0.8c-19.9-12.7-45.5-9.9-62.2,6.8l-41.4,41.4c-2.9,2.9-7.1,3.7-10.9,2.2c-46.7-19.3-85.1-48.4-114.1-86.5c-22.1-29-30.1-52.1-31.1-55.3c-0.1-0.7-0.3-1.4-0.6-2.1c-1.6-3.8-0.7-8,2.2-10.9l41.4-41.4c16.7-16.7,19.5-42.3,6.8-62.2c-0.1-0.2-0.2-0.3-0.3-0.5l-54.8-76.2C129.5-3.9,92-7.9,69.4,14.6l-8.3,8.3c0,0,0,0,0,0L31.5,52.6C-3.8,87.8-9.6,140.9,14.8,205.9c20.9,55.7,62.7,116.7,117.7,171.7c56.3,56.3,118.9,98.9,176.3,119.8c56.8,20.8,117.3,23.3,160.1-19.5l27.9-27.9C519.3,427.6,515.3,390.3,488.9,373z M400.6,324.1l75.9,64.5c0.3,0.3,0.7,0.6,1.1,0.8c16.2,10.3,18.7,33,5.1,46.5l-2.1,2.1l-129.4-96.9l12.6-12.6C373.7,318.7,388.7,316.9,400.6,324.1z M130.1,33.9c0.1,0.2,0.2,0.3,0.3,0.5l54.8,76.2c7.4,11.9,5.7,27.1-4.2,37.1l-12.2,12.2L81.2,31.1l2.3-2.3C97.2,15.2,119.8,17.8,130.1,33.9z M315.7,478.7c-54.7-20-114.8-60.9-169.1-115.2c-53-53-93.2-111.5-113.1-164.6C18.6,159,8.6,103.8,45.7,66.7l21.2-21.2l87.5,128.7L139.6,189c-8.4,8.4-11,20.8-6.8,31.8c3.2,13,17.7,39.8,32.3,59.7c21.6,29.2,60.7,69.5,124.7,95.9c11.2,4.7,24.1,2.1,32.7-6.5l14.5-14.5l129.4,96.9l-11.6,11.5C415,503.6,357.2,493.9,315.7,478.7z" />
        <path d="M213.7,366.4c-10.6-9.3-28.8-25.2-38.5-35.1c-23.6-24.2-48.3-53.6-63.1-74.9c-3.1-4.5-9.4-5.7-13.9-2.5c-4.5,3.1-5.7,9.4-2.5,13.9c18.6,26.8,45.8,57.6,65.2,77.4c10.2,10.5,28.6,26.5,39.6,36.2c4.1,3.6,10.5,3.2,14.1-0.9C218.3,376.4,217.9,370.1,213.7,366.4z" />
      </g>
    </SvgIcon>
  );
};

export default IconPhone;
