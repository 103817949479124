import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconFastDriving = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
            d="M5.82,188.36H27.26v-.27a21.83,21.83,0,0,1,43.65,0v.27H185.56v-.27a21.83,21.83,0,0,1,43.65,0v.26h1.4c6.1,0,18.61-3.13,18.61-11V157.29H12C7,164.74,6.57,174.47,5.82,188.36Z"
          />
        </>
      )}
      <g
        style={{
          fill: "none",
          stroke: getIconColor(active, theme),
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      >
        <path
          style={{ strokeWidth: "6.92px", fillRule: "evenodd" }}
          d="M5.82,188.36H27.26v-.27m57.85.27h86.26m69.41-2c4.52-1.72,8.45-4.63,8.45-9V137.53H161.93m-14.2,0H56.56C7.43,147.61,7.46,158.2,5.82,188.36"
        />
        <line style={{ strokeWidth: "6.91px" }} x1="63.3" y1="137.53" x2="94.31" y2="108.86" />
        <path
          style={{ strokeWidth: "6.91px", fillRule: "evenodd" }}
          d="M143.46,119.76h5.91a2.51,2.51,0,0,1,2.46,3.44l-4.1,14.33h-12.8L139,123.2A4.94,4.94,0,0,1,143.46,119.76Z"
        />
        <line style={{ strokeWidth: "6.91px" }} x1="260.36" y1="137.53" x2="295.51" y2="137.58" />
        <line style={{ strokeWidth: "6.92px" }} x1="260.36" y1="162.93" x2="286.99" y2="162.97" />
        <line style={{ strokeWidth: "6.92px" }} x1="260.36" y1="188.32" x2="274.06" y2="188.32" />
        <line style={{ strokeWidth: "7.04px" }} x1="23.01" y1="226.27" x2="294.3" y2="226.32" />
        <path style={{ strokeWidth: "6.92px" }} d="M69.53,195.51a21.76,21.76,0,1,1,0-14.89" />
        <path style={{ strokeWidth: "6.92px" }} d="M227.84,195.54a21.77,21.77,0,1,1,0-14.89" />
      </g>
    </SvgIcon>
  );
};

export default IconFastDriving;
