import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLicensePointsOneToThree = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "6.9px",
        }}
      >
        <polyline style={{ stroke: active ? theme.palette.primary.main : "#137F7B", fillRule: "evenodd" }} points="39.9 260.1 259.57 260.1 259.57 40.43" />
        <path
          style={{ fill: getIconColor(active, theme), fillRule: "evenodd" }}
          d="M100.59,115.75H80.06v8.47H90.37v60.55h10.22v-69Zm108.77,24.4,16.39-24.4h-38v8.47H209l-15.83,22.84,4.51,3.77c9.29-4.6,18-.09,18,11.23,0,9.2-6.35,14.71-13.43,14.71a13.93,13.93,0,0,1-11.51-6l-9.2,3.94a25.84,25.84,0,0,0,21.17,10.49,23,23,0,0,0,23.19-23.17C225.93,149.91,219.4,142.92,209.36,140.15Z"
        />
        <line style={{ stroke: getIconColor(active, theme) }} x1="139.62" y1="150.25" x2="159.85" y2="150.28" />
        <path
          style={{ stroke: getIconColor(active, theme), fillRule: "evenodd" }}
          d="M35.75,23.86h228a12.46,12.46,0,0,1,12.42,12.42v228a12.46,12.46,0,0,1-12.42,12.43h-228a12.47,12.47,0,0,1-12.42-12.43V70.76m0-21.94V36.28A12.46,12.46,0,0,1,35.75,23.86"
        />
      </g>
    </SvgIcon>
  );
};

export default IconLicensePointsOneToThree;
