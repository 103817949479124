import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconKurzarbeitMinimal = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27">
        <g>
          <path style={{ fill: "none" }} d="M217.207,81.104c5.805,4.74,11.139,10.037,15.917,15.813l6.808-6.809l-15.865-15.865L217.207,81.104z" />
          <path
            style={{ fill: "none" }}
            d="M260.12,84.5c0.661-0.66,1.024-1.537,1.024-2.471s-0.364-1.811-1.024-2.471l-25.506-25.506
			c-1.319-1.32-3.622-1.32-4.945,0l-6.747,6.75l30.448,30.447L260.12,84.5z"
          />
          <path
            style={{ fill: "none" }}
            d="M133.243,55.922c3.868-0.393,7.769-0.604,11.693-0.604c3.623,0,7.206,0.178,10.744,0.508v-9.574
			h-22.437V55.922z"
          />
          <g>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M202.93,111.742c-15.492-15.492-36.09-24.023-58.001-24.023c-21.914,0-42.516,8.533-58.011,24.029
				c-31.981,31.98-31.979,84.018,0.003,116.002c15.496,15.494,36.097,24.029,58.008,24.029c21.908,0,42.505-8.533,57.997-24.025
				C234.912,195.77,234.914,143.727,202.93,111.742z M140.581,99.723c0-2.402,1.946-4.348,4.347-4.348s4.347,1.945,4.347,4.348
				v12.914c0,2.4-1.946,4.348-4.347,4.348s-4.347-1.947-4.347-4.348V99.723z M95.769,115.287c2.966,0,5.37,2.402,5.37,5.367
				s-2.404,5.371-5.37,5.371c-2.965,0-5.368-2.406-5.368-5.371S92.804,115.287,95.769,115.287z M74.902,174.094
				c-2.401,0-4.347-1.947-4.347-4.348s1.946-4.348,4.347-4.348h12.914c2.401,0,4.347,1.947,4.347,4.348s-1.946,4.348-4.347,4.348
				H74.902z M95.835,224.273c-2.965,0-5.368-2.404-5.368-5.369s2.403-5.367,5.368-5.367s5.37,2.402,5.37,5.367
				S98.8,224.273,95.835,224.273z M149.273,239.771c0,2.4-1.946,4.348-4.347,4.348s-4.347-1.947-4.347-4.348v-12.912
				c0-2.402,1.946-4.348,4.347-4.348s4.347,1.945,4.347,4.348V239.771z M185.657,134.186l-26.134,41.268
				c-0.763,1.961-1.926,3.799-3.506,5.379c-0.025,0.025-0.052,0.047-0.077,0.07c-0.018,0.018-0.036,0.033-0.054,0.049
				c-2.948,2.883-6.829,4.475-10.959,4.475c-4.189,0-8.127-1.631-11.088-4.594c-2.961-2.961-4.593-6.898-4.592-11.088
				c0-4.188,1.631-8.125,4.592-11.088c0.015-0.014,0.032-0.027,0.047-0.043c0.019-0.018,0.038-0.033,0.058-0.051
				c1.603-1.576,3.487-2.754,5.536-3.512l41.406-25.682c1.377-0.854,3.162-0.643,4.304,0.508
				C186.33,131.029,186.524,132.816,185.657,134.186z M194.018,115.221c2.966,0,5.37,2.402,5.37,5.367s-2.404,5.371-5.37,5.371
				c-2.965,0-5.367-2.406-5.367-5.371S191.053,115.221,194.018,115.221z M194.086,224.205c-2.965,0-5.369-2.402-5.369-5.367
				s2.404-5.369,5.369-5.369s5.368,2.404,5.368,5.369S197.051,224.205,194.086,224.205z M214.953,174.096h-12.914
				c-2.401,0-4.347-1.947-4.347-4.348s1.946-4.348,4.347-4.348h12.914c2.401,0,4.347,1.947,4.347,4.348
				S217.354,174.096,214.953,174.096z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M156.015,158.656c1.326,1.326,2.346,2.838,3.097,4.447l12.561-19.834l-19.934,12.363
				C153.302,156.389,154.751,157.393,156.015,158.656z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M144.927,161.02c-0.837,0-1.654,0.121-2.436,0.348l-3.958,2.455
				c-1.5,1.617-2.332,3.705-2.332,5.924c0,2.33,0.907,4.521,2.555,6.168c1.648,1.648,3.839,2.557,6.17,2.557
				c2.225,0,4.317-0.834,5.937-2.344l2.376-3.752c0.945-3.004,0.235-6.422-2.143-8.801
				C149.449,161.928,147.257,161.02,144.927,161.02z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M234.247,226.363c10.739-16.906,16.416-36.484,16.416-56.615
				c0-58.303-47.429-105.734-105.727-105.734c-20.765,0-40.864,6.014-58.125,17.393c-2.004,1.318-4.701,0.766-6.022-1.238
				c-0.046-0.07-0.073-0.146-0.115-0.219C77.28,82.381,74.03,85,70.939,87.793c0.064,0.059,0.136,0.102,0.197,0.164
				c1.675,1.721,1.639,4.473-0.081,6.148c-0.361,0.352-0.718,0.707-1.075,1.063c-0.849,0.85-1.961,1.273-3.074,1.273
				s-2.225-0.424-3.074-1.273c-0.057-0.057-0.097-0.123-0.15-0.184c-3.054,3.316-5.909,6.82-8.542,10.492
				c0.072,0.043,0.15,0.068,0.221,0.115c2.004,1.322,2.557,4.02,1.235,6.023c-11.385,17.26-17.403,37.363-17.403,58.133
				c0,58.303,47.436,105.734,105.743,105.734c20.565,0,40.5-5.908,57.652-17.086c2.011-1.309,4.705-0.742,6.016,1.268
				c0.047,0.072,0.074,0.152,0.117,0.227c3.6-2.553,7.04-5.314,10.303-8.27c-0.064-0.059-0.137-0.104-0.198-0.166
				c-1.678-1.717-1.647-4.469,0.07-6.146c0.414-0.406,0.823-0.813,1.229-1.227c1.684-1.711,4.437-1.732,6.147-0.049
				c0.064,0.064,0.109,0.137,0.168,0.203c3.379-3.695,6.506-7.625,9.363-11.756c-0.072-0.039-0.147-0.072-0.218-0.117
				C233.559,231.076,232.959,228.389,234.247,226.363z M209.074,233.902c-17.134,17.135-39.914,26.57-64.144,26.57
				c-24.234,0-47.018-9.438-64.156-26.574C45.401,198.523,45.4,140.969,80.771,105.6c17.137-17.137,39.922-26.574,64.158-26.574
				c24.233,0,47.015,9.436,64.148,26.568C244.451,140.969,244.45,198.527,209.074,233.902z"
            />
            <rect x="117.507" y="24.518" style={{ fill: "#137F7B" }} width="53.905" height="13.041" />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M80.774,233.898c17.138,17.137,39.922,26.574,64.156,26.574c24.23,0,47.01-9.436,64.144-26.57
				c35.376-35.375,35.377-92.934,0.004-128.309c-17.133-17.133-39.915-26.568-64.148-26.568c-24.236,0-47.021,9.438-64.158,26.574
				C45.4,140.969,45.401,198.523,80.774,233.898z M86.918,111.748c15.495-15.496,36.097-24.029,58.011-24.029
				c21.911,0,42.509,8.531,58.001,24.023c31.983,31.984,31.981,84.027-0.004,116.012c-15.492,15.492-36.089,24.025-57.997,24.025
				c-21.912,0-42.512-8.535-58.008-24.029C54.939,195.766,54.938,143.729,86.918,111.748z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M144.926,185.426c4.13,0,8.011-1.592,10.959-4.475c0.018-0.016,0.036-0.031,0.054-0.049
				c0.025-0.023,0.052-0.045,0.077-0.07c1.58-1.58,2.744-3.418,3.506-5.379l26.134-41.268c0.868-1.369,0.673-3.156-0.467-4.309
				c-1.142-1.15-2.927-1.361-4.304-0.508l-41.406,25.682c-2.049,0.758-3.934,1.936-5.536,3.512c-0.02,0.018-0.039,0.033-0.058,0.051
				c-0.016,0.016-0.032,0.029-0.047,0.043c-2.961,2.963-4.592,6.9-4.592,11.088c0,4.189,1.631,8.127,4.592,11.088
				C136.799,183.795,140.737,185.426,144.926,185.426z M171.672,143.27l-12.561,19.834c-0.75-1.609-1.771-3.121-3.097-4.447
				c-1.264-1.264-2.713-2.268-4.276-3.023L171.672,143.27z M138.533,163.822l3.958-2.455c0.782-0.227,1.599-0.348,2.436-0.348
				c2.331,0,4.522,0.908,6.17,2.555c2.377,2.379,3.088,5.797,2.143,8.801l-2.376,3.752c-1.62,1.51-3.711,2.344-5.937,2.344
				c-2.331,0-4.521-0.908-6.17-2.557c-1.648-1.646-2.555-3.838-2.555-6.168C136.202,167.527,137.033,165.439,138.533,163.822z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M226.272,244.033c-1.711-1.684-4.464-1.662-6.147,0.049c-0.406,0.414-0.815,0.82-1.229,1.227
				c-1.717,1.678-1.749,4.43-0.07,6.146c0.061,0.063,0.133,0.107,0.198,0.166c0.826,0.75,1.863,1.143,2.912,1.143
				c1.096,0,2.193-0.412,3.038-1.238c0.455-0.445,0.903-0.891,1.349-1.344c1.621-1.646,1.645-4.25,0.119-5.945
				C226.381,244.17,226.336,244.098,226.272,244.033z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M63.833,95.168c0.849,0.85,1.961,1.273,3.074,1.273s2.225-0.424,3.074-1.273
				c0.357-0.355,0.713-0.711,1.075-1.063c1.72-1.676,1.756-4.428,0.081-6.148c-0.061-0.063-0.133-0.105-0.197-0.164
				c-1.687-1.539-4.293-1.531-5.951,0.084c-0.389,0.379-0.772,0.76-1.156,1.143c-1.64,1.643-1.68,4.258-0.15,5.965
				C63.735,95.045,63.775,95.111,63.833,95.168z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M208.603,259.664c-1.311-2.01-4.004-2.576-6.016-1.268
				c-17.151,11.178-37.087,17.086-57.652,17.086c-58.307,0-105.743-47.432-105.743-105.734c0-20.77,6.018-40.873,17.403-58.133
				c1.322-2.004,0.769-4.701-1.235-6.023c-0.071-0.047-0.148-0.072-0.221-0.115c-1.979-1.148-4.527-0.58-5.802,1.352
				c-12.325,18.686-18.839,40.443-18.839,62.92c0,63.096,51.336,114.43,114.437,114.43c22.255,0,43.832-6.396,62.399-18.498
				c1.939-1.262,2.519-3.805,1.385-5.789C208.677,259.816,208.65,259.736,208.603,259.664z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M144.928,116.984c2.401,0,4.347-1.947,4.347-4.348V99.723c0-2.402-1.946-4.348-4.347-4.348
				s-4.347,1.945-4.347,4.348v12.914C140.581,115.037,142.527,116.984,144.928,116.984z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="194.018" cy="120.59" r="5.369" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.692,169.748c0,2.4,1.946,4.348,4.347,4.348h12.914c2.401,0,4.347-1.947,4.347-4.348
				s-1.946-4.348-4.347-4.348h-12.914C199.638,165.4,197.692,167.348,197.692,169.748z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="194.086" cy="218.837" r="5.368" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M144.926,222.512c-2.401,0-4.347,1.945-4.347,4.348v12.912c0,2.4,1.946,4.348,4.347,4.348
				s4.347-1.947,4.347-4.348v-12.912C149.273,224.457,147.327,222.512,144.926,222.512z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="95.835" cy="218.905" r="5.368" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M74.902,174.094h12.914c2.401,0,4.347-1.947,4.347-4.348s-1.946-4.348-4.347-4.348H74.902
				c-2.401,0-4.347,1.947-4.347,4.348S72.501,174.094,74.902,174.094z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="95.769" cy="120.656" r="5.369" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M250.297,100.473c0.815,0.816,1.921,1.273,3.074,1.273c1.153,0,2.259-0.459,3.074-1.273
				l9.822-9.824c2.304-2.301,3.572-5.363,3.572-8.619s-1.269-6.316-3.571-8.619l-25.506-25.506c-2.302-2.303-5.362-3.57-8.618-3.57
				c-3.255,0-6.316,1.268-8.621,3.57l-9.822,9.826c-1.697,1.697-1.697,4.449,0,6.146l4.832,4.832l-7.607,7.607
				c-13.856-9.816-29.982-16.625-47.421-19.484v-10.58h12.255c2.401,0,4.347-1.947,4.347-4.348V20.17c0-2.4-1.946-4.348-4.347-4.348
				h-62.6c-2.401,0-4.347,1.947-4.347,4.348v21.734c0,2.4,1.946,4.348,4.347,4.348h12.257v10.744
				c-15.442,2.654-30.186,8.443-43.392,17.15c-1.934,1.275-2.5,3.824-1.352,5.803c0.042,0.072,0.068,0.148,0.115,0.219
				c1.321,2.004,4.018,2.557,6.022,1.238c17.261-11.379,37.36-17.393,58.125-17.393c58.298,0,105.727,47.432,105.727,105.734
				c0,20.131-5.677,39.709-16.416,56.615c-1.287,2.025-0.688,4.713,1.338,6c0.071,0.045,0.146,0.078,0.218,0.117
				c0.666,0.373,1.39,0.561,2.109,0.561c1.438,0,2.845-0.713,3.673-2.016c11.626-18.303,17.771-39.492,17.771-61.277
				c0-24.816-7.944-47.811-21.417-66.582l7.525-7.525L250.297,100.473z M117.507,37.559V24.518h53.905v13.041H117.507z
				 M155.679,55.826c-3.538-0.33-7.121-0.508-10.744-0.508c-3.924,0-7.825,0.211-11.693,0.604v-9.67h22.437V55.826z M229.67,54.053
				c1.323-1.32,3.625-1.32,4.945,0l25.506,25.506c0.66,0.66,1.024,1.537,1.024,2.471s-0.363,1.811-1.024,2.471l-6.75,6.75
				l-30.448-30.447L229.67,54.053z M233.124,96.916c-4.777-5.775-10.112-11.072-15.917-15.813l6.859-6.861l15.865,15.865
				L233.124,96.916z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconKurzarbeitMinimal;
