import React, { useEffect, useState } from "react";
import { getQueryParam } from "../../services/urlParamService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/reactQuery/reactQueryService";
import TextContainer from "../../components/Container/TextContainer";
import LegalbirdLoader from "../../components/ContentLoader/LegalbirdLoader";
import Container from "../../components/Container/Container";
import TextCard from "../../components/Cards/TextCard/TextCard";
import DocumentPaid from "../../components/Payment/Paid/Product/DocumentPaid";
import TrafficPaid from "../../components/Payment/Paid/Product/TrafficPaid";
import CheckIcon from "../../assets/icons/common/toggleable/IconHaken";
import { CheckoutOrderProvider, useCheckoutOrder } from "../../provider/CheckoutOrder/CheckoutOrderProvider";
import PaymentFailed from "../../components/Payment/PaymentFailed/PaymentFailed";
import { useLocation } from "react-router-dom";
import Error404Page from "../Pages/Generic/Error404/Error404Page";

export default function CheckoutConfirmation() {
  const location = useLocation();
  const orderId = getQueryParam(location.search, "orderId");

  if (!orderId) {
    return <Error404Page />;
  }

  return (
    <CheckoutOrderProvider orderId={orderId}>
      <CheckoutConfirmationInner />
    </CheckoutOrderProvider>
  );
}

function CheckoutConfirmationInner() {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("");
  const { checkoutOrder, isLoading: isLoadingCheckoutOrder } = useCheckoutOrder();

  const transactionId = getQueryParam(location.search, "transactionId");

  const apiUri = "/payment_transactions";
  const convertedTransactionId = transactionId ? parseInt(transactionId) : 0;
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data) => queryClient.setQueryData(queryKeys.item(apiUri, convertedTransactionId), data),
  });

  useEffect(() => {
    if (!checkoutOrder || checkoutOrder.paidDate) {
      return;
    }
    finishTransaction();
  }, [checkoutOrder]);
  const finishTransaction = async () => {
    setIsLoading(true);
    const transaction = await updateMutation.mutateAsync({ uri: apiUri, id: convertedTransactionId, data: {} });
    setTransactionStatus(transaction.transactionStatus);
    setIsLoading(false);
  };

  if (isLoading || isLoadingCheckoutOrder) {
    return (
      <TextContainer>
        <LegalbirdLoader />
      </TextContainer>
    );
  }

  if (!checkoutOrder.paidDate && transactionStatus !== "charged") {
    return <PaymentFailed />;
  }

  const boxHeading = checkoutOrder.isFreeOrder ? "Vielen Dank - Ihr Dokument wurde erstellt!" : "Vielen Dank für Ihren Kauf - Ihre Zahlung war erfolgreich!";

  return (
    <Container>
      <TextCard boxHeading={boxHeading} headingColor={"primary"} minHeadingHeight={0}>
        <TextContainer>
          <CheckIcon active sx={{ width: 100, height: 100 }} />
          {checkoutOrder.product.checkoutItem.id === 3 ? (
            <TrafficPaid product={checkoutOrder.product} />
          ) : (
            <DocumentPaid isFreeOrder={checkoutOrder.isFreeOrder} product={checkoutOrder.product} />
          )}
        </TextContainer>
      </TextCard>
    </Container>
  );
}
