import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconErbe = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M145.7,203.814c0.869,1.122,2.208,1.779,3.627,1.779h0.001c1.418,0,2.757-0.656,3.626-1.777l56.716-73.114
        c1.074-1.384,1.266-3.259,0.495-4.832c-0.771-1.573-2.37-2.57-4.122-2.57h-28.617c0.809-36.924,11.438-66.392,30.861-85.418
        c15.223-14.912,30.703-18.356,30.844-18.386c2.29-0.475,3.854-2.596,3.634-4.924c-0.219-2.329-2.158-4.12-4.497-4.159
        l-0.327-0.002c-3.141,0-31.484,0.428-59.044,15.248c-24.398,13.12-53.36,40.668-54.839,97.642H92.684
        c-1.751,0-3.35,0.997-4.121,2.569c-0.771,1.573-0.58,3.447,0.493,4.832L145.7,203.814z M128.591,132.479
        c2.535,0,4.59-2.055,4.59-4.589c0-44.606,16.845-76.282,50.066-94.146c9.783-5.261,19.66-8.568,28.289-10.646
        c-3.012,2.213-6.121,4.794-9.231,7.799c-15.559,15.038-34.106,44.073-34.106,96.994c0,2.535,2.055,4.589,4.59,4.589h23.887
        l-47.345,61.033l-47.286-61.033H128.591z"
        />
        <path
          d="M173.729,191.33c-1.774,0-3.213,1.438-3.213,3.213s1.438,3.213,3.213,3.213h62.921v84.032H63.35v-74.242
        c0-1.774-1.438-3.213-3.213-3.213s-3.213,1.438-3.213,3.213v80.667h186.151V191.33H173.729z"
        />
        <path
          d="M60.137,197.755h64.058c1.774,0,3.213-1.438,3.213-3.213s-1.438-3.213-3.213-3.213H60.137
        c-1.774,0-3.213,1.438-3.213,3.213S58.363,197.755,60.137,197.755z"
        />
        <path
          d="M60.137,184.267h53.742c1.774,0,3.213-1.438,3.213-3.213s-1.438-3.213-3.213-3.213H60.137
        c-1.774,0-3.213,1.438-3.213,3.213S58.363,184.267,60.137,184.267z"
        />
        <path
          d="M60.137,170.38h45.41c1.774,0,3.213-1.438,3.213-3.212s-1.438-3.213-3.213-3.213h-45.41
        c-1.774,0-3.213,1.439-3.213,3.213S58.363,170.38,60.137,170.38z"
        />
        <path
          d="M233.912,184.274c1.774,0,3.213-1.438,3.213-3.213v-13.894c0-1.447-0.967-2.715-2.361-3.099
        c-1.395-0.383-2.874,0.213-3.613,1.456c-6.359,10.696-36.335,12.188-47.345,11.859c-1.79-0.059-3.254,1.341-3.307,3.115
        c-0.053,1.773,1.341,3.254,3.114,3.308c3.779,0.114,32.739,0.65,47.087-8.964v6.219
        C230.699,182.835,232.137,184.274,233.912,184.274z"
        />
        <path
          d="M220.819,162.323c1.774,0,3.213-1.438,3.213-3.213v-15.35c0-1.774-1.438-3.213-3.213-3.213
        s-3.213,1.439-3.213,3.213c0,3.078-10.639,10.19-22.767,15.22c-1.639,0.679-2.417,2.559-1.738,4.198
        c0.513,1.237,1.708,1.983,2.969,1.983c0.41,0,0.826-0.079,1.228-0.246c3.996-1.655,13.753-5.992,20.307-11.342v5.536
        C217.606,160.884,219.045,162.323,220.819,162.323z"
        />
        <path
          d="M157.923,220.579c3.383,0,6.622,0.757,9.626,2.252c1.589,0.79,3.518,0.142,4.308-1.446
        c0.79-1.589,0.143-3.517-1.446-4.308c-3.902-1.94-8.103-2.924-12.487-2.924c-12.059,0-22.365,7.644-26.331,18.34h-4.439
        c-1.774,0-3.213,1.438-3.213,3.213s1.438,3.213,3.213,3.213h2.899c-0.128,1.085-0.201,2.186-0.201,3.305s0.073,2.221,0.201,3.306
        h-2.899c-1.774,0-3.213,1.438-3.213,3.213s1.438,3.213,3.213,3.213h4.439c3.966,10.696,14.271,18.34,26.33,18.34
        c6.741,0,13.259-2.426,18.354-6.831c1.342-1.16,1.49-3.189,0.329-4.531c-1.161-1.343-3.189-1.491-4.531-0.329
        c-3.928,3.395-8.953,5.265-14.151,5.265c-8.437,0-15.756-4.854-19.326-11.914h24.176c1.774,0,3.213-1.438,3.213-3.213
        s-1.438-3.213-3.213-3.213h-26.244c-0.166-1.078-0.252-2.182-0.252-3.306c0-1.123,0.086-2.227,0.252-3.305h26.244
        c1.774,0,3.213-1.438,3.213-3.213s-1.438-3.213-3.213-3.213h-24.177C142.166,225.433,149.486,220.579,157.923,220.579z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconErbe;
