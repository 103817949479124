import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconBus = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd" }}>
        {!active && (
          <>
            <path
              d="M193.1,140.84l-159.95,0c-26.64,0-29.36,54.81-19.06,54.59L31,195c3.74-19.5,17.84-25.94,31.45-22.3C73.33,175.63,78,187.92,77.86,195H193.1Z"
              style={{ fill: "#137F7B" }}
            />
            <path
              d="M108.57,141.21H289.29v42.54c0,5-7.31,11.64-14.1,11.64h-4.51c-3.74-19.5-17.84-25.95-31.45-22.31-10.88,2.91-15.53,15.2-15.42,22.31H108.57Z"
              style={{ fill: "#137F7B" }}
            />
          </>
        )}
        <path
          d="M6.21,195H29.53v-.28m63,.28h116M284,192.9c4.92-1.86,9.19-5,9.19-9.8V82.16m-14.83,57.55H34.86c-28,0-26.87,22.5-28.65,55.31"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <path
          d="M24.49,139.71,36.1,94.88c2.38-9.21,11.66-12.72,18.5-12.72H278.49"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.91px",
          }}
        />
        <path
          d="M75.53,202.81a23.69,23.69,0,1,1,0-16.2"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <path
          d="M269.94,202.84a23.69,23.69,0,1,1,0-16.2"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="77.42"
          y1="126.43"
          x2="77.42"
          y2="98.36"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="120.36"
          y1="126.43"
          x2="120.36"
          y2="98.36"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="163.3"
          y1="126.43"
          x2="163.3"
          y2="98.36"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="206.24"
          y1="126.43"
          x2="206.24"
          y2="98.36"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
        <line
          x1="249.18"
          y1="126.43"
          x2="249.18"
          y2="98.36"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconBus;
