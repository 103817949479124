import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconCar = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  470 470">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="m63.819,208.587h342.362c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-342.362c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5z" />
        <path d="m463.47,228.624l-19.39-30.53-7.263-29.174h15.683c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-19.417l-19.566-78.595c-4.858-19.514-24.416-34.8-44.525-34.8h-267.984c-20.109,0-39.667,15.286-44.525,34.8l-19.566,78.595h-19.417c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h15.682l-7.263,29.174-19.39,30.531c-3.722,5.862-6.529,15.519-6.529,22.462v108.638c0,4.142 3.358,7.5 7.5,7.5h2.5v54.75c0,4.142 3.358,7.5 7.5,7.5h78.911c4.142,0 7.5-3.358 7.5-7.5v-32.25c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v24.75h-63.911v-47.25h106.947c0.021,0 206.215,0 206.215,0 0.068,0 106.838,0 106.838,0v47.25h-63.911v-24.75c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v32.25c0,4.142 3.358,7.5 7.5,7.5h78.911c4.142,0 7.5-3.358 7.5-7.5v-54.75h2.5c4.142,0 7.5-3.358 7.5-7.5v-108.638c0-6.943-2.807-16.6-6.53-22.463zm-448.47,22.463c0-4.092 1.998-10.966 4.192-14.42l20.042-31.558c0.432-0.681 0.752-1.427 0.947-2.209l30.858-123.952c3.161-12.697 16.885-23.423 29.969-23.423h267.984c13.084,0 26.809,10.727 29.969,23.424l30.857,123.95c0.195,0.782 0.515,1.528 0.947,2.209l20.042,31.558c2.194,3.455 4.192,10.329 4.192,14.421v101.138h-111.734l-18.115-45.328c-1.138-2.849-3.896-4.717-6.964-4.717h-166.372c-3.068,0-5.826,1.868-6.964,4.717l-18.115,45.328h-111.735v-101.138zm312.112,101.138h-184.224l14.006-35.045h156.212l14.006,35.045z" />
        <path d="m37.5,314.681h28.22c22.357,0 40.547-18.189 40.547-40.547 0-22.357-18.189-40.547-40.547-40.547h-22.681c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h22.681c14.086,0 25.547,11.46 25.547,25.547 0,14.087-11.46,25.547-25.547,25.547h-28.22c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5z" />
        <path d="m363.733,274.134c0,22.358 18.189,40.547 40.547,40.547h28.22c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-28.22c-14.086,0-25.547-11.46-25.547-25.547 0-14.086 11.46-25.547 25.547-25.547h22.682c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-22.682c-22.358-2.84217e-14-40.547,18.189-40.547,40.547z" />
      </g>
    </SvgIcon>
  );
};

export default IconCar;
