import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconSpeedZeroToTwentyKmH = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {active ? (
        <>
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), fillRule: "evenodd", strokeWidth: "6.91" }}
            d="M67.71,227.82a128.5,128.5,0,0,1,22.71-72.94"
          />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.91", fillRule: "evenodd" }}
            d="M101,227.82a95.19,95.19,0,0,1,11.44-45.25"
          />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.91", fillRule: "evenodd" }}
            d="M135,227.82a61.28,61.28,0,0,1,3.57-20.6"
          />
        </>
      ) : (
        <>
          <path
            d="M176,227.28l-142.52.13A163,163,0,0,1,72.2,122l35.89,30.46Z"
            style={{
              fill: "#137F7B",
              stroke: "#137F7B",
              strokeWidth: "1.24",
            }}
          />
        </>
      )}
      <g
        style={{
          strokeLinecap: "round",
          strokeLinejoin: "round",
          fillRule: "evenodd",
        }}
      >
        <path
          d="M7.84,227.41c0-104.2,84.46-188.66,188.65-188.66a188.66,188.66,0,0,1,91.59,23.73"
          style={{
            fill: "none",
            stroke: "#332c2b",
            strokeWidth: "6.9",
          }}
        />
        <path
          d="M7.84,227.41c0-104.2,84.46-188.66,188.65-188.66A188.68,188.68,0,0,1,292.82,65.2"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9",
          }}
        />
        <path
          d="M192.8,207.26a20.48,20.48,0,1,1-16.79,20L97,144l84.68,55.94"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9",
          }}
        />
        <path
          d="M196.49,235.38a8,8,0,1,1,8-8"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.9",
          }}
        />
        <path
          d="M33.49,221.75a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,33.49,221.75Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M43.52,165.35A5.66,5.66,0,1,1,37.86,171,5.65,5.65,0,0,1,43.52,165.35Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M72.53,116a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,72.53,116Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M116.76,79.59a5.66,5.66,0,1,1-5.65,5.66A5.65,5.65,0,0,1,116.76,79.59Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M170.86,60.77a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,170.86,60.77Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M228.12,61.85a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,228.12,61.85Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <path
          d="M281.49,82.66a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,281.49,82.66Z"
          style={{
            fill: getIconColor(active, theme),
            stroke: getIconColor(active, theme),
            strokeWidth: "5.52",
          }}
        />
        <line
          x1="26.67"
          y1="260.57"
          x2="279.89"
          y2="260.6"
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeWidth: "6.94",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconSpeedZeroToTwentyKmH;
