import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconReview = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_3">
        <g>
          <path
            fill="#137F7B"
            d="M64.449,114.826l-46.383,6.629L82.5,183.559c0.961,0.926,1.396,2.272,1.16,3.588l-15.756,87.658
			l80.211-42.854c1.146-0.613,2.518-0.63,3.682-0.046l64.57,32.457C131.191,195.364,86.828,145.203,64.449,114.826z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M291.385,118.384c-0.791-2.431-3.027-4.084-6.146-4.537l-89.601-12.807l-39.428-80.602
			C154.822,17.619,152.559,16,150,16c-2.557,0-4.82,1.618-6.215,4.448l-39.42,80.591l-89.611,12.809
			c-3.111,0.451-5.348,2.105-6.139,4.537c-0.791,2.432,0.045,5.086,2.313,7.299l64.482,62.149l-15.779,87.782
			c-0.531,3.096,0.35,5.734,2.418,7.238c1.049,0.762,2.279,1.145,3.605,1.145c1.291,0,2.672-0.363,4.059-1.092l80.344-42.926
			l82.542,41.492c1.976,0.99,4.379,0.197,5.371-1.777c0.992-1.973,0.197-4.377-1.777-5.369l-19.825-9.967l-64.57-32.457
			c-1.164-0.584-2.535-0.567-3.682,0.046l-80.211,42.854l15.756-87.658c0.236-1.314-0.199-2.662-1.16-3.588l-64.434-62.104
			l46.383-6.629l43.145-6.166c1.312-0.188,2.445-1.013,3.027-2.202L150,25.947l39.385,80.511c0.582,1.19,1.715,2.015,3.025,2.202
			l89.523,12.795L217.5,183.559c-0.973,0.938-1.406,2.303-1.152,3.629l14.687,77.013c0.414,2.17,2.521,3.59,4.678,3.18
			c2.17-0.414,3.595-2.509,3.181-4.679l-14.279-74.885l64.479-62.149C291.34,123.47,292.176,120.815,291.385,118.384z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconReview;
