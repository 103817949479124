import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import {getIconColor} from "../../../../services/iconService";

const IconIncomplete = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  60 60">
      <g
        style={{
          fill: getIconColor(active, theme),
        }}
      >
        <circle cx="56" cy="24" r="1" />
        <circle cx="53" cy="21" r="1" />
        <circle cx="47" cy="15" r="1" />
        <circle cx="50" cy="12" r="1" />
        <circle cx="44" cy="12" r="1" />
        <circle cx="47" cy="21" r="1" />
        <circle cx="50" cy="24" r="1" />
        <circle cx="50" cy="18" r="1" />
        <circle cx="44" cy="24" r="1" />
        <circle cx="44" cy="18" r="1" />
        <circle cx="41" cy="9" r="1" />
        <circle cx="41" cy="15" r="1" />
        <circle cx="41" cy="21" r="1" />
        <circle cx="35" cy="9" r="1" />
        <circle cx="38" cy="6" r="1" />
        <circle cx="35" cy="15" r="1" />
        <circle cx="38" cy="12" r="1" />
        <circle cx="35" cy="21" r="1" />
        <circle cx="38" cy="24" r="1" />
        <circle cx="38" cy="18" r="1" />
        <path d="M60,29l-0.1-1.1c-1-14.9-13-26.8-27.9-27.9L31,0l0,29L60,29z M33,27l0-24.8c0.4,0,0.8,0.1,1.2,0.2 C34.1,2.5,34,2.7,34,3c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1c0-0.1-0.1-0.2-0.1-0.3c2.6,0.6,5,1.5,7.3,2.7C43.1,5.5,43,5.8,43,6 c0,0.6,0.4,1,1,1c0.4,0,0.8-0.3,0.9-0.6c0.8,0.5,1.6,1.1,2.4,1.7C47.2,8,47.1,8,47,8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1 c0.6,0,1-0.4,1-1c0-0.2-0.1-0.4-0.2-0.5c1.9,1.6,3.7,3.5,5.1,5.6c-0.5,0-0.9,0.5-0.9,1c0,0.6,0.4,1,1,1c0.4,0,0.7-0.2,0.9-0.6 c0.4,0.7,0.8,1.4,1.2,2.2C55,17.7,55,17.9,55,18c0,0.4,0.3,0.8,0.7,0.9c1.1,2.5,1.8,5.2,2.2,8.1L54,27c0-0.6-0.4-1-1-1 c-0.6,0-1,0.4-1,1l-4,0c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1l-4,0c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1l-4,0c0-0.6-0.4-1-1-1 c-0.6,0-1,0.4-1,1L33,27L33,27z" />
        <path d="M49.6,52.7c6.1-5.3,9.8-12.6,10.4-20.7L60,31l-31,0l0-31l-1.1,0.1C21.4,0.5,15.4,3,10.4,7.3h0 C10,7.7,9.5,8.1,9,8.7l-1.4,1.5c-0.6,0.6-1,1-1.3,1.5C2.2,17,0,23.4,0,30c0,0.8,0,1.6,0.1,2.3C1.2,46.9,13,58.7,27.5,59.9 C35.6,60.6,43.4,58,49.6,52.7z M27.7,57.9C14.1,56.8,3.1,45.8,2.1,32.2c-0.5-6.9,1.5-13.8,5.7-19.3c0.3-0.4,0.7-0.8,1.2-1.4 l1.4-1.5c0.5-0.5,0.9-0.9,1.3-1.3c4.4-3.8,9.6-6,15.3-6.6L27,33l30.8,0c-0.8,7.1-4.1,13.5-9.6,18.2C42.5,56.1,35.2,58.5,27.7,57.9z" />
      </g>
    </SvgIcon>
  );
};

export default IconIncomplete;
