import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPriceIncrease = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 122.9 87.9">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M14,43.9l16.1-9.8c-1.5-2-3-4-4.5-5.9l-15.4,9.4c-1.7,1.1-2.3,3.3-1.2,5.1C10,44.4,12.3,45,14,43.9z" />
        <path d="M17.2,81.6C12,81.6,3.5,80,0.7,75.1C0.3,75.9,0,76.8,0,77.8c0,5,7.7,9,17.2,9s17.2-4,17.2-9 c0-0.9-0.3-1.8-0.7-2.6C31.6,78.9,25.1,81.6,17.2,81.6z" />
        <path d="M101.7,58.1c-6.3,0-16.8-1.9-20.2-7.9c-0.6,1-0.9,2.1-0.9,3.2l0,0c0,6.1,9.5,11.1,21.1,11.1s21.1-5,21.1-11.1 c0-1.1-0.3-2.2-0.9-3.2C119.3,54.7,111.3,58.1,101.7,58.1z" />
        <path d="M38.6,29l8.6-5.2l10.5,9.4c1.3,1.2,3.2,1.2,4.6,0.2l27.4-19.8L88.9,21c-0.2,2,1.2,3.9,3.2,4.1s3.9-1.2,4.1-3.2 L98,6.2V6c0.2-2-1.2-3.8-3.2-4.1L78.9,0c-2-0.2-3.8,1.3-4,3.4c-0.2,2,1.2,3.7,3.2,4l6.6,0.8L60.5,25.7l-10.3-9.3 c-1.2-1.1-3-1.3-4.4-0.4l-11.6,7C35.7,25,37.2,27,38.6,29z" />
        <path d="M80.5,41c0,6.1,9.5,11.1,21.1,11.1s21.1-5,21.1-11.1s-9.3-11.1-20.9-11.1h-0.5C89.9,29.9,80.5,34.9,80.5,41z M101.4,32.2h0.8c9.4,0.1,16.9,3.1,16.9,6.8s-7.8,6.8-17.4,6.8S84.3,42.7,84.3,39S91.9,32.2,101.4,32.2z" />
        <path d="M101.7,76.3c11.7,0,21.1-5,21.1-11.1c0-1.2-0.4-2.3-1-3.3c-2.7,4.5-10.7,7.8-20.2,7.8c-6.2,0-16.7-1.9-20.2-7.8 c-0.6,1-1,2.1-1,3.3h0.1C80.5,71.3,90,76.3,101.7,76.3z" />
        <path d="M121.9,73.5c-2.7,4.5-10.7,7.8-20.2,7.8c-6.2,0-16.7-1.8-20.2-7.8c-0.6,1-1,2.1-1,3.3h0.1 c0,6.1,9.5,11.1,21.1,11.1s21.1-5,21.1-11.1C122.9,75.6,122.6,74.5,121.9,73.5z" />
        <path d="M58.3,48.2h-1.6c-9.1,0.2-16.4,4.2-16.4,9c0,5,7.7,9,17.2,9s17.2-4,17.2-9S67.5,48.3,58.3,48.2z M57.5,61.1 c-7.8,0-14.2-2.6-14.2-5.6s5.8-5.4,13.2-5.5h1.7c7.5,0.1,13.4,2.5,13.4,5.5S65.4,61.1,57.5,61.1z" />
        <path d="M57.5,81.3c-5.1,0-13.6-1.5-16.5-6.3c-0.5,0.8-0.8,1.8-0.8,2.7l0,0c0,5,7.7,9,17.2,9s17.2-4,17.2-9 c0-1-0.3-1.9-0.8-2.7C71.8,78.7,65.3,81.3,57.5,81.3z" />
        <path d="M57.5,71.4c-5.1,0-13.7-1.5-16.5-6.4c-0.5,0.8-0.7,1.7-0.7,2.6v0.1c0,5,7.7,9,17.2,9s17.2-4.1,17.2-9 c0-0.9-0.2-1.9-0.7-2.6C72,68.7,65.4,71.5,57.5,71.4z" />
        <path d="M18,58.3h-1.7C7.2,58.5,0,62.5,0,67.3c0,5,7.7,9,17.2,9s17.2-4.1,17.2-9S27.2,58.4,18,58.3z M17.3,71.2 c-7.8,0-14.2-2.6-14.2-5.6s5.8-5.4,13.2-5.5l0,0H18c7.5,0.1,13.5,2.5,13.5,5.6S25.1,71.2,17.3,71.2z" />
      </g>
    </SvgIcon>
  );
};

export default IconPriceIncrease;
