import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconCostSecurity = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67">
        <g>
          <path
            style={{ fill: "none" }}
            d="M150,50.275C119.946,78.824,71.531,81.32,56.438,81.361c-0.007,0.549-0.011,1.157-0.011,1.823
			c0.006,9.321,0.932,29.83,7.659,54.29c0.48,1.747,0.99,3.515,1.532,5.3C75.494,175.331,98.149,220.53,150,252.041
			c6.481-3.939,12.506-8.092,18.107-12.407c2.8-2.157,5.495-4.355,8.087-6.588s5.083-4.499,7.475-6.793
			c2.393-2.295,4.687-4.617,6.888-6.96c1.1-1.172,2.177-2.349,3.23-3.53c3.161-3.545,6.115-7.131,8.876-10.735
			c16.564-21.627,26.163-43.94,31.718-62.253c5.415-17.849,7.692-33.944,8.61-45.651c0.046-0.585,0.089-1.159,0.128-1.723
			c0.079-1.126,0.145-2.207,0.2-3.24c0.248-4.648,0.274-8.328,0.243-10.799C228.47,81.32,180.055,78.824,150,50.275z
			 M168.543,130.128c0.005,0,0.01,0,0.015,0c2.768,0,5.016,2.24,5.024,5.01c0.008,2.775-2.235,5.031-5.01,5.039l-36.082,0.106
			c-0.192,1.945-0.353,3.976-0.47,6.129c0.088,1.867,0.216,3.631,0.363,5.341l35.719-0.106c0.005,0,0.01,0,0.016,0
			c2.768,0,5.016,2.24,5.024,5.01c0.008,2.774-2.234,5.031-5.009,5.039l-34.406,0.103c4.73,24.567,18.004,27.791,36.609,29.377
			c2.765,0.235,4.815,2.668,4.58,5.433c-0.224,2.62-2.419,4.599-5,4.599c-0.144,0-0.287-0.007-0.433-0.019
			c-20.94-1.785-40.131-6.415-45.916-39.359l-18.175,0.054c-0.005,0-0.01,0-0.016,0c-2.768,0-5.016-2.239-5.024-5.009
			c-0.008-2.775,2.234-5.031,5.009-5.04l16.941-0.05c-0.137-1.663-0.25-3.377-0.332-5.157c-0.008-0.164-0.007-0.329,0.001-0.494
			c0.106-2.017,0.25-3.951,0.425-5.818l-16.566,0.049c-0.005,0-0.01,0-0.015,0c-2.768,0-5.016-2.239-5.024-5.01
			c-0.008-2.774,2.235-5.03,5.01-5.039l17.988-0.053c6.127-32.305,25.167-36.749,45.908-38.378c2.772-0.218,5.186,1.85,5.402,4.615
			c0.217,2.767-1.849,5.186-4.615,5.402c-18.335,1.44-31.487,4.521-36.494,28.33L168.543,130.128z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M259.094,59.338c-9.882,2.192-19.586,3.302-28.903,3.303c-45.801,0.003-71.664-25.97-80.191-36.385
			c-8.527,10.411-34.392,36.383-80.188,36.385c-9.313,0-19.017-1.109-28.9-3.302c-1.15,16.185-2.834,76.183,28.993,129.999
			c32.859,55.568,71.429,81.051,80.097,86.3c8.668-5.249,47.239-30.731,80.098-86.3C261.964,135.451,260.253,75.511,259.094,59.338z
			 M241.681,144.677c-10.364,34.328-34.349,82.146-89.759,115c-0.592,0.352-1.257,0.527-1.922,0.527s-1.33-0.176-1.922-0.527
			c-55.411-32.854-79.396-80.672-89.759-115c-11.217-37.156-9.373-66.177-9.289-67.394c0.141-2.039,1.896-3.595,3.922-3.506
			c0.011,0.001,1.13,0.048,3.105,0.048c14.333,0,63.273-2.27,91.219-31.473c0.711-0.742,1.694-1.162,2.723-1.162
			s2.012,0.42,2.723,1.162c27.947,29.203,76.887,31.473,91.22,31.473c1.976,0,3.093-0.047,3.104-0.048
			c2.048-0.08,3.782,1.468,3.923,3.506C251.054,78.5,252.898,107.521,241.681,144.677z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M170.484,101.9c2.766-0.217,4.833-2.636,4.615-5.402c-0.217-2.766-2.63-4.833-5.402-4.615
				c-20.74,1.629-39.78,6.073-45.908,38.378l-17.988,0.053c-2.775,0.009-5.018,2.265-5.01,5.039c0.008,2.771,2.256,5.01,5.024,5.01
				c0.005,0,0.01,0,0.015,0l16.566-0.049c-0.175,1.867-0.319,3.802-0.425,5.818c-0.008,0.165-0.009,0.33-0.001,0.494
				c0.082,1.78,0.195,3.494,0.332,5.157l-16.941,0.05c-2.775,0.009-5.018,2.265-5.009,5.04c0.008,2.77,2.256,5.009,5.024,5.009
				c0.005,0,0.01,0,0.016,0l18.175-0.054c5.785,32.944,24.975,37.574,45.916,39.359c0.146,0.012,0.289,0.019,0.433,0.019
				c2.582,0,4.777-1.979,5-4.599c0.235-2.765-1.815-5.197-4.58-5.433c-18.605-1.586-31.879-4.81-36.609-29.377l34.406-0.103
				c2.775-0.008,5.018-2.265,5.009-5.039c-0.008-2.77-2.256-5.01-5.024-5.01c-0.005,0-0.01,0-0.016,0l-35.719,0.106
				c-0.147-1.71-0.274-3.474-0.363-5.341c0.117-2.153,0.278-4.184,0.47-6.129l36.082-0.106c2.775-0.008,5.018-2.264,5.01-5.039
				c-0.008-2.77-2.256-5.01-5.024-5.01c-0.005,0-0.01,0-0.015,0l-34.553,0.103C138.997,106.421,152.149,103.341,170.484,101.9z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M266.197,54.2c-0.134-1.071-0.721-2.033-1.612-2.643c-0.892-0.609-2-0.807-3.048-0.543
				c-10.759,2.713-21.306,4.089-31.347,4.089c-0.003,0-0.004,0-0.007,0c-51.859,0-76.79-36.805-77.032-37.172
				c-0.696-1.062-1.88-1.7-3.15-1.7c0,0,0,0,0,0c-1.27,0-2.454,0.639-3.15,1.699c-0.244,0.372-25.007,37.172-77.04,37.173
				c-10.037,0.001-20.583-1.375-31.346-4.089c-1.047-0.265-2.156-0.066-3.048,0.543s-1.479,1.571-1.613,2.643
				c-0.375,2.996-8.74,74.123,29.612,138.974c38.229,64.651,82.919,89.136,84.803,90.146c0.557,0.298,1.169,0.447,1.782,0.447
				s1.225-0.149,1.781-0.447c1.884-1.011,46.575-25.495,84.804-90.146C274.935,128.323,266.572,57.196,266.197,54.2z M150,275.638
				c-8.668-5.249-47.238-30.731-80.097-86.3C38.076,135.521,39.76,75.523,40.91,59.339c9.884,2.192,19.587,3.302,28.9,3.302
				c45.797-0.002,71.661-25.974,80.188-36.385c8.528,10.415,34.39,36.388,80.191,36.385c9.317-0.001,19.021-1.11,28.903-3.303
				c1.159,16.173,2.87,76.113-28.996,130C197.239,244.906,158.668,270.389,150,275.638z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M250.97,77.283c-0.141-2.038-1.875-3.586-3.923-3.506c-0.011,0.001-1.128,0.048-3.104,0.048
				c-14.333,0-63.273-2.27-91.22-31.473c-0.711-0.742-1.694-1.162-2.723-1.162s-2.012,0.42-2.723,1.162
				c-27.946,29.203-76.886,31.473-91.219,31.473c-1.975,0-3.094-0.047-3.105-0.048c-2.027-0.089-3.782,1.467-3.922,3.506
				c-0.084,1.217-1.928,30.237,9.289,67.394c10.363,34.328,34.348,82.146,89.759,115c0.592,0.352,1.257,0.527,1.922,0.527
				s1.33-0.176,1.922-0.527c55.411-32.854,79.396-80.672,89.759-115C252.898,107.521,251.054,78.5,250.97,77.283z M183.67,226.253
				c-2.393,2.294-4.883,4.561-7.475,6.793s-5.287,4.431-8.087,6.588c-5.601,4.315-11.626,8.468-18.107,12.407
				c-51.851-31.511-74.506-76.71-84.382-109.267c-0.542-1.785-1.051-3.553-1.532-5.3c-0.283-1.029-0.552-2.049-0.815-3.063
				c-5.985-23.1-6.837-42.297-6.844-51.227c0-0.666,0.003-1.274,0.011-1.823C71.531,81.32,119.946,78.824,150,50.275
				c30.055,28.549,78.47,31.045,93.563,31.086c0.032,2.471,0.005,6.15-0.243,10.799c-0.056,1.033-0.121,2.114-0.2,3.24
				c-0.039,0.563-0.082,1.138-0.128,1.723c-0.058,0.731-0.12,1.48-0.188,2.246c-1.026,11.479-3.345,26.672-8.421,43.405
				c-3.125,10.301-7.529,21.867-13.733,33.866c-4.825,9.332-10.738,18.925-17.985,28.387c-2.761,3.604-5.715,7.19-8.876,10.735
				c-1.054,1.182-2.13,2.358-3.23,3.53C188.357,221.636,186.063,223.958,183.67,226.253z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconCostSecurity;
