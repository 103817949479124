import { Theme } from "@mui/material/styles";

const messageCenterMessageStyle = {
  messageContainer: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 1rem 1rem 0.5rem",
    },
    margin: "0 1rem 1rem 1rem",
    width: "90%",
    color: "black",
  }),
  messageBubble: {
    padding: "1rem",
    borderRadius: "1rem",
    wordBreak: "break-word",
    hyphens: "auto",
  },
  ownMessageContainer: { marginLeft: "10%" },
  otherMessageContainer: {},
  ownMessageBubble: { backgroundColor: "#c9e1e1" },
  otherMessageBubble: { backgroundColor: "#F4F4F4" },
  readBox: { textAlign: "right", paddingRight: "1rem" },
  customerFeedbackBox: { padding: "1rem" },
};
export default messageCenterMessageStyle;
