import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLicencePointsZero = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" }}>
        <polyline
          points="39.9 260.11 259.55 260.11 259.55 40.45"
          style={{ fill: "none", stroke: active ? theme.palette.primary.main : "#137F7B", strokeWidth: "6.9px" }}
        />
        <path
          d="M185.34,167.81V132.75c-.28-22.78-14.78-34.93-35.62-34.93S114.11,110,114.11,132.75v35.06c0,22.91,14.77,34.92,35.61,34.92s35.34-12,35.62-34.92ZM170,133.3v33.95c0,16.57-8.55,22.78-20.29,22.78s-20.29-6.21-20.29-22.78V133.3c0-16.57,8.56-22.78,20.29-22.78S170,116.73,170,133.3Z"
          style={{ fill: getIconColor(active, theme) }}
        />
        <path
          d="M35.75,23.88H263.69A12.46,12.46,0,0,1,276.12,36.3v228a12.46,12.46,0,0,1-12.43,12.42H35.75a12.46,12.46,0,0,1-12.42-12.42V70.77m0-21.93V36.3A12.46,12.46,0,0,1,35.75,23.88"
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "6.9px" }}
        />
      </g>
    </SvgIcon>
  );
};

export default IconLicencePointsZero;
