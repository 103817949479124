import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconData = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: "#137F7B" }}
          d="M164.735,20.885h-29.763c-6.219,0-10.733,4.078-10.733,9.693v2.199
			c0,5.615,4.514,9.693,10.733,9.693h29.763c6.22,0,10.734-4.078,10.734-9.693v-1.082v-0.029v-1.088
			C175.469,24.963,170.955,20.885,164.735,20.885z"
        />
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M241.792,210.885c-1.958,0-3.545,1.586-3.545,3.545v56.221c0,2.711-2.206,4.918-4.917,4.918
				H66.661c-2.71,0-4.916-2.207-4.916-4.918V95.549c0-1.959-1.587-3.545-3.545-3.545c-1.958,0-3.545,1.586-3.545,3.545V270.65
				c0,6.621,5.386,12.008,12.006,12.008h166.668c6.621,0,12.008-5.387,12.008-12.008V214.43
				C245.337,212.471,243.75,210.885,241.792,210.885z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M233.327,28.137h-54.314c-0.079,0-0.153,0.018-0.229,0.023c-1.16-6.311-6.805-10.82-14.048-10.82
				h-29.763c-7.243,0-12.888,4.512-14.048,10.82c-0.08-0.006-0.156-0.023-0.237-0.023H66.655c-6.617,0-12,5.383-12,11.998v39.949
				c0,1.957,1.587,3.545,3.545,3.545c1.958,0,3.545-1.588,3.545-3.545V40.135c0-2.707,2.202-4.908,4.91-4.908h54.031
				c0.082,0,0.158-0.02,0.238-0.023c1.162,6.305,6.807,10.813,14.047,10.813h29.763c7.24,0,12.885-4.508,14.047-10.813
				c0.078,0.006,0.151,0.023,0.23,0.023h54.314c2.717,0,4.927,2.201,4.927,4.908v156.389c0,1.959,1.588,3.545,3.545,3.545
				c1.958,0,3.545-1.586,3.545-3.545V40.135C245.345,33.52,239.954,28.137,233.327,28.137z M164.735,42.471h-29.763
				c-6.219,0-10.733-4.078-10.733-9.693v-2.199c0-5.615,4.514-9.693,10.733-9.693h29.763c6.22,0,10.734,4.078,10.734,9.693v1.088
				v0.029v1.082C175.469,38.393,170.955,42.471,164.735,42.471z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M87.729,191.514h121.283c0.979,0,1.772-0.793,1.772-1.771s-0.793-1.773-1.772-1.773H87.729
				c-0.979,0-1.772,0.795-1.772,1.773S86.75,191.514,87.729,191.514z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M87.729,172.326h121.283c0.979,0,1.772-0.795,1.772-1.773s-0.793-1.771-1.772-1.771H87.729
				c-0.979,0-1.772,0.793-1.772,1.771S86.75,172.326,87.729,172.326z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M197.968,226.342H98.894c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.074
				c0.979,0,1.772-0.795,1.772-1.773S198.947,226.342,197.968,226.342z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M197.968,207.156H98.894c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.074
				c0.979,0,1.772-0.795,1.772-1.773S198.947,207.156,197.968,207.156z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M87.729,104.514h121.283c0.979,0,1.772-0.793,1.772-1.771s-0.793-1.773-1.772-1.773H87.729
				c-0.979,0-1.772,0.795-1.772,1.773S86.75,104.514,87.729,104.514z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M87.729,85.324h121.283c0.979,0,1.772-0.793,1.772-1.771s-0.793-1.773-1.772-1.773H87.729
				c-0.979,0-1.772,0.795-1.772,1.773S86.75,85.324,87.729,85.324z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M197.968,139.342H98.894c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.074
				c0.979,0,1.772-0.793,1.772-1.771S198.947,139.342,197.968,139.342z"
          />
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M197.968,120.156H98.894c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h99.074
				c0.979,0,1.772-0.795,1.772-1.773S198.947,120.156,197.968,120.156z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconData;
