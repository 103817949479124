import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconClub = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M247.2,152.5h-58.9c-3.9,0-7.9,2-7.9,5.9v110.9c0,4,4.5,5.9,8.9,5.9c4.5,0,8.9-1.8,8.9-5.9v-48.5h26
        c4,0,5.7-3.7,5.7-6.9c0-3.9-2-7.2-5.7-7.2h-26v-38.4h49c3.7,0,5.7-3.7,5.7-8.1C252.9,156.6,251.2,152.5,247.2,152.5z"
        />
        <path
          d="M328.1,235.6c-4.9,0-8.4,1.5-8.7,5.9c-0.7,7.4-2.2,19-19.3,19c-13.3,0-20.5-6.9-20.5-21.6V190
        c0-14.8,7.2-21.6,20.1-21.6c17.3,0,19.3,12.1,19.6,18c0.2,4,3.5,5.9,8.9,5.9c6,0,8.9-1.7,8.9-9.2c0-17.8-15.1-30.4-38.1-30.4
        c-20.3,0-37.2,10.2-37.2,37.4v48.8c0,27.2,16.8,37.4,37.1,37.4c23,0,38.3-12.9,38.3-31.4C337.1,237.4,334.3,235.6,328.1,235.6z"
        />
        <path
          d="M456.4,112.8c-1.3-8.3-8.9-14.4-17.3-13.8c-1.9,0.1-3.1,0.2-3.7,0.2c-17.2,0-33.6-5.8-46.2-16.2
        c-12.3-10.3-19.9-24.1-21.5-39.1c-0.8-7.5-7-13.1-14.6-13.1H158.7c-7.6,0-13.8,5.6-14.6,13.1c-1.5,14.9-9.1,28.8-21.5,39.1
        c-12.6,10.5-29,16.2-46.2,16.2c-0.9,0-2.2-0.1-3.8-0.2c-8.4-0.6-16,5.5-17.3,13.8c-4.4,29-5,58.4-1.6,87.5
        c0.4,3.9,3.7,6.7,7.5,6.7c0.3,0,0.6,0,0.9-0.1c4.2-0.5,7.1-4.2,6.6-8.4c-3.2-27.7-2.7-55.8,1.5-83.5c0.1-0.6,0.7-1,1.3-1
        c2,0.1,3.5,0.2,4.8,0.2c20.7,0,40.5-7,55.9-19.8c15.3-12.7,24.8-30,26.8-48.7h193.7c2,18.7,11.5,36,26.8,48.7
        c15.3,12.7,35.1,19.8,55.9,19.8c1.2,0,2.8-0.1,4.7-0.2c0.6-0.1,1.2,0.4,1.3,1c4.2,27.6,4.8,55.7,1.5,83.4
        c-0.5,4.2,2.5,7.9,6.7,8.4c4.1,0.5,7.9-2.5,8.4-6.7C461.4,171.2,460.9,141.8,456.4,112.8z"
        />
        <path
          d="M508.8,404.1c2.6-2.5,3.7-6.2,2.9-9.7c-0.8-3.6-3.4-6.4-6.8-7.5c-16.7-5.4-33.8-10.4-50.8-14.9l5.3-22.7
			c1.3-5.7-2.2-11.4-7.9-12.7c-12-2.8-23.9-5.4-35.9-7.7c16.8-29.3,29-60.6,36.4-93c0.9-4.1-1.6-8.1-5.7-9.1
			c-4.1-0.9-8.1,1.6-9.1,5.7c-7.5,32.7-20.1,64.1-37.5,93.5c-6.6-1.1-13.2-2.2-19.8-3.2c8.9-14.2,16.6-29,23-44.2
			c17.9-42.4,25.9-88.9,23-134.3c-0.4-6.6-5.2-12.1-11.7-13.4c-33.2-6.6-60.5-28.1-73.2-57.5c-2.3-5.4-7.6-8.9-13.4-8.9H184.3
			c-5.8,0-11.1,3.5-13.4,8.9c-12.6,29.4-40,50.9-73.2,57.5c-6.5,1.3-11.3,6.8-11.7,13.4c-2.8,45.4,5.2,91.9,23,134.3
			c6.4,15.2,14.1,30,23,44.2c-6.6,1-13.2,2.1-19.8,3.2c-17.4-29.3-30-60.7-37.5-93.3c-0.9-4.1-5-6.6-9.1-5.7c-4.1,0.9-6.6,5-5.7,9.1
			c7.4,32.4,19.7,63.5,36.4,92.8c-12,2.3-24.1,4.9-36.1,7.8c-5.7,1.3-9.2,7.1-7.9,12.7l5.3,22.7c-17,4.4-34,9.4-50.6,14.8
			c-3.5,1.1-6,3.9-6.8,7.5c-0.8,3.6,0.3,7.2,2.9,9.7c6.9,6.6,13.7,13.4,20.4,20.2c-1.9,9.7-3.7,19.5-5.3,29.3
			c-0.6,3.6,0.7,7.1,3.5,9.4c1.9,1.6,4.3,2.5,6.7,2.5c1.1,0,2.1-0.2,3.2-0.5c37.8-12.3,75.2-21.4,114.2-28.1c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.1,1-0.2c0.1,0,0.1,0,0.2-0.1c0.7-0.2,1.3-0.5,1.9-0.9c0.1,0,0.1-0.1,0.2-0.1
			c0.3-0.2,0.5-0.4,0.8-0.7c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.5,0.6-0.7c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.4-0.6,0.5-0.9
			c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.7,0.4-1c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0.1-0.3
			c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5
			c0-0.1,0-0.1,0-0.2l-3.6-25c1.3-0.2,2.7-0.4,4-0.6c14.4,14.7,30.3,28.4,47.1,40.8l47.7,34.9c2.6,1.9,5.6,2.9,8.7,2.9
			c3,0,6.1-1,8.7-2.9l47.7-34.9c16.9-12.4,32.7-26.1,47.1-40.8c1.3,0.2,2.7,0.4,4,0.6l-3.6,25c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.3
			c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0.1,0.3,0.3,0.7,0.4,1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.3,0.6,0.5,0.9
			c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.5,0.5,0.8,0.7c0.1,0,0.1,0.1,0.2,0.1
			c0.6,0.4,1.2,0.7,1.9,0.9c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,1,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0c39.1,6.6,76.4,15.8,114.2,28.1
			c1.1,0.3,2.1,0.5,3.2,0.5c2.4,0,4.8-0.9,6.7-2.5c2.7-2.4,4-5.9,3.5-9.4c-1.6-9.7-3.3-19.6-5.3-29.3
			C495.1,417.4,501.9,410.7,508.8,404.1z M123.1,272.7c-16.9-40.2-24.5-84.1-21.9-127.1c37.7-7.7,68.9-32.3,83.5-66h142.6
			c14.6,33.7,45.8,58.3,83.5,66c2.6,43-5,86.9-21.9,127.1c-6.9,16.5-15.5,32.5-25.5,47.8c-71.5-9.2-143.3-9.2-214.7,0
			C138.6,305.2,130,289.1,123.1,272.7z M34.6,448.1c1.3-7.8,2.8-15.7,4.3-23.4c0.7-3.3-0.4-6.7-2.7-9.2c-5.4-5.6-11-11.2-16.7-16.7
			c13.8-4.3,27.7-8.3,41.6-12l5.7,24.6c0.5,2,1.7,3.7,3.4,4.7c1,0.6,2.1,1,3.2,1.1c13.5,3.2,26.7,6.6,39.7,10.4
			C86.7,433.2,60.7,439.9,34.6,448.1z M107.3,410.3c8.8-1.7,17.5-3.3,26.3-4.7l1.9,13C126.1,415.6,116.7,412.9,107.3,410.3z
			 M303.4,431.3L256,466l-47.4-34.7c-13-9.5-25.4-19.9-37-30.9c8.3-0.9,16.7-1.7,25-2.4c7.4,6.4,15.1,12.6,22.9,18.3l27.8,20.4
			c2.6,1.9,5.6,2.8,8.6,2.8s6-0.9,8.6-2.8l27.8-20.3c7.8-5.7,15.5-11.9,22.9-18.3c8.3,0.7,16.7,1.5,25,2.4
			C328.8,411.4,316.4,421.8,303.4,431.3z M218.7,396.6c24.9-1.2,49.8-1.2,74.6,0c-3.3,2.6-6.5,5.1-9.9,7.5L256,424.2l-27.5-20.1
			C225.2,401.7,221.9,399.1,218.7,396.6z M373.1,389.4c0,0-0.1,0-0.1,0c-5-0.8-9.9-1.5-14.9-2.1c-0.2,0-0.4-0.1-0.6-0.1
			c-14.5-1.9-29-3.4-43.5-4.5c-0.4-0.1-0.7-0.1-1.1-0.1c-37.9-2.8-75.9-2.8-113.9,0c-0.3,0-0.7,0-1,0.1c-14.6,1.1-29.1,2.6-43.6,4.5
			c-0.2,0-0.3,0-0.5,0.1c-5,0.7-9.9,1.4-14.9,2.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-19.6,3-39.2,6.7-58.8,11.2l-11.6-50.4
			c124.6-28.9,250.8-28.9,375.3,0l-11.6,50.4c-19.5-4.5-39.1-8.2-58.6-11.2C373.2,389.4,373.2,389.4,373.1,389.4z M378.4,405.6
			c8.8,1.4,17.5,3,26.3,4.7c-9.4,2.6-18.8,5.3-28.2,8.2L378.4,405.6z M475.8,415.5c-2.4,2.4-3.4,5.9-2.7,9.2
			c1.5,7.7,3,15.6,4.3,23.4c-26.1-8.2-52.1-15-78.6-20.5c13.3-3.8,26.7-7.3,40.5-10.6c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.4-0.1
			c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.2
			c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3
			c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.1-0.4
			c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0,0,0-0.1,0-0.1l5.7-24.6c14,3.7,28,7.7,41.8,12
			C486.8,404.3,481.2,409.9,475.8,415.5z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconClub;
