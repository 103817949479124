import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAnwaelte = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          fill="none"
          d="M89.948,139.202v-23.365c-4.173,5.171-10.899,8.448-18.173,8.448c-9.224,0-17.42-5.039-20.882-12.836
			c-0.391-0.881,0.006-1.912,0.887-2.303c0.883-0.39,1.912,0.006,2.303,0.887c2.902,6.538,9.847,10.763,17.692,10.763
			c8.281,0,15.705-4.918,18.167-11.985C89.833,94.934,78.622,84.883,63.2,84.883c-15.497,0-26.745,10.149-26.745,24.133v11.988
			c3.487-0.372,6.484-2.601,7.794-5.924c0.354-0.897,1.369-1.334,2.263-0.983c0.896,0.354,1.337,1.367,0.983,2.263
			c-1.84,4.668-6.106,7.79-11.04,8.171v14.672c0,11.958,8.528,21.469,21.222,23.665c3.427,0.594,7.148,0.618,10.496,0.089
			C81.199,160.939,89.948,151.394,89.948,139.202z"
        />
        <path
          fill="none"
          d="M264.757,139.629v-23.365c-4.173,5.171-10.899,8.449-18.173,8.449c-9.223,0-17.419-5.038-20.882-12.835
			c-0.391-0.88,0.006-1.912,0.886-2.302c0.88-0.392,1.912,0.006,2.302,0.886c2.903,6.538,9.848,10.762,17.693,10.762
			c8.282,0,15.706-4.917,18.168-11.985c-0.111-13.877-11.321-23.927-26.744-23.927c-15.497,0-26.745,10.149-26.745,24.133v11.987
			c3.488-0.372,6.484-2.601,7.793-5.924c0.353-0.896,1.366-1.338,2.262-0.983c0.896,0.353,1.337,1.366,0.984,2.263
			c-1.839,4.668-6.104,7.79-11.04,8.171v14.672c0,11.958,8.528,21.469,21.222,23.666c3.414,0.591,7.137,0.616,10.497,0.089
			C256.008,161.368,264.757,151.822,264.757,139.629z"
        />
        <path
          fill="none"
          d="M235.731,172.642l0.114,0.037c1.234,0.4,2.612,0.384,3.732,0.005l0.107-0.037
			c1.51-0.511,2.334-1.469,2.41-1.775l-0.003-3.875c-1.335,0.152-2.696,0.255-4.083,0.255c-1.57,0-3.131-0.101-4.658-0.299v3.98
			C233.42,171.181,234.241,172.136,235.731,172.642z"
        />
        <path
          fill="none"
          d="M189.985,128.677V94.188c-6.16,7.632-16.087,12.469-26.824,12.469c-13.616,0-25.715-7.437-30.825-18.946
			c-0.577-1.3,0.009-2.821,1.309-3.398c1.301-0.579,2.822,0.008,3.398,1.309c4.284,9.65,14.536,15.886,26.118,15.886
			c12.225,0,23.183-7.258,26.816-17.69c-0.163-20.483-16.711-35.319-39.477-35.319c-22.875,0-39.477,14.981-39.477,35.623v17.717
			c5.149-0.548,9.569-3.862,11.502-8.768c0.522-1.324,2.018-1.971,3.34-1.452c1.323,0.521,1.973,2.017,1.452,3.34
			c-2.715,6.891-9.01,11.498-16.294,12.061v21.659c0,17.65,12.588,31.688,31.324,34.932c5.059,0.876,10.547,0.91,15.494,0.13
			C177.07,160.763,189.985,146.673,189.985,128.677z"
        />
        <path
          fill="none"
          d="M61.027,172.253c0.61,0.196,1.258,0.291,1.894,0.291c0.434,0,0.858-0.06,1.271-0.146
			c0.197-0.042,0.397-0.079,0.582-0.14c1.579-0.501,2.436-1.5,2.513-1.815l-0.003-3.874c-1.334,0.152-2.694,0.255-4.083,0.255
			c-1.568,0-3.128-0.101-4.658-0.299v3.98C58.613,170.758,59.467,171.757,61.027,172.253z"
        />
        <path
          fill="none"
          d="M177.926,202.743c-0.072,1.734,0.997,2.824,1.326,3.117c1.417,1.26,4.539,3.499,7.292,5.474
			c1.535,1.101,3.002,2.154,4.228,3.069c1.606-1.502,2.49-3.042,2.551-4.511c0.072-1.733-0.998-2.823-1.327-3.115
			c-1.416-1.26-4.535-3.498-7.287-5.472c-1.537-1.103-3.005-2.157-4.232-3.073C178.871,199.734,177.987,201.273,177.926,202.743z"
        />
        <g>
          <path
            fill="#137F7B"
            d="M147.294,177.463c0.005,0.001,0.011,0.003,0.017,0.004c0.446,0.144,0.908,0.251,1.375,0.322
				c0,0,0.001,0,0.001,0c1.413,0.213,2.88,0.099,4.136-0.319c2.33-0.74,3.595-2.215,3.709-2.679l-0.005-5.681
				c-1.965,0.224-3.985,0.339-6.028,0.339c-2.313,0-4.617-0.149-6.875-0.441v5.875C143.73,175.256,144.991,176.731,147.294,177.463z
				"
          />
          <path
            fill="#137F7B"
            d="M152.071,181.175c-0.002,0-0.005,0-0.007,0.001c-0.298,0.047-0.602,0.082-0.909,0.107
				c-0.023,0.002-0.046,0.006-0.069,0.008c-0.664,0.052-1.341,0.052-2.013,0c-0.005,0-0.01-0.001-0.015-0.001
				c-0.322-0.026-0.643-0.064-0.96-0.114c-0.008-0.002-0.017-0.002-0.026-0.003l-12.629,56.634
				c2.582,2.32,10.983,9.911,14.629,13.708c3.646-3.797,12.047-11.388,14.629-13.708L152.071,181.175z"
          />
        </g>
        <g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M218.174,196.203c-1.415,0.144-2.445,1.408-2.302,2.822c1.968,19.356,1.311,46.218,1.304,46.487
				c-0.036,1.422,1.087,2.604,2.508,2.64c0.022,0,0.045,0.001,0.067,0.001c1.391,0,2.537-1.11,2.573-2.509
				c0.007-0.272,0.673-27.454-1.328-47.139C220.853,197.09,219.589,196.067,218.174,196.203z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M195.418,202.927c-1.617-1.438-4.713-3.659-7.707-5.807c-2.047-1.469-3.982-2.857-5.292-3.884
				c-0.717-0.561-0.938-2.068-0.493-3.36c0.301-0.873,1.135-2.336,3.34-2.336c4.266,0,5.905,5.003,5.98,5.241
				c0.418,1.354,1.85,2.114,3.21,1.704c1.36-0.414,2.128-1.853,1.714-3.213c-0.11-0.362-2.795-8.881-10.905-8.881
				c-3.829,0-6.974,2.226-8.209,5.809c-0.719,2.085-0.657,4.283,0.052,6.123c-3.38,3.096-4.241,6.113-4.328,8.208
				c-0.112,2.699,1.029,5.383,3.05,7.18c1.618,1.438,4.716,3.662,7.713,5.81c2.046,1.468,3.978,2.854,5.288,3.878
				c0.717,0.561,0.938,2.068,0.492,3.36c-0.301,0.873-1.135,2.336-3.34,2.336c-4.266,0-5.906-5.003-5.98-5.24
				c-0.418-1.354-1.853-2.115-3.21-1.704c-1.36,0.414-2.128,1.853-1.714,3.213c0.11,0.362,2.795,8.88,10.905,8.88
				c3.829,0,6.975-2.226,8.209-5.809c0.719-2.085,0.657-4.282-0.052-6.122c3.38-3.096,4.241-6.113,4.328-8.208
				C198.581,207.407,197.44,204.724,195.418,202.927z M179.252,205.86c-0.329-0.293-1.398-1.382-1.326-3.117
				c0.061-1.47,0.944-3.009,2.55-4.511c1.227,0.916,2.696,1.971,4.232,3.073c2.752,1.974,5.871,4.212,7.287,5.472
				c0.33,0.292,1.399,1.382,1.327,3.115c-0.061,1.469-0.944,3.009-2.551,4.511c-1.226-0.915-2.693-1.968-4.228-3.069
				C183.791,209.359,180.669,207.12,179.252,205.86z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M283.292,181.348c0.942-0.201,1.543-1.128,1.342-2.07c-0.381-1.784-0.827-3.257-1.327-4.382
				c-2.424-5.425-5.473-9.5-12.472-11.758c-4.097-1.321-8.148-3.179-10.547-4.352c5.032-4.896,7.958-11.576,7.958-19.156v-30.185
				c0-15.748-13-27.623-30.239-27.623c-17.236,0-30.234,11.875-30.234,27.623v30.185c0,7.271,2.725,13.749,7.435,18.603
				c-2.514,1.324-6.978,3.531-11.236,4.906c-0.982,0.319-1.879,0.675-2.721,1.067c-0.735-0.291-1.491-0.572-2.294-0.831
				c-6.047-1.951-12.027-4.692-15.568-6.423c7.427-7.227,11.746-17.086,11.746-28.274V84.121c0-23.245-19.189-40.773-44.635-40.773
				c-25.441,0-44.627,17.529-44.627,40.773v44.557c0,10.732,4.021,20.292,10.972,27.457c-3.713,1.957-10.307,5.216-16.582,7.241
				c-0.469,0.151-0.913,0.314-1.358,0.477c-0.891-0.425-1.84-0.806-2.88-1.142c-4.097-1.323-8.146-3.182-10.542-4.354
				c5.03-4.896,7.955-11.575,7.955-19.153v-30.186c0-15.748-13-27.623-30.238-27.623c-17.236,0-30.234,11.875-30.234,27.623v30.186
				c0,7.271,2.725,13.748,7.433,18.602c-2.512,1.324-6.973,3.531-11.234,4.906c-7.001,2.258-10.049,6.333-12.471,11.759
				c-4.193,9.393-3.395,42.567-3.359,43.975c0.024,0.948,0.8,1.7,1.743,1.7c0.015,0,0.03,0,0.045,0
				c0.963-0.025,1.724-0.826,1.7-1.789c-0.009-0.337-0.816-33.787,3.057-42.463c2.154-4.825,4.54-7.985,10.356-9.861
				c5.3-1.71,10.723-4.542,12.923-5.746c3.603,2.823,8.029,4.853,13.057,5.854v4.368c0,1.267,1.307,2.708,3.107,3.563l-4.053,18.159
				c-0.14,0.627,0.255,1.249,0.882,1.389c0.628,0.14,1.249-0.255,1.389-0.882l4.01-17.968c0.01,0.001,0.021,0,0.031,0.002
				c0.436,0.068,0.879,0.104,1.326,0.104c0.463,0,0.915-0.036,1.352-0.105c0.002,0,0.004,0,0.006,0l8.557,38.367
				c-1.751,1.572-7.445,6.714-9.917,9.284c-2.469-2.572-8.161-7.712-9.911-9.284l3.551-15.919c0.14-0.627-0.255-1.249-0.882-1.389
				c-0.629-0.142-1.249,0.255-1.389,0.882l-3.848,17.25l0.509,0.455c2.647,2.366,9.795,8.857,11.053,10.46l0.913,1.163l0.916-1.161
				c1.264-1.604,8.414-8.096,11.063-10.462l0.509-0.455l-8.896-39.89c1.806-0.855,3.115-2.296,3.115-3.563v-4.259
				c4.966-0.898,9.356-2.784,12.973-5.438c1.969,1.008,6.767,3.354,11.86,5.05c-6.366,3.499-9.77,8.565-12.586,14.875
				c-6.189,13.865-5.012,62.833-4.958,64.911c0.036,1.399,1.181,2.509,2.573,2.509c0.022,0,0.045,0,0.067-0.001
				c1.422-0.036,2.545-1.218,2.508-2.64c-0.013-0.497-1.206-49.868,4.513-62.68c3.179-7.122,6.699-11.787,15.287-14.557
				c7.81-2.519,15.818-6.698,19.077-8.479c5.317,4.167,11.85,7.163,19.27,8.641v6.446c0,1.871,1.928,4.006,4.59,5.268l-13.128,58.87
				l0.75,0.671c3.908,3.494,14.458,13.08,16.318,15.447l1.35,1.717l1.35-1.718c1.859-2.367,12.41-11.953,16.318-15.447l0.75-0.671
				l-13.129-58.87c2.669-1.264,4.599-3.397,4.599-5.268v-6.286c7.328-1.326,13.807-4.108,19.146-8.024
				c3.005,1.536,10.476,5.189,18.271,7.703c8.584,2.77,12.105,7.435,15.286,14.556c0.59,1.322,1.137,3.153,1.627,5.445
				c0.258,1.209,1.327,2.037,2.516,2.037c0.178,0,0.359-0.019,0.541-0.058c1.391-0.297,2.277-1.666,1.979-3.057
				c-0.565-2.642-1.225-4.817-1.961-6.467c-2.703-6.053-5.938-10.965-11.816-14.444c5.14-1.725,10.293-4.411,12.421-5.575
				c3.602,2.822,8.028,4.851,13.054,5.852v4.368c0,1.268,1.31,2.711,3.112,3.564l-3.956,17.729c-0.14,0.627,0.255,1.249,0.882,1.389
				c0.627,0.14,1.249-0.255,1.389-0.882l3.914-17.543c0.042,0.007,0.086,0.009,0.129,0.016c0.393,0.057,0.791,0.091,1.192,0.092
				c0.01,0,0.02,0.002,0.03,0.002c0.003,0,0.006-0.001,0.01-0.001c0.439,0,0.868-0.035,1.285-0.098
				c0.019-0.003,0.038-0.004,0.056-0.007c0.002,0,0.005,0,0.007-0.001l8.556,38.369c-1.75,1.572-7.442,6.712-9.911,9.283
				c-2.471-2.569-8.16-7.711-9.909-9.283l3.646-16.35c0.14-0.627-0.255-1.249-0.882-1.388c-0.625-0.142-1.248,0.254-1.388,0.882
				l-3.942,17.679l0.508,0.455c2.646,2.366,9.79,8.858,11.056,10.462l0.916,1.16l0.913-1.163c1.257-1.602,8.405-8.093,11.053-10.459
				l0.509-0.455l-8.896-39.892c1.806-0.855,3.115-2.296,3.115-3.563v-4.259c4.964-0.898,9.354-2.783,12.97-5.436
				c2.036,1.041,7.097,3.516,12.378,5.219c5.815,1.876,8.201,5.037,10.356,9.859c0.401,0.901,0.772,2.143,1.102,3.69
				c0.175,0.819,0.899,1.381,1.704,1.381C283.047,181.387,283.169,181.375,283.292,181.348z M36.455,139.202V124.53
				c4.934-0.381,9.2-3.503,11.04-8.171c0.354-0.896-0.087-1.909-0.983-2.263c-0.894-0.351-1.909,0.086-2.263,0.983
				c-1.31,3.323-4.307,5.552-7.794,5.924v-11.988c0-13.984,11.248-24.133,26.745-24.133c15.422,0,26.633,10.051,26.743,23.927
				c-2.462,7.067-9.886,11.985-18.167,11.985c-7.846,0-14.791-4.225-17.692-10.763c-0.391-0.88-1.419-1.277-2.303-0.887
				c-0.88,0.391-1.277,1.422-0.887,2.303c3.461,7.797,11.658,12.836,20.882,12.836c7.274,0,14-3.277,18.173-8.448v23.365
				c0,12.192-8.749,21.737-21.776,23.754c-3.348,0.529-7.069,0.505-10.496-0.089C44.983,160.67,36.455,151.16,36.455,139.202z
				 M64.774,172.258c-0.185,0.062-0.385,0.098-0.582,0.14c-0.413,0.086-0.837,0.146-1.271,0.146c-0.636,0-1.284-0.094-1.894-0.291
				c-1.561-0.496-2.414-1.495-2.485-1.748v-3.98c1.529,0.198,3.09,0.299,4.658,0.299c1.389,0,2.749-0.103,4.083-0.255l0.003,3.874
				C67.209,170.757,66.353,171.757,64.774,172.258z M111.023,128.677v-21.659c7.284-0.563,13.58-5.17,16.294-12.061
				c0.521-1.323-0.129-2.818-1.452-3.34c-1.322-0.519-2.818,0.128-3.34,1.452c-1.933,4.906-6.353,8.22-11.502,8.768V84.121
				c0-20.642,16.602-35.623,39.477-35.623c22.766,0,39.314,14.836,39.477,35.319c-3.634,10.432-14.592,17.69-26.816,17.69
				c-11.582,0-21.833-6.235-26.118-15.886c-0.577-1.3-2.098-1.887-3.398-1.309c-1.3,0.577-1.886,2.099-1.309,3.398
				c5.109,11.509,17.209,18.946,30.825,18.946c10.737,0,20.664-4.837,26.824-12.469v34.489c0,17.996-12.915,32.086-32.144,35.062
				c-4.947,0.78-10.436,0.746-15.494-0.13C123.611,160.365,111.023,146.327,111.023,128.677z M156.533,174.792
				c-0.114,0.463-1.379,1.939-3.709,2.679c-1.256,0.418-2.723,0.533-4.136,0.319c0,0-0.001,0-0.001,0
				c-0.467-0.071-0.928-0.178-1.375-0.322c-0.005-0.001-0.011-0.003-0.017-0.004c-2.303-0.732-3.564-2.207-3.67-2.58v-5.875
				c2.258,0.292,4.562,0.441,6.875,0.441c2.043,0,4.063-0.115,6.028-0.339L156.533,174.792z M150.071,251.513
				c-3.646-3.797-12.047-11.388-14.629-13.708l12.629-56.634c0.009,0.001,0.018,0.001,0.026,0.003
				c0.317,0.05,0.638,0.088,0.96,0.114c0.005,0,0.01,0.001,0.015,0.001c0.671,0.053,1.349,0.052,2.013,0
				c0.023-0.002,0.045-0.006,0.069-0.008c0.307-0.025,0.611-0.06,0.909-0.107c0.002,0,0.005,0,0.007-0.001l12.629,56.63
				C162.118,240.125,153.717,247.716,150.071,251.513z M211.263,139.629v-14.672c4.935-0.381,9.201-3.503,11.04-8.171
				c0.353-0.896-0.087-1.91-0.984-2.263c-0.896-0.355-1.91,0.087-2.262,0.983c-1.309,3.323-4.306,5.552-7.793,5.924v-11.987
				c0-13.984,11.248-24.133,26.745-24.133c15.423,0,26.633,10.05,26.744,23.927c-2.462,7.067-9.886,11.985-18.168,11.985
				c-7.845,0-14.79-4.225-17.693-10.762c-0.391-0.88-1.422-1.278-2.302-0.886c-0.88,0.391-1.277,1.422-0.886,2.302
				c3.462,7.797,11.659,12.835,20.882,12.835c7.274,0,14-3.277,18.173-8.449v23.365c0,12.192-8.75,21.738-21.776,23.755
				c-3.36,0.527-7.083,0.502-10.497-0.089C219.791,161.098,211.263,151.587,211.263,139.629z M239.685,172.647l-0.107,0.037
				c-1.12,0.379-2.498,0.396-3.732-0.005l-0.114-0.037c-1.491-0.506-2.312-1.461-2.381-1.708v-3.98
				c1.527,0.198,3.087,0.299,4.658,0.299c1.388,0,2.748-0.103,4.083-0.255l0.003,3.875
				C242.019,171.178,241.195,172.136,239.685,172.647z"
          />
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M285.766,186.936c-0.098-0.958-0.95-1.651-1.913-1.559c-0.958,0.098-1.656,0.954-1.559,1.913
				c1.334,13.113,0.889,31.311,0.884,31.494c-0.024,0.963,0.736,1.764,1.7,1.789c0.015,0,0.03,0,0.045,0
				c0.942,0,1.719-0.752,1.743-1.7C286.671,218.688,287.122,200.272,285.766,186.936z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconAnwaelte;
