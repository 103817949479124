import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconLostPackage = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 448.5 448.5">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M441,360.3c-10,0-15.3,3.4-19.6,6.2c-3.5,2.3-5.9,3.8-11.4,3.8s-7.9-1.5-11.4-3.8c-4.3-2.8-9.6-6.2-19.6-6.2
		s-15.3,3.4-19.6,6.2c-3.3,2.1-5.6,3.6-10.3,3.8c-12.7-15.6-28-28.3-44.9-38c1,5.4,1.1,11.8-3.8,15.2c6.3,3.8,12.3,8.1,17.9,12.9
		c-0.4,0-0.9,0-1.3,0c-10,0-15.3,3.4-19.6,6.2c-3.5,2.3-5.9,3.8-11.4,3.8s-7.9-1.5-11.4-3.8c-4.3-2.8-9.6-6.2-19.6-6.2
		c-10,0-15.3,3.4-19.5,6.2c-3.5,2.3-5.9,3.8-11.4,3.8s-7.9-1.5-11.4-3.8c-4.3-2.8-9.6-6.2-19.5-6.2c-10,0-15.3,3.4-19.6,6.2
		c-3.5,2.3-5.9,3.8-11.4,3.8s-7.9-1.5-11.4-3.8c-4.3-2.8-9.6-6.2-19.6-6.2c-0.4,0-0.9,0-1.3,0c20.9-17.6,46.1-28.9,73.3-32.7
		c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.5,0.1-0.7c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0.1-0.7,0.1-1.4,0.2-2.1c-0.3-1.1-0.4-2.3-0.5-3.5
		c-0.2-2.6-0.2-5.4,0.2-8c-4,0.5-8,1.2-11.9,2c4.6-74.3-22.5-128.3-30.1-141.7c1.1-3.3,2-7.2,2.8-11.8c26.7,35.5,43.9,77.3,50,121.2
		c2.9-0.1,5.3-1.7,7.8-3.3c2.1-1.3,4.2-2.1,6.5-2.3c-7.7-50.5-29.2-98-62.4-137.3c0-1.5,0-3,0-4.6l72.5,12.1
		c0.4,0.1,0.8,0.1,1.2,0.1c1.7,0,3.4-0.6,4.7-1.6c1.7-1.3,2.7-3.3,2.8-5.5c1-19.2-14.9-46.2-37-62.9c-16.4-12.4-33.1-16.5-47-11.8
		c-2.7,0.9-5.2,2.1-7.7,3.4c-6.5-2.5-13.4-3.8-20.4-3.8c-23,0-43.7,13.8-52.6,34.8c-2.6,0.8-5.2,1.9-7.7,3.2
		c-13.1,6.7-21.7,21.6-24.2,42c-3.3,27.5,5.1,57.7,19.5,70.3c1.4,1.2,3.1,1.8,4.9,1.8c0.3,0,0.6,0,0.9-0.1c2.1-0.3,4-1.4,5.2-3.2
		l29.8-43.4c6.7,8.1,18.2,19,33.7,21.8c0.4,0.1,0.9,0.1,1.3,0.1c1.5,0,3-0.5,4.3-1.4c0.5-0.3,1.3-1,2.2-2
		c10,20.8,28.3,68.5,23.4,129.6c-29.8,9.2-56.5,27.1-76.9,51.9c-4.8-0.2-7-1.6-10.3-3.8c-4.3-2.8-9.6-6.2-19.5-6.2
		c-10,0-15.3,3.4-19.5,6.2c-3.5,2.3-5.9,3.8-11.4,3.8c-5.5,0-7.9-1.5-11.4-3.8c-4.3-2.8-9.6-6.2-19.5-6.2c-4.1,0-7.5,3.4-7.5,7.5
		c0,4.1,3.4,7.5,7.5,7.5c5.5,0,7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.5,6.2c10,0,15.3-3.4,19.5-6.2c3.5-2.3,5.9-3.8,11.4-3.8
		s7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.5,6.2c10,0,15.3-3.4,19.5-6.2c3.5-2.3,5.9-3.8,11.4-3.8c5.5,0,7.9,1.5,11.4,3.8
		c4.3,2.8,9.6,6.2,19.6,6.2c10,0,15.3-3.4,19.6-6.2c3.5-2.3,5.9-3.8,11.4-3.8c5.5,0,7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.5,6.2
		c10,0,15.3-3.4,19.5-6.2c3.5-2.3,5.9-3.8,11.4-3.8c5.5,0,7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.6,6.2c10,0,15.3-3.4,19.6-6.2
		c3.5-2.3,5.9-3.8,11.4-3.8s7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.6,6.2s15.3-3.4,19.6-6.2c3.5-2.3,5.9-3.8,11.4-3.8
		c5.5,0,7.9,1.5,11.4,3.8c4.3,2.8,9.6,6.2,19.6,6.2c10,0,15.3-3.4,19.6-6.2c3.5-2.3,5.9-3.8,11.4-3.8c4.1,0,7.5-3.4,7.5-7.5
		C448.5,363.7,445.1,360.3,441,360.3z M135.3,79.7c2.1,0,4.2,0.2,6.3,0.5c-4.3,5.2-7.7,11.4-10,18.6c-1,3.1-1.8,6.1-2.3,8.8
		c-2.4-1.5-5-3.1-8-4.5c-6.8-3.3-13.6-5.2-20.3-5.7C108.7,86.5,121.4,79.7,135.3,79.7z M74.6,196.9c-7.3-12-11.2-32.3-9-50.5
		c1.1-8.8,4.6-24.5,16.1-30.4c4.9-2.5,10-3.8,15.3-3.8c5.6,0,11.4,1.4,17.3,4.2c4.5,2.1,8.2,4.6,10.9,6.7L74.6,196.9z M143.5,176.2
		c-11.2-3.7-19.5-13.6-23.4-19l18.6-27.1l12.3,2.1C151.5,159.2,146.4,171.5,143.5,176.2z M143.1,115.6c1.1-10,5.8-29.7,25.1-36.3
		c12.2-4.2,26,4.2,33.1,9.5c14.6,11,26.5,28,30,41.5L143.1,115.6z"
        />
        <path
          d="M274.8,228.9c0.2,11.7,9.7,21.2,21.5,21.2h76c8.5,0,15.5-7,15.5-15.5c0-0.3,0.2-0.5,0.5-0.5h12c11.9,0,21.5-9.6,21.5-21.5
		s-9.6-21.5-21.5-21.5h-43.4c0.1-1.2,0.1-2.3,0.1-3.5c0-28.4-23.1-51.5-51.5-51.5S254,159.2,254,187.6
		C254,204.1,261.7,219.2,274.8,228.9z M406.7,212.6c0,3.6-2.9,6.5-6.5,6.5h-12c-8.5,0-15.5,7-15.5,15.5c0,0.3-0.2,0.5-0.5,0.5h-76
		c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5h12c8.5,0,15.5-7,15.5-15.5c0-0.3,0.2-0.5,0.5-0.5h76C403.8,206.1,406.7,209,406.7,212.6z
		 M305.5,151.1c20.1,0,36.5,16.4,36.5,36.5c0,1.2-0.1,2.3-0.2,3.5h-17.6c-8.5,0-15.5,7-15.5,15.5c0,0.3-0.2,0.5-0.5,0.5h-12
		c-6.3,0-11.9,2.7-15.8,7c-7.2-6.8-11.4-16.3-11.4-26.5C269,167.5,285.4,151.1,305.5,151.1z"
        />
        <path
          d="M306,286l-51.5-19.3c-0.5-0.2-1.1-0.2-1.6,0L201,286.2c-0.9,0.3-2,1.7-1.2,3.2v54.5c0,1,0.6,1.8,1.5,2.1l51.1,17.6
	c0.3,0.2,0.5,0.3,1.1,0.4c0.8,0.1,1.2-0.1,1.2-0.1l51.9-17.9c0.9-0.3,1.5-1.2,1.5-2.1v-55.6C308.1,287.2,306.8,286.3,306,286z
	 M299.1,288.3l-45.3,17l-13.6-5.1l43.2-17.8L299.1,288.3z M277.1,280l-45.4,18.7l-1.1,10.9l-8.4-3.4v-10.1l47.1-19L277.1,280z
	 M253.6,271.2l9.4,3.5l-43.6,17.6l-11.1-4.2L253.6,271.2z M204.3,291.6l13.4,5v12.6l16.8,6.8l1.3-12.6l15.8,5.9v49.2l-47.4-16.4
	C204.3,342.2,204.3,291.6,204.3,291.6z M303.6,342.2l-47.4,16.4v-49.3l47.4-17.7V342.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconLostPackage;
