import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDelete = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64">
        <g>
          <path
            style={{ fill: "none" }}
            d="M69.718,115.969c1.783,24.395,13.213,112.563,15.24,127.773c2.499,18.721,8.066,25.682,20.55,25.682
			h88.969c12.48,0,18.051-6.961,20.547-25.682c2.031-15.21,13.457-103.378,15.244-127.773c0.758-10.366,3.648-17.561,6.988-22.519
			H62.729C66.069,98.408,68.96,105.603,69.718,115.969z M183.374,133.623h21.965l-10.857,99.253h-11.107V133.623z M138.551,133.623
			h22.405v99.253h-22.405V133.623z M116.128,133.623v99.253H105.5l-10.857-99.253H116.128z"
          />
          <g>
            <polygon style={{ fill: "#137F7B" }} points="105.5,232.876 116.128,232.876 116.128,133.623 94.643,133.623 			" />
            <rect x="138.551" y="133.623" style={{ fill: "#137F7B" }} width="22.405" height="99.253" />
            <polygon style={{ fill: "#137F7B" }} points="183.374,232.876 194.481,232.876 205.339,133.623 183.374,133.623 			" />
            <path
              style={{ fill: "#137F7B" }}
              d="M244.452,60.542H55.547c-4.857,0-8.813,3.956-8.813,8.813v13.52h206.533v-13.52
				C253.267,64.497,249.313,60.542,244.452,60.542z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M131.376,46.88c0-3.825,3.085-6.909,6.909-6.909h23.411c3.826,0,6.91,3.084,6.91,6.909v3.085
				h14.084V39.389c0-4.864-3.949-8.813-8.814-8.813h-47.77c-4.864,0-8.813,3.949-8.813,8.813v10.576h14.084V46.88z"
            />
          </g>
          <path
            style={{ fill: theme.palette.iconColor.main }}
            d="M244.452,49.965h-51.186V39.389c0-10.7-8.693-19.389-19.391-19.389h-47.77
			c-10.697,0-19.39,8.689-19.39,19.389v10.576H55.547c-10.689,0-19.389,8.7-19.389,19.389V93.45h11.437
			c2.289,1.078,10.314,6.059,11.574,23.29c1.804,24.653,13.271,113.142,15.307,128.399C75.75,254.672,79.126,280,105.508,280h88.969
			c26.379,0,29.76-25.328,31.029-34.861c2.035-15.258,13.506-103.746,15.311-128.399c1.26-17.23,9.285-22.212,11.574-23.29h11.449
			V69.354C263.841,58.665,255.146,49.965,244.452,49.965z M117.292,39.389c0-4.864,3.949-8.813,8.813-8.813h47.77
			c4.865,0,8.814,3.949,8.814,8.813v10.576h-14.084V46.88c0-3.825-3.084-6.909-6.91-6.909h-23.411c-3.824,0-6.909,3.084-6.909,6.909
			v3.085h-14.084V39.389z M230.269,115.969c-1.787,24.395-13.213,112.563-15.244,127.773c-2.496,18.721-8.066,25.682-20.547,25.682
			h-88.969c-12.483,0-18.051-6.961-20.55-25.682c-2.027-15.21-13.457-103.378-15.24-127.773
			c-0.758-10.366-3.649-17.561-6.989-22.519h174.528C233.917,98.408,231.026,105.603,230.269,115.969z M253.267,82.874H46.734
			v-13.52c0-4.857,3.956-8.813,8.813-8.813h188.905c4.861,0,8.814,3.956,8.814,8.813V82.874z"
          />
        </g>
      </g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDelete;
