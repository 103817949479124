import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconBeides = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M190.533,123.142v12.555c0,7.401,3.549,10.229,5.664,11.257c0.909,0.441,2.242,0.885,3.929,0.885 c2.239,0,5.103-0.783,8.421-3.394l55.91-43.998c3.943-3.102,6.204-7.494,6.204-12.05c0-4.557-2.261-8.949-6.204-12.051 l-55.91-43.998c-5.816-4.577-10.234-3.536-12.349-2.51c-2.116,1.027-5.665,3.856-5.665,11.258V53.65H80.034 c-2.407,0-4.358,1.952-4.358,4.359s1.951,4.359,4.358,4.359h114.858c2.406,0,4.358-1.952,4.358-4.359V41.096 c0-2.378,0.607-3.306,0.739-3.409c0.195-0.057,1.3,0.043,3.166,1.512l55.91,43.998c1.855,1.46,2.877,3.307,2.877,5.2 s-1.021,3.74-2.877,5.2l-55.91,43.998c-1.868,1.469-2.974,1.564-3.135,1.527c-0.163-0.118-0.771-1.046-0.771-3.424v-16.914 c0-2.407-1.952-4.359-4.358-4.359H35.461c-3.365,0-6.102-2.737-6.102-6.102V68.47c0-3.365,2.736-6.102,6.102-6.102h21.908 c2.408,0,4.359-1.952,4.359-4.359s-1.951-4.359-4.359-4.359H35.461c-8.172,0-14.82,6.648-14.82,14.819v39.853 c0,8.171,6.648,14.819,14.82,14.819H190.533z" />
        <path d="M41.975,84.248c-2.889,0-5.23,2.342-5.23,5.23v12.786c0,2.889,2.342,5.23,5.23,5.23H54.76 c2.889,0,5.23-2.342,5.23-5.23s-2.342-5.23-5.23-5.23h-7.555v-7.555C47.205,86.59,44.863,84.248,41.975,84.248z" />
        <path d="M264.539,176.859h-21.908c-2.408,0-4.359,1.952-4.359,4.359s1.951,4.359,4.359,4.359h21.908 c3.365,0,6.102,2.737,6.102,6.102v39.853c0,3.365-2.736,6.102-6.102,6.102H105.107c-2.406,0-4.358,1.952-4.358,4.359v16.913 c0,2.378-0.607,3.306-0.738,3.409c-0.196,0.054-1.298-0.042-3.167-1.511l-55.91-43.998c-1.855-1.46-2.877-3.307-2.877-5.2 s1.021-3.74,2.877-5.2l55.91-43.998c1.867-1.47,2.971-1.57,3.135-1.527c0.163,0.119,0.771,1.046,0.771,3.424v16.914 c0,2.407,1.952,4.359,4.358,4.359h114.858c2.407,0,4.358-1.952,4.358-4.359s-1.951-4.359-4.358-4.359H109.467v-12.555 c0-7.401-3.549-10.229-5.664-11.257c-2.115-1.027-6.533-2.068-12.35,2.509l-55.91,43.998c-3.943,3.102-6.204,7.494-6.204,12.05 c0,4.557,2.261,8.949,6.204,12.051l55.91,43.998c3.318,2.611,6.182,3.394,8.421,3.393c1.687,0,3.021-0.443,3.929-0.885 c2.115-1.027,5.664-3.856,5.664-11.257v-12.554h155.072c8.172,0,14.82-6.648,14.82-14.819v-39.853 C279.359,183.507,272.711,176.859,264.539,176.859z" />
        <path d="M258.025,230.703c2.889,0,5.23-2.342,5.23-5.23v-12.786c0-2.889-2.342-5.23-5.23-5.23 s-5.23,2.342-5.23,5.23v7.555h-7.555c-2.889,0-5.23,2.342-5.23,5.23s2.342,5.23,5.23,5.23H258.025z" />
      </g>
    </SvgIcon>
  );
};

export default IconBeides;
