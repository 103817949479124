import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconBicycle = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g style={{ fill: "none", stroke: getIconColor(active, theme), strokeLinecap: "round", strokeLinejoin: "round", fillRule: "evenodd" }}>
        <path d="M102.29,201.29a47.83,47.83,0,1,1,4.49-20.23" style={{ strokeWidth: "6.91px" }} />
        <path d="M284.42,201.29a47.85,47.85,0,1,1,4.48-20.23" style={{ strokeWidth: "6.91px" }} />
        <polyline points="59.03 180.57 101.63 71.09 129.55 71.09" style={{ strokeWidth: "6.92px" }} />
        <polyline points="97.81 121.87 162.1 180.57 195.07 95.86 217.85 95.86 172.23 95.86" style={{ strokeWidth: "6.92px" }} />
        <polyline points="241.06 181.06 186.5 121.87 97.81 121.87" style={{ strokeWidth: "6.91px" }} />
      </g>
    </SvgIcon>
  );
};

export default IconBicycle;
