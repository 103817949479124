import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDistanceFiveToTen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            d="M4.75,237.25H24.47V237c0-12.26,10.33-22.21,23.07-22.21S70.63,224.72,70.63,237v.26H72.1c6.45,0,19.68-3.19,19.68-11.15V205.63h-87Z"
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
          />
          <path
            d="M197.92,237.28H220.2V237a22.69,22.69,0,0,1,45.37,0v.27h29.09V205.66H204.33C199.12,213.24,198.71,223.15,197.92,237.28Z"
            style={{ fill: "#137F7B", fillRule: "evenodd" }}
          />
        </>
      )}
      <g>
        <path
          d="M87.79,47.76c-3.56,0-8.84,1.29-12,4.51V34H101V25.49H65.66v32.2l10.1,4.51c1.28-3.13,5.2-6,9.75-6,7.37,0,12,7,12,14.72,0,9.2-5.28,15.55-14.3,15.55-6.65,0-10-3.59-12.11-6.8l-7.56,5.7C68.12,91.46,75.31,95,83.69,95c12.29,0,24-8.28,24-24,0-13.89-8.38-23.18-19.85-23.18Zm34.87,63.76,31.32-86h-9.47l-31.41,86Zm58-86H160.35V34h10.2V94.5h10.11v-69Zm55.18,46.19V48.31C235.66,33.13,226.1,25,212.35,25s-23.5,8.1-23.5,23.28V71.68C188.85,87,198.6,95,212.35,95s23.31-8,23.49-23.28Zm-10.11-23V71.31c0,11-5.64,15.18-13.38,15.18S199,82.35,199,71.31V48.68c0-11,5.65-15.18,13.39-15.18S225.73,37.64,225.73,48.68Z"
          style={{ fill: getIconColor(active, theme), fillRule: "evenodd" }}
        />
        <path
          d="M87.79,47.76c-3.56,0-8.84,1.29-12,4.51V34H101V25.49H65.66v32.2l10.1,4.51c1.28-3.13,5.2-6,9.75-6,7.37,0,12,7,12,14.72,0,9.2-5.28,15.55-14.3,15.55-6.65,0-10-3.59-12.11-6.8l-7.56,5.7C68.12,91.46,75.31,95,83.69,95c12.29,0,24-8.28,24-24,0-13.89-8.38-23.18-19.85-23.18Zm34.87,63.76,31.32-86h-9.47l-31.41,86Zm58-86H160.35V34h10.2V94.5h10.11v-69Zm55.18,46.19V48.31C235.66,33.13,226.1,25,212.35,25s-23.5,8.1-23.5,23.28V71.68C188.85,87,198.6,95,212.35,95s23.31-8,23.49-23.28Zm-10.11-23V71.31c0,11-5.64,15.18-13.38,15.18S199,82.35,199,71.31V48.68c0-11,5.65-15.18,13.39-15.18S225.73,37.64,225.73,48.68Z"
          style={{ fill: getIconColor(active, theme), fillRule: "evenodd" }}
        />
        <line x1="5.48" y1="275.54" x2="293.92" y2="275.56" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9" }} />

        <path d="M4.75,237.25h7.79m70.26-1.84c4.76-1.72,9-4.73,9-9.31V185.52h-87" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <path d="M70,244.56a22.15,22.15,0,1,1,0-15.16" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />

        <path
          d="M197.92,237.28h21.82V237m58.87.27h16m0-51.73H249.56c-50,10.26-50,21-51.64,51.73"
          style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }}
        />
        <line x1="256.42" y1="185.55" x2="287.98" y2="156.37" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9" }} />
        <path d="M262.76,244.58a22.15,22.15,0,1,1,0-15.15" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", fill: "none" }} />
        <line x1="116.08" y1="137.42" x2="116.11" y2="262.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", strokeDasharray: "6.9 20.7" }} />
        <line x1="131.65" y1="137.42" x2="131.67" y2="262.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", strokeDasharray: "6.9 20.7" }} />
        <line x1="147.22" y1="137.42" x2="147.24" y2="262.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", strokeDasharray: "6.9 20.7" }} />
        <line x1="162.78" y1="137.42" x2="162.81" y2="262.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", strokeDasharray: "6.9 20.7" }} />
        <line x1="178.35" y1="137.42" x2="178.37" y2="262.64" style={{ stroke: getIconColor(active, theme), strokeWidth: "6.9", strokeDasharray: "6.9 20.7" }} />
      </g>
    </SvgIcon>
  );
};

export default IconDistanceFiveToTen;
