import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconHaken = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 211.53 194.4">
      <defs>
        <clipPath id="clip-path" transform="translate(-38.78 -45.06)">
          <rect x="38.99" y="45.21" width="211.12" height="194.05" fill="none" />
        </clipPath>
      </defs>
      <title>haken</title>
      <g style={{ fill: "none", stroke: getIconColor(active, theme), strokeLinecap: "round", strokeWidth: "5px" }}>
        <polyline points="15.77 116.6 3.54 133.25 86.66 190.92 141.8 111.44" />
        <polyline points="16.14 116.84 80.71 160.62 190.41 2.5" />
        <polyline points="148.69 101.51 208.11 15.85 190.71 2.65" />
      </g>
    </SvgIcon>
  );
};

export default IconHaken;
