import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconProviderCancellation = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 117.9 122.9">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M58.9,15.2c1.7,0,3.4,0.2,5,0.5c3.3,0.7,6.4,2,9.2,3.8c1.4,0.9,2.7,2,3.9,3.2c1.2,1.2,2.3,2.5,3.2,3.9
	c0.9,1.4,1.7,2.9,2.4,4.4c0.6,1.5,1.1,3.1,1.5,4.8c0.7,3.3,0.7,6.7,0,9.9c-0.7,3.3-2,6.4-3.8,9.2c-1.9,2.8-4.3,5.1-7.1,7
	c-2.8,1.9-5.9,3.2-9.2,3.8s-6.7,0.6-9.9,0c-1.6-0.3-3.2-0.8-4.8-1.4c-1.5-0.6-3-1.4-4.4-2.4c-1.4-0.9-2.7-2-3.9-3.2l-0.1-0.1
	c-1.2-1.2-2.2-2.4-3.1-3.8c-0.9-1.4-1.7-2.9-2.4-4.4c-0.6-1.5-1.1-3.1-1.5-4.8c-0.6-3.3-0.6-6.7,0-9.9c0.3-1.6,0.8-3.2,1.5-4.8
	c0.6-1.5,1.4-3,2.4-4.4c1.9-2.8,4.2-5.1,7-7c1.4-0.9,2.9-1.7,4.4-2.4c1.6-0.6,3.2-1.1,4.8-1.4C55.6,15.3,57.3,15.2,58.9,15.2
	L58.9,15.2z M56.1,24.2l13.5,29.4l0.5-0.4c0.2-0.2,0.4-0.4,0.7-0.7c0.8-0.8,1.5-1.6,2.1-2.5s1.1-1.9,1.5-2.9c1.7-4.1,1.7-8.7,0-12.8
	c-0.4-1-0.9-2-1.6-2.9c-0.6-0.9-1.3-1.8-2.1-2.5l-0.1-0.1c-0.8-0.7-1.6-1.4-2.5-2s-1.9-1.1-2.9-1.5s-2.1-0.7-3.1-0.9
	c-1.1-0.2-2.2-0.3-3.3-0.3c-0.5,0-0.9,0-1.3,0s-0.9,0.1-1.3,0.1L56.1,24.2L56.1,24.2z M61.7,57.3L48.2,27.8l-0.5,0.4
	c-0.2,0.2-0.5,0.4-0.7,0.6c-1.6,1.6-2.8,3.4-3.7,5.4c-0.4,1-0.7,2.1-0.9,3.1c-0.4,2.2-0.4,4.4,0,6.6c0.2,1.1,0.5,2.1,0.9,3.1
	s1,2,1.6,2.9c1.2,1.8,2.8,3.4,4.7,4.7c0.9,0.6,1.9,1.1,2.9,1.5c2,0.8,4.2,1.3,6.4,1.3c0.5,0,0.9,0,1.3,0S61.2,57.3,61.7,57.3
	L61.7,57.3L61.7,57.3z"
        />
        <path
          d="M112.4,69.8v40L76.6,78.4l35.8-31.3v12.7c1.8,0,3.7,0.1,5.5,0.1V46.6c-0.1-1.6-0.7-3.1-1.7-4.3
	c-0.4-0.4-0.8-0.8-1.3-1.1c-0.6-0.4-1.3-0.6-2.1-0.6c-0.9,0-1.8,0.3-2.4,0.9l0,0v-9.1c0-0.7-0.3-1.4-0.8-1.9L81,1
	c-0.5-0.6-1.3-1-2.1-1H9.7C8.2,0,7,1.2,7,2.7v38.5c-0.6-0.4-1.3-0.6-2-0.6s-1.4,0.2-2,0.6c-0.5,0.3-0.9,0.6-1.3,1
	c-1,1.2-1.7,2.7-1.7,4.3v39.8c1.8-0.1,3.7-0.1,5.5-0.1v-39l35.3,31.6L5.5,109.6V96.3c-1.8,0-3.7,0.1-5.5,0.1v20.8
	c0,1.5,0.6,2.9,1.7,4s2.5,1.7,4,1.7h106.5c1.5,0,2.9-0.6,4-1.7s1.7-2.5,1.7-4V69.9C116.1,69.9,114.2,69.8,112.4,69.8z M12.4,46V5.5
	H78v0.2l24.3,25.1l0,0l2.6,5.5v10L58.4,87.1L46.8,76.7l-0.2-0.1L12.4,46z M112.3,117.2L112.3,117.2L112.3,117.2L5.6,117.3H5.5v-0.1
	v-0.3l39.4-34.5l11.6,10.4l0,0c1,0.9,2.6,0.9,3.6,0L72.5,82l39.9,35L112.3,117.2L112.3,117.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconProviderCancellation;
