import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDokumenteUpload = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84">
        <g>
          <path
            style={{ fill: "#137F7B" }}
            d="M150.832,27.652c-8.062,13.158-28.032,45.75-33.409,54.527h11.804c0.77,0,2.573,0,3.944,1.473
			c1.342,1.441,1.22,3.16,1.161,3.984c-0.016,0.221-0.037,0.494-0.037,0.832v72.254h33.074V88.469c0-0.338-0.021-0.611-0.037-0.832
			c-0.059-0.824-0.181-2.543,1.161-3.984c1.371-1.473,3.173-1.473,3.942-1.473h11.802C178.788,73.279,158.879,40.785,150.832,27.652
			z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M228.465,46.926h-45.389c-2.209,0-4,1.791-4,4s1.791,4,4,4h45.389
				c2.189,0,3.971,1.785,3.971,3.977V206.09c0,2.209,1.791,4,4,4s4-1.791,4-4V58.902C240.436,52.299,235.065,46.926,228.465,46.926z
				"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M63.564,102.094c2.209,0,4-1.791,4-4V58.902c0-2.191,1.782-3.977,3.973-3.977h47.068
				c2.209,0,4-1.791,4-4s-1.791-4-4-4H71.537c-6.602,0-11.973,5.373-11.973,11.977v39.191
				C59.564,100.303,61.355,102.094,63.564,102.094z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M236.436,216.082c-2.209,0-4,1.791-4,4v51.945c0,2.191-1.781,3.973-3.971,3.973H71.537
				c-2.19,0-3.973-1.781-3.973-3.973V112.086c0-2.209-1.791-4-4-4s-4,1.791-4,4v159.941c0,6.602,5.371,11.973,11.973,11.973h156.928
				c6.601,0,11.971-5.371,11.971-11.973v-51.945C240.436,217.873,238.645,216.082,236.436,216.082z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M108.915,87.18c1.038,1.85,2.933,3,4.946,3h12.434V163c0,5.723,4.861,5.723,6.459,5.723h36.156
				c1.598,0,6.459,0,6.459-5.723V90.18h12.434c2.014,0,3.909-1.15,4.946-3c1.176-2.1,1.037-4.729-0.372-7.031
				c-1.391-2.273-38.133-62.238-38.133-62.238c-0.727-1.186-2.022-1.91-3.414-1.91s-2.684,0.725-3.41,1.91
				c0,0-36.742,59.965-38.133,62.238C107.878,82.451,107.739,85.08,108.915,87.18z M172.436,82.18c-0.77,0-2.571,0-3.942,1.473
				c-1.342,1.441-1.22,3.16-1.161,3.984c0.016,0.221,0.037,0.494,0.037,0.832v72.254h-33.074V88.469
				c0-0.338,0.021-0.611,0.037-0.832c0.059-0.824,0.181-2.543-1.161-3.984c-1.372-1.473-3.175-1.473-3.944-1.473h-11.804
				c5.377-8.777,25.347-41.369,33.409-54.527c8.047,13.133,27.956,45.627,33.405,54.527H172.436z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M207.398,176.75H92.07c-2.209,0-4,1.791-4,4s1.791,4,4,4h115.328c2.209,0,4-1.791,4-4
				S209.607,176.75,207.398,176.75z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M211.398,197.082c0-2.209-1.791-4-4-4H92.07c-2.209,0-4,1.791-4,4s1.791,4,4,4h115.328
				C209.607,201.082,211.398,199.291,211.398,197.082z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M194.732,211.746h-89.996c-2.209,0-4,1.791-4,4s1.791,4,4,4h89.996c2.209,0,4-1.791,4-4
				S196.941,211.746,194.732,211.746z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M194.732,230.078h-89.996c-2.209,0-4,1.791-4,4s1.791,4,4,4h89.996c2.209,0,4-1.791,4-4
				S196.941,230.078,194.732,230.078z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDokumenteUpload;
