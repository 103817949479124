import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconZweiPersonen = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0  225.9 202.3">
      <g>
        <g>
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5 }}
            d="M153.6,2.5c0,0-40,0-40,40v39.4c0,0,0,40,40,40h3.3c0,0,40,0,40-40V42.5c0,0,0-40-40-40H153.6z"
          />
          <path style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5, strokeLinecap: "round" }} d="M114,59.7c0,0,11.1,3.2,15.9-9.4" />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5, strokeLinecap: "round" }}
            d="M139.2,42.2c0,0,7.8,18,28.8,18s28.9-19.8,28.9-19.8"
          />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5.3, strokeLinecap: "round" }}
            d="M182.4,113.7c0,0,11.4,4.8,19.2,7.8s15,4.8,18,22.2"
          />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5.3, strokeLinecap: "round" }}
            d="M125,113.7c0,0-11.4,4.8-19.2,7.8s-15,4.8-18,22.2s-3.6,55.9-3.6,55.9"
          />
          <path
            style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: 5.3, strokeLinecap: "round" }}
            d="M221.4,155.8c1.4,18.1,1.9,43.9,1.9,43.9"
          />
        </g>
      </g>
      <path
        style={{ fill: getIconColor(active, theme) }}
        d="M15.8,43.5C7.1,43.5,0,50.6,0,59.3v40.5c0,8.7,7.1,15.8,15.8,15.8h67.5c8.7,0,15.8-7.1,15.8-15.8V59.3
	c0-8.7-7.1-15.8-15.8-15.8H15.8z M15.8,48h67.5c6.2,0,11.3,5,11.3,11.3v40.5c0,6.2-5,11.3-11.3,11.3H15.8c-6.2,0-11.3-5-11.3-11.3
	V59.3C4.5,53.1,9.6,48,15.8,48z M49.6,61.5c-10.4,0-11.4,8.8-11.2,12.9h4.3c0-1.2,0.3-9,6.9-9c6.2,0,7,5.5,7,6.5
	c0,10.9-19.1,10.5-19.1,25.7h23.1v-3.9H42.3c1-8,18.5-9.5,18.5-21.8C60.8,70.1,60,61.5,49.6,61.5z"
      />
    </SvgIcon>
  );
};

export default IconZweiPersonen;
