import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconAssistantSmile = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74">
        <g>
          <path
            style={{ fill: "none" }}
            d="M115.875,120.443c-13.166,0-25.526-6.873-32.145-17.478c-0.619,0.88-1.376,1.875-2.252,2.902
			c-0.033,0.117-0.053,0.234-0.098,0.35c-2.002,5.084-6.05,9.146-10.83,10.867c-1.477,0.532-3.349,0.96-5.545,0.96
			c-1.531,0-3.224-0.219-5.046-0.744v24.861c0,10.928,4.478,20.765,12.61,27.699c1.215,1.044,2.513,2.021,3.872,2.91
			c4.958,3.276,10.648,5.486,16.923,6.578c5.432,0.939,11.292,0.974,16.577,0.14c5.076-0.784,9.828-2.323,14.138-4.573
			c2.556-1.319,4.914-2.877,7.025-4.634c1.536-1.255,2.969-2.641,4.285-4.138c5.772-6.586,8.826-14.881,8.826-23.981v-38.141
			C137.495,113.193,127.32,120.443,115.875,120.443z M82.461,131.641c0-2.109,1.71-3.822,3.821-3.822
			c2.11,0,3.821,1.713,3.821,3.822c0,2.112-1.711,3.823-3.821,3.823C84.171,135.464,82.461,133.753,82.461,131.641z
			 M116.485,155.494c-0.28,0.286-6.986,7.013-16.402,7.013c-9.667,0-15.249-7.96-15.481-8.298c-0.737-1.073-0.465-2.541,0.607-3.277
			c1.07-0.736,2.533-0.467,3.271,0.6c0.056,0.079,4.465,6.263,11.603,6.263c7.398,0,12.987-5.548,13.042-5.605
			c0.915-0.922,2.405-0.933,3.331-0.021C117.38,153.08,117.395,154.567,116.485,155.494z M117.899,135.532
			c-2.11,0-3.821-1.711-3.821-3.821c0-2.111,1.711-3.823,3.821-3.823c2.112,0,3.825,1.712,3.825,3.823
			C121.724,133.821,120.011,135.532,117.899,135.532z"
          />
          <path
            style={{ fill: "none" }}
            d="M102.082,55.71c-4.852,0-9.433,0.64-13.669,1.822c-2.2,11.417-4.317,25.831-3.523,36.14
			c1.398,0.01,2.73,0.814,3.334,2.176c4.582,10.319,15.695,17.251,27.652,17.251c12.564,0,24.122-12.277,28.052-24.159
			c-1.621-13.531-10.228-24.273-22.904-29.587c-0.437-0.085-0.84-0.252-1.206-0.477C114.505,56.832,108.531,55.71,102.082,55.71z"
          />
          <path
            style={{ fill: "none" }}
            d="M97.279,207.054c1.558,1.046,2.04,3.071,1.211,4.702c0.771,0.278,1.54,0.49,2.301,0.605
			c-0.016-1.773,1.246-3.347,3.051-3.65c3.125-0.525,6.376-2.881,9.4-6.816c1.07-1.393,2.942-1.8,4.469-1.083
			c-1.315-1.078-1.743-2.963-0.927-4.532c1.954-3.752,3.453-7.949,4.378-12.099c-3.218,1.159-6.589,2.021-10.087,2.561
			c-2.889,0.457-5.919,0.688-8.993,0.688c-3.364,0-6.719-0.286-9.973-0.849c-4.202-0.731-8.178-1.914-11.911-3.507
			C82.692,190.233,87.744,200.653,97.279,207.054z"
          />
          <path
            style={{ fill: "none" }}
            d="M151.56,93.636v32.42h32.743c1.109,0,2.16,0.502,2.856,1.366c0.337,0.418,7.137,9.072,5.572,24.451
			c4.874-4.517,11.153-12.511,11.5-22.276c0.07-1.976,1.692-3.541,3.67-3.541h63.183c0.868,0,1.573-0.706,1.573-1.573V25.039
			c0-0.867-0.705-1.572-1.573-1.572H126.967c-0.867,0-1.574,0.705-1.574,1.572v28.327C141.214,60.746,151.56,75.643,151.56,93.636z
			 M219.849,73.435c2.421,0,4.382,1.962,4.382,4.383c0,2.419-1.961,4.382-4.382,4.382c-2.419,0-4.382-1.963-4.382-4.382
			C215.467,75.397,217.43,73.435,219.849,73.435z M203.93,73.435c2.42,0,4.382,1.962,4.382,4.383c0,2.419-1.962,4.382-4.382,4.382
			c-2.419,0-4.382-1.963-4.382-4.382C199.548,75.397,201.51,73.435,203.93,73.435z M188.01,73.435c2.42,0,4.382,1.962,4.382,4.383
			c0,2.419-1.961,4.382-4.382,4.382c-2.421,0-4.382-1.963-4.382-4.382C183.628,75.397,185.589,73.435,188.01,73.435z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M135.833,178.604c-0.67-0.354-1.185-0.886-1.518-1.508c-1.594,1.213-3.291,2.339-5.077,3.359
				c-0.745,6.469-2.815,13.217-5.94,19.218c-0.654,1.257-1.935,1.977-3.26,1.977c-0.539,0-1.083-0.129-1.598-0.379
				c1.56,1.245,1.845,3.512,0.623,5.102c-4.175,5.43-9.019,8.743-14.005,9.582c-0.206,0.033-0.411,0.051-0.613,0.051
				c-1.76,0-3.315-1.271-3.616-3.064c-0.033-0.194-0.037-0.387-0.039-0.579c-0.761-0.115-1.529-0.327-2.301-0.605
				c-0.066,0.132-0.125,0.267-0.209,0.393c-0.708,1.056-1.869,1.626-3.052,1.626c-0.704,0-1.415-0.203-2.043-0.624
				c-15.772-10.588-20.879-29.566-22.076-35.156c-0.499-0.353-0.994-0.707-1.477-1.078c-1.792,0.792-3.477,1.53-5.072,2.223
				c3.663,10.731,9.168,25.519,13.872,32.543c5.188,7.75,18.603,16.9,28.521,14.647c7.942-1.805,25.02-11.858,30.093-47.096
				C136.628,179.018,136.212,178.805,135.833,178.604z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M68.063,110.176c2.821-1.015,5.245-3.5,6.485-6.649c0.742-1.887,2.874-2.813,4.761-2.07
				c1.771,0.697,2.678,2.617,2.169,4.412c0.875-1.028,1.633-2.022,2.252-2.902c-0.828-1.326-1.58-2.701-2.219-4.138
				c-0.822-1.853,0.013-4.023,1.866-4.845c0.492-0.219,1.005-0.314,1.511-0.311c-0.793-10.309,1.323-24.722,3.523-36.14
				c-17.075,4.767-28.453,18.497-28.453,36.104v15.854C62.876,110.83,65.598,111.063,68.063,110.176z"
            />
          </g>
          <g>
            <circle style={{ fill: theme.palette.iconColor.main }} cx="86.283" cy="131.641" r="3.821" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="117.899" cy="131.71" r="3.822" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M60.484,223.876c-2.028,0-3.672,1.643-3.672,3.672v52.657c0,2.027,1.644,3.672,3.672,3.672
				c2.027,0,3.672-1.645,3.672-3.672v-52.657C64.156,225.519,62.512,223.876,60.484,223.876z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M143.352,223.876c-2.027,0-3.672,1.643-3.672,3.672v52.657c0,2.027,1.645,3.672,3.672,3.672
				c2.028,0,3.672-1.645,3.672-3.672v-52.657C147.024,225.519,145.38,223.876,143.352,223.876z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M271.083,16.123H126.967c-4.917,0-8.917,4-8.917,8.916v25.57
				c-4.972-1.453-10.328-2.241-15.968-2.241c-28.201,0-49.466,19.46-49.466,45.269v48.526c0,11.165,3.923,21.385,11.124,29.326
				c-10.582,4.632-15.385,6.458-17.565,7.162c-11.554,3.736-16.58,10.446-20.576,19.376c-6.811,15.283-5.522,64.783-5.465,66.883
				c0.056,1.993,1.688,3.57,3.668,3.57c0.035,0,0.069-0.001,0.103-0.002c2.027-0.055,3.625-1.744,3.569-3.771
				c-0.383-13.841-0.261-52.265,4.829-63.686c3.369-7.527,7.088-12.459,16.128-15.381c3.015-0.971,8.432-3.156,16.129-6.498
				c1.596-0.692,3.28-1.431,5.072-2.223c0.483,0.371,0.978,0.725,1.477,1.078c1.197,5.589,6.304,24.567,22.076,35.156
				c0.628,0.421,1.339,0.624,2.043,0.624c1.183,0,2.343-0.571,3.052-1.626c0.084-0.126,0.143-0.261,0.209-0.393
				c0.829-1.631,0.347-3.656-1.211-4.702c-9.535-6.401-14.586-16.821-17.081-23.979c3.732,1.593,7.708,2.776,11.911,3.507
				c3.254,0.563,6.609,0.849,9.973,0.849c3.074,0,6.104-0.232,8.993-0.688c3.498-0.541,6.87-1.402,10.087-2.561
				c-0.925,4.149-2.424,8.347-4.378,12.099c-0.816,1.569-0.388,3.454,0.927,4.532c-1.527-0.717-3.398-0.31-4.469,1.083
				c-3.024,3.935-6.275,6.291-9.4,6.816c-1.805,0.303-3.066,1.876-3.051,3.65c0.002,0.192,0.006,0.385,0.039,0.579
				c0.301,1.794,1.856,3.064,3.616,3.064c0.202,0,0.407-0.018,0.613-0.051c4.986-0.839,9.83-4.152,14.005-9.582
				c1.222-1.59,0.937-3.856-0.623-5.102c0.515,0.25,1.059,0.379,1.598,0.379c1.325,0,2.605-0.719,3.26-1.977
				c3.125-6.001,5.195-12.749,5.94-19.218c1.786-1.021,3.483-2.146,5.077-3.359c0.333,0.622,0.848,1.153,1.518,1.508
				c0.38,0.201,0.796,0.415,1.214,0.631c3.9,2.007,9.349,4.552,15.087,6.402c9.042,2.923,12.761,7.855,16.127,15.38
				c5.113,11.452,5.902,49.905,5.774,63.756c-0.018,2.028,1.61,3.687,3.638,3.706c0.011,0,0.023,0,0.035,0
				c2.012,0,3.652-1.622,3.671-3.638c0.02-2.099,0.397-51.566-6.413-66.82c-3.993-8.925-9.019-15.634-20.575-19.372
				c-5.643-1.819-11.038-4.398-14.71-6.321c0.418-0.438,0.828-0.885,1.228-1.34c6.969-7.95,10.652-17.918,10.652-28.826v-8.761
				h30.763c1.792,3.15,5.323,11.558,1.807,24.667c-0.411,1.525,0.05,3.09,1.202,4.083c0.574,0.492,1.427,0.955,2.606,0.955
				c0.695,0,1.504-0.16,2.437-0.567c5.564-2.433,18.565-13.933,20.864-29.137h59.845c4.916,0,8.917-4,8.917-8.917V25.039
				C280,20.123,276,16.123,271.083,16.123z M144.216,142.162c0,9.1-3.054,17.395-8.826,23.981c-1.316,1.498-2.749,2.883-4.285,4.138
				c-2.111,1.757-4.47,3.314-7.025,4.634c-4.31,2.25-9.062,3.789-14.138,4.573c-5.285,0.834-11.145,0.8-16.577-0.14
				c-6.275-1.092-11.965-3.302-16.923-6.578c-1.358-0.889-2.657-1.865-3.872-2.91c-8.132-6.934-12.61-16.771-12.61-27.699v-24.861
				c1.822,0.525,3.515,0.744,5.046,0.744c2.196,0,4.068-0.428,5.545-0.96c4.78-1.72,8.828-5.783,10.83-10.867
				c0.045-0.116,0.065-0.233,0.098-0.35c0.509-1.794-0.397-3.714-2.169-4.412c-1.887-0.743-4.019,0.184-4.761,2.07
				c-1.24,3.149-3.664,5.634-6.485,6.649c-2.465,0.888-5.187,0.654-8.104-0.686V93.636c0-17.607,11.378-31.337,28.453-36.104
				c4.236-1.182,8.818-1.822,13.669-1.822c6.449,0,12.423,1.122,17.735,3.165c0.366,0.225,0.77,0.392,1.206,0.477
				c12.677,5.314,21.283,16.056,22.904,29.587c-3.93,11.882-15.488,24.159-28.052,24.159c-11.957,0-23.07-6.932-27.652-17.251
				c-0.604-1.361-1.936-2.166-3.334-2.176c-0.506-0.003-1.019,0.092-1.511,0.311c-1.853,0.822-2.688,2.992-1.866,4.845
				c0.639,1.437,1.391,2.812,2.219,4.138c6.619,10.605,18.979,17.478,32.145,17.478c11.444,0,21.619-7.25,28.341-16.421V142.162z
				 M272.656,124.483c0,0.867-0.705,1.573-1.573,1.573h-63.183c-1.978,0-3.6,1.565-3.67,3.541
				c-0.348,9.766-6.627,17.759-11.5,22.276c1.565-15.379-5.235-24.033-5.572-24.451c-0.697-0.864-1.747-1.366-2.856-1.366H151.56
				v-32.42c0-17.993-10.346-32.89-26.166-40.27V25.039c0-0.867,0.707-1.572,1.574-1.572h144.116c0.868,0,1.573,0.705,1.573,1.572
				V124.483z"
            />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="188.01" cy="77.817" r="4.382" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="203.93" cy="77.817" r="4.382" />
            <circle style={{ fill: theme.palette.iconColor.main }} cx="219.849" cy="77.817" r="4.382" />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M113.125,152.189c-0.055,0.057-5.644,5.605-13.042,5.605c-7.137,0-11.547-6.184-11.603-6.263
				c-0.738-1.066-2.201-1.336-3.271-0.6c-1.072,0.736-1.344,2.204-0.607,3.277c0.232,0.338,5.814,8.298,15.481,8.298
				c9.416,0,16.122-6.727,16.402-7.013c0.91-0.927,0.896-2.414-0.029-3.325C115.53,151.257,114.04,151.267,113.125,152.189z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconAssistantSmile;
