import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDeath = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28">
        <g>
          <path
            style={{ fill: "#FFFFFF" }}
            d="M187.776,259.459l33.13-169.126l-44.678-66.312h-52.457L79.094,90.333l4.442,22.677
			c12.647,33.583,32.156,78.104,55.908,108.34c18.133,23.088,34.601,41.295,47.573,54.629h0.832c0.265,0,0.523,0.029,0.774,0.078
			l2.327-11.88c0,0-0.001,0-0.001,0C188.77,263.75,187.349,261.638,187.776,259.459z M161.512,99.557v78.491
			c0,3.948-3.2,7.149-7.147,7.149h-6.768c-3.948,0-7.148-3.201-7.148-7.149V99.557h-41.18c-3.946,0-7.146-3.2-7.146-7.148v-6.768
			c0-3.948,3.2-7.148,6.702-7.148h41.624V46.1c0-3.946,3.2-7.146,7.148-7.146h6.768c3.947,0,7.147,3.2,7.147,7.146v32.393h38.95
			c4.395,0,7.595,3.2,7.595,7.148v6.768c0,3.948-3.647,7.148-7.595,7.148H161.512z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M83.536,113.01l31.923,162.969h71.558c-12.973-13.334-29.44-31.541-47.573-54.629
				C115.692,191.113,96.184,146.593,83.536,113.01z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M200.462,78.492h-38.95V46.1c0-3.946-3.2-7.146-7.147-7.146h-6.768
				c-3.948,0-7.148,3.2-7.148,7.146v32.393H98.825c-3.502,0-6.702,3.2-6.702,7.148v6.768c0,3.948,3.2,7.148,7.146,7.148h41.18
				v78.491c0,3.948,3.2,7.149,7.148,7.149h6.768c3.947,0,7.147-3.201,7.147-7.149V99.557h38.95c3.947,0,7.595-3.2,7.595-7.148
				v-6.768C208.057,81.692,204.857,78.492,200.462,78.492z M199.605,93.396h-44.253v80.276c0,2.414-1.958,4.37-4.371,4.37
				s-4.372-1.956-4.372-4.37V93.396h-44.25c-2.413,0-4.372-1.958-4.372-4.372c0-2.413,1.959-4.37,4.372-4.37h44.25V49.683
				c0-2.416,1.959-4.374,4.372-4.374s4.371,1.958,4.371,4.374v34.971h44.253c2.413,0,4.369,1.957,4.369,4.37
				C203.974,91.438,202.018,93.396,199.605,93.396z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#333333" }}
              d="M150.981,178.042c2.413,0,4.371-1.956,4.371-4.37V93.396h44.253c2.413,0,4.369-1.958,4.369-4.372
				c0-2.413-1.956-4.37-4.369-4.37h-44.253V49.683c0-2.416-1.958-4.374-4.371-4.374s-4.372,1.958-4.372,4.374v34.971h-44.25
				c-2.413,0-4.372,1.957-4.372,4.37c0,2.414,1.959,4.372,4.372,4.372h44.25v80.276
				C146.609,176.086,148.568,178.042,150.981,178.042z"
            />
            <path
              style={{ fill: "#333333" }}
              d="M228.505,87.224l-46.805-69.469c-0.747-1.109-1.997-1.774-3.334-1.774h-56.735
				c-1.337,0-2.587,0.665-3.334,1.774L71.495,87.224c-0.597,0.885-0.816,1.972-0.611,3.019l37.321,190.529
				c0.37,1.887,2.023,3.248,3.945,3.248h75.699c2.22,0,4.021-1.8,4.021-4.021c0-1.956-1.397-3.581-3.247-3.942
				c-0.251-0.049-0.509-0.078-0.774-0.078h-0.832h-71.558L83.536,113.01l-4.442-22.677l44.676-66.312h52.457l44.678,66.312
				l-33.13,169.126c-0.427,2.179,0.994,4.291,3.172,4.718c0,0,0.001,0,0.001,0c2.18,0.417,4.29-0.993,4.717-3.173l33.45-170.762
				C229.322,89.195,229.102,88.108,228.505,87.224z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDeath;
