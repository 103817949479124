import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconInternet = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path
          d="M458.6,54.3h-42.7c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h42.7c21.2,0,38.4,17.3,38.4,38.5v286.4
		c0,21.2-17.2,38.5-38.4,38.5H53.4c-21.2,0-38.4-17.3-38.4-38.5v-53.1c0-4.1-3.4-7.5-7.5-7.5S0,337,0,341.1v53.1
		c0,29.5,23.9,53.5,53.4,53.5h152.3L190.5,497h-33.3c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h197.4c4.1,0,7.5-3.4,7.5-7.5
		s-3.4-7.5-7.5-7.5h-33.3l-15.1-49.3h152.3c29.4,0,53.4-24,53.4-53.5V107.8C512,78.3,488.1,54.3,458.6,54.3L458.6,54.3z M206.2,497
		l15.1-49.3h69.3l15.1,49.3H206.2z"
        />
        <path
          d="M7.5,313.7c4.1,0,7.5-3.4,7.5-7.5V107.8c0-21.2,17.2-38.5,38.4-38.5h21.8c4.1,0,7.5-3.4,7.5-7.5
		s-3.4-7.5-7.5-7.5H53.4C23.9,54.3,0,78.3,0,107.8v198.5C0,310.4,3.4,313.7,7.5,313.7z"
        />
        <path
          d="M482.1,106.5c0-13.1-10.6-23.7-23.7-23.7h-26.7c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h26.7
		c4.8,0,8.7,3.9,8.7,8.7v147.1c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V106.5z"
        />
        <path
          d="M482.1,364.5v-75.6c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v75.6c0,4.8-3.9,8.7-8.7,8.7H53.6
		c-4.8,0-8.7-3.9-8.7-8.7V106.5c0-4.8,3.9-8.7,8.7-8.7h14.1c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H53.6
		c-13.1,0-23.7,10.7-23.7,23.7v257.9c0,13.1,10.6,23.7,23.7,23.7h404.7C471.4,388.2,482.1,377.6,482.1,364.5z"
        />
        <path d="M64.1,403c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h30.6c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H64.1z" />
        <path d="M124.1,403c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h30.6c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H124.1z" />
        <path
          d="M256.4,343.8c30.5,0,60.4-8.1,86.6-23.3c25.4-14.8,46.7-36,61.7-61.3c2.1-3.6,0.9-8.2-2.6-10.3
		c-3.6-2.1-8.2-0.9-10.3,2.6c-9.2,15.5-20.9,29.3-34.7,40.8c-6.2-2.8-12.6-5.3-19.2-7.5c16-29.4,25.2-66.3,26.2-105.5h49.2
		c-0.7,14.7-3.4,29.1-8.1,43c-1.3,3.9,0.8,8.2,4.7,9.5c0.8,0.3,1.6,0.4,2.4,0.4c3.1,0,6-2,7.1-5.1c6-17.8,9.1-36.4,9.1-55.3
		C428.5,77.1,351.3,0,256.4,0c-27.4,0-53.7,6.3-77.9,18.6c-21.4,10.9-40.5,26.3-55.7,45c-2.4-0.7-4.9-1.1-7.5-1.1
		c-13.8,0-25,11.2-25,25c0,7,2.9,13.3,7.5,17.8c-8.9,21.1-13.4,43.5-13.4,66.7C84.4,266.7,161.6,343.8,256.4,343.8L256.4,343.8z
		 M99.5,179.4h49.2c1,39.2,10.2,76.1,26.2,105.5c-6.6,2.2-13,4.7-19.1,7.5C123.1,265,101.7,224.7,99.5,179.4z M169.8,302.7
		c4.3-1.7,8.7-3.3,13.1-4.7c5.1,7.6,10.6,14.3,16.4,20.1C188.9,314,179,308.9,169.8,302.7z M248.9,328.3
		c-18.5-2.5-36.1-14.5-50.7-34.5c16-3.8,33.1-6,50.7-6.5V328.3z M248.9,272.3c-20.7,0.5-40.6,3.3-59.3,8.2
		c-15.8-27.6-24.9-63.1-25.9-101.1h85.2V200c-10.1,3.2-17.5,12.7-17.5,23.8c0,11.2,7.4,20.7,17.5,23.8L248.9,272.3L248.9,272.3z
		 M263.9,328.3v-41c17.6,0.5,34.7,2.7,50.7,6.5C300,313.8,282.5,325.8,263.9,328.3z M343.2,302.8c-2.5,1.7-5.1,3.3-7.7,4.8
		c-7,4.1-14.4,7.6-22,10.6c5.8-5.8,11.3-12.5,16.5-20.1C334.5,299.5,338.9,301.1,343.2,302.8z M351.6,128.7c-5.5,0-10-4.5-10-10
		s4.5-10,10-10c5.5,0,10,4.5,10,10S357.2,128.7,351.6,128.7z M413.3,164.4h-49.2c-0.2-7.8-0.7-15.6-1.6-23.2
		c8.3-4.1,14.1-12.6,14.1-22.5c0-13.5-10.7-24.5-24.1-25c-4-12.6-8.9-24.2-14.7-34.8c6.6-2.2,13-4.7,19.1-7.5
		C389.7,78.8,411.2,119.1,413.3,164.4z M343.1,41.1c-4.3,1.7-8.7,3.3-13.1,4.7c-4.2-6.3-8.8-12-13.7-17.2c-0.9-1-1.9-2-2.8-2.9
		C323.9,29.8,333.9,35,343.1,41.1z M263.9,15.5c18.6,2.6,36.3,14.9,50.6,34.5c-16,3.8-33,6-50.6,6.5V15.5z M263.9,71.6
		c20.7-0.5,40.6-3.3,59.3-8.2C329,73.5,334,85,338.1,97.7c-6.9,4.5-11.5,12.2-11.5,21c0,12.4,9.1,22.8,21.1,24.7
		c0.8,6.9,1.2,14,1.4,21h-85.2V71.6z M263.9,179.4h85.2c-1.1,38-10.2,73.4-25.9,101.1c-18.6-4.9-38.6-7.7-59.3-8.2v-24.6
		c10.1-3.2,17.5-12.7,17.5-23.8c0-11.2-7.4-20.7-17.5-23.8V179.4z M256.4,213.8c5.5,0,10,4.5,10,10s-4.5,10-10,10
		c-5.5,0-10-4.5-10-10S250.9,213.8,256.4,213.8z M248.9,15.5v41c-17.6-0.5-34.7-2.7-50.8-6.5C212.5,30.4,230.1,18.1,248.9,15.5z
		 M185.3,32c4.7-2.4,9.5-4.5,14.3-6.4c-6,5.9-11.6,12.6-16.7,20.2c-4.5-1.4-8.9-3-13.2-4.7C174.7,37.8,179.9,34.7,185.3,32z
		 M115.3,77.4c5.5,0,10,4.5,10,10s-4.5,10-10,10s-10-4.5-10-10S109.8,77.4,115.3,77.4z M111.2,112.1c1.3,0.2,2.7,0.3,4.1,0.3
		c13.8,0,25-11.2,25-25c0-5.7-1.9-11-5.2-15.2c6.2-7.5,13.1-14.5,20.6-20.7c6.2,2.8,12.6,5.3,19.2,7.5
		c-7.3,13.4-13.4,28.8-17.9,45.8c-1.1,4,1.3,8.1,5.3,9.2c0.6,0.2,1.3,0.3,1.9,0.3c3.3,0,6.4-2.2,7.2-5.6c4.5-17,10.6-32.3,18-45.2
		c18.7,4.9,38.6,7.7,59.3,8.2v92.9h-85.2c0.2-7.8,0.8-15.5,1.7-23.1c0.5-4.1-2.5-7.8-6.6-8.3c-4.1-0.5-7.8,2.5-8.3,6.6
		c-1,8.2-1.6,16.5-1.8,24.9H99.5C100.4,146.3,104.3,128.7,111.2,112.1L111.2,112.1z"
        />
        <path
          d="M109.1,298.7H77c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h32.1c4.1,0,7.5-3.4,7.5-7.5S113.3,298.7,109.1,298.7z
		"
        />
        <path
          d="M69.5,341.2c0,4.1,3.4,7.5,7.5,7.5h52.1c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H77
		C72.8,333.7,69.5,337,69.5,341.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconInternet;
