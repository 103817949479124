import { Box, Theme } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";

type IconWrapperProps = {
  icon: string | React.ComponentType<{ sx?: SxProps<Theme> }>;
  sx?: SxProps;
  alt?: string;
};

const IconWrapper = ({ icon, sx = {}, alt = "" }: IconWrapperProps) => {
  if (typeof icon === "string") {
    return <Box component={"img"} src={icon} sx={sx} alt={alt} />;
  }

  const IconComponent = icon;
  return <IconComponent sx={sx} />;
};

export default IconWrapper;
