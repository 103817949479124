import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconPerson = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 144.47 202.31">
      <defs>
        <clipPath id="clip-path" transform="translate(-69.5 -37.58)">
          <rect x="69.5" y="37.58" width="144.47" height="202.31" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M141.59,40.08s-40,0-40,40v39.4s0,40,40,40h3.34s40,0,40-40V80.08s0-40-40-40Z"
          transform="translate(-69.5 -37.58)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M102,97.23s11.06,3.25,15.87-9.37"
          transform="translate(-69.5 -37.58)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5px", strokeLinecap: "round" }}
          d="M127.19,79.8s7.81,18,28.84,18S184.88,78,184.88,78"
          transform="translate(-69.5 -37.58)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5.3px", strokeLinecap: "round" }}
          d="M170.45,151.31s11.42,4.81,19.23,7.81,15,4.81,18,22.24"
          transform="translate(-69.5 -37.58)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5.3px", strokeLinecap: "round" }}
          d="M113,151.31s-11.42,4.81-19.23,7.81-15,4.81-18,22.24-3.6,55.89-3.6,55.89"
          transform="translate(-69.5 -37.58)"
        />
        <path
          style={{ fill: "none", stroke: getIconColor(active, theme), strokeWidth: "5.3px", strokeLinecap: "round" }}
          d="M209.39,193.36c1.43,18.15,1.93,43.89,1.93,43.89"
          transform="translate(-69.5 -37.58)"
        />
      </g>
    </SvgIcon>
  );
};

export default IconPerson;
