import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconMoney = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12">
        <g>
          <path
            style={{ fill: "none" }}
            d="M169.604,37.008H78.768c3.223,1.975,25.429,16.101,31.672,33.654h27.493
			C144.175,53.108,166.38,38.983,169.604,37.008z"
          />
          <g>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M218.842,206.643c0.605,3.81,1.392,7.873,2.393,12.254c0.845,3.7,2.26,7.249,4.154,10.653
				c4.417,0.616,9.611,1.014,15.575,1.014c20.586,0,32.042-4.721,33.798-6.898v-20.38c-9.609,4.039-23.801,5.364-33.798,5.364
				C234.545,208.649,226.397,208.101,218.842,206.643z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M233.867,240.869c7.241,7.621,16.885,14.358,27.694,20.259c7.714-1.563,12.154-3.741,13.2-5.03
				v-20.385c-9.609,4.04-23.801,5.364-33.798,5.364C238.77,241.077,236.372,241.011,233.867,240.869z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M240.963,176.22c-6.271,0-14.195-0.522-21.604-1.906c-1.704,5.934-2.361,12.849-1.8,21.328
				c5.622,1.388,13.44,2.495,23.403,2.495c20.586,0,32.042-4.721,33.798-6.897v-20.382
				C265.152,174.895,250.96,176.22,240.963,176.22z"
            />
            <path
              style={{ fill: "#FFFFFF" }}
              d="M274.035,159.466c-3.208-2.346-14.374-6.241-33.072-6.241c-3.086,0-5.963,0.108-8.637,0.295
				c-3.455,3.425-6.389,6.964-8.719,10.905c4.782,0.766,10.569,1.281,17.355,1.281C259.66,165.707,270.826,161.812,274.035,159.466z
				"
            />
          </g>
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M217.56,195.641c-0.562-8.479,0.096-15.394,1.8-21.328c-4.401-0.822-8.613-1.952-12.195-3.458
				v20.382C208.07,192.362,211.564,194.161,217.56,195.641z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M225.388,229.55c-1.894-3.404-3.309-6.953-4.154-10.653c-1-4.382-1.787-8.444-2.393-12.254
				c-4.214-0.813-8.237-1.911-11.677-3.357v20.381C208.412,225.212,214.556,228.04,225.388,229.55z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M233.867,240.869c-8.914-0.504-19.201-2.003-26.703-5.156v20.386
				c1.765,2.178,13.222,6.895,33.798,6.895c8.399,0,15.277-0.786,20.599-1.865C250.752,255.227,241.108,248.49,233.867,240.869z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M232.326,153.521c-13.529,0.95-21.759,3.987-24.436,5.945c2.043,1.494,7.323,3.615,15.717,4.959
				C225.938,160.485,228.871,156.946,232.326,153.521z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M153.974,81.175h-11.988c-0.005,0-0.011,0-0.016,0h-35.502c-0.003,0-0.007,0.001-0.01,0.001
				c-0.004,0-0.007-0.001-0.011-0.001H95.34c-5.284,0-9.583,4.299-9.583,9.583s4.299,9.582,9.583,9.582h58.634
				c5.283,0,9.582-4.298,9.582-9.582S159.257,81.175,153.974,81.175z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M285.274,159.242c0-0.465-0.08-0.907-0.193-1.336c-2.37-11.932-28.419-15.193-44.118-15.193
				s-41.748,3.261-44.118,15.193c-0.113,0.429-0.193,0.872-0.193,1.336c0,0.077,0.013,0.148,0.015,0.224
				c-0.002,0.076-0.015,0.148-0.015,0.224v31.981v32.427v32.429c0,13.402,27.853,16.978,44.311,16.978s44.311-3.576,44.311-16.978
				v-32.429v-32.427V159.69c0-0.076-0.013-0.148-0.015-0.224C285.261,159.39,285.274,159.318,285.274,159.242z M274.761,256.098
				c-1.045,1.289-5.486,3.467-13.2,5.03c-5.321,1.079-12.2,1.865-20.599,1.865c-20.576,0-32.033-4.717-33.798-6.895v-20.386
				c7.501,3.153,17.789,4.652,26.703,5.156c2.504,0.142,4.903,0.208,7.096,0.208c9.997,0,24.189-1.325,33.798-5.364V256.098z
				 M274.761,223.666c-1.756,2.177-13.212,6.898-33.798,6.898c-5.964,0-11.157-0.397-15.575-1.014
				c-10.832-1.51-16.977-4.338-18.224-5.884v-20.381c3.44,1.446,7.463,2.544,11.677,3.357c7.556,1.458,15.703,2.006,22.121,2.006
				c9.997,0,24.189-1.325,33.798-5.364V223.666z M232.326,153.521c2.674-0.188,5.551-0.295,8.637-0.295
				c18.698,0,29.864,3.895,33.072,6.241c-3.208,2.346-14.375,6.24-33.072,6.24c-6.786,0-12.574-0.515-17.355-1.281
				c-8.394-1.344-13.673-3.465-15.717-4.959C210.567,157.508,218.797,154.471,232.326,153.521z M219.359,174.313
				c7.408,1.384,15.332,1.906,21.604,1.906c9.997,0,24.189-1.325,33.798-5.364v20.382c-1.756,2.176-13.212,6.897-33.798,6.897
				c-9.963,0-17.781-1.106-23.403-2.495c-5.995-1.48-9.489-3.279-10.395-4.403v-20.382
				C210.746,172.361,214.958,173.491,219.359,174.313z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M165.422,107.251c5.218-3.633,8.646-9.667,8.646-16.493c0-11.081-9.014-20.096-20.094-20.096
				h-4.587c5.557-10.447,18.626-20.256,24.985-24.244c4.715-0.82,8.312-4.941,8.312-9.888c0-5.534-4.502-10.036-10.036-10.036
				H75.724c-5.535,0-10.038,4.502-10.038,10.036c0,4.947,3.599,9.07,8.317,9.888c6.362,3.98,19.432,13.775,24.987,24.244H95.34
				c-11.081,0-20.095,9.015-20.095,20.096c0,6.619,3.231,12.483,8.183,16.146C45.354,133.388,34.966,208.53,33.407,221.833
				c-1.406,1.385-2.88,2.788-4.316,4.155c-9.084,8.646-16.929,16.113-13.573,23.658c0.229,0.516,0.42,0.989,0.596,1.42
				c3.045,7.497,7.09,8.127,18.811,8.127h151.855c2.903,0,5.256-2.354,5.256-5.257c0-2.903-2.353-5.256-5.256-5.256H34.924
				c-5.507,0-7.744-0.238-8.609-0.474c-0.135-0.294-0.311-0.726-0.46-1.096c-0.159-0.39-0.328-0.807-0.52-1.253
				c0.787-2.53,7.148-8.583,11.004-12.254c1.938-1.845,3.943-3.753,5.827-5.658l1.306-1.322l0.187-1.848
				c0.097-0.957,10.165-95.885,53.99-113.74c0.124-0.05,0.228-0.125,0.346-0.183h52.38c0.118,0.059,0.222,0.133,0.346,0.183
				c12.921,5.264,24.213,17.888,33.563,37.523c0.901,1.892,2.785,2.998,4.75,2.998c0.758,0,1.527-0.165,2.256-0.512
				c2.621-1.248,3.734-4.385,2.486-7.006C185.793,127.277,176.286,114.964,165.422,107.251z M95.34,81.175h11.106
				c0.004,0,0.007,0.001,0.011,0.001c0.003,0,0.007-0.001,0.01-0.001h35.502h0.016h11.988c5.283,0,9.582,4.299,9.582,9.583
				s-4.298,9.582-9.582,9.582H95.34c-5.284,0-9.583-4.298-9.583-9.582S90.056,81.175,95.34,81.175z M78.768,37.008h90.836
				c-3.223,1.975-25.429,16.101-31.671,33.654H110.44C104.196,53.108,81.991,38.983,78.768,37.008z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M126.791,162.029c5.612,0,12.63,0,12.63,0l2.105-10.525c0,0-4.747,0-17.34,0
				c-9.868,0-19.609,9.902-23.369,21.05h-5.771v9.355h4.147v3.275h-4.147v9.355h5.4c3.452,11.615,13.529,22.219,23.74,22.219
				c12.593,0,17.34,0,17.34,0l-2.105-10.525c0,0-7.018,0-12.63,0c-4.181,0-11.215-4.543-14.533-11.694h20.169l2.622-9.355h-24.631
				v-3.275h24.631l2.624-9.355h-24.825C116.354,166.083,122.844,162.029,126.791,162.029z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconMoney;
