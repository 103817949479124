import React, { useState } from "react";
import icon from "../../assets/icons/generic/IconZahnrad";
import ContentBox from "../Box/ContentBox/ContentBox";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelectField from "../FormFields/ValidatorSelectField";
import { FormProvider } from "../../provider/Form/FormProvider";
import ReactGA from "react-ga4";

export default function ThemeSelect() {
  const loadedTheme = localStorage.getItem("theme") || "light";
  const [theme, setTheme] = useState(loadedTheme);

  const handleSubmit = () => {
    localStorage.setItem("theme", theme);
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const themeMode = event.target.value as string;

    setTheme(themeMode);
    ReactGA.event({
      category: "Theme",
      action: "Switched Theme to " + themeMode,
      nonInteraction: true,
    });
  };

  return (
    <>
      <FormProvider initialValues={{ themeSelect: theme }}>
        <form onSubmit={handleSubmit}>
          <ContentBox icon={icon} headline={"Light/Dark Mode wechseln"} alignContent={"stretch"}>
            <Box textAlign={"center"} marginBottom={"2rem"}>
              Legen Sie hier fest, ob Sie den Light Mode oder den Dark Mode in Ihrer Servicewelt nutzen möchten.
            </Box>

            <ValidatorSelectField label="Mode wählen" name="themeSelect" value={theme} onChange={handleChange}>
              <MenuItem value={"light"}>Light Mode</MenuItem>
              <MenuItem value={"dark"}>Dark Mode </MenuItem>
              <MenuItem value={"custom"}>Geräteeinstellung</MenuItem>
            </ValidatorSelectField>

            <Button type="submit" fullWidth variant={"contained"} color={"primary"}>
              Speichern
            </Button>
          </ContentBox>
        </form>
      </FormProvider>
    </>
  );
}
