import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconProgress = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38">
        <g>
          <path
            style={{ fill: "none" }}
            d="M240.653,23.686H59.346c-3.042,0-5.518,2.475-5.518,5.518v241.592c0,3.045,2.476,5.521,5.518,5.521
			h181.307c3.043,0,5.519-2.477,5.519-5.521V29.203C246.172,26.16,243.696,23.686,240.653,23.686z M146.321,56.223h1.178
			c2.035,0,3.685,1.648,3.685,3.684s-1.649,3.686-3.685,3.686h-1.178c-2.035,0-3.685-1.65-3.685-3.686
			S144.286,56.223,146.321,56.223z M146.321,135.391h1.178c2.035,0,3.685,1.65,3.685,3.684c0,2.035-1.649,3.686-3.685,3.686h-1.178
			c-2.035,0-3.685-1.65-3.685-3.686C142.637,137.041,144.286,135.391,146.321,135.391z M142.637,90.779
			c0-2.035,1.649-3.684,3.685-3.684h48.293c2.035,0,3.685,1.648,3.685,3.684s-1.649,3.686-3.685,3.686h-48.293
			C144.286,94.465,142.637,92.814,142.637,90.779z M146.321,214.557h1.178c2.035,0,3.685,1.65,3.685,3.684
			c0,2.035-1.649,3.686-3.685,3.686h-1.178c-2.035,0-3.685-1.65-3.685-3.686C142.637,216.207,144.286,214.557,146.321,214.557z
			 M142.637,169.947c0-2.035,1.649-3.684,3.685-3.684h48.293c2.035,0,3.685,1.648,3.685,3.684s-1.649,3.686-3.685,3.686h-48.293
			C144.286,173.633,142.637,171.982,142.637,169.947z M95.082,180.574L70.17,167.25l4.965-9.283l16.582,8.869l29.755-43.574
			l8.693,5.936L95.082,180.574z M95.082,101.406L70.17,88.08l4.966-9.281l16.581,8.869l29.755-43.574l8.693,5.938L95.082,101.406z
			 M194.614,252.799h-48.293c-2.035,0-3.685-1.65-3.685-3.686s1.649-3.684,3.685-3.684h48.293c2.035,0,3.685,1.648,3.685,3.684
			S196.649,252.799,194.614,252.799z M225.489,237.363h-79.168c-2.035,0-3.685-1.65-3.685-3.686s1.649-3.684,3.685-3.684h79.168
			c2.035,0,3.685,1.648,3.685,3.684S227.524,237.363,225.489,237.363z M225.489,221.926h-67.288c-2.035,0-3.685-1.65-3.685-3.686
			c0-2.033,1.649-3.684,3.685-3.684h67.288c2.035,0,3.685,1.65,3.685,3.684C229.174,220.275,227.524,221.926,225.489,221.926z
			 M225.489,158.195h-79.168c-2.035,0-3.685-1.65-3.685-3.686s1.649-3.684,3.685-3.684h79.168c2.035,0,3.685,1.648,3.685,3.684
			S227.524,158.195,225.489,158.195z M225.489,142.76h-67.288c-2.035,0-3.685-1.65-3.685-3.686c0-2.033,1.649-3.684,3.685-3.684
			h67.288c2.035,0,3.685,1.65,3.685,3.684C229.174,141.109,227.524,142.76,225.489,142.76z M225.489,79.029h-79.168
			c-2.035,0-3.685-1.65-3.685-3.686s1.649-3.684,3.685-3.684h79.168c2.035,0,3.685,1.648,3.685,3.684S227.524,79.029,225.489,79.029
			z M225.489,63.592h-67.288c-2.035,0-3.685-1.65-3.685-3.686s1.649-3.684,3.685-3.684h67.288c2.035,0,3.685,1.648,3.685,3.684
			S227.524,63.592,225.489,63.592z"
          />
          <g>
            <polygon
              style={{ fill: "#137F7B" }}
              points="130.166,50.031 121.472,44.094 91.717,87.668 75.136,78.799 70.17,88.08 95.082,101.406
							"
            />
            <polygon
              style={{ fill: "#137F7B" }}
              points="91.717,166.836 75.135,157.967 70.17,167.25 95.082,180.574 130.166,129.197
				121.472,123.262 			"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M240.653,16.316H59.346c-7.106,0-12.887,5.781-12.887,12.887v241.592
				c0,7.107,5.781,12.891,12.887,12.891h181.307c7.106,0,12.888-5.783,12.888-12.891V29.203
				C253.541,22.098,247.76,16.316,240.653,16.316z M246.172,270.795c0,3.045-2.476,5.521-5.519,5.521H59.346
				c-3.042,0-5.518-2.477-5.518-5.521V29.203c0-3.043,2.476-5.518,5.518-5.518h181.307c3.043,0,5.519,2.475,5.519,5.518V270.795z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.321,63.592h1.178c2.035,0,3.685-1.65,3.685-3.686s-1.649-3.684-3.685-3.684h-1.178
				c-2.035,0-3.685,1.648-3.685,3.684S144.286,63.592,146.321,63.592z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,56.223h-67.288c-2.035,0-3.685,1.648-3.685,3.684s1.649,3.686,3.685,3.686h67.288
				c2.035,0,3.685-1.65,3.685-3.686S227.524,56.223,225.489,56.223z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,71.66h-79.168c-2.035,0-3.685,1.648-3.685,3.684s1.649,3.686,3.685,3.686h79.168
				c2.035,0,3.685-1.65,3.685-3.686S227.524,71.66,225.489,71.66z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.321,94.465h48.293c2.035,0,3.685-1.65,3.685-3.686s-1.649-3.684-3.685-3.684h-48.293
				c-2.035,0-3.685,1.648-3.685,3.684S144.286,94.465,146.321,94.465z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.321,142.76h1.178c2.035,0,3.685-1.65,3.685-3.686c0-2.033-1.649-3.684-3.685-3.684h-1.178
				c-2.035,0-3.685,1.65-3.685,3.684C142.637,141.109,144.286,142.76,146.321,142.76z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,135.391h-67.288c-2.035,0-3.685,1.65-3.685,3.684c0,2.035,1.649,3.686,3.685,3.686
				h67.288c2.035,0,3.685-1.65,3.685-3.686C229.174,137.041,227.524,135.391,225.489,135.391z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,150.826h-79.168c-2.035,0-3.685,1.648-3.685,3.684s1.649,3.686,3.685,3.686h79.168
				c2.035,0,3.685-1.65,3.685-3.686S227.524,150.826,225.489,150.826z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.321,173.633h48.293c2.035,0,3.685-1.65,3.685-3.686s-1.649-3.684-3.685-3.684h-48.293
				c-2.035,0-3.685,1.648-3.685,3.684S144.286,173.633,146.321,173.633z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M146.321,221.926h1.178c2.035,0,3.685-1.65,3.685-3.686c0-2.033-1.649-3.684-3.685-3.684h-1.178
				c-2.035,0-3.685,1.65-3.685,3.684C142.637,220.275,144.286,221.926,146.321,221.926z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,214.557h-67.288c-2.035,0-3.685,1.65-3.685,3.684c0,2.035,1.649,3.686,3.685,3.686
				h67.288c2.035,0,3.685-1.65,3.685-3.686C229.174,216.207,227.524,214.557,225.489,214.557z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M225.489,229.994h-79.168c-2.035,0-3.685,1.648-3.685,3.684s1.649,3.686,3.685,3.686h79.168
				c2.035,0,3.685-1.65,3.685-3.686S227.524,229.994,225.489,229.994z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M194.614,245.43h-48.293c-2.035,0-3.685,1.648-3.685,3.684s1.649,3.686,3.685,3.686h48.293
				c2.035,0,3.685-1.65,3.685-3.686S196.649,245.43,194.614,245.43z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconProgress;
