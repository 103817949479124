import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconGuidebook = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80">
        <g>
          <path
            style={{ fill: "none" }}
            d="M143.832,180.237h23.598c-0.008-1.738-0.019-3.509-0.029-5.076h-23.539
			C143.851,176.729,143.84,178.499,143.832,180.237z"
          />
          <path
            style={{ fill: "none" }}
            d="M155.631,199.718c1.911,0,6.377-0.001,9.236-4.328h-18.472
			C149.254,199.717,153.72,199.718,155.631,199.718z"
          />
          <path
            style={{ fill: "none" }}
            d="M144.31,189.89h22.643c0.236-1.228,0.398-2.6,0.458-4.152h-23.56
			C143.912,187.29,144.073,188.662,144.31,189.89z"
          />
          <path
            style={{ fill: "none" }}
            d="M249.598,246.39V31.614c0.001-2.124-0.824-4.118-2.324-5.618c-1.5-1.501-3.496-2.327-5.62-2.327
			H73.615V246.39H249.598z M120.414,172.451l-8.332,8.328c-0.716,0.716-1.654,1.073-2.592,1.073c-0.938,0-1.877-0.358-2.593-1.074
			c-1.432-1.432-1.431-3.753,0.001-5.185l8.332-8.328c1.432-1.432,3.753-1.431,5.185,0.001
			C121.847,168.698,121.846,171.02,120.414,172.451z M204.364,180.775c-0.716,0.716-1.654,1.073-2.592,1.073
			s-1.876-0.357-2.592-1.073l-8.328-8.328c-1.432-1.432-1.432-3.754,0-5.186s3.753-1.432,5.185,0l8.328,8.328
			C205.795,177.021,205.795,179.344,204.364,180.775z M209.107,128.38h11.775c2.025,0,3.666,1.642,3.666,3.666
			s-1.641,3.666-3.666,3.666h-11.775c-2.025,0-3.666-1.642-3.666-3.666S207.083,128.38,209.107,128.38z M190.848,91.637l8.332-8.328
			c1.432-1.433,3.753-1.43,5.185,0.001c1.432,1.432,1.431,3.753-0.001,5.185l-8.332,8.328c-0.716,0.716-1.654,1.073-2.592,1.073
			c-0.938,0-1.877-0.358-2.593-1.074C189.415,95.39,189.416,93.068,190.848,91.637z M151.963,66.794
			c0-2.024,1.641-3.666,3.666-3.666s3.666,1.642,3.666,3.666v11.775c0,2.024-1.641,3.666-3.666,3.666s-3.666-1.642-3.666-3.666
			V66.794z M155.631,85.329c25.179,0,45.664,20.483,45.664,45.662c0,18.032-10.352,34.056-26.582,41.499
			c0.023,2.979,0.058,8.07,0.058,11.786c0,14.473-6.977,22.773-19.141,22.773s-19.141-8.301-19.141-22.773
			c0-3.716,0.035-8.808,0.058-11.786c-16.23-7.442-26.582-23.466-26.582-41.499C109.967,105.813,130.452,85.329,155.631,85.329z
			 M106.896,83.313c1.431-1.432,3.752-1.435,5.185-0.001l8.33,8.326c1.432,1.432,1.433,3.753,0.001,5.185
			c-0.716,0.716-1.654,1.074-2.593,1.074c-0.938,0-1.876-0.357-2.592-1.073l-8.33-8.326
			C105.464,87.066,105.464,84.745,106.896,83.313z M90.379,128.38h11.777c2.025,0,3.666,1.642,3.666,3.666s-1.641,3.666-3.666,3.666
			H90.379c-2.025,0-3.666-1.642-3.666-3.666S88.354,128.38,90.379,128.38z"
          />
          <path
            style={{ fill: "none" }}
            d="M181.896,158.961c7.579-7.09,12.066-17.093,12.066-27.97c0-20.813-16.681-37.795-37.375-38.306
			c-7.118,7.561-13.478,15.37-16.841,22.026C130.876,132.24,141.306,170.196,181.896,158.961z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M139.746,114.712c3.363-6.656,9.723-14.466,16.841-22.026c-0.319-0.008-0.636-0.024-0.957-0.024
				c-21.136,0-38.332,17.195-38.332,38.33c0,15.854,9.531,29.858,24.281,35.676c1.412,0.557,2.334,1.926,2.321,3.443
				c0,0.002-0.02,2.181-0.039,5.051H167.4c-0.02-2.871-0.039-5.051-0.039-5.051c-0.014-1.518,0.909-2.887,2.321-3.443
				c4.631-1.827,8.746-4.462,12.214-7.706C141.306,170.196,130.876,132.24,139.746,114.712z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M51.014,31.614L50.44,250.675c3.227-2.675,7.369-4.285,11.878-4.285h11.297V23.669h-15.27
				C54.166,23.669,51.014,27.085,51.014,31.614z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M90.379,135.712h11.777c2.025,0,3.666-1.642,3.666-3.666s-1.641-3.666-3.666-3.666H90.379
				c-2.025,0-3.666,1.642-3.666,3.666S88.354,135.712,90.379,135.712z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M115.227,96.824c0.716,0.716,1.654,1.073,2.592,1.073c0.938,0,1.877-0.358,2.593-1.074
				c1.432-1.432,1.431-3.753-0.001-5.185l-8.33-8.326c-1.433-1.434-3.754-1.431-5.185,0.001c-1.432,1.432-1.431,3.753,0.001,5.185
				L115.227,96.824z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M155.629,82.235c2.025,0,3.666-1.642,3.666-3.666V66.794c0-2.024-1.641-3.666-3.666-3.666
				s-3.666,1.642-3.666,3.666v11.775C151.963,80.594,153.604,82.235,155.629,82.235z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M115.23,167.266l-8.332,8.328c-1.432,1.432-1.433,3.753-0.001,5.185
				c0.716,0.716,1.654,1.074,2.593,1.074c0.938,0,1.876-0.357,2.592-1.073l8.332-8.328c1.432-1.432,1.433-3.753,0.001-5.185
				C118.984,165.835,116.663,165.834,115.23,167.266z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M196.036,167.262c-1.432-1.432-3.753-1.432-5.185,0s-1.432,3.754,0,5.186l8.328,8.328
				c0.716,0.716,1.654,1.073,2.592,1.073s1.876-0.357,2.592-1.073c1.432-1.432,1.432-3.754,0-5.186L196.036,167.262z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M193.439,97.896c0.938,0,1.876-0.357,2.592-1.073l8.332-8.328
				c1.432-1.432,1.433-3.753,0.001-5.185c-1.431-1.431-3.752-1.434-5.185-0.001l-8.332,8.328c-1.432,1.432-1.433,3.753-0.001,5.185
				C191.563,97.537,192.501,97.896,193.439,97.896z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M209.107,135.712h11.775c2.025,0,3.666-1.642,3.666-3.666s-1.641-3.666-3.666-3.666h-11.775
				c-2.025,0-3.666,1.642-3.666,3.666S207.083,135.712,209.107,135.712z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M136.548,172.49c-0.023,2.979-0.058,8.07-0.058,11.786c0,14.473,6.977,22.773,19.141,22.773
				s19.141-8.301,19.141-22.773c0-3.716-0.035-8.808-0.058-11.786c16.23-7.443,26.582-23.467,26.582-41.499
				c0-25.179-20.485-45.662-45.664-45.662s-45.664,20.483-45.664,45.662C109.967,149.024,120.318,165.048,136.548,172.49z
				 M166.952,189.89H144.31c-0.236-1.228-0.398-2.6-0.458-4.152h23.56C167.35,187.29,167.188,188.662,166.952,189.89z
				 M155.631,199.718c-1.911,0-6.377-0.001-9.236-4.328h18.472C162.008,199.717,157.542,199.718,155.631,199.718z M193.963,130.991
				c0,10.877-4.487,20.88-12.066,27.97c-3.469,3.244-7.583,5.879-12.214,7.706c-1.412,0.557-2.334,1.926-2.321,3.443
				c0,0,0.02,2.18,0.039,5.051c0.011,1.567,0.021,3.338,0.029,5.076h-23.598c0.008-1.738,0.019-3.509,0.029-5.076
				c0.02-2.87,0.039-5.049,0.039-5.051c0.014-1.518-0.909-2.887-2.321-3.443c-14.75-5.817-24.281-19.821-24.281-35.676
				c0-21.135,17.196-38.33,38.332-38.33c0.321,0,0.638,0.017,0.957,0.024C177.282,93.196,193.963,110.178,193.963,130.991z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M62.318,253.722H256.93V31.616c0.002-4.081-1.585-7.918-4.471-10.805
				c-2.885-2.886-6.722-4.475-10.805-4.475H58.346c-8.223,0-14.664,6.711-14.664,15.268l-0.611,233.41l0.611,0.002
				c0,0.002,0,0.005,0,0.008c0,10.277,8.36,18.639,18.637,18.639h190.945v-7.332H62.318c-6.233,0-11.305-5.072-11.305-11.307
				C51.014,258.792,56.085,253.722,62.318,253.722z M247.274,25.996c1.5,1.5,2.325,3.494,2.324,5.618V246.39H73.615H62.318
				c-4.51,0-8.651,1.61-11.878,4.285l0.574-219.061c0-4.529,3.152-7.945,7.332-7.945h15.27h168.039
				C243.778,23.669,245.773,24.495,247.274,25.996z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconGuidebook;
