import React from "react";
import PropTypes from "prop-types";
import ContentBox from "../../Box/ContentBox/ContentBox";
import { Box, Button, Link, Rating, Typography } from "@mui/material";
import iconReview from "../../../assets/icons/serviceOverview/IconReview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { getThirdPartyCustomerRatingPlatform } from "../../../services/customerFeedbackService";

export default function CustomerFeedbackProvenExpertBox({ customerFeedback, product }) {
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();
  const { ratingPlatform, ratingPlatformLink } = getThirdPartyCustomerRatingPlatform(customerFeedback.caseId);
  const hideBox = async () => {
    await updateMutation.mutateAsync({
      uri: "/customer_feedbacks",
      id: customerFeedback.id,
      data: { showProvenExpertRatingRequest: false },
    });

    await queryClient.invalidateQueries(
      queryKeys.collection("/customer_feedbacks", {
        source: "fullReview",
        caseId: product.id,
      })
    );
  };

  return (
    <ContentBox icon={iconReview} headline={"Danke für Ihre Bewertung!"}>
      <Box sx={{ textAlign: "center", marginBottom: 1 }}>
        <Rating name="stars" value={5} size="large" disabled />
      </Box>
      <Typography paragraph sx={{ color: "primary.main", fontWeight: 700, textAlign: "center" }}>
        Helfen Sie jetzt auch anderen Kunden, sich zu orientieren!
      </Typography>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Über das Bewertungsportal {ratingPlatform} können Sie die Unterstützung durch Legalbird öffentlich sichtbar darstellen und bewerten.
      </Typography>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Dies dauert nur eine Minute und hilft anderen Rechtsuchenden bei der Auswahl der richtigen Kanzlei.
      </Typography>
      <Button
        fullWidth
        component={Link}
        href={ratingPlatformLink}
        variant={"contained"}
        target={"_blank"}
        onClick={hideBox}
        sx={{ marginBottom: "1rem", textAlign: "center" }}
      >
        Auf {ratingPlatform} bewerten
      </Button>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Wir bedanken uns im Voraus und unterstützen Sie in Zukunft jederzeit gerne wieder!
      </Typography>
      <Button onClick={hideBox} fullWidth>
        Nicht bewerten
      </Button>
    </ContentBox>
  );
}
CustomerFeedbackProvenExpertBox.propTypes = {
  customerFeedback: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};
