import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconBird = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91">
        <g>
          <path style={{ fill: "none" }} d="M216.862,236.441c0,0,1.273,5.477,12.805,4.76C229.667,241.201,229.667,231.906,216.862,236.441z" />
          <path
            style={{ fill: "#60C0B8" }}
            d="M114.872,97.352c-5.775-3.294-12.964-7.539-21.69-13.713
			C68.771,66.352,60.559,47.281,57.925,34.566c0,0-0.002-0.002-0.002-0.002S56.551,56.9,68.844,73.398
			c12.295,16.5,44.324,39.145,31.705,61.791c0,0-0.645-18.766-14.557-29.764S54.608,75.992,52.67,67.254
			c0,0-4.527,19.412,17.146,39.145c21.672,19.732,30.086,23.291,27.498,47.877c0,0-4.209-13.605-18.117-24.109
			c-13.91-10.504-23.943-20.531-24.586-28.297c0,0-2.264,19.084,18.117,35.908C93.11,154.6,96.02,156.539,94.727,174.656
			c0,0-4.529-9.383-14.557-17.793c-10.029-8.41-20.381-15.848-20.381-18.115c0,0,0,14.557,14.557,25.232
			c14.559,10.676,22.322,11.324,21.674,32.998c0,0-11.645-10.027-17.793-14.557c-6.146-4.529-10.676-8.734-10.676-8.734
			s11.344,21.674,19.26,30.084s17.945,16.822,22.471,21.998c0,0-8.408-34.938,1.621-71.17
			C119.692,122.843,127.185,114.938,114.872,97.352z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M241.752,244.268c-0.258-0.229-0.516-0.459-0.764-0.688c-6.824-6.293-9.455-12.83-21.998-14.191
			c-12.996-1.383-43.641,11.146-65.934-3.234c-21.074-13.607-11.477-78.518-8.352-100.283c2.434-16.953,6.5-32.523-10.205-45.053
			S66.713,37.645,56.95,20c0,0-0.85,5.759,0.975,14.566c2.634,12.715,10.847,31.785,35.257,49.072
			c8.726,6.174,15.915,10.419,21.69,13.713c21.572,12.302,23.363,11.289,11.275,47.547c0,0-13.467,61.287-5.117,79.402
			c8.355,18.088,23.234,13.469,23.234,19.967c0,6.502-6.969,1.383-19.525,2.766C112.209,248.447,87.594,260.973,69.95,280
			c0,0,81.256-20.41,109.576-24.143c28.348-3.705,59.904-6.5,67.316,2.322C246.842,258.18,250.852,252.207,241.752,244.268z
			 M216.862,236.441c12.805-4.535,12.805,4.76,12.805,4.76C218.135,241.918,216.862,236.441,216.862,236.441z"
          />
        </g>
      </g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconBird;
