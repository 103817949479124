import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconParkingBill = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path style={{ fill: "#137F7B", strokeWidth: "6.9px" }} d="M230.9,231.48v45.29a45.29,45.29,0,0,1,0-90.58Z" />
        </>
      )}
      <g style={{ fill: "none", stroke: getIconColor(active, theme), strokeLinecap: "round", strokeLinejoin: "round" }}>
        <path
          style={{ strokeWidth: "6.9px" }}
          d="M173.84,276.67H35.75a12.46,12.46,0,0,1-12.42-12.42V36.33A12.46,12.46,0,0,1,35.75,23.9H263.67a12.46,12.46,0,0,1,12.42,12.43V174.47"
        />
        <path style={{ strokeWidth: "8.28px" }} d="M108.14,65.13h52a47.43,47.43,0,0,1,47.29,47.29h0a47.43,47.43,0,0,1-47.29,47.29H126.5" />
        <line style={{ strokeWidth: "8.31px" }} x1="108.03" y1="219.88" x2="108.08" y2="65.13" />
        <path style={{ strokeWidth: "6.9px" }} d="M183.91,206.66a53.15,53.15,0,1,0,14.89-17.53" />
        <path style={{ strokeWidth: "6.9px" }} d="M250.25,255.8a36.59,36.59,0,0,1-21.14,0c-16-4.89-24.3-19.74-18.47-33.17s23.57-20.36,39.61-15.47" />
        <line style={{ strokeWidth: "5.53px" }} x1="238.81" y1="236.06" x2="198.74" y2="236.06" />
        <line style={{ strokeWidth: "5.53px" }} x1="238.81" y1="225.66" x2="198.74" y2="225.66" />
      </g>
    </SvgIcon>
  );
};

export default IconParkingBill;
