import React from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconKreuz";
import PhoneIcon from "../../../../assets/icons/contract/toggleable/IconPhone";
import InternetIcon from "../../../../assets/icons/contract/toggleable/IconInternet";
import QuestionMarkIcon from "../../../../assets/icons/common/toggleable/IconFragezeichen";
import { translate } from "../../../../services/translations/translations";
import { useForm } from "../../../../provider/Form/FormProvider";
import Group from "../../../Wizard/Group";
import { requiredValidator } from "../../../../services/validationRules";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";
import wizardStyle from "../../wizardStyle";

export default function SubscriptionContractDetails({ submitProps, product }: ContractDetailsProps) {
  const { handleSubmit, values } = useForm();
  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} sx={{ ...wizardStyle.applicationForm, ...wizardStyle.wizardForm }} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} sx={wizardStyle.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="wasDeceived"
                question="Wurden Sie aus Ihrer Sicht beim Abschluss des Abonnements getäuscht?"
                options={[
                  {
                    labelText: "Ja",
                    labelIcon: <CheckIcon />,
                    labelIconActive: <CheckIcon active />,
                    optionHintText: "Ich wurde meiner Meinung nach getäuscht und war mir über den Abschluss des Abonnements nicht bewusst.",
                    value: "yes",
                  },
                  {
                    labelText: "Nein",
                    labelIcon: <CrossIcon />,
                    labelIconActive: <CrossIcon active />,
                    optionHintText:
                      "Ich wurde beim Abschluss nicht getäuscht, habe aber ein anderes Anliegen bezüglich eines Abonnements, bei dem ich rechtlichen Beistand benötige.",
                    value: "no",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="salesChannel"
                question="Bitte geben Sie hier an, wo Sie das Abonnement abgeschlossen haben"
                options={[
                  {
                    labelText: translate(`${product.name}.salesChannel.options.phone.label`, values),
                    labelIcon: <PhoneIcon />,
                    labelIconActive: <PhoneIcon active />,
                    value: "phone",
                  },
                  {
                    labelText: translate(`${product.name}.salesChannel.options.online.label`, values),
                    labelIcon: <InternetIcon />,
                    labelIconActive: <InternetIcon active />,
                    value: "online",
                  },
                  {
                    labelText: translate(`${product.name}.salesChannel.options.other.label`, values),
                    labelIcon: <QuestionMarkIcon />,
                    labelIconActive: <QuestionMarkIcon active />,
                    optionHintText: "Zum Beispiel vor Ort an einem Verkaufsstand oder auf schriftlichem Weg per Post.",
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12} sx={[wizardStyle.gridTopSpaced, wizardStyle.question]}>
              <FormLegend>Wie lautet der Name oder die Beschreibung des Abonnements / der Serviceleistung?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label="Name des Abonnements"
                name="subscriptionName"
                validators={[requiredValidator("Bitte geben Sie den Namen an")]}
                helperText="Zum Beispiel: Zeitungsabo Rheinische Post"
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </Box>
      </FormFader>
    </Paper>
  );
}
