import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconHelp = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g>
        <path
          style={{ fill: theme.palette.iconColor.main }}
          d="m215.144 409.225v-19.982c0-.078-.001-.157-.003-.235-.364-15.471-.741-31.468-5.183-48.134-10.128-37.893-32.084-58.816-73.408-69.961l-.671-.182c-1.162-.313-2.588-.696-3.107-.917-.191-.201-.719-.832-1.803-2.595l-26.044-42.238c-4.992-8.117-12.825-13.451-21.444-15.509l-6.245-80.556c-1.472-18.794-15.961-32.995-33.724-33.033-18.008 0-32.546 14.211-33.817 33.059l-9.581 142.571c-.007.102-.012.203-.016.305-1.382 37.703 11.877 49.72 31.947 67.908l.385.35c2.533 2.296 5.517 4.847 8.676 7.548 17.362 14.843 43.6 37.274 43.6 58.892v2.709c-10.087 3.258-17.405 12.729-17.405 23.88v36.301c0 13.836 11.264 25.093 25.108 25.093h12.558c5.523 0 10-4.478 10-10s-4.477-10-10-10h-12.557c-2.769 0-5.108-2.332-5.108-5.093v-36.301c0-2.761 2.339-5.093 5.108-5.093h115.03c2.77 0 5.109 2.332 5.109 5.093v36.301c0 2.809-2.292 5.093-5.109 5.093h-12.643c-5.523 0-10 4.478-10 10s4.477 10 10 10h12.643c13.845 0 25.109-11.257 25.109-25.093v-36.301c0-11.151-7.318-20.622-17.405-23.88zm-20-1.213h-90.438v-1.496c0-30.831-30.452-56.866-50.603-74.094-3.045-2.604-5.922-5.063-8.24-7.164l-.387-.351c-18.661-16.912-26.404-23.932-25.396-52.207l9.571-142.414c.554-8.211 6.513-14.402 13.84-14.402 8.314.018 13.239 7.356 13.806 14.587l6.214 80.152c-2.131.781-4.215 1.781-6.222 3.013-16.25 10.01-21.342 31.372-11.348 47.626l42.208 68.497c2.897 4.702 9.058 6.165 13.76 3.268s6.165-9.058 3.268-13.76l-42.203-68.489c-4.218-6.859-2.063-15.883 4.791-20.104 6.879-4.219 15.908-2.074 20.132 4.795l26.042 42.234c5.22 8.497 9.11 10.286 16.742 12.342l.656.177c39.698 10.706 52.417 30.057 59.301 55.812 3.817 14.319 4.174 29.063 4.51 43.327v18.651z"
        />
        <path
          style={{ fill: theme.palette.iconColor.main }}
          d="m511.897 271.818c-.004-.102-.009-.203-.016-.305l-9.581-142.573c-1.271-18.846-15.811-33.057-33.839-33.057-17.741.038-32.229 14.239-33.702 33.041l-6.244 80.546c-8.618 2.057-16.449 7.387-21.438 15.5l-26.061 42.264c-1.075 1.75-1.602 2.379-1.793 2.58-.52.221-1.945.604-3.106.917l-.662.179c-41.334 11.147-63.29 32.071-73.418 69.97-4.443 16.664-4.819 32.659-5.183 48.128-.002.079-.003.157-.003.235v19.982c-10.086 3.258-17.404 12.729-17.404 23.879v36.301c0 13.836 11.264 25.093 25.108 25.093h115.03c13.845 0 25.108-11.257 25.108-25.093v-36.301c0-11.15-7.318-20.621-17.404-23.879v-2.709c0-21.618 26.238-44.05 43.601-58.894 3.158-2.7 6.142-5.25 8.674-7.546l.386-.35c20.069-18.188 33.328-30.205 31.947-67.908zm-87.203 197.587c0 2.761-2.34 5.093-5.108 5.093h-115.03c-2.769 0-5.108-2.332-5.108-5.093v-36.301c0-2.761 2.34-5.093 5.108-5.093h115.03c2.769 0 5.108 2.332 5.108 5.093zm41.825-144.498-.387.351c-2.317 2.101-5.193 4.56-8.238 7.163-20.151 17.228-50.604 43.263-50.604 74.095v1.496h-90.438v-18.65c.335-14.262.691-29.006 4.509-43.323 6.884-25.761 19.604-45.111 59.311-55.82l.648-.175c7.631-2.056 11.521-3.844 16.73-12.326l26.058-42.259c2.894-4.707 8.053-7.191 13.226-6.927.014.001.028 0 .042.001 2.353.127 4.708.822 6.852 2.137 3.319 2.04 5.647 5.268 6.555 9.087.904 3.805.283 7.714-1.754 11.014l-42.208 68.497c-2.897 4.702-1.435 10.862 3.268 13.76 4.705 2.898 10.863 1.435 13.76-3.268l42.203-68.49c4.844-7.845 6.331-17.128 4.189-26.138-2.145-9.024-7.664-16.66-15.548-21.505-2.002-1.228-4.081-2.226-6.207-3.005l6.214-80.142c.567-7.239 5.491-14.578 13.784-14.596 7.349 0 13.309 6.191 13.862 14.4l9.57 142.416c1.007 28.275-6.736 35.295-25.397 52.207z"
        />
        <path
          style={{ fill: theme.palette.iconColor.main }}
          d="m375.61 170.088c34.692-35.894 34.692-94.299 0-130.193-14.789-15.3-35.048-23.225-57.089-22.328-20.895.855-41.075 9.434-56.816 24.147l-1.051.981c-1.597 1.492-3.324 3.106-4.656 4.259-1.332-1.152-3.06-2.767-4.656-4.259l-1.044-.976c-15.749-14.719-35.927-23.297-56.82-24.153-22.016-.89-42.291 7.029-57.062 22.325-34.725 35.896-34.725 94.304 0 130.2.081.083.163.165.247.246l112.394 108.366c1.937 1.867 4.439 2.801 6.941 2.801s5.003-.934 6.94-2.8l112.422-108.365c.085-.082.168-.166.25-.251zm-119.611 87.526-45.226-43.605c1.307-14.331 9.459-27.268 21.828-34.755 6.87 4.043 14.864 6.373 23.398 6.373 8.533 0 16.528-2.33 23.398-6.373 12.371 7.488 20.524 20.431 21.828 34.767zm-26.257-118.204c0-14.455 11.778-26.216 26.255-26.216s26.255 11.761 26.255 26.216c0 14.456-11.778 26.217-26.255 26.217-14.476 0-26.255-11.761-26.255-26.217zm88.384 58.318c-4.29-12.968-12.593-24.391-23.769-32.518 4.983-7.373 7.896-16.253 7.896-25.8 0-25.483-20.75-46.216-46.255-46.216s-46.255 20.732-46.255 46.216c0 9.547 2.913 18.427 7.896 25.8-11.172 8.124-19.474 19.543-23.765 32.505l-43.2-41.652c-27.188-28.238-27.15-74.079.124-102.272 21.744-22.521 60.252-21.38 85.849 2.546l1.039.971c6.922 6.47 11.496 10.743 18.312 10.743s11.39-4.273 18.313-10.743l1.046-.977c25.586-23.916 64.108-25.053 85.873-2.537 27.245 28.189 27.284 74.031.118 102.272z"
        />
        <path
          style={{ fill: theme.palette.iconColor.main }}
          d="m149.868 474.498c-5.523 0-10 4.478-10 10s4.477 10 10 10h.057c5.523 0 9.972-4.478 9.972-10s-4.506-10-10.029-10z"
        />
      </g>
    </SvgIcon>
  );
};

export default IconHelp;
