import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconGeldUndLeistungEinfordern = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 1077.29 512.08">
      <g style={{ fill: getIconColor(active, theme) }}>
        <path d="M473.5,259.4a37.14,37.14,0,0,0-15.5,3.5,38.05,38.05,0,0,0-47.5-10.6l-.5.3-15.7,9.3a37.91,37.91,0,0,0-46.6-9.6l-.6.4-60.2,36.9c-13.6-4-28.7-8.4-43.3-12.6-15.4-4.4-30.2-8.4-42-11.4-5.9-1.5-11.1-2.7-15.4-3.6-2.1-.4-4-.8-5.7-1a25.58,25.58,0,0,0-4.7-.4,20.68,20.68,0,0,0-3.5.3c-4.4.6-10.7,1.9-18.2,3.7-15.2,3.6-34.6,8.8-45.3,11.7V258.2a23,23,0,0,0-22.9-22.9h-63A22.52,22.52,0,0,0,0,257.9V435.2a23,23,0,0,0,22.9,22.9H85.8a23,23,0,0,0,22.9-22.9V421.4c13.6,3,43.7,9.5,75.5,16,21.5,4.4,43.7,8.8,62.1,12.2,9.2,1.7,17.4,3.1,24.1,4a116.21,116.21,0,0,0,15.7,1.6,35.06,35.06,0,0,0,8.9-1.4,121.47,121.47,0,0,0,19.6-7.9c25.9-12.6,63-36.2,98-59.6s67.7-46.4,83.7-57.4c10-6.9,15.7-18.6,15.7-31A38.45,38.45,0,0,0,473.5,259.4ZM91.5,435.2a5.76,5.76,0,0,1-5.7,5.7H22.9a5.76,5.76,0,0,1-5.7-5.7V257.9a5.76,5.76,0,0,1,5.7-5.7H85.8a5.76,5.76,0,0,1,5.7,5.7ZM486.6,314.7c-19.7,13.6-63.8,44.7-106.5,72.5-21.3,13.9-42.3,26.8-59.5,36.3-8.6,4.7-16.3,8.5-22.4,11.1a64.66,64.66,0,0,1-7.9,2.8,18.79,18.79,0,0,1-4.3.8,130.49,130.49,0,0,1-13.2-1.4c-22.5-3.3-64.7-11.7-101.1-19.3-18.2-3.8-34.9-7.4-47.1-10-7.1-1.5-12.6-2.7-16-3.5V293.8c1.7-.5,3.9-1.1,6.5-1.8,9-2.5,22.8-6.1,35.2-9.2,6.2-1.5,12-2.9,16.7-3.9,2.3-.5,4.3-.9,5.9-1.1a15.31,15.31,0,0,1,2.7-.3s.9.1,2.2.2c4.7.7,14.4,3,26.3,6.1,35.6,9.3,91.8,26.1,114.5,33,7.9,2.4,15,5.9,19.6,10.3a24.45,24.45,0,0,1,5.3,7,19.13,19.13,0,0,1,1.9,8.6A24.24,24.24,0,0,1,321.3,367l-22.1-2.2-75.6-9.7a8.57,8.57,0,1,0-2.2,17l75.8,9.7,23.5,2.3h.4a41.59,41.59,0,0,0,41.5-41.5,37.46,37.46,0,0,0-3.5-15.9A43.34,43.34,0,0,0,344,309.8a75.67,75.67,0,0,0-20.4-9.4c-4-1.2-9-2.7-14.7-4.5l46.6-28.5a20.79,20.79,0,0,1,23.7,3.3l-23,13.6a8.61,8.61,0,1,0,8.8,14.8l53.4-31.7a20.82,20.82,0,0,1,9.4-2.4,21.33,21.33,0,0,1,15.1,6.4l-62.2,36.9a8.61,8.61,0,1,0,8.8,14.8l67.2-39.9a14.62,14.62,0,0,0,2-.5,8.84,8.84,0,0,0,3.4-2.6l2.1-1.2a20.82,20.82,0,0,1,9.4-2.4,21.57,21.57,0,0,1,15.1,6.2,21.15,21.15,0,0,1,6.2,15.1C494.8,305.1,491.5,311.3,486.6,314.7Z" />
        <path d="M314.1,183.9a59.65,59.65,0,0,1-30.2,8.2,60.65,60.65,0,0,1-42.8-17.7,59.9,59.9,0,0,1-11.4-16h65.1a8.6,8.6,0,0,0,0-17.2H224.2a58,58,0,0,1,0-19.2h70.6a8.6,8.6,0,0,0,0-17.2H229.7a60.11,60.11,0,0,1,54.2-33.7,59.65,59.65,0,0,1,30.2,8.2,8.56,8.56,0,0,0,8.6-14.8,77.35,77.35,0,0,0-111.6,40.4H200.3a8.6,8.6,0,0,0,0,17.2h6.6a76.1,76.1,0,0,0,0,19.2H200.3a8.6,8.6,0,0,0,0,17.2h6.8a77.35,77.35,0,0,0,111.6,40.4A8.56,8.56,0,0,0,314.1,183.9Z" />
        <path d="M969.5,55.3H684.2c-58.6,0-106.1,47.6-106.1,106.1V350.7c0,58.6,47.6,106.1,106.1,106.1H969.5c58.6,0,106.1-47.6,106.1-106.1V161.4C1075.6,102.9,1028.1,55.3,969.5,55.3Zm88.9,295.4c0,48.9-39.8,88.7-88.7,88.7H684.2c-48.9,0-88.7-39.8-88.7-88.7V161.4c0-48.9,39.8-88.7,88.7-88.7H969.5c48.9,0,88.7,39.8,88.7,88.7Z" />
        <path d="M862.2,197.5H736a8.57,8.57,0,1,0,0,17.1h107.4L735.3,324.4a8.57,8.57,0,1,0,12.1,12.1L855.5,226.7V335.5a8.57,8.57,0,0,0,17.1,0V205.3A8.33,8.33,0,0,0,862.2,197.5Z" />
      </g>
    </SvgIcon>
  );
};

export default IconGeldUndLeistungEinfordern;
