import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconTruck = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      {!active && (
        <>
          <path
            style={{ fill: "#137F7B" }}
            d="M192.47,148.1l-159.94,0c-26.64,0-29.36,54.8-19.06,54.58l16.9-.36c3.74-19.5,17.84-25.94,31.45-22.3,10.88,2.91,15.53,15.19,15.42,22.3H192.47Z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M107.94,148.46H288.65V191c0,5-7.31,11.63-14.1,11.63H270c-3.74-19.5-17.84-25.94-31.44-22.3-10.89,2.91-15.54,15.2-15.42,22.3H107.94Z"
          />
        </>
      )}
      <g style={{ fillRule: "evenodd" }}>
        <g
          style={{
            fill: "none",
            stroke: getIconColor(active, theme),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6.9px",
          }}
        >
          <path d="M7.75,202.64H30.82v-.28m62.27.28H207.83m74.72-2.1c4.86-1.84,9.09-5,9.09-9.7V91h-164M99.19,147.93H36.09c-27.69,0-26.58,22.25-28.34,54.71" />
          <path d="M47.72,147.93l7.11-30.86c1.52-6.59,11.54-8.76,18.31-8.76h20" />
          <path d="M76.32,210.34a23.42,23.42,0,1,1,0-16" />
          <path d="M268.63,210.37a23.43,23.43,0,1,1,0-16" />
          <polyline points="107.94 182.96 107.94 74.82 77.29 74.82" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconTruck;
