/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { translate } from "../../../../services/translations/translations";
import { requiredValidator } from "../../../../services/validationRules";
import Group from "../../../Wizard/Group";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconHaken";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconKreuz";

export default function CreditRatingProblems() {
  const { values, errors } = useForm();
  const charCount = values.personalAffection?.creditRatingProblemsDescription?.length || 0;
  const descriptionHasError = (charCount > 0 && charCount < 50) || typeof errors["personalAffection.creditRatingProblemsDescription"] !== "undefined";

  return (
    <>
      <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
        <Grid item xs={12}>
          <Group
            type="radio"
            name="personalAffection.hadProblemsDueToCreditRating"
            question={translate("dataLeakContract.hadProblemsDueToCreditRating.question", values)}
            options={[
              {
                labelText: "Ja",
                labelIcon: <CheckIcon />,
                labelIconActive: <CheckIcon active />,
                value: "yes",
              },
              {
                labelText: "Nein",
                labelIcon: <CrossIcon />,
                labelIconActive: <CrossIcon active />,
                value: "no",
              },
            ]}
            validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
          />
        </Grid>
        {values.personalAffection.hadProblemsDueToCreditRating === "yes" && (
          <ValidatorTextField
            name="personalAffection.creditRatingProblemsDescription"
            label={translate("dataLeakContract.creditRatingProblemsDescription.label", values)}
            placeholder={translate("dataLeakContract.creditRatingProblemsDescription.placeholder", values)}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={10}
            inputProps={{ minLength: 50, maxLength: 500 }}
            error={descriptionHasError}
            helperText={
              "[" + charCount + "/500] " + (descriptionHasError ? translate("dataLeakContract.creditRatingProblemsDescription.errors.tooShort", values) : "")
            }
            validators={[requiredValidator("Bitte geben Sie einen Text ein")]}
          />
        )}
      </Grid>
    </>
  );
}
