import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconVerkehrsrecht = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58">
        <g>
          <path
            style={{ fill: "none" }}
            d="M164.734,20.886h-29.763c-6.22,0-10.734,4.078-10.734,9.695v2.197c0,5.617,4.514,9.693,10.734,9.693
			h29.763c6.22,0,10.734-4.076,10.734-9.693v-1.07c0-0.01-0.002-0.018-0.002-0.025s0.002-0.016,0.002-0.023v-1.078
			C175.468,24.964,170.954,20.886,164.734,20.886z"
          />
          <path
            style={{ fill: "#137F7B" }}
            d="M94.745,114.415c-0.247,0.311-0.484,0.629-0.699,0.959c-0.936,1.439-1.51,3.021-1.709,4.711v6.096
			h6.35c0.866-6.066,6.082-10.752,12.385-10.752c5.187,0,9.897,3.26,11.72,8.115c0.344,0.916-0.12,1.938-1.036,2.281
			c-0.207,0.078-0.418,0.109-0.627,0.109v2.02h4.698c0-0.002,0-0.004,0-0.004c0-0.979,0.793-1.773,1.772-1.773h42.415
			c0.979,0,1.772,0.795,1.772,1.773c0,0,0,0.002,0,0.004h2.779c0-6.906,5.617-12.525,12.522-12.525c5.189,0,9.9,3.26,11.723,8.115
			c0.345,0.916-0.119,1.938-1.036,2.281c-0.207,0.078-0.418,0.109-0.626,0.109v2.02h3.161c0-0.717,0.433-1.393,1.145-1.66
			c1.195-0.455,3.688-1.877,3.83-3.389v-8.49H94.745z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M241.791,210.003c-1.958,0-3.545,1.586-3.545,3.545v57.104c0,2.711-2.206,4.918-4.918,4.918
				H66.662c-2.711,0-4.917-2.207-4.917-4.918V92.896c0-1.957-1.587-3.545-3.545-3.545s-3.544,1.588-3.544,3.545v177.756
				c0,6.621,5.386,12.008,12.006,12.008h166.667c6.621,0,12.008-5.387,12.008-12.008v-57.104
				C245.336,211.589,243.749,210.003,241.791,210.003z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M233.326,28.138h-54.315c-0.078,0-0.151,0.018-0.229,0.023c-1.16-6.311-6.805-10.82-14.048-10.82
				h-29.763c-7.243,0-12.889,4.512-14.048,10.82c-0.08-0.004-0.156-0.023-0.238-0.023H66.654c-6.616,0-11.999,5.383-11.999,12
				v37.293c0,1.959,1.586,3.545,3.544,3.545s3.545-1.586,3.545-3.545V40.138c0-2.707,2.202-4.91,4.909-4.91h54.031
				c0.082,0,0.158-0.018,0.239-0.023c1.162,6.307,6.807,10.813,14.047,10.813h29.763c7.24,0,12.885-4.506,14.047-10.813
				c0.077,0.006,0.151,0.023,0.229,0.023h54.315c2.718,0,4.929,2.203,4.929,4.91v155.506c0,1.957,1.587,3.545,3.545,3.545
				s3.545-1.588,3.545-3.545V40.138C245.345,33.521,239.953,28.138,233.326,28.138z M175.468,32.778
				c0,5.617-4.514,9.693-10.734,9.693h-29.763c-6.22,0-10.734-4.076-10.734-9.693v-2.197c0-5.617,4.514-9.695,10.734-9.695h29.763
				c6.22,0,10.734,4.078,10.734,9.695v1.078c0,0.008-0.002,0.016-0.002,0.023s0.002,0.016,0.002,0.025V32.778z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.729,192.397h121.283c0.979,0,1.772-0.793,1.772-1.771s-0.793-1.773-1.772-1.773H87.729
				c-0.979,0-1.772,0.795-1.772,1.773S86.75,192.397,87.729,192.397z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M85.957,171.437c0,0.979,0.793,1.771,1.772,1.771h121.283c0.979,0,1.772-0.793,1.772-1.771
				s-0.793-1.773-1.772-1.773H87.729C86.75,169.663,85.957,170.458,85.957,171.437z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.967,227.226H98.893c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.075
				c0.979,0,1.772-0.793,1.772-1.771S198.946,227.226,197.967,227.226z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.967,208.04H98.893c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.075
				c0.979,0,1.772-0.793,1.772-1.771S198.946,208.04,197.967,208.04z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M182.631,84.493c-0.333-0.34-0.79-0.531-1.266-0.531h-38.434c-5.854,0-8.669,2.361-12.006,5.697
				c-2.95,2.951-11.1,10.32-12.91,11.955c-7.35,0.58-21.449,3.35-26.942,11.83c-1.257,1.932-2.021,4.066-2.271,6.348
				c-0.007,0.064-0.011,0.129-0.011,0.193v7.969c0,0.979,0.793,1.771,1.772,1.771h8.122c0.866,6.068,6.082,10.756,12.385,10.756
				c5.201,0,9.911-3.268,11.722-8.133c0.341-0.916-0.125-1.938-1.042-2.279c-0.918-0.342-1.938,0.125-2.28,1.043
				c-1.296,3.484-4.672,5.824-8.399,5.824c-4.707,0-8.572-3.643-8.944-8.258c0.101-0.221,0.159-0.465,0.159-0.725
				s-0.059-0.504-0.159-0.727c0.372-4.613,4.237-8.254,8.944-8.254c3.718,0,7.094,2.338,8.401,5.816
				c0.267,0.709,0.94,1.143,1.656,1.145c0.208,0,0.42-0.031,0.627-0.109c0.916-0.344,1.38-1.365,1.036-2.281
				c-1.823-4.855-6.533-8.115-11.72-8.115c-6.304,0-11.52,4.686-12.385,10.752h-6.35v-6.096c0.199-1.689,0.773-3.271,1.709-4.711
				c0.214-0.33,0.452-0.648,0.699-0.959c6.16-7.758,22.007-9.383,26.086-9.383h37.635c0.979,0,1.772-0.795,1.772-1.773V92.505
				c0-0.979-0.793-1.773-1.772-1.773s-1.772,0.795-1.772,1.773v8.982h-33.258c3.222-2.93,7.859-7.184,9.997-9.32
				c3.168-3.168,5.138-4.66,9.5-4.66h37.689l9.633,9.824c0.686,0.699,1.808,0.709,2.507,0.025c0.699-0.686,0.71-1.809,0.024-2.508
				L182.631,84.493z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M127.599,126.177c-0.979,0-1.772,0.795-1.772,1.773c0,0,0,0.002,0,0.004
				c0.002,0.977,0.794,1.768,1.772,1.768h42.415c0.977,0,1.77-0.791,1.772-1.768c0-0.002,0-0.004,0-0.004
				c0-0.979-0.793-1.773-1.772-1.773H127.599z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M205.283,122.905c-0.141,1.512-2.635,2.934-3.83,3.389c-0.712,0.268-1.145,0.943-1.145,1.66
				c0,0.207,0.03,0.416,0.107,0.621c0.267,0.713,0.942,1.152,1.659,1.152c0.207,0,0.418-0.037,0.623-0.115
				c0.599-0.225,5.863-2.316,6.126-6.531c0.003-0.037,0.004-0.072,0.004-0.109v-19.713c0-0.979-0.794-1.771-1.773-1.771h-40.623
				c-0.979,0-1.772,0.793-1.772,1.771s0.793,1.773,1.772,1.773h38.851v9.383V122.905z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M187.086,118.974c3.72,0,7.098,2.338,8.404,5.816c0.267,0.709,0.94,1.143,1.656,1.145
				c0.208,0,0.42-0.031,0.626-0.109c0.917-0.344,1.381-1.365,1.036-2.281c-1.823-4.855-6.534-8.115-11.723-8.115
				c-6.905,0-12.522,5.619-12.522,12.525s5.617,12.527,12.522,12.527c5.199,0,9.911-3.268,11.725-8.131
				c0.342-0.918-0.125-1.938-1.042-2.281c-0.916-0.34-1.938,0.125-2.28,1.043c-1.299,3.482-4.676,5.824-8.403,5.824
				c-4.95,0-8.977-4.029-8.977-8.982S182.137,118.974,187.086,118.974z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconVerkehrsrecht;
