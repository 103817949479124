import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconWareEinfordern = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 512 512">
      <g>
        <g>
          <path style={{ fill: getIconColor(active, theme) }} d="M156,370c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10C166,374.5,161.5,370,156,370z" />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M450.1,190l58.9-58.9c2.5-2.5,3.5-6.1,2.6-9.5c-0.8-3.4-3.4-6.1-6.8-7.1L361.5,70.2C348.5,28.6,310.1,0,266,0
			c-41,0-77.6,25-92.8,62.6L7,114.5c-3.3,1-5.9,3.8-6.7,7.2c-0.8,3.4,0.2,7,2.6,9.5l60.5,60.5l-38.1,63.6c-1.6,2.6-1.9,5.8-0.8,8.6
			c1.1,2.8,3.3,5.1,6.2,6L86,288.2V442c0,4.2,2.6,7.9,6.5,9.4c33.8,12.7,159.5,59.8,160.2,60.1c2,0.7,4.2,0.8,6.4,0.1
			c0,0,0.1,0,0.1,0l180-60c4.1-1.4,6.8-5.2,6.8-9.5V284.1l59-18.5c3.3-1,5.9-3.8,6.7-7.2c0.8-3.4-0.2-7-2.6-9.5L450.1,190z M366,100
			c0-2.5-0.1-5.1-0.3-7.6l117.5,36.3l-49.9,49.9l-81.5-27.2C361,136,366,118.2,366,100z M190.3,74c11.1-32.3,41.5-54,75.7-54
			c36.8,0,68.7,24.9,77.6,60.5c1.6,6.3,2.4,12.9,2.4,19.5c0,18.6-6.2,36-17.8,50.4C312.9,169.2,290.2,180,266,180
			c-26.2,0-50.8-12.9-65.7-34.4c-9.3-13.4-14.3-29.2-14.3-45.6C186,91.1,187.5,82.3,190.3,74z M28.8,128.6l138.3-43.1
			c-0.7,4.8-1.1,9.6-1.1,14.5c0,16.1,3.8,31.7,11.1,45.8l-98.4,32.8L28.8,128.6z M48.8,254.7L80.4,202L241,255.5l-31.6,52.7
			C192.2,302.5,67.1,260.8,48.8,254.7z M246,487.6l-140-52.5V294.9c110.1,36.5,104.8,35.4,107.8,35.4c3.4,0,6.7-1.8,8.6-4.9
			l23.6-39.3V487.6z M256,239.5L107.6,190l80.8-26.9c18.9,23.2,47.4,36.9,77.6,36.9c27.9,0,54.2-11.5,73.1-31.8l65.3,21.8L256,239.5
			z M426,434.8l-160,53.3V278.5l35.5,44.3c2.6,3.2,6.9,4.5,10.8,3.3L426,290.4V434.8z M312.8,305l-40-50l160.5-53.5l50,50L312.8,305
			z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M219.5,392.2l-22.2-8.3c-5.2-1.9-10.9,0.7-12.9,5.9c-1.9,5.2,0.7,10.9,5.9,12.9l22.2,8.3
			c5.2,1.9,10.9-0.7,12.9-5.9C227.3,399.9,224.7,394.1,219.5,392.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M219.5,434.9l-60-22.5c-5.2-1.9-10.9,0.7-12.9,5.9s0.7,10.9,5.9,12.9l60,22.5c1.2,0.4,2.3,0.6,3.5,0.6
			c4,0,7.9-2.5,9.4-6.5C227.3,442.6,224.7,436.9,219.5,434.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: getIconColor(active, theme) }}
            d="M323.1,62.9c-3.9-3.9-10.2-3.9-14.1,0L256,115.9l-12.9-12.9c-3.9-3.9-10.2-3.9-14.1,0s-3.9,10.2,0,14.1l20,20
			c3.9,3.9,10.2,3.9,14.1,0l60-60C327,73.2,327,66.8,323.1,62.9z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconWareEinfordern;
